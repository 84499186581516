<template>
    <div v-show="activeVerifyModal" class="verify-code-modal-overlay">
        <div class="verify-code-modal">
            <div class="verify-code-modal__header">
                <img v-if="canClose" class="verify-code-modal__header-back" src="@/assets/images/icons/arrow-left-icon.svg" alt="back"
                     @click="open('auth')">
                <img v-if="canClose" class="verify-code-modal__header-close" src="@/assets/images/icons/close-icon.svg" alt="close"
                     @click="close">
            </div>
            <img src="@/assets/images/logo.png" alt="logo" class="verify-code-modal__logo">
            <div class="verify-code-modal__title">{{ type === 'email' ? $t('auth.enter-email-code', {'email': login}) : $t('auth.enter-phone-code', {'phone': login}) }}</div>
            <form id="verify-form" class="verify-code-modal__form">
                <div class="verify-code-modal__fieldset">
                    <input type="tel" class="verify-code-modal__code" @input="checkInputs" required>
                    <input type="tel" class="verify-code-modal__code" @input="checkInputs" required>
                    <input type="tel" class="verify-code-modal__code" @input="checkInputs" required>
                    <span class="verify-code-modal__code-delimiter">-</span>
                    <input type="tel" class="verify-code-modal__code" @input="checkInputs" required>
                    <input type="tel" class="verify-code-modal__code" @input="checkInputs" required>
                    <input type="tel" class="verify-code-modal__code" @input="checkInputs" required>
                </div>
                <div class="verify-code-modal__error">{{ $t('auth.invalid-code') }}</div>
                <div v-if="!isResendDisabled" class="verify-code-modal__resend" @click="resendCode">{{ $t('auth.resend-code') }}</div>
                <div v-else class="verify-code-modal__resend">Следующая попытка через {{formatTimer}}</div>
            </form>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
var timer = null;
export default {
    name: "VerifyCodeModal",
    data() {
        return {
            login: '',
            code: '',
            type: '',
            isResendDisabled: false,
            resendTimeRemaining: 0,
            canClose: true,
        }
    },
    mounted() {
        const inputElements = [...document.querySelectorAll('input.verify-code-modal__code')]

        inputElements.forEach((ele, index) => {
            ele.addEventListener('keydown', (e) => {
                // if the keycode is backspace & the current field is empty
                // focus the input before the current. Then the event happens
                // which will clear the "before" input box.
                if (e.keyCode === 8 && e.target.value === '') inputElements[Math.max(0, index - 1)].focus()
            })
            ele.addEventListener('input', (e) => {
                // take the first character of the input
                // this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
                // but I'm willing to overlook insane security code practices.
                const [first, ...rest] = e.target.value
                e.target.value = first ?? '' // first will be undefined when backspace was entered, so set the input to ""
                const lastInputBox = index === inputElements.length - 1
                const didInsertContent = first !== undefined

                if (didInsertContent && !lastInputBox) {
                    // continue to input the rest of the string
                    inputElements[index + 1].focus()
                    inputElements[index + 1].value = rest.join('')
                    inputElements[index + 1].dispatchEvent(new Event('input'))
                }
            })
        })
    },
    computed: {
        ...mapGetters({
            isActive: 'modalManager/active',
            modalName: 'modalManager/opened',
            parameters: 'modalManager/parameters'
        }),
        activeVerifyModal() {
            return this.isActive && this.modalName === 'verify-code'
        },
        formatTimer() {
            const minutes = Math.floor(this.resendTimeRemaining / 60);
            const remainingSeconds = this.resendTimeRemaining % 60;
            return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        }
    },
    watch: {
        activeVerifyModal() {
            this.login = ''
            this.type = ''
            this.code = ''
            this.isResendDisabled = false
            this.resendTimeRemaining = 0
            this.canClose = true
            clearInterval(timer);
            document.getElementById('verify-form').reset()
        },
        parameters(newParam) {
            this.login = newParam?.login
            this.type = newParam?.type
            this.canClose = newParam?.canClose
        }
    },
    methods: {
        ...mapActions({
            setUserInfo: 'user/setUserInfo',
            setAuthorized: 'user/setAuthorized',
            setToken: 'user/setToken',
            close: 'modalManager/close',
            open: 'modalManager/open',
            setParameters: 'modalManager/setParameters',
            showPreloader: 'modalManager/showPreloader'
        }),
        checkInputs() {
            const inputElements = [...document.querySelectorAll('input.verify-code-modal__code')]
            this.code = inputElements.map(({value}) => value).join('')

            if (this.code.length === 6) {
                this.verifyCode()
            }
        },
        showUsername() {
            this.setParameters({login: this.login, code: this.code, type: this.type})
            this.open('username')
            this.showPreloader(false)
        },
        verifyCode() {
            const formData = new FormData();
            let url = ''
            if (this.type === 'email') {
                formData.append('email', this.login);
                url = '/auth/verify-email-code'
            } else {
                formData.append('phone', this.login);
                url = '/auth/verify-phone-code'
            }
            formData.append('code', this.code);
            this.showPreloader(true)

            this.axios.post(url, formData).then(response => {
                if (response.data.status) {
                    document.getElementById('verify-form').classList.remove("error");
                    if (response.data.user_exist) {
                        this.authorize()
                    } else {
                        this.showUsername()
                    }
                }
            }).catch(error => {
                document.getElementById('verify-form').classList.add("error");
                document.getElementById('verify-form').reset();
                console.error(error)
                this.showPreloader(false)
            })
        },
        resendCode() {
            let self = this;
            const formData = new FormData();
            let url = ''
            if (this.type === 'email') {
                formData.append('email', this.login);
                url = '/auth/send-email-code'
            } else {
                formData.append('phone', this.login);
                url = '/auth/send-phone-code'
            }
            this.showPreloader(true)
            this.axios.post(url, formData).then(() => {
                document.getElementById('verify-form').classList.remove("error");
                self.startTimer();
            }).catch(error => {
                console.error(error)
            }).finally(() => {
                this.showPreloader(false)
            })
        },
        authorize() {
            const formData = new FormData();
            let url = '';
            if (this.type === 'email') {
                formData.append('email', this.login);
                url = '/auth/login-email';
            } else {
                formData.append('phone', this.login);
                url = '/auth/login-phone';
            }
            formData.append('code', this.code);

            this.axios.post(url, formData).then(response => {
                localStorage.setItem('token', response.data.token)
                this.setUserInfo(response.data.user)
                this.setAuthorized(true)
                this.setToken(response.data.token)
                this.close()
                if (this.$route.query.redirect) {
                    this.$router.push(this.$route.query.redirect)
                }
            }).catch(error => {
                console.error(error)
            }).finally(() => {
                this.showPreloader(false)
            })
        },
        startTimer() {
            this.isResendDisabled = true;
            this.resendTimeRemaining = 60; // Устанавливаем время в секундах
            let self = this
            timer = setInterval(() => {
                self.resendTimeRemaining--;
                if (self.resendTimeRemaining === 0) {
                    clearInterval(timer);
                    self.isResendDisabled = false;
                }
            }, 1000);
        },
    }
}
</script>

<style scoped>

</style>