import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n/i18n'
// import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    beforeEnter(to, from, next) {
      next(i18n.locale)
    }
  },
  {
    path: '/finliteracy',
    name: 'finliteracy',
    beforeEnter(to, from, next) {
      next(i18n.locale)
    }
  },
  {
    path: '/:locale',
    component: {
      render(h) {
        return h('router-view');
      },
    },
    beforeEnter(to, from, next) {
      let locale = to.params.locale

      if (i18n.availableLocales.includes(locale)) {
        if (i18n.locale !== locale) {
          i18n.locale = locale
        }

        return next()
      }

      return next({ path: i18n.locale })
    },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../views/HomeView.vue'),
      },
      {
        path: 'course/:slug',
        name: 'course',
        component: () => import('../views/CourseView.vue'),
      },
      {
        path: 'lessons/:courseSlug/:lessonSlug',
        name: 'lesson',
        meta: { requiresAuth: true },
        component: () => import('../views/LessonView.vue'),
      },
      {
        path: 'lessons/:courseSlug/:lessonSlug/test',
        name: 'test',
        meta: { requiresAuth: true },
        component: () => import('../views/TestView.vue')
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('../views/ProfileView.vue')
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
})

export default router
