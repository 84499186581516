import Vue from 'vue'
import Vuex from 'vuex'
import courses from './modules/courses'
import faq from './modules/faq'
import modalManager from './modules/modalManager'
import user from './modules/user'

Vue.use(Vuex)

const debug = process.env.APP_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    courses,
    faq,
    modalManager,
    user
  },
  strict: debug,
})
