<template>
  <div v-show="activeLessonsModal" class="lessons-modal-overlay">
    <div class="lessons-modal">
      <div class="lessons-modal__header">
        <div class="lessons-modal__header-title">{{ course.name }}</div>
        <div class="lessons-modal__header-close">
          <img class="lessons-modal__close" src="@/assets/images/icons/close-icon.svg" alt="close" @click="close">
        </div>
      </div>
      <div class="lessons-modal__mobile">
          <div class="lessons-modal__mobile-list">
              <div @click="goToCourse(course.slug)" class="lessons-modal__mobile-item">
                  <img src="@/assets/images/icons/logo-icon.svg" alt="logo" class="lessons-modal__mobile-item-icon">
                  <div class="lessons-modal__mobile-item-title">{{ $t('lesson-modal.return-course') }}</div>
                  <img src="@/assets/images/icons/arrow-right-icon.svg" alt="arrow" class="lessons-modal__mobile-item-arrow">
              </div>
              <div @click="goToProfile()" class="lessons-modal__mobile-item">
                  <img src="@/assets/images/icons/profile-icon-header.svg" alt="profile" class="lessons-modal__mobile-item-icon">
                  <div class="lessons-modal__mobile-item-title">{{ $t('lesson-modal.enter-profile') }}</div>
                  <img src="@/assets/images/icons/arrow-right-icon.svg" alt="arrow" class="lessons-modal__mobile-item-arrow">
              </div>
          </div>
      </div>
      <div class="course-lessons">
          <div class="course-lessons__title">{{ $t('course.lessons') }}</div>
          <div class="course-lessons__list">
              <div v-for="lesson in course.lessons"
                   :key="lesson.id"
                   @click="goToLesson(course.slug, lesson.slug)"
                   class="course-lessons__item"
                   :class="{'course-lessons__item_opened' : canAccess(lesson.id)}"
              >
                  <div class="course-lessons__item-number">{{ lesson.number }}</div>
                  <div class="course-lessons__item-content">
                      <div class="course-lessons__item-name">{{ lesson.name }}</div>
                      <div class="course-lessons__item-time">{{ lesson.duration }}</div>
                  </div>
                  <div class="course-lessons__item-status"></div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "LessonsModal",
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
        isActive: 'modalManager/active',
        modalName: 'modalManager/opened',
        authorized: 'user/authorized',
        getCourseById: 'courses/getCourseById',
        parameters: 'modalManager/parameters'
    }),
    activeLessonsModal() {
        return this.isActive && this.modalName === 'lessons'
    },
    course() {
      return this.getCourseById(this.parameters.courseId)
    },
  },
  methods: {
    ...mapActions({
        close: 'modalManager/close',
    }),
    canAccess(lessonId) {
      if (lessonId === 1) {
        return true
      } else if (this.authorized) {
        return true
      }

      return false
    },
    goToLesson(courseSlug, lessonSlg) {
      this.close()
      this.$router.push({
        name: 'lesson',
        params: {
          courseSlug: courseSlug,
          lessonSlug: lessonSlg
        }
      })
    },
    goToCourse(courseSlug) {
      this.close()
      this.$router.push({
          name: 'course',
          params: {
              slug: courseSlug
          }
      })
    },
    goToProfile() {
      this.close()
      this.$router.push({
          name: 'profile',
      })
    }
  }
}
</script>

<style scoped>

</style>