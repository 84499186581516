import i18n from '../../i18n/i18n'

const state = () => ({
    description: {
        ru: "<h2>👋</h2>\n" +
            "<h2>Сәлем, инвесторы!</h2>\n" +
            "<p>Мы — независимая платформа знаний о деньгах, инвестициях и финансовой грамотности. Рассказываем, как заработать, сохранить и приумножить капитал. У нас вы можете прочитать полезные статьи о деньгах и пройти курсы — о сложных финансах простым языком.</p>\n" +
            "<p>Мы называем наших читателей инвесторами, и это неслучайно: инвестиции — это не только про сложный фондовый рынок, это про вклад в себя, улучшение своей жизни, образование и, конечно, будущее. Наше медиа — тоже инвестиция, сегодня вы читаете текст и учитесь планировать бюджет, завтра — применяете знания на практике и достигаете финансовых высот.</p>",
        kz: "<h2>👋</h2>\n" +
            "<h2>Сәлем, инвесторлар!</h2>\n" +
            "<p>Біз — ақша, инвестиция мен қаржылық сауат туралы тәуелсіз білім платформасымыз. Капитал көбейту мен сақтауды үйретеміз. Бізден ақша туралы пайдалы мақалалар оқып, түрлі курстардан өте аласыз. Қаржы туралы қиын тақырыпты тілге жеңіл түсіндіреміз.</p>\n" +
            "<p>Біз оқырмандарымызды инвестор деп атаймыз. Оның себебі: инвестиция деген түсінуге қиын қор нарығы ғана емес, бұл — өзіңізге инвестиция құю, тұрмысыңызды жақсарту, білім алу мен болашаққа жоспар құру. Біздің медиа да инвестиция: бүгін бюджет туралы оқығаныңызды ертең қажетіңізге жаратасыз, капиталыңызды арттырасыз.</p>",
    },
    questions: {
        ru: [
            {
                id: 1,
                name: 'Как всё устроено',
                active: false,
                description: "<p>Мы верим, что знания бесценны, поэтому все наши курсы можно пройти совершенно бесплатно. В каждом курсе от 5 уроков в текстовом формате, один урок займёт не больше 30 минут вашего времени. Сразу после сможете пройти небольшой тест, который поможет вам определить, насколько информация была усвоена. Ответили неправильно — не волнуйтесь. Мы не оставим вас гадать, где ошибка, а сразу дадим объяснение.</p>\n" +
                    "              <p>Редакция выбирает темы, которые напрямую или косвенно касаются денег — вы найдёте курсы как об инвестициях, так и о том, как справиться с выгоранием, потому что оно тоже влияет на наши финансы.</p>",
            },
            {
                id: 2,
                name: 'Как пройти курс',
                active: false,
                description: "<p>Наши курсы только онлайн. Первый урок открыт для всех. Чтобы получить доступ ко второму и последующим, необходимо пройти регистрацию (только один раз) — ввести почту и пароль. Это нужно, чтобы мы сохраняли ваши достижения в личном кабинете.</p>\n" +
                    "              <p>Мы не будем назойливо присылать спамные письма на вашу почту, только полезные подборки и важные обновления.</p>",
            },
            {
                id: 3,
                name: 'На сайте только курсы',
                active: false,
                description: "<p>Сайт сейчас в тестовом режиме, поэтому в доступе только курсы — мы хотим узнать, интересна ли аудитории тема финансов, получить обратную связь после первых курсов и выяснить, как ещё можем быть полезны. Мы открыты к обсуждению, если курс вам понравился, или наоборот — пожалуйста, сообщите нам любым удобным способом: в Telegram-канале, по почте или в Instagram.</p>",
            },
            {
                id: 4,
                name: 'Какие ещё будут курсы',
                active: false,
                description: "<p>Их будет много — про инвестиции, карьеру, жизнь, здоровье, детей, путешествия. И каждый курс будет непременно связан с финансами: с подсчётами, примерами и упором на пользу.</p>\n" +
                    "<p>Редакция собирает полезную информацию, советуется с экспертами, оформляет визуальную часть — все, чтобы обучение было максимально удобным для читателя. Для этого нам нужно время, периодичность выпуска курсов — 1-2 месяца.</p>"
            },
            {
                id: 5,
                name: 'Как и по каким вопросам можно связаться с редакцией',
                active: false,
                description: "<p>Мы хотим сделать курсы максимально полезными, если вы заметили ошибку в тексте или у вас появились вопросы, пожалуйста, напишите нам на почту — hello@saleminvestor.kz. Мы постараемся ответить в самое ближайшее время.</p>\n" +
                    "<p>Мы не проводим консультации и не делаем разбор ваших личных финансов. Если у вас есть идеи, которые вы бы хотели прочитать у нас, или предложение о сотрудничестве — вы автор или рекламодатель — то оставьте соответствующую пометку, чтобы письмо не затерялось.</p>\n" +
                    "<p>Если письмо не имеет ценности — мы оставляем за собой право не отвечать на него.</p>",
            },
        ],
        kz: [
            {
                id: 1,
                name: 'Сабақтардың құрылымы',
                active: false,
                description: "<p>Біз білім баға жетпейтін ресурс дегенге сенеміз. Сондықтан курсымыз тегін. Әр сабақ мәтін түрінде жасалған. Бір сабаққа көп дегенде 30 минут кетеді. Әр сабақтың аяғында кішігірім тест беріледі. Содан өтіп, ақпаратты қаншалықты ұққаныңызды анықтай аласыз. Қате жауап берсеңіз, қапаланбаңыз. Қай жері қате екен деп отырмайсыз, оны бірден көрсетеміз. Редакция шығарған материалдар — ақшаға тікелей не жанама қатысты тақырыптар. Инвестиция туралы курс та, қаражатымыз бен ақша табу қабілетімізге әсер ететін қажу туралы мақалалар да бар.</p>",
            },
            {
                id: 2,
                name: 'Курстан қалай өтесіз?',
                active: false,
                description: "<p>Біздің курстан тек онлайн өтуге болады. Бірінші сабақ бәріне ашық болады. Өзге сабақтарды көру үшін тіркеуден (бір-ақ рет) өтуіңіз қажет. Бар-жоғы пошта мен құпиясөз енгізу керек: курста жеткен жетістігіңізді жазып отырамыз.</p>\n" +
                    "              <p>Қорықпаңыз, поштаңызға неше түрлі хат жіберіп, мазаңызды алмаймыз. Тек пайдалы әрі маңызды деген хаттар жібереміз.</p>",
            },
            {
                id: 3,
                name: 'Сайтта тек курстар болады',
                active: false,
                description: "<p>Сайт қазір сынақтан өтіп жатыр, сондықтан онда әзірге тек курстар болады. Қаржы тақырыбына қызықсаңыз, айтыңыз, ескереміз. Сізге тағы қандай пайдамыз тиерін білгіміз келеді. Курс туралы айтар сыныңыз не ұсынысыңыз болса, өзіңізге ыңғайлы тәсілмен бізге хабарласып айтыңыз: Telegram-тағы арнадан, поштадан я Instagram-нан.</p>",
            },
            {
                id: 4,
                name: 'Тағы қандай курстар болады?',
                active: false,
                description: "<p>Ондай курстың түр-түрі болады: инвестиция, мансап жолы, өмір, денсаулық, балалар мен саяхат туралы. Әрбір курс міндетті түрде қаржыға қатысты болады: онда есептейміз, мысал келтіреміз, пайдалы дегеннің бәрін қосамыз.</p>\n" +
                    "<p>Редакция курс дайындарда пайдалы ақпарат жинап, сарапшылармен ақылдасады. Визуалдық жағын да назардан тыс қалдырмайды. Бәрі оқырманға ыңғайлы болсын деп істеледі. Ол үшін бізге уақыт керек, сонда курс 1-2 ай сайын шығып тұрады.</p>"
            },
            {
                id: 5,
                name: 'Редакцияға қандай мәселе я сұрақ туғанда хабарласуға болады?',
                active: false,
                description: "<p>Біз курстарымыз пайдалы әрі қатесіз болса екен дейміз. Сондықтан мәтіндерімізден қате тауып жатсаңыз не сұрақ туындаса, біздің поштаға жазыңыз: hello@saleminvestor.kz. Сізге тез арада жауап беруге тырысамыз.</p>\n" +
                    "<p>Біз бюджетіңізді реттеуге қатысты ақыл-кеңес бермейміз, ескере жүріңіз. Идея ұсынам я серіктес болғым келеді десеңіз, яғни автормын я жарнама берушімін десеңіз, хат жолдаңыз. Хат көрінбей қалмасын десеңіз, тақырыбын міндетті түрде жазыңыз.</p>\n" +
                    "<p>Хатыңыздан назар аударуға тұрарлық ештеңе таппасақ, жауап бермеуіміз мүмкін.</p>",
            },
        ]
    }
})

// getters
const getters = {
    getQuestions: (state) => {
        return state.questions[i18n.locale]
    },
    getDescription: (state) => {
        return state.description[i18n.locale]
    }
}

// actions
const actions = {}

// mutations
const mutations = {
    toggleQuestion(state, id) {
        const el = state.questions[i18n.locale].find(question => question.id === id)
        el.active = !el.active
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}