<template>
  <div>
    <router-view></router-view>
    <auth-modal></auth-modal>
    <verify-code-modal></verify-code-modal>
    <username-modal></username-modal>
    <loader-modal></loader-modal>
    <lessons-modal></lessons-modal>
  </div>
</template>

<script>
import i18n from '@/i18n/i18n'
import AuthModal from "@/components/AuthModal"
import VerifyCodeModal from "@/components/VerifyCodeModal.vue";
import UsernameModal from "@/components/UsernameModal.vue";
import LoaderModal from "@/components/LoaderModal.vue";
import LessonsModal from "@/components/LessonsModal.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'app',
    components: {
        UsernameModal,
        VerifyCodeModal,
        AuthModal,
        LoaderModal,
        LessonsModal
    },
    computed: {
        ...mapGetters({
            userInfo: 'user/userInfo',
            authorized: 'user/authorized',
        }),
        locale() {
            return i18n.locale
        }
    },
    watch: {
        locale() {
            this.$router.replace({params: {locale: this.locale}}).catch(() => {
            })

            this.axios.defaults.headers.common = {
              'X-Localization': this.$route.params.locale,
            }
        }
    },
    mounted() {
        let token = localStorage.getItem('token')

        this.axios.defaults.headers.common = {
            'X-Localization': this.locale,
        }

        if (!this.authorized && token) {
            this.showPreloader(true)
            this.axios.get('/get-user-info', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(response => {
                this.setUserInfo(response.data.user)
                this.setAuthorized(true)
                this.setToken(token)
            }).catch(error => {
                console.error(error)
            }).finally(() => {
                this.showPreloader(false)
            })
        }
    },
    methods: {
        ...mapActions({
            setUserInfo: 'user/setUserInfo',
            setAuthorized: 'user/setAuthorized',
            setToken: 'user/setToken',
            showPreloader: 'modalManager/showPreloader',
            open: 'modalManager/open',
        }),
    }
}
</script>

<style lang="scss">
#app {
  width: 100%;
}
</style>
