const state = () => ({
    userInfo: null,
    token: null,
    authorized: false,
    canAccessLesson: false,
})

// getters
const getters = {
    userInfo: (state) => state.userInfo,
    authorized: (state) => state.authorized,
    token: (state) => state.token,
}

// actions
const actions = {
    setUserInfo: ({commit}, userInfo) => commit('SET_USER_INFO', userInfo),
    setAuthorized: ({commit}, authorized) => commit('SET_AUTHORIZED', authorized),
    setToken: ({commit}, token) => commit('SET_TOKEN', token),
    logout: ({commit}) => commit('LOGOUT'),
    canAccessLesson: ({state, commit}, lessonId) => {
        commit('CAN_ACCESS_LESSON', lessonId)

        return state.canAccessLesson
    }
}

// mutations
const mutations = {
    SET_USER_INFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
    SET_AUTHORIZED: (state, authorized) => {
        state.authorized = authorized
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    CAN_ACCESS_LESSON: (state, lessonId) => {
        state.canAccessLesson = lessonId === 1
    },
    LOGOUT: (state) => {
        state.authorized = false
        state.token = null
        state.userInfo = null
        localStorage.setItem('token', '')
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}