const state = () => ({
    opened: '',
    active: false,
    parameters: {},
    preloader: false,
})

// getters
const getters = {
    active: (state) => state.active,
    opened: (state) => state.opened,
    parameters: (state) => state.parameters,
    preloader: (state) => state.preloader
}

// actions
const actions = {
    open: ({commit}, name) => commit('OPEN', name),
    close: ({commit}, name) => commit('CLOSE', name),
    setParameters: ({commit}, parameters) => commit('SET_PARAMETERS', parameters),
    showPreloader: ({commit}, preloader) => commit('SHOW_PRELOADER', preloader),
}

// mutations
const mutations = {
    OPEN: (state, name) => {
        state.opened = name
        state.active = true
    },
    CLOSE: (state) => {
        state.open = ''
        state.active = false
    },
    SET_PARAMETERS: (state, parameters) => state.parameters = parameters,
    SHOW_PRELOADER: (state, preloader) => {
        state.preloader = preloader
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}