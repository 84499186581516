<template>
  <div v-show="activeAuthModal" class="auth-modal-overlay">
    <div class="auth-modal">
      <img v-if="canClose" class="auth-modal__close" src="@/assets/images/icons/close-icon.svg" alt="close" @click="close">
      <img class="auth-modal__logo" src="@/assets/images/logo.png" alt="logo">
      <div class="auth-modal__title">{{ $t('auth.sign-in-to-continue') }}</div>
      <form @submit.prevent="submitForm" class="auth-modal__form">
        <label v-if="type === 'email'" for="email" class="auth-modal__form-raw auth-modal__form-raw_email">
          <div class="auth-modal__form-raw-switch" @click="toggleSwitcher">
            <img class="auth-modal__form-raw-icon" src="@/assets/images/icons/email-icon.svg" alt="icon">
            <img class="auth-modal__form-raw-switch-icon" :class="{reversed: showSwitcher === true}" src="@/assets/images/icons/arrow-down-icon.svg" alt="icon">
          </div>
          <input id="email" v-model="login" :placeholder="$t('auth.enter-email')" type="email" class="auth-modal__form-input" required>
        </label>
        <label v-else for="phone" class="auth-modal__form-raw auth-modal__form-raw_phone">
          <div class="auth-modal__form-raw-switch" @click="toggleSwitcher">
            <img class="auth-modal__form-raw-icon" src="@/assets/images/icons/phone-icon.svg" alt="icon">
            <img class="auth-modal__form-raw-switch-icon" :class="{reversed: showSwitcher === true}" src="@/assets/images/icons/arrow-down-icon.svg" alt="icon">
          </div>
          <input id="phone" v-mask="'+7 (###) ###-##-##'" v-model="login" :placeholder="$t('auth.enter-phone')" type="text" class="auth-modal__form-input" required>
        </label>
        <div v-if="showSwitcher" class="auth-modal__form-type-switcher">
          <div class="auth-modal__form-type-switcher-item" :class="{active: type === 'phone'}" @click="setType('phone')">
            <img src="@/assets/images/icons/phone-icon.svg" alt="icon" class="auth-modal__form-type-switcher-item-icon">
            <div class="auth-modal__form-type-switcher-item-text">{{ $t('auth.by-phone') }}</div>
          </div>
          <div class="auth-modal__form-type-switcher-item" :class="{active: type === 'email'}" @click="setType('email')">
            <img src="@/assets/images/icons/email-icon.svg" alt="icon" class="auth-modal__form-type-switcher-item-icon">
            <div class="auth-modal__form-type-switcher-item-text">{{ $t('auth.by-email') }}</div>
          </div>
        </div>

        <button class="auth-modal__form-btn" type="submit">{{ $t('auth.get-code') }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { VueMaskDirective } from 'v-mask';

export default {
  name: "AuthModal",
  data() {
    return {
        login: '',
        canClose: true,
        type: 'email',
        showSwitcher: false,
    };
  },
  directives: {
    mask: VueMaskDirective,
  },
  computed: {
    ...mapGetters({
        isActive: 'modalManager/active',
        modalName: 'modalManager/opened',
        parameters: 'modalManager/parameters',
    }),
    activeAuthModal() {
        return this.isActive && this.modalName === 'auth'
    },
    formattedPhone() {
      return this.login.replace(/[()\s-]/g, "");
    }
  },
  watch: {
      activeAuthModal() {
          this.login = ''
          this.type = 'email'
          this.canClose = true
          this.showSwitcher = false
      },
      parameters(newParam) {
        this.canClose = newParam?.canClose
      },
  },
  methods: {
    ...mapActions({
        close: 'modalManager/close',
        open: 'modalManager/open',
        setParameters: 'modalManager/setParameters',
        showPreloader: 'modalManager/showPreloader'
    }),
    submitForm() {
      const formData = new FormData();
      let url = ''

      if (this.type === 'email') {
          formData.append('email', this.login);
          url = '/auth/send-email-code'
      } else {
          formData.append('phone', this.formattedPhone);
          url = '/auth/send-phone-code'
      }
      this.showPreloader(true)

      this.axios.post(url, formData).then(response => {
          if (response.data.status) {
              this.showVerify()
          }
      }).catch(error => {
          console.error(error)
      }).finally(() => {
          this.showPreloader(false)
      })
    },
    showVerify() {
      let login = this.login

      if (this.type === 'phone') {
        login = this.formattedPhone
      }

      this.setParameters({login: login, type: this.type, canClose: this.canClose})
      this.open('verify-code');
    },
    setType(type) {
      this.login = ''
      this.type = type
      this.showSwitcher = false
    },
    toggleSwitcher() {
      this.showSwitcher = !this.showSwitcher;
    }
  }
}
</script>

<style scoped>

</style>