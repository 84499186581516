<template>
    <div v-show="activeUsernameModal" class="username-modal-overlay">
        <div class="username-modal">
            <img class="username-modal__logo" src="@/assets/images/logo.png" alt="logo">
            <div class="username-modal__title">{{ $t('auth.how-call-you') }}</div>
            <form @submit.prevent="submitForm" class="username-modal__form">
                <label for="name" class="username-modal__form-raw">
                    <input id="name" v-model="name" :placeholder="$t('auth.enter-username')" type="text"
                           class="username-modal__form-input" required>
                </label>
                <button class="username-modal__form-btn" type="submit">{{ $t('auth.save') }}</button>
            </form>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "UsernameModal",
    data() {
        return {
            login: '',
            code: '',
            name: '',
            type: ''
        };
    },
    computed: {
        ...mapGetters({
            isActive: 'modalManager/active',
            modalName: 'modalManager/opened',
            parameters: 'modalManager/parameters'
        }),
        activeUsernameModal() {
            return this.isActive && this.modalName === 'username'
        }
    },
    watch: {
        activeUsernameModal() {
            this.login = ''
            this.code = ''
            this.name = ''
            this.type = ''
        },
        parameters(newParam) {
            this.login = newParam?.login
            this.code = newParam?.code
            this.type = newParam?.type
        }
    },
    methods: {
        ...mapActions({
            setUserInfo: 'user/setUserInfo',
            setAuthorized: 'user/setAuthorized',
            setToken: 'user/setToken',
            close: 'modalManager/close',
            open: 'modalManager/open',
            setParameters: 'modalManager/setParameters',
            showPreloader: 'modalManager/showPreloader'
        }),
        submitForm() {
            const formData = new FormData();
            let url = '';
            if (this.type === 'email') {
                formData.append('email', this.login);
                url = '/auth/register-email'
            } else {
                formData.append('phone', this.login);
                url = '/auth/register-phone'
            }
            formData.append('name', this.name);
            formData.append('code', this.code);
            this.showPreloader(true)
            this.axios.post(url, formData).then(response => {
                localStorage.setItem('token', response.data.token)
                this.setUserInfo(response.data.user)
                this.setAuthorized(true)
                this.setToken(response.data.token)
                this.close()
                if (this.$route.query.redirect) {
                    this.$router.push(this.$route.query.redirect)
                }
            }).catch(error => {
                console.error(error)
            }).finally(() => {
                this.showPreloader(false)
            })
        },
    }
}
</script>

<style scoped>

</style>