import i18n from '../../i18n/i18n'

const state = () => ({
    all: [
        {
            ru: [{
                id: 1,
                name: 'Как управлять личными финансами',
                slug: 'personal-finance',
                background: '#0029FA',
                payType: 'Бесплатный курс',
                duration: '2 часа 13 минут',
                lessonCount: '6 уроков',
                bgImage: require('@/assets/images/course-header-image.png'),
                promoImage: require('@/assets/images/courses/course-promo-1.png'),
                mobilePromoImage: require('@/assets/images/courses/course-promo-mobile-1.png'),
                mobilePromoCardRemHeight: '28.8125',
                mobilePromoBackgroundColor: '#FFC8BC',
                content: {
                    welcomeText: [
                        {
                            icon: '👋',
                            content: "<h2>Как управлять личными финансами?</h2>" +
                                "<p>Это бесплатный курс по финансовой грамотности. Он поможет вам найти ваши деньги, наладить с ними отношения и уверенно посмотреть в финансовое будущее.</p>",
                        },
                        {
                            icon: '💸',
                            content: "<h2>Финансовая грамотность</h2>" +
                                "<p>базовый и самый важный навык на пути к денежной независимости.</p>",
                        },
                    ],
                    blockquote: '<h2>Не будем голословными. Начнем с примера:</h2>' +
                        '<p>Вам сейчас 25 лет. Вы собираетесь работать до 60 лет, а потом отправиться в кругосветное путешествие и никогда не слышать слова «работа». У вас есть 30 лет. Вы решаете открыть депозит и откладываете каждый месяц на протяжении 35 лет 40 000 тенге. При текущей ставке в 15% ваша итоговая сумма составит 601 804 387 тенге. Нехило, правда?</p>' +
                        '<p>Стабильные сбережения на депозите — это только одно из решений финансово грамотного человека. И таких решений много: подушка безопасности, правильное использование кредитов, инвестиции, диверсификация, защита от инфляции и много чего ещё.</p>' +
                        '<p>Учиться пользоваться каждым из них — значит улучшать качество жизни. Жить без кредитной нагрузки, иметь возможность оплачивать обучение и обживаться в собственной квартире не должно звучать как что-то недостижимое. А этот курс — доступная и понятная инструкция.</p>',
                    advantages: {
                        title: '6 простых уроков, которые поменяют ваши финансовые привычки',
                        subtitle: 'Чему вы научитесь:',
                        content: [
                            {
                                title: 'Определите свое настоящее положение',
                                subtitle: 'Проведём анализ вашего финансового состояния, чтобы выявить проблемы, которые в следующих уроках будем устранять.',
                            },
                            {
                                title: 'Научитесь следить за деньгами, и узнаете, куда они исчезают',
                                subtitle: 'Все здесь — как замечать ненужные траты, следить за накоплениями и планировать большие покупки.',
                            },
                            {
                                title: 'Узнаете, как ставить цели',
                                subtitle: 'Все о финансовых целях: где взять, как ставить, когда достигать.',
                            },
                            {
                                title: 'Научитесь брать и погашать кредит правильно',
                                subtitle: 'Посмотрите в глаза самому распространённому страху казахстанцев — кредитам. Внутри практичные советы и детали, о которых не расскажут банкиры.',
                            },
                            {
                                title: 'Выберите удобные инструменты',
                                subtitle: 'Из всего разнообразия финансовых инструментов разберем самые интересные, а после — определим, какие лучше подходят в вашей ситуации.',
                            },
                            {
                                title: 'Соберете свой финансовый план',
                                subtitle: 'Научим систематизировать все данные и рассчитывать свое будущее — с удобной таблицей со всеми финансами вашей жизни.',
                            },
                        ],
                    },
                    description: [
                        "<h2>Когда лучше начать?</h2>" +
                        "<p>Сейчас. Если вам больше 18, то начинайте сегодня, потому что никогда не поздно. Если меньше — поздравляем, вы выиграли себе время, чтобы войти во взрослую жизнь максимально подготовленными. Потому что никогда не рано.</p>" +
                        "<p>Как вы знаете, способность к увеличению вашего богатства максимальна в годы молодости и зрелости, а к старости она угасает.</p>" +
                        "<p>Конечно, кто-то и в 60 лет готов работать и зарабатывать, но большинство захочет остановиться и выдохнуть. Это говорит нам только об одном — чтобы наслаждаться будущим, задумайтесь о нем сейчас — в настоящем.</p>",

                        "<h2>Как читать?</h2>" +
                        "<p>Как вам удобнее. Можете прочитать все уроки за несколько часов, ведь на каждый урок уходит около 30 минут. А можете поделить и проходить по уроку в день, и затем тестировать прочитанную информацию на себе.</p>" +
                        "<p>Так даже эффективнее — в каждом уроке мы предлагаем использовать таблицу для подсчета ваших финансовых данных. Скопируйте ее к себе во время первого урока и выполняйте наши указания во время каждого из последующих. Поверьте, к концу шестого урока вы начнете понимать себя намного лучше.</p>",

                        "<h2>Примеры, инструкции, словарик и тесты</h2>" +
                        "<p>Мы написали уроки так, чтобы они могли помочь каждому, кто хочет выстроить ясные отношения с деньгами. В тексте много детальных инструкций и примеров. А в конце каждого урока есть словарик с терминами и тест для закрепления знаний. Если вы будете внимательно читать, делать упражнения, вникать в термины, а потом еще и пройдете тест, вам не о чем переживать.</p>",

                        "<h2>Начнем?</h2>" +
                        "<p>Да, все это звучит, как предложение взобраться на Эверест, но в реальности вы строите простой и надежный плот, который позволит оставаться на плаву при любой буре.</p>" +
                        "<p>Поплыли вместе!</p>",
                    ]
                },
                lessons: [
                    {
                        id: 1,
                        number: 1,
                        name: 'Проводим оценку активов',
                        slug: 'provodim-ocenku-aktivov',
                        duration: '17 мин',
                        bgImage: require('@/assets/images/lessons/lesson-1.png'),
                        content: {
                            shortDescription: "<h2>Оценка активов: как посчитать, сколько у вас денег на самом деле</h2>\n" +
                                "<p>В первом уроке посчитаем, сколько у вас денег. И нет, мы не просто откроем банковское приложение и посмотрим на остаток счета. А научимся считать все — сбережения, долги, кредиты, счета, машины и квартиры. Это своеобразная и очень нужная проверка, которая поможет открыть глаза на ваше реальное финансовое положение.</p>",
                            shortList: "<h2>Что будет в уроке:</h2>\n" +
                                "<div><p>Узнаете, по каким показателям оценивается богатство, и почему хорошая зарплата — не гарантия финансового благополучия.</p></div>\n" +
                                "<div><p>Проведёте ревизию своих ценностей, активов и обязательств.</p></div>\n" +
                                "<div><p>Сможете определить свой денежный статус в текущий момент и получить совет и рекомендации на будущее.</p></div>",
                            description: "<h2>Зачем нужна оценка и что она даёт</h2>\n" +
                                "<p>Оценка и анализ — отправная точка всего. Сложно достигать целей, когда нет понимания текущего положения дел.</p>\n" +
                                "<p>Отношения среднестатистического казахстанца с деньгами нельзя назвать стабильными — человек может точно знать свой доход (чаще всего это только зарплата), но вот на что она уходит — непонятно. Это порождает тревогу и нежелание ставить цели — зачем, если денег в конце месяца все равно не остается?</p>\n" +
                                "<p>Пока вы не разберетесь, сколько у вас денег есть на самом деле, неопределенность и чувство тревоги будут расти. К тому же, цены продолжают расти, расходов становится больше и накопление сбережений так и будет казаться невыполнимой задачей.</p>\n" +
                                "<p>Значит, будем считать. Прежде, чем мы начнем подсчет, обозначим несколько позитивных моментов:</p>\n" +
                                "<ul>\n" +
                                "<li><span class='green'>Оценка — это не планирование.</span> Вам не нужно высчитывать, какими средствами погасить кредиты и долги, в чем себе нужно отказать ради экономии и как жить дальше. Сейчас вам нужно выписать всё, что у вас есть и что вы должны, и получить предварительную картину финансового положения;</li>\n" +
                                "<li><span class='green'>Размер зарплаты никак не связан с финансовой грамотностью</span>: правильно усвоив наш курс, вы сможете грамотно распоряжаться доходами, даже если ваша зарплата ниже рыночной. У каждого из нас есть знакомый, который зарабатывает немного, но ему удается откладывать на квартиру, досрочно погашать кредиты или позволять себе небольшие путешествия. А есть и те, кто зарабатывает в разы больше, но во многом себя ограничивает и жалуется на то, что качество жизни с каждым годом становится хуже, несмотря на рост в карьере и финансах.</li>\n" +
                                "</ul>\n" +
                                "<p>Рассмотрим на примере:</p>\n" +
                                "<blockquote>\n" +
                                "<p>Думан — начальник маркетингового отдела в небольшой фирме, он получает 500 тысяч тенге в месяц. Такая зарплата считается выше средней в Казахстане. Что если мы скажем вам, что при всём этом его финансовое положение нестабильно?</p>\n" +
                                "<p>Объясняем: нам может казаться, что человек хорошо зарабатывает — и это есть достаток. Но если мы проведем оценку, то выясним, что у Думана нет сбережений, активов и понимания, на что уходят деньги.</p>\n" +
                                "<p>Думан снимает квартиру и отдаёт за неё 250 тысяч тенге; до работы и обратно добирается на такси — траты варьируются в зависимости от времени суток, погоды и ситуации на дорогах, в среднем это 50 тысяч тенге в месяц; прибавляем сюда другие обязательные, невидимые, незапланированные траты и те, что он совершает под влиянием эмоций. В итоге окажется, что он живёт по сценарию «‎от зарплаты до зарплаты»‎. Если по разным причинам он потеряет работу, то даже самая базовая потребность — например, жилье — окажется не по карману.</p>\n" +
                                "</blockquote>\n" +
                                "<p>Не спешите расстраиваться, если узнали себя в Думане — на самом деле выстроить здоровые отношения с деньгами не так сложно, как кажется. К концу урока вы уже поймете, в каком финансовом положении находитесь сейчас и что нужно сделать, чтобы его улучшить.</p>\n" +
                                "<h2>Активы и финансовые обязательства</h2>\n" +
                                "<p>Активы и долговые обязательства звучат по-бухгалтерски страшно. Не волнуйтесь, мы не будем делать сложные подсчёты и ставить уравнения. На самом деле всё куда проще, чем звучит.</p>\n" +
                                "<p>К активам относятся все финансовые и нефинансовые накопления, которые у вас есть — недвижимость, автомобиль, деньги на депозитах и наличными, золото и драгоценные камни. Для удобства все ценности на руках лучше рассматривать в денежном эквиваленте — во-первых, такие данные помогут при планировании в будущем, а во-вторых, так вам попросту будет легче считать.</p>\n" +
                                "<h2>Какими бывают активы</h2>\n" +
                                "<p>Как мы говорили выше, к активам относится всё, что в настоящем или будущем можно превратить в деньги. Например, квартира — недвижимость можно конвертировать в наличные.</p>\n" +
                                "<p>Однако не спешите относить к активам старые вещи и б/у технику, которую вы можете продать на OLX. Ведь с каждым днем эти вещи изнашиваются и теряют стоимость и ценность. При продаже вы оправдаете только часть стоимости, хотя сам факт продажи — а значит, получение денег — все-таки пополнит активы, но в виде наличных.</p>\n" +
                                "<p><strong>Активы — это ценности, которые дают чувство уверенности в завтрашнем дне и приносят экономическую выгоду владельцу.</strong> Они могут быть «доходными» и «фиксированными». Доходные помогают зарабатывать деньги, а фиксированные не увеличивают капитал, и временами даже могут нуждаться в дополнительных вложениях.</p>\n" +
                                "<p>Вкладываться в доходные активы — правильное решение, но и обходить вниманием фиксированные активы не нужно. Многие склонны относить ценности для собственного пользования к неудачным вложениям. Отсюда и растут ноги у советов вроде «‎не покупай квартиру, это лишние траты»‎ и «‎машина требует содержание, это все расходы»‎.</p>\n" +
                                "<p>Но квартира — актив, даже если вы тратитесь периодически на ремонт и ежемесячно закрываете платежи по коммунальным услугам. Всегда учитывайте скрытую прибыль. Например, до этого вы снимали жилье и оплачивали те же коммунальные, но вдобавок с ежемесячным платежом за возможность проживания в ней. Отсутствие такого платежа — и есть ваш скрытый доход. Даже если квартира в ипотеку, вы уже платите за свою квартиру, которую можно конвертировать в деньги — а это главный принцип активов.</p>\n" +
                                "<p><strong><span class='green'>Любой доходный актив может стать фиксированным, и наоборот</span></strong></p>\n" +
                                "<p>Покажем на простых примерах:</p>\n" +
                                "<div>" +
                                "<h2>Доходный актив</h2>\n" +
                                "<p>На 2 миллиона тенге вы приобрели парковочное место в вашем жилом комплексе, но машины у вас нет. Сосед арендует его у вас за 20 тысяч тенге в месяц. Окупятся вложения через три года с небольшим, а дальше будут приносить чистую прибыль. Деньги работают, а значит, это доходный актив.</p>\n" +
                                "</div>" +
                                "<div>" +
                                "<h2>Фиксированный актив</h2>\n" +
                                "<p>Вы купили машину. Арендуете парковочное место, заливаете бензин и отвозите её на СТО раз в квартал.</p>\n" +
                                "<p>Деньги не производят деньги, периодически отнимают часть бюджета, но и сохраняют — те, что вы потратите на такси. Машину вы можете продать и получить приличные деньги. Это и есть фиксированный актив.</p>\n" +
                                "</div>" +
                                "<div>" +
                                "<h2>Доходный актив, который стал фиксированным</h2>\n" +
                                "<p>Условия всё те же: парковочное место за 2 миллиона тенге, чтобы сдавать в аренду. Ваш сосед переехал и расторгнул договор аренды, вы подаёте новое объявление — но всё пусто. Никто из проживающих в вашем ЖК не нуждается в парковке. Актив становится фиксированным до лучших времён.</p>\n" +
                                "</div>" +
                                "<div>" +
                                "<h2>Фиксированный актив, который стал доходным</h2>\n" +
                                "<p>На своей машине вы начинаете работать курьером или частным извозчиком. Траты всё те же, но есть и доход. Теперь машина, помимо того, что используется для личных целей, становится средством заработка. Вложенные деньги начинают генерить деньги, фиксированный актив переходит в категорию доходных активов.</p>\n" +
                                "</div>" +
                                "<p>В общем, <strong>все ценное, что у вас есть</strong>, вне зависимости от того, нужны им дополнительные средства на содержание и обслуживание, <strong>и что потенциально можно обменять на деньги — активы.</strong></p>\n" +
                                "<h2>Финансовые обязательства и долги</h2>\n" +
                                "<p>Теперь о том, почему важно посчитать финансовые обязательства. <strong>Финобязательства — это то, за что вы должны деньги.</strong> Самый простой пример — кредит. Когда-то вы взяли кредит, чтобы оплатить непредвиденные затраты, а теперь вы должны вернуть их банку с процентами. То, что вы должны — ваше обязательство.</p>\n" +
                                "<p>Такие выплаты, скорее всего, занимают большую часть от вашей зарплаты, а еще в перспективе уменьшают размеры ваших активов – ведь эти же деньги вы могли копить или купить на них машину.</p>\n" +
                                "<p>И да, долг друзьям или родителям тоже считается за обязательство. Хоть в таких ситуациях и нет процентной ставки, но зато есть срок и сумма, а значит, ее стоит учитывать.</p>\n" +
                                "<p>Теперь возвращаемся к нашей таблице. Там мы заполним две графы, которые помогут оценить нам наше состояние: активы и финобязательства.</p>\n" +
                                "<h2>Считаем достаток</h2>\n" +
                                "<p>На самом деле просчеты легки, как математика первого класса. Ваш достаток — разница между активами и финансовыми обязательствами. Это показатель, который даст понимание уровня вашей финансовой свободы: классно, если сумма положительная. Но даже если вышло число с минусом, с этим тоже можно работать.</p>\n" +
                                "<h2>Как и где считать?</h2>\n" +
                                "<p>Таблица в <a href='https://docs.google.com/spreadsheets/d/1s18YgLpIyjTo2hGnM0krhMxHvZZ2gPY6_Ce2TczwCyM/copy' target='_blank'>Google Doc</a> — ваш лучший друг. В ней мы подготовили шаблонную таблицу с просчетами и инструкциями под каждый урок. Ваша задача — скопировать ее себе на Драйв и вбить свои показатели. В каждом уроке вы найдете инструкции к заполнению разных ее частей и листов.</p>\n" +
                                "<p>В этом уроке обратитесь к листу <a href='https://docs.google.com/spreadsheets/d/1s18YgLpIyjTo2hGnM0krhMxHvZZ2gPY6_Ce2TczwCyM/copy' target='_blank'>Мои активы</a>.</p>\n" +
                                "<img src='"+require('@/assets/images/visuals/img-1.png')+"'/>\n" +
                                "<p>Как видите, в нашем примере слева мы заполнили то, что можно отнести к активам, а справа — к обязательствам. Попробуйте составить свой список.</p>\n" +
                                "<p>Деньги на депозите, в заначке или на счету можно записать сразу. А вот квартира и машина — это неликвидные активы. То есть они требуют время для обращения в деньги: выставление на продажу, договоренности с покупателем, оформление и так далее. Их стоимость рассчитывайте по рыночной на текущий момент. Для этого проведите анализ: за сколько сейчас люди готовы купить машину или квартиру с похожей комплектацией. Такие активы имеют тенденцию расти в цене, поэтому вы, скорее всего, будете приятно удивлены.</p>\n" +
                                "<p>Но идем дальше. Если вы дали в долг, то это тоже будет вашим активом, а вот для заёмщика — финансовым обязательством. Одолжили другу 100 000 тенге до следующего месяца? Смело впишите эту сумму в левую таблицу.</p>\n" +
                                "<p>Во вторую таблицу заносим все финансовые обязательства. Если у вас есть кредит в банке, учитывайте не полную сумму с переплатой, а только основной долг — через день или год вы можете погасить его и сэкономить на процентах.</p>\n" +
                                "<p>Узнать сумму основного долга можно через специальный калькулятор или посмотреть в деталях кредита. Быстрее всего будет через функцию «Погасить досрочно» в приложении банка.</p>\n" +
                                "<p>Как видите, в обеих таблицах есть пункт «‎Квартира в ипотеку»‎. Вы оплачиваете деньги банку — это ваш кредит, но в то же время уже выплаченная часть денег постепенно пополняет ваши активы.</p>\n" +
                                "<p>В активах мы поставили рыночную стоимость квартиры — 22 миллиона тенге, а в обязательства внесли оставшийся чистый долг — 10 миллионов. То есть если вы решите продать квартиру, вы получите 22 миллиона, из которых 10 миллионов потратите, чтобы закрыть ипотеку. На руках у вас останется 12 миллионов.</p>\n" +
                                "<p>Как только вы внесете все свои данные, таблица посчитает ваш достаток: отнимет сумму активов от суммы обязательств. Именно такая сумма останется у вас на руках, если решите разом закрыть все долги с помощью ваших владений.</p>\n" +
                                "<p><strong><span class='green'>А теперь задание:</span></strong> попробуйте разделить сумму вашего достатка на примерную сумму месячных расходов. Например, согласно нашей таблице, достаток составил 13 миллионов тенге. Предположим, что сумма ежемесячных расходов у нас составляет 400 000 тенге.</p>\n" +
                                "<p>13 миллионов / 400 тысяч тенге = 32,5 месяца. Или 2 года 7 месяцев. Столько мы смогли бы жить, если бы возникла ситуация, в которой мы вынуждены использовать все наши активы, чтобы закрыть все наши обязательства. Это грубый подсчет, который не учитывает рост расходов и возможный доход, но именно он помогает открыть глаза. Наши активы — то, что должно помочь нам удержаться на плаву при любых жизненных обстоятельствах, и именно поэтому их надо увеличивать, а от финобязательств избавляться.</p>\n" +
                                "<h2>Оцениваем себя</h2>\n" +
                                "<p>Вы выписали активы и финансовые обязательства, высчитали уровень достатка. Теперь посмотрите на результат и оцените общее положение дел.</p>\n" +
                                "<div>" +
                                "<h2>Ваши финансовые обязательства существенно превышают активы</h2>\n" +
                                "<p>Здесь два варианта: либо ваши кредиты и долги больше активов, либо активов совсем нет. Долги нарастают, а возможность создать даже самый базовый актив не видится возможным.</p>\n" +
                                "<p>Не спешите расстраиваться, это поправимо. Теперь вы провели оценку и в курсе, над чем нужно поработать — увеличить и найти способы для пополнения активов, проработать причину финансовой привычки, которая привела к такому результату и особенно внимательно прочитать урок 4 — как правильно брать и погашать кредиты.</p>\n" +
                                "</div>" +
                                "<div>" +
                                "<h2>Ваши финансовые обязательства равны или приблизительно равны активам</h2>\n" +
                                "<p>У вас есть кредиты и долги, но временами получается отложить какую-то часть дохода. А как только финансовая нужда появляется, сбережения приходится использовать. И так по кругу.</p>\n" +
                                "<p>Жить от зарплаты до зарплаты очень сложно. Пройдите следующие уроки, чтобы улучшить свои знания в области финансов и применить их на практике. Информирован — значит, вооружен.</p>\n" +
                                "</div>" +
                                "<div>" +
                                "<h2>Ваших активов больше, чем финансовых обязательств</h2>\n" +
                                "<p>Активы есть, и по-прежнему могут быть кредиты и долги, но они не сильно могут повлиять на ваше финансовое положение. Средств хватает на всё, получается откладывать на финансовую подушку или другую цель.</p>\n" +
                                "<p>Вы прошли хороший путь и наладили свое финансовое положение, но ещё есть куда стремиться. Пройдите все уроки, чтобы узнать, в какой из областей у вас наблюдаются погрешности. После — сможете оценить свое финансовое положение снова, и при правильном подходе к фининструментам убедиться, что стали на ступень выше.</p>\n" +
                                "</div>" +
                                "<div>" +
                                "<h2>Ваши активы существенно превышают финансовые обязательства — финансовая независимость</h2>\n" +
                                "<p>Вы используете кредит как фининструмент, и не нуждаетесь в нём в других случаях. У вас есть страховка, финподушка, пассивный доход и стабильный заработок.</p>\n" +
                                "<p>Это прекрасный результат. Но не спешите покидать курс, мы собрали всё полезное в следующих уроках: вы сможете лишний раз подкрепить знания, а в некоторых вопросах — подкорректировать.</p>\n" +
                                "</div>",
                        },
                        dictionary: [
                            {
                                id: 1,
                                name: 'Активы',
                                description: 'Ресурсы в различных формах собственности, способные приносить прибыль в настоящем или будущем'
                            },
                            {
                                id: 2,
                                name: 'Ликвидность (в отношении активов)',
                                description: 'Ценность, которая может быть быстро и с минимальными затратами обращена в деньги'
                            },
                            {
                                id: 3,
                                name: 'Неликвидность (в отношении активов)',
                                description: 'Имущество, которое не может быть быстро и с минимальными затратами обращено в деньги'
                            },
                            {
                                id: 4,
                                name: 'Кредитная нагрузка',
                                description: 'Все финансовые обязательства клиента перед банком. Помогает оценить, насколько загружен бюджет заемщика и может ли он выплачивать дополнительные займы'
                            },
                            {
                                id: 5,
                                name: 'Индексация зарплаты',
                                description: 'Увеличение зарплаты на уровень инфляции, иногда выше показателя'
                            },
                            {
                                id: 6,
                                name: 'Уровень достатка',
                                description: 'Разница между расходами и доходами'
                            },
                        ],
                        tests: [
                            {
                                id: 1,
                                question: 'Зачем нам оценивать финансовое положение?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Узнать, насколько я крут(-а) и рассказать друзьям',
                                        result: 'Это тоже хорошо, если одна из ваших целей — утвердиться в собственных силах. А если результат будет не столь радужным?',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Чтобы пополнить словарный запас терминами: активы, финансовые обязательства, ликвидность',
                                        result: 'Это тоже хорошо — разбираться в финансовых терминах и не пугаться их, но цель оценки не может ограничиться только этим.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Конечно, чтобы меньше тревожиться',
                                        result: 'Это тоже хорошо. Часто именно тревога становится причиной нездоровых отношений с деньгами, но оценивать положение нужно не только для эмоционального спокойствия.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Все вместе',
                                        result: 'Оценив свое финансовое положение, вы будете меньше тревожиться по поводу денег, не будете пугаться сложных терминов, и главное — сможете опираться на полученные данные для будущих целей и планов.',
                                        correct: true,
                                    }
                                ],
                            },
                            {
                                id: 2,
                                question: 'Какой из вариантов будет высоколиквидным активом?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Антиквариат и произведения искусства',
                                        result: 'Есть денежные и временные затраты: найти покупателя, подтвердить подлинность, организовать транспортировку.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Валюты',
                                        result: 'Высоколиквидность — способность активов быстро и с минимальными затратами быть обращенными в деньги. Валюту легко конвертировать в нужную — онлайн или в ближайшем обменнике.',
                                        correct: true,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Хозяйство из 500 голов овец',
                                        result: 'Вы можете продать их на рынке, а можете обратить в производство. Спрос всегда есть, но для хорошего заработка их нужно вырастить, ухаживать и лечить при необходимости.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Частный дом',
                                        result: 'Чтобы продать нужно время, иногда больше месяца. А это низколиквидный актив.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 3,
                                question: 'У вас есть квартира в ипотеке, стоимость которой 36 миллионов тенге. Осталось платить банку ещё два года и 6 миллионов тенге без учета процентов. Какую сумму вы впишете в таблицу активов?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: '36 миллионов',
                                        result: 'Вы уже знаете, что в активы вписываем полную стоимость, даже если есть невыплаченная сумма. Это поможет правильно оценить финансовое положение.',
                                        correct: true,
                                    },
                                    {
                                        id: 2,
                                        answer: '30 миллионов',
                                        result: '30 миллионов — это уже выплаченная сумма. Вам необходимо вписать полную стоимость актива, которая по условиям равна 36 миллионам.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: '6 миллионов',
                                        result: 'Это сумма оставшегося долга по ипотеке, её нужно вписать в таблицу финобязательств.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: '42 миллиона',
                                        result: 'Вы, возможно, прибавили к стоимости квартиры оставшийся долг. 36 миллионов должны пополнить таблицу активов, а 6 миллионов — финобязательства.',
                                        correct: false,
                                    }
                                ],
                            }
                        ],
                    },
                    {
                        id: 2,
                        number: 2,
                        name: 'Рассчитываем бюджет на месяц и год',
                        slug: 'rasschityvaem-byudzhet-na-mesyac-i-god',
                        duration: '23 мин',
                        bgImage: require('@/assets/images/lessons/lesson-2.png'),
                        content: {
                            shortDescription: "<p>Бюджет — это не только про государства и компании. Это про любые отношения с деньгами, в том числе личные. В этом уроке объясняем, что ничего в его ведении страшного нет и показываем, как это делать легко и просто. Сегодня вы научитесь замечать ненужные траты, следить за накоплениями и планировать большие покупки.</p>",
                            shortList: "<h2>Что будет в уроке:</h2>\n" +
                                "<div><p>Поймете, можно ли назвать ваши отношения с деньгами здоровыми. Если да — то что поможет улучшить их, а если нет — как найти верный путь к благополучию.</p></div>\n" +
                                "<div><p>Узнаете, что бюджет — не так сложно и бюрократично, как может показаться. Мы расскажем, как его вести просто и удобно — так, чтобы не забросить.</p></div>\n" +
                                "<div><p>Вам не нужно чертить таблицы, мы поделимся уже готовой. И объясним, как её вести — каждый месяц или год.</p></div>",
                            description: "<h2>Я сам себе банк</h2>\n" +
                                "<p>Бюджет, учет, расходы, расчеты — звучит сложно, непонятно и как будто необязательно. Однако грамотно составленный бюджет — это возможность получать желаемое без угрызений совести и мыслей о том, как прожить оставшиеся дни до зарплаты. Благодаря ему вы начнете замечать мелкие траты, которые за месяц, а тем более за год, собираются в денежный шар.</p>\n" +
                                "<p>Минутка честности — давайте поймем, болезненные ли у вас отношения с деньгами. Вот несколько основных симптомов:</p>" +
                                "<ul>" +
                                "<li>Ваши ежемесячные накопления — это меньше 5% от дохода. К сожалению, у вас не получается откладывать больше, а иногда даже такие накопления кажутся роскошью</li>" +
                                "<li>Больше половины дохода тратится в первую неделю после получения заработной платы. А вторая половина месяца проходит в нервных проверках оставшихся денег. Хорошо, если не приходится просить в долг в последние дни перед зарплатой.</li>" +
                                "<li>Хаотичный размер трат в течение месяца. Сегодня вы потратили 15 000 тенге, завтра 3 000, а послезавтра — снова 15 000. Такие скачки для вас привычны и неконтролируемы.</li>" +
                                "<li>Каждый месяц вы берете в долг, чтобы отдать его в следующем месяце, чтобы опять взять к его концу новый. И так по кругу.</li>" +
                                "</ul>" +
                                "<p>Хорошо, если ничего из вышеперечисленного вам не подходит, но, скорее всего, вы узнали у себя хотя бы один из симптомов. Такие действия можно сравнить с вредными привычками, а ведение бюджета поможет избавиться от них.</p>\n" +
                                "<p>Для начала мы:</p>" +
                                "<ol>" +
                                "<li>Посчитаем доходы — увидим, на что и на сколько можем рассчитывать.</li>" +
                                "<li>Посчитаем расходы — увидим, на что уходят деньги.</li>" +
                                "<li>Проанализируем статьи расходов, поймем, какие статьи можно исключить или хотя бы сократить.</li>" +
                                "<li>Составим план на следующие месяцы, а после — на год. Этот план поможет нам исключить или минимизировать незапланированные покупки. К примеру, если осенью мы отложим деньги на пуховики и сапоги, а к лету — на отдых, то и дыр в бюджете удастся избежать.</li>" +
                                "</ol>" +
                                "<h2>Первое. Что нужно считать?</h2>" +
                                "<p>Первым шагом надо записать, вывести, посчитать все движения денег — ваши доходы и расходы. Это простое действие поможет узнать совершенно новые данные о себе, которые много расскажут о вас и вашей жизни. Куда ходите, кому отдаете, где тратите, а где — экономите, что беспокоило вас в этом месяце, а на что вы совсем не обращали внимания.</p>" +
                                "<p>Например, вам всегда казалось, что вы совсем не уделяете себе внимание, а в конце месяца увидите, что баловали себя сладким по понедельникам и позволяли расслабиться по пятницам. А значит, что совсем чуть-чуть, но заботились. Таких инсайтов может быть много, и каждый может быть приятным (или не очень) открытием. И это как раз та информация, с которой нужно работать.</p>" +
                                "<p>В общем, вам нужно посчитать все ваши деньги: основные и второстепенные, активные и пассивные источники дохода, обязательные и необязательные расходы. Сейчас разберем каждый тип:</p>" +
                                "<p><strong><span class='green'>Доходы</span></strong></p>" +
                                "<ul>" +
                                "<li>Основной доход — самый стабильный и образующий фундамент вашего кошелька. Например, ежемесячная зарплата, которую получаете на руки;</li>" +
                                "<li>Второстепенный доход — это стипендии, гонорары, ваша фриланс-работа и т.д.</li>" +
                                "</ul>" +
                                "<p>Здесь важно трезво понять, на какую сумму вы можете рассчитывать на определенный период времени. Например, ваша заработная плата составляет 450 000 тенге нетто (чистыми на руки) и 100 000 тенге вы получаете за подработку. Ваш месячный доход — 550 000 тенге. Это цифра, выше которой вы не должны выйти в следующей категории — расходы.</p>" +
                                "<p><strong><span class='green'>Траты</span></strong></p>" +
                                "<p>С тратами немного тяжелее, потому что ежедневно мы можем совершать десятки операций, и самое сложное — все держать в голове. Здесь нам понадобится учет.</p>" +
                                "<p>В тратах больше категорий, на которые стоит уделить предельное внимание, так как от правильной категоризации зависит ваше спокойствие в конце месяца. Обычно выделяют четыре вида:</p>" +
                                "<ul>" +
                                "<li>Обязательные траты — те, по которым у вас есть обязательства (вы же читали первый урок, да?) — кредиты, рассрочки, ипотечные займы, и т.д.;</li>" +
                                "<li>Необходимые — коммунальные услуги, плата за аренду, продуктовая корзина — все то, что существенно влияет на ваш уровень жизни;</li>" +
                                "<li>На будущее — отдельная категория накоплений, которая обязательно должна появиться в вашем бюджете. Это те самые сбережения, к появлению и сохранению которых мы будем стремиться;</li>" +
                                "<li>Повседневные траты — это траты на транспорт, еду вне дома, доставку, кофе, развлечения, походы в ресторан, подарки и т.д.;</li>" +
                                "</ul>" +
                                "<p>Когда вы делите свои расходы на категории, становится легче распределять зарплату по приоритетам, начиная с важных и заканчивая необязательными.</p>" +
                                "<p>Первыми идут обязательные траты. Все ваши кредиты, рассрочки, займы и так далее должны оплачиваться в первую очередь, потому что вы можете быть наказаны за просрочку. Причем не только штрафами и пеней, но и потерей возможности брать льготное кредитование. </p>" +
                                "<p>Вторые на очереди — необходимые траты. Коммуналка, арендная плата и продуктовая корзина — то, что требует ваших трат, так как обеспечивает самым необходимым: жильем, едой, теплом. Сюда же вы можете внести транспорт, если ваша работа находится далеко от дома и дорога требует больших затрат.</p>" +
                                "<p>Третьими в идеальном варианте должны идти траты «На будущее», потому что если придерживаться принципа «‎Что осталось, то и отложу»‎ — то ничего не скопится. Соблазны и эмоциональные покупки сделают своё дело. Поэтому вводим в нашу финансовую игру правило <b>pay yourself first</b>. <strong>То есть при получении дохода обязательно нужно отложить на свое будущее.</strong></p>" +
                                "<p>А все, что осталось от вашего дохода после вычета всех трех категорий трат, и будет ваш бюджет, который вы сможете потратить в течение месяца: перекусы, рестораны, бары, такси, кофе, доставка еды и т.д.</p>" +
                                "<p>Звучит, наверное, сложно, но как только вы выпишите все это в удобный инструмент (нашу таблицу) для учета, все станет намного легче.</p>" +
                                "<h2>Второе. Как вести учет?</h2>" +
                                "<p>Мы советуем использовать <a href='https://docs.google.com/spreadsheets/d/1s18YgLpIyjTo2hGnM0krhMxHvZZ2gPY6_Ce2TczwCyM/copy' target='_blank'>нашу таблицу</a> – страницу Пример Бюджет.  Вести ее проще под конец дня или недели, а траты среди дня или недели можно записывать в заметки на телефоне, специальные приложения (Monefy, Wallet) или хотя бы блокнот. Конечно, открывать при каждой трате google-таблицу неудобно. Но игнорировать ее полностью нельзя. Объясним, почему:</p>" +
                                "<blockquote>" +
                                "<p>Данияр получил зарплату в 500 000 тенге. Сразу оплатил аренду и кредит — на это ушло 220 000 тенге. Оставшиеся 280 000 тенге Данияр с легкой душой оставил на карточном счету и решил, что переведет в конце месяца на депозит оставшиеся деньги. По ощущениям казалось, что около 30 тысячи останутся свободными для накоплений. Каждый день Данияр по привычке заходил в кофейню, в течение недели ездил на такси, когда было лень идти пешком, несколько раз докидывал деньги на баланс, потому что тариф закончился за 20 дней вместо оплаченных 30. К концу месяца оказалось, что денег совсем впритык: заплатить коммуналку и не тратиться больше совсем. Куда ушли — непонятно. Но если посчитать, то увидим: кофе 15х за месяц — 12 000 тенге, такси 10х — 6 000, добавки к основному тарифу на телефон — еще несколько тысяч тенге. То есть бюджет можно оптимизировать: реже брать ненужные перекусы и кофе, иногда пройтись пешком или проехаться на автобусе / метро, поменять тариф на телефоне.</p>" +
                                "<p>Вел бы Данияр учет — заметил, куда утекают деньги. Даже если и страшно начинать вести таблицу, польза от нее того стоит.</p>" +
                                "</blockquote>" +
                                "<h2>Инструкция по таблице</h2>" +
                                "<img src='"+require('@/assets/images/visuals/img-10.png')+"'/>\n" +
                                "<p>Итак, начнем вести таблицу</p>" +
                                "<ol>" +
                                "<li>Вписываем доход — зарплату и дополнительный доход, если таковой имеется.</li>" +
                                "<li>В графе Фиксированные расходы прописываем Обязательные и Необходимые траты: кредиты, рассрочки, коммунальные платежи, аренду.</li>" +
                                "<li>" +
                                "<p>Далее в графе Накопления пропишите, сколько процентов готовы откладывать ежемесячно. Таблица сама посчитает, сколько это в денежном эквиваленте, и даже спрогнозирует накопления на год вперед при условии, что откладываемые суммы останутся на том же уровне.</p>" +
                                "<p>Если у вас еще нет привычки или возможности откладывать, начните с малого. Даже 5% в первый месяц использования таблицы будет достаточно. Потом таблица покажет вам ваши слабые места, которые вы сможете оптимизировать, а значит, увеличить процент.</p>" +
                                "</li>" +
                                "<li>Как только вы введете доход и все фиксированные траты, таблица выведет вам Бюджет на месяц и Бюджет на день. Выходить за эти цифры нежелательно. Оставаясь в рамках этого бюджета, вы гарантированно доживете до следующей зарплаты, не взяв в долг или кредит. А это, согласитесь, классное достижение.</li>" +
                                "<li>" +
                                "<p>В правой графе начинайте прописывать непосредственные расходы. Здесь важно не ошибиться с категориями трат. Например, перевод за покупку или услугу относите в соответствующую графу. Не создавайте отдельно графу для переводов — потом только запутаетесь, что были за переводы и куда.</p>" +
                                "<p>В течение дня записывайте свои расходы в Заметках на телефоне или забивайте в специальные приложения. Главное — сумма и категория. Например, утреннее такси — 700 тенге, обед — 1 800 тенге, кофе — 900 тенге. А в конце дня зайдите в таблицу и занесите в соответствующую графу.</p>" +
                                "<img src='"+require('@/assets/images/visuals/img-9.png')+"'/>\n" +
                                "</li>" +
                                "<li>По завершении месяца вы сможете просмотреть Структуру повседневных доходов: таблица покажет вам в процентном соотношении, сколько каждая категория съедает вашего бюджета.</li>" +
                                "<li>Если у вас по окончании месяца остались средства, они перейдут в следующий месяц как остаток с предыдущего месяца.</li>" +
                                "</ol>" +
                                "<h2>Шаг третий. Оптимизация бюджета</h2>" +
                                "<p>После того, как сделаете учет хотя бы одного месяца, у вас может возникнуть три сценария.</p>" +
                                "<ol>" +
                                "<li>Самый лучший — ваши доходы превышают ваши расходы</li>" +
                                "<li>Средний — ваши расходы равны вашим доходам</li>" +
                                "<li>Наименее желаемый — ваши расходы превышают ваши доходы</li>" +
                                "</ol>" +
                                "<p>Если вышел самый лучший вариант, вам все равно будет полезно пройтись по всем расходам и выявить те статьи, где можно минимизировать. В последних же двух сценариях надо бить тревогу. Да, в среднем варианте, возможно, чуть потише, но в обоих случаях нужна оптимизация бюджета. Хорошая новость — у вас есть, с чем работать, ведь вы только что собрали все ваши данные. </p>" +
                                "<p>Над статьей расходов поработаем особенно усердно. Если с обязательными все понятно — это все кредиты, оплаты за коммунальную услуги, продукты, то есть все, что стабильно и можно внести в точный план — то с необязательными расходами сложнее. <strong>Внутри них есть несколько категорий трат, из-за которых кошелек значительно редеет.</strong> Давайте разберемся.</p>" +
                                "<p><strong><span class='green'>Эмоциональные</span></strong></p>" +
                                "<p>Эмоциональная покупка — самая бесполезная, потому что совершается чаще всего незапланировано, а значит, бесцельно. Мы можем не замечать, но происходит это из-за внешних стимулов — побуждающей рекламы, под влиянием эмоций, в том числе и чужих. Например, увидели новинку (к примеру, утюг) популярного бренда у блогера и купили под влиянием хороших отзывов, хотя на самом деле новый утюг вам сейчас не нужен — старый работает все так же хорошо. Спонтанная покупка порадует, пусть и на время. Но если их становится много, это начинает сказываться на качестве жизни, которое напрямую связано с достатком.</p>" +
                                "<p>Зайти в магазин и накупить вещей, пусть даже и со скидкой, но не нужных — эмоционально ориентированное поведение. Пойти в магазин за нужной зимней курткой в декабре — когда цены гораздо выше — просто не самое рациональное решение. Оба варианта рано или поздно устроят танцы на костях вашего кошелька.</p>" +
                                "<blockquote>" +
                                "<p>В прошлом месяце Данияр купил себе хорошую качественную куртку за 50 000 тенге. Он подошел ответственно — спокойно откладывал на нее несколько месяцев по 16 500 тенге, чтобы покупка не сильно ударила по бюджету. Но вчера взор Данияра, пока он был в ТРЦ, упал на витрину — за ней висела новая парка от известного бренда. Он не удержался, зашел в магазин и купил ее. Правда, обошлась она Данияру в 80 000 тенге. И эти деньги он не откладывал, а потратил в моменте. Стоит ли считать, насколько сильно это ударило по бюджету?</p>" +
                                "</blockquote>" +
                                "<p>Если вы обожаете импульсивные покупки, то вам поможет «Правило 30 дней». Если по истечении этого срока понимаете, что вещь всё-таки нужна — покупаете или копите на неё. А если 30 дней слишком много, то подумайте хотя бы несколько. В любом случае это более разумный и ответственный подход, чем покупка по принципу «Здесь и сейчас».</p>" +
                                "<p>Финансово грамотный человек — человек, который умеет управлять и финансами, и эмоциями в отношении финансов.</p>" +
                                "<p><strong><span class='green'>Непредвиденные</span></strong></p>" +
                                "<p>Если бы не эти траты, финподушку, наверное и не придумали… Это все то, что раздражает сильнее всего — грипп перед праздниками, прорвавшая труба в ванной или сломавшийся компьютер и прочие  кошмары для финансовой стабильности. В такие моменты кажется, что все как назло вылезло. Но если разобраться, окажется, что часть событий можно было избежать, а к другой части — подготовиться.</p>" +
                                "<p>Все довольно просто. По здоровью — старайтесь проводить чекап, как минимум по тем органам, которые беспокоят больше всего. Если это ОРВИ или другая инфекция, вспомните, когда чаще всего болели — так можно предупредить следующее заболевание или начать выделять деньги заранее. По компьютеру — тоже проведите осмотр. Определите, что и когда нужно поменять или починить в первую очередь, а что не критично и может подождать. С сантехникой ситуация повторяется: проверяете, оцениваете, начинаете заранее откладывать.</p>" +
                                "<p><strong><span class='green'>Невидимые</span></strong></p>" +
                                "<p>Наша главная задача — стараться двигаться по запланированному бюджету. Сделать так, чтобы оставшиеся деньги не разлетелись на мелочевку и не закончились за первые две недели месяца, оставляя в вечном ожидании следующей зарплаты и заставляя залезать в сбережения.</p>" +
                                "<p>И здесь часто мешают невидимые траты — те, что не попадают в ваше поле зрения, потому что слишком мелкие. Это такси, кофе перед началом рабочего дня, перекусы, сигареты и т.д. Как правило, за месяц они съедают немало денег.</p>" +
                                "<blockquote>" +
                                "<p>В конце месяца Данияр получает зарплату в 350 000 тенге. Он живет в 20 минутах ходьбы от работы, но ежедневно заказывает такси за 600 тенге. Он мог бы пройтись пешком, но спит до последнего, чтобы собраться впопыхах, а после проклинать свою жизнь. Перед началом дня он верно следует другой вредной привычке — берет стакан кофе у входа в офис. За это он платит ещё 1 000 тенге. Ходить пешком обратно Данияр тоже не любит — хочет побыстрее приехать домой.</p>" +
                                "<p>Теперь посчитаем.</p>" +
                                "<p>~ 1 200 в день — это 25 200 тенге за 21 рабочий день. Ещё кофе — 21 000 тенге. Всего: 46 200 тенге. Это 13,2 % от заработной платы Данияра. Если Данияра устраивает такой расклад, это тоже ок. Главное, что увидев эту сумму в своем бюджете, Данияр будет знать, что и в каких объемах можно оптимизировать при необходимости.</p>" +
                                "</blockquote>" +
                                "<p>Бороться с невидимым врагом сложно. Но помочь может комбо нашей таблицы с фиксацией дневного бюджета и приложение «Тяжеловато», которое показывает, сколько у вас еще осталось. Просто вбейте сумму, которую рассчитала таблица в «Бюджете на месяц». Например, это 100 000 тенге в месяц (30 дней). Приложение посчитает, что в день вы можете потратить не более 3 333 тенге. Ваша задача — не выйти за его пределы.</p>" +
                                "<p>Ваши действия просты. Получили бюджет на день. Вписываете ваши траты в течение дня в Заметки на телефоне, а сумму фиксируете в приложении. Оно в ответ говорит вам, сколько денег вы еще можете потратить сегодня. Вы не выходите за этой предел, а вечером вносите свои данные в нашу таблицу.</p>" +
                                "<p>Не бойтесь длинной рутины. Это как занятия в зале — сначала кажется слишком большим усилием, а потом становится полезной привычкой, которая улучшает ваше состояние. Здесь эффект такой же. </p>" +
                                "<img src='"+require('@/assets/images/visuals/img-8.png')+"'/>\n" +
                                "<h2>Шаг четвертый. Составляем бюджет на год</h2>" +
                                "<img src='"+require('@/assets/images/visuals/img-2.png')+"'/>\n" +
                                "<p>Вы уже знаете, как вести свой месячный бюджет. Попробуйте пожить с ним как минимум 3 месяца. Этого срока хватит, чтобы вы выследили нежелательные траты и тенденции и начали менять свое финансовое поведение.</p>" +
                                "<p>Как только вы приведете в порядок свой месячный бюджет, вы захотите идти дальше. Планировать месяц, знать свои расходы на ближайшие 30 дней и наслаждаться тратами – приятно. Но куда приятнее быть уверенными в тратах на 365 дней. Конечно, никто не может предсказать непредвиденное. Но быть готовыми, вооруженными, спокойными – это мы можем.</p>" +
                                "<p>Обращаемся опять к таблице. Теперь – к листу Бюджет ГОД.</p>" +
                                "<p><strong><span class='green'>В таблице:</span></strong></p>" +
                                "<ol>" +
                                "<li>Внесите в строку Повседневные траты сумму своих месячных затрат (теперь вы ее знаете). Из таблицы бюджета это все ваши расходы за исключением Обязательных и необходимых трат.</li>" +
                                "<li>Далее внесите Обязательные и Необходимые траты.</li>" +
                                "<li>Далее – приступите к планированию. На что вы планируете потратиться в течение года? Чек-ап здоровья? Отдых? Сезонная одежда? Все идеи звучат замечательно. Внесите их стоимость в соответствующую графу. Например, отдохнуть вы хотите один раз в год, а вот купить одежду надо как минимум два.</li>" +
                                "<li>Таблица посчитает ваши результаты и выдаст вам примерный годовой бюджет. Вот такую сумму вы собираете потратить в течение года.</li>" +
                                "<li>Продолжайте возвращаться к таблице каждый месяц, чтобы корректировать суммы затрат.</li>" +
                                "</ol>" +
                                "<p>Зачем составлять такую таблицу? Это дает понимание вашей жизни и ваших трат. Не бойтесь, если сумма выходит большая. Это сумма – ваша комфортная жизнь, и вы ее заслуживаете.</p>" +
                                "<p>Наш следующий урок посвящен постановке и достижению финансовых целей. Как раз для того, чтобы вы не боялись больших сумм ваших расходов и желаний.</p>" +
                                "<p>Двигаемся дальше?</p>"
                        },
                        dictionary: [
                            {
                                id: 1,
                                name: 'Пассивный источник дохода',
                                description: 'тот, который не требует от вас активных действий. Например, доход от депозита, аренды квартиры или дивиденды от акций.'
                            },
                            {
                                id: 2,
                                name: 'Pay yourself first',
                                description: 'принцип, предполагающий, что сперва нужно отложить деньги на будущее, а после — на ежедневные нужды. Термина приписывают разным людям — инвестору и предпринимателю Роберту Кийосаки, писателю Наполеону Хиллу, предпринимателю Джорджу Сэмюэлю Клейсону.'
                            },
                            {
                                id: 3,
                                name: 'Эмоциональная (импульсивная) покупка',
                                description: 'та, что вы совершаете просто потому, что захотели так секунду назад. Ещё одна футболка, дорогая сумка или необязательный перекус, потому что учуяли запах донера.'
                            },
                            {
                                id: 4,
                                name: 'Невидимые траты',
                                description: 'все те, что не требуют долгих размышлений и в общих масштабах выглядят незначительно: такси, кофе перед началом рабочего дня, перекусы. Как правило, за месяц они съедают немало денег.'
                            },
                            {
                                id: 5,
                                name: 'Статья дохода',
                                description: 'любой источник, который гарантирует вам доход: заработная плата, гонорар, дивиденды, вознаграждение по депозиту и т.д.'
                            },
                            {
                                id: 6,
                                name: 'Статья расхода',
                                description: 'любая ваша трата. Может быть бытовой, систематичной (кредиты, рассрочки, коммунальные платежи).'
                            },
                        ],
                        tests: [
                            {
                                id: 1,
                                question: 'Вы собрались посчитать свой месячный бюджет. Какие категории учтете?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Просто укажу все доходы и все расходы, а остаток пойдет на сбережения',
                                        result: 'Боимся, этого будет мало для ясной картины. К тому же, подход «Буду экономить, а оставшееся в конце месяца отложу» — нерабочий. Откладывать нужно в самом начале месяца. А чтобы знать, сколько вы можете отложить, нужно как раз детально посчитать свой месячный бюджет — со всем доходами и расходами.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Посчитаю зарплату и основные расходы. Зачем остальное считать? Каждый месяц все равно по-разному выходит…',
                                        result: 'Нерабочий подход. Во-первых, учитывать нужно не только зарплату, но и все другие источники дохода, если они есть. А не указывая всех категорий трат, будет сложно разобраться в финансовых отношениях: четкое видение поможет скорректировать денежное поведение.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Укажу все возможные источники доходов и расходов.',
                                        result: 'Да! Честность в финансовых отношениях, как и в обычных с людьми, — залог успеха. В список войдут: — Обязательные траты — кредиты, рассрочки, ипотечные займы, и т.д.; необходимые — коммунальные услуги, плата за аренду, продуктовая корзина; на будущее — те самые сбережения, к появлению и сохранению которых мы будем стремиться; повседневные траты — это траты на транспорт, еду вне дома, доставку, кофе, развлечения, походы в ресторан, подарки и т.д.',
                                        correct: true,
                                    },
                                ],
                            },
                            {
                                id: 2,
                                question: 'Хочу посчитать годовой бюджет. Траты за какой месяц брать как образец?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Без разницы',
                                        result: 'Месяц месяцу рознь. В какой-то есть праздники, в какой-то, как правило, вы чаще болеете. Опираться на них — брать в расчет не самые верные для общей картины данные.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Возьму самый затратный',
                                        result: 'Опять-таки, ориентироваться на самый тяжелый месяц — затея не лучшая. Нужно быть готовым, что такие месяца есть, но зачем нам излишний пессимизм при планировании? Давайте ориентироваться на лучшее, хоть и готовясь к худшему.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Посчитаю по всем',
                                        result: 'Когда вы увидите наиболее затратные месяца — например, в ноябре нужно закупиться одеждой на зиму, в декабре подарками, а вот в апреле все спокойно и можно отложить побольше — вам будет легко обрисовать годовой бюджет.',
                                        correct: true,
                                    },
                                ],
                            },
                            {
                                id: 3,
                                question: 'Получил зарплату. Когда лучше отложить деньги?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Под конец месяца',
                                        result: 'Воплотить такой план в реальность будет сложно — много соблазнов. Скорее всего, от изначальной суммы к концу месяца у вас ничего не останется. Или останется гораздо меньше, чем могло бы.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Первым делом',
                                        result: 'Хорошо, но слишком оптимистично. Главное, что нужно делать при получении дохода, — избавиться от финобязательств: квартплаты, кредитов, рассрочек и т.д. Иначе пеня, испорченная история и, в худшем случае, судебные иски.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'После выплаты всех финобязательств',
                                        result: 'Лучший вариант: вы избавились от выплат по кредиту, рассрочке, квартплате и дышать стало легче. Теперь можно отложить деньги на сбережения.',
                                        correct: true,
                                    },
                                ],
                            }
                        ]
                    },
                    {
                        id: 3,
                        number: 3,
                        name: 'Ставим финансовые цели',
                        slug: 'stavim-finansovye-celi',
                        duration: '20 мин',
                        bgImage: require('@/assets/images/lessons/lesson-3.png'),
                        content: {
                            shortDescription: "<h2>Вижу цель: Карты, деньги, ипотека</h2>" +
                                "<p>Научим ставить цели и достигать их. А еще понимать, что вам по-настоящему нужно, а что — нет. Больше никаких абстракций в вашем будущем.</p>",
                            shortList: "<h2>Что будет в уроке:</h2>\n" +
                                "<div><p>Расскажем, как использовать полученные знания для установки финансовых целей, какими они бывают и какие нужны вам.</p></div>\n" +
                                "<div><p>Выясним, что мешает достижению намеченных планов, а главное — как убрать преграды с пути.</p></div>\n" +
                                "<div><p>Оценим прогресс и разберем варианты, когда что-то может пойти не так.</p></div>",
                            description: "<h2>Что такое финансовые цели?</h2>" +
                                "<p>Каждый год американский журнал Forbes публикует список самых богатых людей мира. И пока Джефф Безос соревнуется с Илоном Маском в количестве миллиардов, нам остается вздохнуть и помечтать. Мы очень хотим быть как они: не переживать из-за приближающихся дат выплат по кредитам, поднимающихся цен на аренду квартиры и продуктовой корзины, и красоваться на обложке красивого глянцевого журнала. Но сколько бы мы ни читали советов, как живут богатые люди — встают в 5 утра, читают по книге в неделю (или в день?), работают в выходные — мы не становимся ближе к нашей мечте. И вот почему. </p>" +
                                "<p>Быть богатым человеком — мечта. Неконкретная, безграничная, неосязаемая. Одно лишь желание разбогатеть не приведет вас к многомиллионным счетам швейцарском банке, потому что у него (вашего желания) нет деталей, мотивации, четко расписанного плана, и, давайте будем честными, какого-либо описания. </p>" +
                                "<p>Что такое — быть богатым? Это десять миллионов тенге или сто? Это иметь квартиру или яхту? Это путешествовать или получать образование? Это дорогая одежда или драгоценности? Или все вместе? А сколько каждого?</p>" +
                                "<p>И здесь пора познакомиться с финансовыми целями. </p>" +
                                "<p>Финансовая цель — это то, что вы планируете достичь с помощью денег. Каждая финансовая цель имеет три составляющие:</p>" +
                                "<ul>" +
                                "<li>Что вы хотите?</li>" +
                                "<li>Сколько это стоит?</li>" +
                                "<li>Сколько времени нужно, чтобы это купить/достичь?</li>" +
                                "</ul>" +
                                "<p>Финансовая подушка, пенсионный фонд, покупка дома или автомобиля, погашение кредитов — примеры финансовых целей.</p>" +
                                "<p>Ставить финансовые цели очень важно. Таким образом в вашей жизни появляется больше конкретики. Например, не копить деньги ради денег — а копить деньги на Kia Rio за 7 млн тенге, которую нужно купить к рождению ребенка (вы планируете его в следующем году), чтобы не возить его на автобусе. Сразу появилась мотивация, дедлайн, и примерный расчет.</p>" +
                                "<p>Если вы не работаете над чем-то конкретным, вы, вероятно, потратите больше, чем должны были. Или скопите меньше. Или вообще так и не осуществите ни одно из своих желаний. А Илон Маск так и будет зарабатывать миллиарды и смотреть на вас со страниц глянцевых журналов.</p>" +
                                "<h2>Какие бывают цели?</h2>" +
                                "<p>У финансовых целей есть категоризация. Желаний может быть много, и каждое из них удобнее разделить согласно временному отрезку, который необходим для их достижения. Такая классификация поможет вам визуализировать цели, сделать их более ясными и соответственно подстроиться под них. Как только вы установите сроки, ваша жизнь обретет границы и четкое направление. Это сделает вас более продуктивным и эффективным.</p>" +
                                "<p><i>Итак, 3 типа целей:</i></p>" +
                                "<ul>" +
                                "<li>" +
                                "<h2>Краткосрочные цели</h2>" +
                                "<p>Это то, чего вы хотите достичь в обозримом будущем в течение следующих нескольких месяцев — от трех месяцев до года. Такие цели, как правило, меньше по объему в денежном эквиваленте. Например, закрыть быстрее рассрочку на айфон, сделать косметический ремонт или купить предмет мебели. Вы же давно мечтали о том синем диване? Или удобном кресле? А как же полка для книг?</p>" +
                                "<p>Постановка и выполнение краткосрочных финансовых целей выполняет важную дополнительную функцию — дает вам уверенность в себе. А она точно понадобится для достижения более крупных целей, требующих больше времени и денег. </p>" +
                                "</li>" +
                                "<li>" +
                                "<h2>Среднесрочные цели</h2>" +
                                "<p>Краткосрочные цели планируются на год, долгосрочные — на десятилетия, а среднесрочные находятся между ними. Это цели, на достижение которых потребуются больше года, но меньше десяти лет.</p>" +
                                "<p>Кстати, иногда нужно сначала достигнуть среднесрочных целей, чтобы потом достигнуть долгосрочных. Например, накопить на первоначальный взнос на квартиру. Квартира — большая долгосрочная цель, а первоначальный взнос на нее — среднесрочная, но меньше по объему. Таким образом вы можете отслеживать ваш прогресс по достижению желаемого именно по среднесрочными целям — проверять, движетесь ли вы в правильном направлении.</p>" +
                                "<p>Но самодостаточной и независимой среднесрочная цель тоже может быть. Купить автомобиль или накопить на первый год жизни ребенка — вполне себе самостоятельные классные цели.</p>" +
                                "</li>" +
                                "<li>" +
                                "<h2>Долгосрочные цели</h2>" +
                                "<p>Долгосрочные цели требуют большего обдумывания и денег. Выход на пенсию, покупка дома или квартиры и финансирование высшего образования вашего ребенка — пример типичных долгосрочных целей. Такие планировать тяжело, потому что срок очень далекий, а суммы большие. И даже думать о таких цифрах иногда становится страшно (ведь только год в престижном университете в США стоит от 50 тыс. долларов). Но далекий срок цели — ваш соратник, ведь время будет играть вам на руку.</p>" +
                                "<p>Например, рассмотрим очень легкий пример с пенсией. Абсолютно все когда-то выйдут на пенсию. Но как и когда вы выйдете, а также в каких условиях будете жить — то, что можно запланировать. Представим, что в месяц на пенсии вы хотите иметь возможность тратить 500 000 тенге, и ориентировочно на пенсии вы проживете 30 лет. 500 000 тенге в месяц = 6 миллионов тенге в год = 180 миллионов тенге за 30 лет. Выглядит, как очень большая сумма денег (ей и является). </p>" +
                                "<p>Но! До пенсии вам еще 34 года. И если вы начнете откладывать по 20 000 тенге каждый месяц в течение этих 34 лет, при текущей ставке в 15% вы накопите 259 005 471 тенге. Это очень грубый подсчет, и процентная ставка, скорее всего, будет колебаться, и, конечно, нужно учитывать рост инфляции, но это наглядный пример, как можно поставить цель, определить ее стоимость и временной отрезок, а потом рассчитать, как ее достичь.</p>" +
                                "</li>" +
                                "</ul>" +
                                "<p>Такая категоризация позволяет внести ясность в ход вашей жизни. Но это еще не все. </p>" +
                                "<h2>Как достигать целей?</h2>" +
                                "<p>Понять, сколько стоит цель и просто поделить ее стоимость на количество времени — полдела. Теперь каждую цель нужно проверить на прочность. Сможете ли вы (и нужно ли?) реально ее достичь?</p>" +
                                "<p>Для этого есть специальная формула, через которую надо прогонять все финансовые цели. Эту формулу, кстати, можно применять не только к финансам, а, например, целям в ваших личных отношениях. В общем, если вы возьмете ее в привычку, выиграете везде. </p>" +
                                "<p>Итак, ваши мечты должны быть SMART. Это аббревиатура на английском языке, которая гласит, что каждая из ваших целей должна быть Конкретной (Specific), Измеримой (Measurable), Достижимой (Achievable), Актуальной (Relevant) и Иметь срок (Time-bound). </p>" +
                                "<p>Предположим, вы хотите создать финансовую подушку. Вот как это выглядит, если применять формулу SMART:</p>" +
                                "<p><strong><span class='green'>Конкретизация</span></strong></p>" +
                                "<p>Я хочу финансовую подушку, чтобы она покрывала расходы трех месяцев моей обычной комфортной жизни. Из своей таблицы бюджета я знаю, что трачу каждый месяц 400 000 тенге. </p>" +
                                "<p>Значит, я хочу создать подушку в размере 1 200 000 тенге.</p>" +
                                "<p><strong><span class='green'>Измеримость</span></strong></p>" +
                                "<p>Я хочу скопить половину суммы — 600 000 тенге — за полгода. Это 100 000 тенге в месяц или 3 400 тенге в день. Ага, теперь есть осечки, где можно проверять результат.</p>" +
                                "<p><strong><span class='green'>Достижимость</span></strong></p>" +
                                "<p>Каждый месяц у меня остается около 80 000 тенге, которые я могу откладывать, что не позволяет мне копить по 100 000 тенге в месяц. Однако, после ревизии бюджета появились категории, которые я смогу оптимизировать, и выйти на 100 000 тенге в месяц. Эта цель для меня достижима.</p>" +
                                "<p><strong><span class='green'>Релевантность</span></strong></p>" +
                                "<p>Очень важный пункт, в котором нужно критически подойти к оценке цели. Спросите себя, действительно ли она сделает вас счастливее? Если условия и уровень вашей жизни улучшатся, то цель стоит достижения. Если же она принесет минутное удовольствие и не будет иметь продолжительного эффекта, оценивайте контекст. Можете ли вы себе это позволить?</p>" +
                                "<p>В нашем примере подушка безопасности — важный пункт на дороге к финансовой независимости. Если вы накопите хотя бы минимальный ее размер (3 месяца), вам станет легче жить даже психологически. Ведь внешние потрясения, которые вы не можете контролировать, больше не будут иметь над вами власти. У вас будет к кому обратиться за помощью. К себе — а не в банк, к друзьям или родителям. Звучит потрясно. Надо делать. </p>" +
                                "<p><strong><span class='green'>Срок</span></strong></p>" +
                                "<p>Я хочу накопить свою подушку в 1 200 000 тенге в течение одного года, чтобы потом перейти к другим финансовым целям. Хочу с каждым годом пополнять копилку выполненных финансовых целей.</p>" +
                                "<p>Конечно, у вас может быть и не одна цель. Их может быть и 5, и 10. Но пробежавшись по ним с формулой, вы сможете понять свои возможности и критически их оценить. Некоторые отойдут на более дальний срок, некоторые исчезнут вообще, а некоторые обретут высокий приоритет. Звучит, как план. </p>" +
                                "<h2>С каких целей начать?</h2>" +
                                "<p>Никто не может и не должен диктовать вам, какие цели важны и неважны для вашего благополучия. У каждого человека своя уникальная ситуация, и не всегда общие советы могут подойти личным обстоятельствам.</p>" +
                                "<p>Однако есть несколько целей, которые можно назвать универсальными. Они подойдут для всех и уж точно никому не помешают. </p>" +
                                "<p>1. <strong><span class='green'>Подушка безопасности</span></strong></p>" +
                                "<p>Резервный фонд, подушка безопасности или финансовая подушка — все это обязательный финансовый инструмент для достижения финансовой независимости. Простыми словами, это определенная сумма денег, которую можно потратить в непредвиденных обстоятельствах: пандемия, сокращение на работе, несчастный случай и тд. То, от чего, никто не застрахован, но что повлияет на вашу возможность зарабатывать.</p>" +
                                "<p>Помните, когда началась пандемия и многие были вынуждены покинуть рабочие места? Если быть точными, 4 миллиона 200 тысяч казахстанцев остались без работы с марта по апрель 2020 года. А платить по кредитам, рассрочкам и ипотекам нужно было продолжать, долги возвращать. Государство пыталось помочь — выдавали пособия и отсрочки, но стресс, в который погрузились более четырех миллионов людей, вряд ли можно назвать приятным. Отсрочки истекают, платежи выставляют, а работу найти сложно. </p>" +
                                "<p>Есть минимальная и стабильная подушка безопасности. Минимальная — в размере трёх ежемесячных расходов, а стабильная должна составлять две минимальные подушки, то есть расходы шести месяцев. Можно накопить сумму и в размере 12-месячных расходов, но это уже менее оптимальный вариант. Ведь у вас, скорее всего, есть и другие финансовые цели, которые тоже ждут своего часа. Фокус только на одной забирает энергию, время и мотивацию достигать другие. </p>" +
                                "<p>Имейте в виду, в сумму расходов входят оплаты всех обязательных и необходимых платежей — оплаты по кредитам, выплаты долгов, арендная плата, продукты, расходы на транспорт и тд. Не пытайтесь преуменьшить эту сумму. Ведь если возникнет необходимость в резерве, вы будете благодарны себе из прошлого, что смогли учесть все строчки расходов. </p>" +
                                "<p>Где хранить подушку — тоже важный вопрос. Инструмент, которому вы доверите свой фонд должен быть: безопасным, ликвидным (это значит, что у вас будет возможность получить быстрый доступ к деньгам), и должен покрывать инфляцию хотя бы частично (чтобы ваши годовые накопления не испарились под ростом цен). </p>" +
                                "<p>Рекомендуемые инструменты:</p>" +
                                "<ol>" +
                                "<li>Наличные — самый ликвидный инструмент, который обеспечит деньгами моментально. Наличные выходят из моды, но в ситуации, когда не работает сотовая связь и интернет, именно они окажут первую и такую необходимую помощь. Минусы наличных — менее безопасны (их могут украсть) и теряют свою стоимость по прошествии времени. Но это риск, который, по сравнению с их спасательной возможностью, можно взять.</li>" +
                                "<li>Депозиты — безопасный инструмент, который покрывает инфляцию, но менее ликвидный, чем наличные.</li>" +
                                "</ol>" +
                                "<p><i>(В уроке номер 5 мы детально рассмотрим каждый инструмент, а также их плюсы и минусы).</i></p>" +
                                "<p>Рекомендуется миксовать инструменты. Оставьте 30% накоплений в наличных, а остальное положите на депозит. Таким образом вы покроете нестандартные ситуации с исчезновением интернета, но и всю сумму на инфляции не потеряете.</p>" +
                                "<p>Подушка — хорошая первая цель. Во-первых, это цель с высоким приоритетом, которая сразу придаст вам уверенности для выполнения следующих. Во-вторых, нестабильный внешний мир теперь имеет над вами намного меньше власти. А это всегда хорошие новости. </p>" +
                                "<p>2. <strong><span class='green'>Вторая цель, которую советуют поставить все финансовые эксперты — закрыть кредиты.</span></strong></p>" +
                                "<p>Звучит уже страшно и нереалистично, но чтобы улучшить финансовое состояние, надо постепенно освобождаться от кредитов по следующим причинам: </p>" +
                                "<ol>" +
                                "<li>" +
                                "<p>Чем быстрее вы сможете погасить кредит, тем меньше он будет стоить вам в виде процентов. Ведь вы, наверное, замечали, что чем выше ставка кредита, тем выше общая переплата за него. </p>" +
                                "<p>Представим, что вы взяли кредит в 1 500 000 тенге, чтобы купить технику домой. Чтобы в месяц выплачивать подъемную для вас сумму в 94 900 тенге, вы выбрали срок кредита 2 года. Звучит хорошо: техника есть, ежемесячный платеж вы оплачивать можете, а два года пролетят незаметно. </p>" +
                                "<p>Но переплата за такой кредит составляет около 800 000 тенге — практически половина вашего кредита. А все потому что его ставка более 40%. То есть взяли вы 1,5 млн, а должны 2,3 млн. И именно поэтому так важно закрывать кредиты раньше — чтобы стоили они для вас меньше. </p>" +
                                "</li>" +
                                "<li>В случае просрочки кредиты негативно влияют на вашу возможность исполнять большие финансовые цели. Если вы испортите кредитную историю, банк не выдаст вам льготную ипотеку или автокредит. А это — активы, которые могут улучшить ваши условия жизни. Еще обидно, если вам откажут в ипотеке, потому что у вас большая кредитная нагрузка — вы уже взяли много кредитов, и банк не доверит выдать вам новый.</li>" +
                                "<li>Также кредиты составляют нагрузку на ваш бюджет. В вашей бюджетной таблице вы, наверное, заметили, как много от вашего дохода забирают обязательные выплаты по кредитам. Месяц за месяцем огромные суммы, которые вы могли бы потратить на себя, идут в прибыль банкам и другим финансовым организациям. Как только вы освободитесь от этого бремени, вы сможете направлять свободные деньги на выполнение других финансовых целей, а значит, повышать свой уровень жизни.</li>" +
                                "</ol>" +
                                "<p>Как видите, содержать кредиты невыгодно. Особенно с высокой процентной ставкой. </p>" +
                                "<p><i>(Как правильно и выгодно выплачивать кредиты мы детально разберем в следующем уроке)</i></p>" +
                                "<p>А пока совет — проводите критическую оценку каждого кредита. Ипотека или автокредитование могут быть хорошим решением, потому что вы приобретаете актив, который увеличит ваш достаток, возьмет на себя какие-то расходы, а в будущем даже принесет прибыль. Кредит на покупки или наличные — менее желаемая цель. На такие кредиты большая процентная ставка, и вещи, которые вы купите в кредит, в течение времени потеряют свою ценность. На такие расходы оптимальнее ставить краткосрочные цели и копить. </p>" +
                                "<p>Существует спор, что нужно сделать в первую очередь — закрыть кредиты или создать финансовую подушку. С одной стороны, непредвиденные обстоятельства могут загнать вас в еще большую кредитную яму. А с другой, ждать, пока накопится подушка, дорого — проценты по кредиту будут съедать то, что могло бы быть вашими накоплениями.</p>" +
                                "<p>Конечно, лучший вариант — это совмещать, но не у всех есть такая возможность. В таком случае оцените вашу ситуацию. </p>" +
                                "<ul>" +
                                "<li>Насколько спокойно и уверенно вы чувствуете себя в отношении своего дохода</li>" +
                                "<li>Есть ли у вас уже какие-то накопления</li>" +
                                "<li>Как много долгов</li>" +
                                "<li>Есть ли поддержка со стороны</li>" +
                                "</ul>" +
                                "<p>Выписав за и против на обе цели, вы сможете понять, куда направить энергию в первую очередь. </p>" +
                                "<h2>Последний шаг. Давайте вести учет! </h2>" +
                                "<p>Держать все в голове и надеяться на феноменальную память, как вы поняли к этому уроку, неэффективное решение. Если вы решили ставить и достигать, надо подходить к задаче комплексно.</p>" +
                                "<ol>" +
                                "<li>Выпишите все цели, которые хотели бы достигнуть в течение жизни. Не стесняйтесь и не бойтесь своих желаний. Пусть их будет много — если вы готовы их выполнять, почему бы и нет?</li>" +
                                "<li>Проведите оценку целей по формуле SMART</li>" +
                                "<li>Реорганизуйте список, согласно приоритезации целей. Из предыдущего шага вы должны понять, что сейчас вам необходимо и достижимо, а что можно оставить в целях, но приступить позже.</li>" +
                                "<li>Выберите цель, которую закроете первой. Обозначьте дату старта, график накоплений, срок окончания</li>" +
                                "<li>Теперь давайте использовать таблицу.</li>" +
                                "</ol>" +
                                "<img src='"+require('@/assets/images/visuals/img-3.png')+"'/>\n" +
                                "<p>Внесите данные, которые вы только что для себя обозначали:</p>" +
                                "<ul>" +
                                "<li>срок окончания — когда вы хотите достичь эту цель</li>" +
                                "<li>ее сумма</li>" +
                                "<li>и какой инструмент с какой доходностью будете применять (урок по фининструментам впереди, прочитайте его, если сомневаетесь)</li>" +
                                "</ul>" +
                                "<p>Как только вы укажите сумму, срок и ставку, таблица выдаст вам ежемесячный платеж. Именно столько вам нужно откладывать каждый месяц, чтобы добиться цели в нужный срок и при текущей доходности.</p>" +
                                "<p>В таблицах снизу можете начать фиксировать достижения по каждой цели отдельно для себя. Отличным способом к мотивации будет поставить себе какие-то осечки, по которым можно будет оценивать успех. Например, к октябрю согласно плану должно выйти 600 000 тенге. А потом в октябре вернитесь к таблице и проверьте, все ли идет согласно вашему же плану.</p>" +
                                "<p>С таблицей перепады будут видны, что позволит вам адаптироваться под ситуацию. Если к одному из этапов вы скопили меньше, вам придется принимать решение — увеличить ежемесячный платеж или растянуть итоговый срок накопления.</p>" +
                                "<p>Такое же решение придется принимать, если желаемая сумма изменилась. Например, вы копите на ремонт. По вашим расчетам вам понадобится 6 000 000 тенге. Однако в следующем году может произойти высокий рост цен на стройматериалы, а значит, понадобится уже 8 000 000 тенге.</p>" +
                                "<p>Но не надо бояться таких изменений. Это жизнь, и финансы, как большая ее часть, тоже подвержены изменениями. Главное, что у вас есть все инструменты, которые помогут справиться в такими колебаниями. Что-то будет дорожать, а что-то дешеветь, но вы, ваши планы и ваши сбережения заслуживают того, чтобы случаться. </p>"
                        },
                        dictionary: [
                            {
                                id: 1,
                                name: 'Финансовая цель',
                                description: "это то, что вы планируете достичь с помощью денег. Каждая финансовая цель имеет три составляющие:" +
                                    "<ul>" +
                                    "<li>Что вы хотите?</li>" +
                                    "<li>Сколько это стоит?</li>" +
                                    "<li>Сколько времени нужно, чтобы это купить/достичь?</li>" +
                                    "</ul>" +
                                    "Финансовая подушка, пенсионный фонд, покупка дома или автомобиля, погашение кредитов — примеры финансовых целей."
                            },
                            {
                                id: 2,
                                name: 'Формула SMART',
                                description: 'это подход, при котором каждая из ваших целей должна быть Конкретной (Specific), Измеримой (Measurable), Достижимой (Achievable), Актуальной (Relevant) и Иметь срок (Time-bound). '
                            },
                            {
                                id: 3,
                                name: 'Краткосрочные цели',
                                description: 'это то, чего вы хотите достичь в обозримом будущем в течение следующих нескольких месяцев — от трех месяцев до года. Такие цели, как правило, меньше по объему в денежном эквиваленте. Например, закрыть быстрее рассрочку на айфон, сделать косметический ремонт или купить предмет мебели.'
                            },
                            {
                                id: 4,
                                name: 'Среднесрочные цели',
                                description: 'краткосрочные цели планируются на год, долгосрочные — на десятилетия, а среднесрочные находятся между ними. Это цели, на достижение которых потребуются больше года, но меньше десяти лет.'
                            },
                            {
                                id: 5,
                                name: 'Долгосрочные цели',
                                description: 'долгосрочные цели требуют большего обдумывания и денег. Выход на пенсию, покупка дома или квартиры и финансирование высшего образования вашего ребенка — пример типичных долгосрочных целей. Такие планировать тяжело, потому что срок очень далекий, а суммы большие.'
                            },
                        ],
                        tests: [
                            {
                                id: 1,
                                question: 'Как поставить цель?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'определить приоритетность и распределить по SMART пунктам',
                                        result: 'Да, это хороший способ, который позволяет посмотреть на цель со стороны и оценить ее достижимость и значимость.',
                                        correct: true,
                                    },
                                    {
                                        id: 2,
                                        answer: 'рассчитать, сколько нужно денег в каждом месяце, и начать копить',
                                        result: 'Это правильное действие, но его может быть недостаточно. Важно еще учесть ряд других факторов: нагрузку на бюджет, доходность от депозита (если есть), инфляцию и т.д.).',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'сперва закрыть все обязательства, а потом уже ставить цели',
                                        result: 'Не всегда наличие обязательств мешает достижению целей. А даже если так, то это временно. С четким планом будет легче понять, когда вы освободитесь от фин.обязательств, а значит, и когда начнет достигать цели.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 2,
                                question: 'Лучшие цели перед постановкой других?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'накопить подушку',
                                        result: 'Подушка — хорошая первая цель. Во-первых, это цель с высоким приоритетом, которая сразу даст уверенности для выполнения следующих. Во-вторых, у нестабильного внешнего мира будет меньше власти.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'закрывать кредиты и копить подушку',
                                        result: 'Лучший вариант — это совмещать, но не всегда есть такая возможность. В таком случае оцените вашу ситуацию.',
                                        correct: true,
                                    },
                                    {
                                        id: 3,
                                        answer: 'закрыть кредиты',
                                        result: 'Решение тоже хорошее. Чем быстрее погасите кредит, тем меньше он будет стоить в виде процентов. Вы, наверное, замечали, что чем выше ставка кредита, тем выше общая переплата.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'взять в рассрочку что-то, что может помочь зарабатывать больше',
                                        result: 'Здесь нужна осторожность. Насколько стабилен будет такой источник дохода? Если в вы нем уверенны, то при небольшой нагрузке можно и рассмотреть подобный заем.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 3,
                                question: 'Почему важен точный срок для цели?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Чтобы понять, сколько ежемесячно нужно сбережений',
                                        result: 'Выбрав цель и указав сумму, важно поделить ее на дни/месяцы/годы для систематических сбережений.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Чтобы рассчитать возможную инфляцию',
                                        result: 'Конечно, спрогнозировать инфляцию сложно. Этим занимаются эксперты и не всегда им это удается. Но с небольшой погрешностью это сделать можно. Посмотрите ее уровень на сайте госстатистики, почитайте комментарии экспертов — и учитывайте средний процент при расчетах.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Чтобы посчитать пассивный доход, и учесть эти данные. Например, доход от вашего депозита',
                                        result: 'Если считать инфляцию дело не из приятных, то здесь наоборот — узнаете, сколько у вас будет денег всего.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Все варианты',
                                        result: 'Как только вы установите сроки, ваша жизнь обретет границы и четкое направление. Это сделает вас более продуктивным и эффективным.',
                                        correct: true,
                                    }
                                ],
                            }
                        ],
                    },
                    {
                        id: 4,
                        number: 4,
                        name: 'Берём и погашаем кредит',
                        slug: 'beryom-i-pogashaem-kredit',
                        duration: '34 мин',
                        bgImage: require('@/assets/images/lessons/lesson-4.png'),
                        content: {
                            shortDescription: "<h2>Учимся брать и погашать кредит правильно</h2>\n" +
                                "<p>Это самый большой урок курса, в котором вы посмотрите в глаза самому распространённому страху казахстанцев — кредитам, и научитесь рассматривать их как финансовый инструмент для достижения целей.</p>" +
                                "<p>Читать интересно и удобно: понятные примеры, практичные советы и детали, о которых вам не расскажут банкиры. Мы разделили его на три блока: как брать банковский заём, как выгодно погасить его и зачем вам нужна кредитная история.</p>",
                            shortList: "<h2>Что будет в уроке:</h2>\n" +
                                "<div><p>Узнаете, почему человек с кредитами может быть более финансово грамотным, чем живущий по принципу «‎без»‎.</p></div>\n" +
                                "<div><p>Разберётесь, какие условия предлагают банки и на какие пункты обратить внимание при подаче заявки.</p></div>\n" +
                                "<div><p>На простых примерах выясните, в каких случаях можно брать кредиты, а в каких — нет необходимости.</p></div>" +
                                "<div><p>Откроете для себя кредитную историю и посмотрите на себя глазами банков.</p></div>" +
                                "<div><p>Научитесь нехитрым стратегиям погашения, а если окажетесь в затруднительной ситуации по кредитам — мы подскажем, как и куда обратиться за помощью.</p></div>",
                            description: "<p><strong><span class='green'>Каждый второй казахстанец имеет дело с кредитами</span></strong></p>" +
                                "<p>По данным Первого кредитного бюро, 77 % экономически активного населения страны — это 7,2 из 9,2 миллиона человек, выплачивают кредит. Четверть из них имеют просрочку по платежам — это примерно 1,8 миллиона заёмщиков.</p>" +
                                "<p>При этом со всех страниц курсов по финграмотности твердят: избавьтесь от кредитов и тогда заживёте. Но статистика показывает, насколько востребован ресурс для казахстанцев.</p>" +
                                "<p>На самом деле человек с ипотекой, айфоном в рассрочку и бизнесом может быть более финансово грамотным, чем живущий по принципу «без кредитов».</p>" +
                                "<blockquote>" +
                                "<p>Вспомните, у вас наверняка есть друг или знакомый, который работает «как все» и зарабатывает «как все». Но у него уже своя квартира, а вчера он закрыл досрочно кредит на авто, ничуть не снизив свой уровень жизни. Откуда средства, если при таких же условиях дохода вы живёте по другому сценарию: деньги куда-то исчезают в первые две недели, дальше вы экономите, а после — грустно ждёте зарплату в конце месяца.</p>" +
                                "</blockquote>" +
                                "<p><strong><span class='green'>Знакомая ситуация? Давайте разбираться вместе</span></strong></p>" +
                                "<p>С одной стороны, опасаясь страшных историй о коллекторах и долгах, вы можете упускать возможности: например, ипотеку с минимальной переплатой или льготный автокредит. С другой стороны, можете бездумно брать невыгодные займы, и все больше утопать в болоте кредитов.</p>" +
                                "<p>Получается палка о двух концах, на каждой стороне — отсутствие практичной информации и инструкции к применению. В самом большом уроке этого курса будем учиться балансировать, потому что кредит — не добро, и не зло. Это финансовый инструмент, которым нужно уметь пользоваться.</p>" +
                                "<h2>Берём кредит</h2>" +
                                "<p>Кредитуют банки, микрофинансовые и микрокредитные организации, ломбарды, родственники, друзья. У всех свои условия и подводные камни: от высоких требований, тройной переплаты, порции стресса и до испорченных отношений.</p>" +
                                "<p>Мы разберём самый популярный и безопасный вариант — банковские кредиты.</p>" +
                                "<p>Все кредиты можно собрать в две категории: <strong><span class='green'>целевой — ипотека, автокредит или оборудование для бизнеса; и нецелевой — наличные, которые тратятся на усмотрение заёмщика</span></strong>. Мы будем преимущественно говорить о потребительских нецелевых кредитах, потому что это самый востребованный вариант, а ещё — самый невыгодный.</p>" +
                                "<p>С виду всё проще некуда: минута на рассмотрение, без залога, вы получаете деньги сразу на руки. Но за ширмой — немыслимая процентная ставка, переплата в сумму кредита, а иногда — даже больше.</p>" +
                                "<blockquote>" +
                                "<p>Реальный пример: в некоем популярном банке кредит наличными в 1 миллион тенге может обойтись вам в 1 177 949 тенге процентов переплаты сверху. За четыре года вы оплатите больше двух миллионов вместе с основным долгом. Намного проще будет накопить нужную сумму. А вот с кредитом ежемесячное обязательство будет мешать не только накоплению, но и планам по досрочному погашению.</p>" +
                                "</blockquote>" +
                                "<p>Давайте сравним два случая</p>" +
                                "<p>1. <strong><span class='green'>Вы взяли кредит в банке</span></strong></p>" +
                                "<p>На миллион, который взяли в кредит, купили новый диван в гостиную и семейный тур в Турцию. Теперь ваши обязательства:</p>" +
                                "<ul>" +
                                "<li>45 375 отдаёте каждый месяц на оплату кредита;</li>" +
                                "<li>выплачиваете долг четыре года.</li>" +
                                "</ul>" +
                                "<p>2. <strong><span class='green'>Вы решили накопить ту же сумму</span></strong></p>" +
                                "<p>Отложили покупку и поездку в Турцию, составили план накопления для этих целей. Ваши действия:</p>" +
                                "<ul>" +
                                "<li>45 375 откладываете на отдельный депозитный счёт;</li>" +
                                "<li>на диван накопите за полгода, а на поездку — через год.</li>" +
                                "</ul>" +
                                "<p>Это грубый подсчёт. Вы можете откладывать больше или получить премию на работе, тем самым пополнить свои резервы. А ещё можете передумать лететь на море через неделю или найти хороший диван за приемлемую цену. В любом случае, кредит на такие цели не так необходим, как может казаться в моменте. Получается, что за диван, вместо 300 тысяч тенге, вы оплачиваете 700 тысяч, а ваша недельная поездка будет равна нескончаемым месяцам кредита.</p>" +
                                "<p>Другое дело — целевые. Да, это по-прежнему кредит и проценты, но существенно меньше переплата. С миллионом наличными процентная ставка — 45%, а, например, в льготном автокредите она равна 4% годовых.</p>" +
                                "<h2>Давайте рассмотрим, когда выгодно брать кредит, а когда — нет</h2>" +
                                "<p>Не всегда кредит — зло и истории о коллекторах. Если правильно использовать и понимать, что вы не просто из воздуха достали деньги, а взяли их в долг под проценты и вам нужно их вернуть — оправданный инструмент для достижения целей. Перед тем, как использовать банковский кредит, вам нужно определиться, стоит ли оно того. Разберем на простых и понятных примерах: да — можно брать, нет — эмоциональный поступок или неправильное решение.</p>" +
                                "<p><strong><span class='green'>Да: цель нужна в ближайшее время, но стоит больших денег (очень больших)</span></strong></p>" +
                                "<p>На нужные, но дорогие вещи копить сложно и не всегда оправдано. Например, недвижимость с выгодной процентной ставкой — лучше взять ипотеку. Да, вы оплачиваете проценты банку, но через время, когда вы закроете ипотеку, <strong><span class='green'>переплата будет равна или даже меньше расходов из-за роста цен</span></strong>.</p>" +
                                "<blockquote>" +
                                "<p>Сравним на реальном примере: по республике средняя цена одного квадратного метра нового жилья в 2007 году равнялась 159 тысячам тенге, а в 2022 году — 386 тысячам тенге.  Значит в 2007 году за 45 квадратных метров вы бы заплатили около 7 миллионов тенге, а в 2022 году — в районе 17 миллионов тенге.</p>" +
                                "</blockquote>" +
                                "<p>Цены на недвижимость растут, а взяв ипотеку:</p>" +
                                "<ol>" +
                                "<li>Вы зафиксируете цену и расходы на квартиру на долгие годы;</li>" +
                                "<li>Инфляция не съест ваши накопления</li>" +
                                "<li>Ваши доходы за 15 лет, скорее всего, увеличатся, а расходы на квартиру останутся на прежнем уровне. С арендой такое не сработает.</li>" +
                                "</ol>" +
                                "<p>Например, вы вчера взяли квартиру в ипотеку с переплатой 18 миллионов — вроде, много, но только если брать всю сумму единоразово. Учитывайте, что следующие 25 лет вы будете отдавать её маленькими порциями, а на момент, когда выплатите полностью, она будет эквивалентна сегодняшним 5-8 миллионам тенге из-за инфляции. Уже не так плохо, согласитесь. Некоторые государственные программы предлагают ещё более выгодные условия: 7-20-25, военная ипотека, «Нұрлы жер» и другие. По ним и переплата меньше, и первоначальный взнос, и процентная ставка.</p>" +
                                "<p><strong><span class='green'>Нет: покупка принесёт радость, но она не по карману</span></strong></p>" +
                                "<p>Здесь мы возвращаемся к началу нашего урока и напоминаем, что радость от покупки имеет временный срок, далее придут финансовые обязательства. Конечно, презентация Тима Кука выглядит потрясающе, но iPhone 13 необязательно менять на iPhone 14 под влиянием всеобщего ажиотажа, если ваш смартфон ещё работает исправно. Пример пусть и заезженный, но не теряет своей актуальности.</p>" +
                                "<p>Финансово грамотный человек — человек, который умеет управлять и финансами, и эмоциями в отношении финансов.</p>" +
                                "<p><strong><span class='green'>Да: магазин предлагает небывалую скидку, а зарплата не скоро</span></strong></p>" +
                                "<p>Не все скидки и акции — уловка маркетинга, чтобы заставить вас выложить последние деньги. Некоторые из них работают на привлечение внимания к бренду или как один из вариантов повышения лояльности.</p>" +
                                "<blockquote>" +
                                "<p>Вы давно мечтали купить Nike Air Force, которые стоят 55 тысяч тенге, магазин проводит сезонную распродажу и отдаёт кроссовки за 38 500 тенге. Зарплата поступит через два дня, а товар разбирают за считанные часы. Выгоднее купить сейчас и оплатить кредитной картой — это карта с деньгами банка, которые вам нужно вернуть в пределах установленного срока, за просрочку будут начисляться проценты. Через два дня, получив зарплату, вы вернёте деньги банку. И никаких процентов за использование.</p>" +
                                "</blockquote>" +
                                "<p>Если покупка запланирована и вы всё равно потратите деньги, а тут выдалась возможность сэкономить какую-то часть — не грех использовать банковские деньги, но только с обязательным условием, что закроете досрочно и без процентов.</p>" +
                                "<p><strong><span class='green'>Нет: у вас нестабильный доход</span></strong></p>" +
                                "<p>Если у вас присутствует вероятность, что в следующем месяце дохода не будет, не стоит прыгать в финансовые обязательства. Кредит не будет учитывать ваше финансовое состояние, и ежемесячный платеж нужно выплачивать, к тому же в срок.</p>" +
                                "<p><strong><span class='green'>Нет: постоянно покупаете всё в рассрочку</span></strong></p>" +
                                "<p>Купить в рассрочку новый диван, последние эйрподсы, золотые серёжки на юбилей бабушки, велосипед, чтобы не тратиться на такси — кажется, что это выгодно, но всё вместе складывается в ощутимую сумму, которая будет с вами не один месяц. Поставьте себе лимит на покупки в рассрочку, чтобы не провалиться в долги.</p>" +
                                "<p><i>По количеству</i>. Не больше трёх, а значит, следующую покупку вы можете совершить после того, как закроете один из имеющихся.</p>" +
                                "<p><i>По сроку</i>. Нет рассрочкам на 12 и 24 месяца: представьте, два года выплачивать велосипед. Легче и приятнее закрыть за три-шесть месяцев.</p>" +
                                "<p><i>По категориям</i>. Сюда идёт всё, что можно купить за наличные: одежда, косметика, еда, книги, аксессуары и так далее. Если денег нет, но товар нужен — можно сделать исключение, но обязательно закрыть досрочно с зарплаты, а не оставлять их на три месяца.</p>" +
                                "<p><strong><span class='green'>Да: кредит поможет улучшить вашу жизнь</span></strong></p>" +
                                "<p>Представьте, молодая семья: муж, жена, дочь, сын, кот в однокомнатной квартире. Неудобства каждый день испытывают все члены семьи. Дети еще маленькие, но они не останутся такими надолго — каждому нужно своё пространство. Такие ситуации — не редкость, в которых спасательным кругом может стать та же ипотека. </p>" +
                                "<p>Как можно решить ситуацию без больших затрат?</p>" +
                                "<p>Во-первых, продать текущую тесную квартиру и всю сумму направить на первоначальный взнос по ипотеке, так переплата будет намного меньше. Во-вторых, если есть сомнения по долговой нагрузке, можно часть денег пустить на первоначалку, а часть отложить на год ежемесячных платежей. Это даст небольшую уверенность, а в течение года можно улучшить свое благосостояние — подкопить для досрочного погашения или сменить работу с повышением.</p>" +
                                "<p><strong><span class='green'>Нет: брать кредит при первой же нужде в деньгах</span></strong></p>" +
                                "<p>Это всё тот же импульсивный поступок, но в такие моменты нужно взять себя в руки. Если дело касается покупок новых вещей, рекомендуем воспользоваться правилом недели или месяца — возьмите себе время. Оно даст понимание, правда ли вещь необходима или это искусственная нужда. Если нужны деньги на оплату счетов или необходимых вещей, попробуйте найти способы с меньшей процентной ставкой, чем кредит наличными. Долг у друга или родителей, конечно, не самая лучшая жизненная ситуация, но отдать его будет намного легче. </p>" +
                                "<p>А на будущее возьмите за правило оплачивать счета и платежи сразу при поступлении зарплаты — так основные финансовые обязательства будут закрыты и не придется в суете искать деньги (в уроке про Бюджет мы останавливаемся на этом детально).</p>" +
                                "<h2>Детали кредита</h2>" +
                                "<p>Вы уже имеете представление о выгодных и не очень кредитах. Теперь разберём небольшие детали, на которые нужно ориентироваться.</p>" +
                                "<p>Банковский заём можно оформить в отделении, на сайте и через приложение. Любым из способов заполняете заявку в нужном вам банке, предварительно изучив все условия: указываете сумму, срок и удобный для вас график платежей.</p>" +
                                "<p>Есть два графика погашения:</p>" +
                                "<p><strong><span class='green'>Аннуитетный</span></strong> — ежемесячный платёж на весь период кредита останется фиксированным, но в начале существенную часть суммы будут составлять проценты, и только потом — основной долг.</p>" +
                                "<p><strong><span class='green'>Дифференцированный</span></strong> — основной долг делится на равные части, ежемесячно к ним прибавляются проценты на убывающий остаток. Так как ваш долг с каждым месяцем уменьшается, то и платёж будет снижаться за счёт процентного снижения.</p>" +
                                "<blockquote>" +
                                "<p>Как это работает: вы подаёте заявку на 2 миллиона тенге сроком до 36 месяцев. При 10 % ставке: аннуитетными платежами ежемесячно отдаёте 64 534 тенге, а значит, переплата составит 323 237 тенге; дифференцированным графиком получится от 72 222 в первый месяц и до 56 018 тенге — в последний, а переплата будет 308 333 тенге.</p>" +
                                "</blockquote>" +
                                "<p>Кажется, что переплата не сильно ощущается — разница в 14 904 тенге. Отличие будет в том, что если вы захотите погасить кредит досрочно, то при дифференцированном основной долг будет меньше с каждым месяцем, чем при аннуитетном. А значит, и погашать нужно будет меньшую сумму.</p>" +
                                "<p><strong><span class='green'>Вы заполнили заявку, что дальше?</span></strong> Калькулятор посчитает ежемесячный платёж и покажет переплату по кредиту. Многие считают, что переплата — это проценты поверх основного долга, но другие расходы тоже входят в это понятие, например, страховка, услуги нотариуса или комиссия за организацию. Поэтому лучше вам самостоятельно посчитать или ознакомиться со всеми пунктами договора.</p>" +
                                "<p>Если вас всё устраивает, скоринговая система проверит ваше досье: количество одобрений и отказов по кредитам, финансовое поведение, семейное положение, стаж работы, наличие высшего образования и так далее. Чем большую сумму вы запросите, тем больше данных о вас нужно собрать банку для принятия решения.</p>" +
                                "<p>Однако в редких случаях банки ошибочно выдают непосильные займы. Поэтому не стоит полагаться на скоринговую систему, она не подумает за вас о правильности вашего кредита. Система основывается на пенсионных отчислениях и официальных показателях и не учитывает другие финансовые нагрузки на ваш кошелёк. Например, дополнительно вы должны родственнику, заложили в ломбард фамильную ценность, а ещё у вас есть пёс, который подхватил вирус.</p>" +
                                "<p>Кредит — это ваша ответственность, будьте честны с собой и не пытайтесь обмануть банки. Потому что обманутыми в конце концов вы окажетесь сами, когда весь ваш доход будет уходить на оплату кредитных платежей.</p>" +
                                "<p>Чтобы вы оставались в более или менее комфортных условиях, кредиты не должны составлять больше 30-40 % вашего бюджета. Ведь даже ваше психологическое состояние начнет страдать из-за непосильно больших платежей. А если вы потом не сможете какой-то из них оплатить, придут последствия в виде пени, штрафов, заблокированных счетов и испорченной кредитной истории. </p>" +
                                "<h2>Следим за кредитной историей</h2>" +
                                "<p>Кредитная история — официальное досье, по которому банки определяют, можно ли вам выдавать заём или следует обходить стороной.</p>" +
                                "<p>Вот как это работает, разберем на реальной ситуации:</p>" +
                                "<blockquote>" +
                                "<p>Вы никогда не брали кредит и гордитесь этим. Но ситуация изменилась, и вам срочно нужны деньги. Недолго думая, подаёте заявку в местный банк, вам приходит одобрение, но сумма существенно меньше запрошенного.</p>" +
                                "</blockquote>" +
                                "<p>Почему? Потому что банк не знает ваше кредитное поведение, поэтому не может оценить, сможете ли вы ответственно выполнять свои обязанности перед ним. Пустая история по кредитам — это неопределённость, поэтому вы получили одобрение, пусть и на небольшую сумму.</p>" +
                                "<blockquote>" +
                                "<p>Вы взяли предложенный кредит, а другую часть одолжили у своего дяди. Затем полгода своевременно оплачивали: часть — банку, часть — родственнику на оговорённых с ним условиях. Но у него тоже возникла ситуация, и срочно нужна вся оставшаяся сумма, все деньги отдаёте ему, а на оплату кредита ничего не остаётся. У вас просрочка. В отчаянии хотите взять ещё один кредит, чтобы покрыть действующий, подаёте заявку в свой банк и получаете отказ.</p>" +
                                "</blockquote>" +
                                "<p>Всё шло хорошо и в кредитной истории не было черных пятен, но вы не оплатили платёж — это было записано и задокументировано вашим банком. Он видит просрочку, и знает, что у вас нет средств, поэтому не может выдать новый заём. Риск того, что вы не потянете ещё один кредит, пусть и в сумме месячного платежа, слишком высок.</p>" +
                                "<blockquote>" +
                                "<p>Банков же много, может другой одобрит? В ход идёт ещё одна заявка, и на экране красным светится отказ.</p>" +
                                "</blockquote>" +
                                "<p>Другой банк видит следующую картину: это ваш первый кредит, который вы не смогли оплатить, но кроме просроченного платежа там дополнительно появился ещё один пункт — отказ в новом займе. Банк видит это и точно не станет вам помогать. Ваша кредитная история испорчена окончательно.</p>" +
                                "<blockquote>" +
                                "<p>Вы чудным образом выбрались из сложившейся ситуации и избавились от долгов, но через два года вам снова понадобились деньги, а единственным доступным вариантом видится банковский кредит. Сумма небольшая, поэтому вы уверены, что перед вами зелёный коридор — всё верно, вы получаете одобрение. Но в течение дня успеваете передумать и решаете поискать ещё варианты. А перед этим закрываете кредит, фактически попользовавшись им несколько часов. Через пару дней ситуация всё та же, необходимость в деньгах обостряется, и вы снова подаёте заявку — вам ведь уже одобрили позавчера, так что можно смело запрашивать. Но приходит отказ.</p>" +
                                "</blockquote>" +
                                "<p>Это никак не связано с вашим прошлым кредитным фейлом. Банк отказывает, потому что ваше кредитное поведение нестабильно. Подавать заявку и сразу же отказываться или закрывать заём в тот же день — ориентир для банков в принятии решений. Они не знают о ваших обстоятельствах и расценивают ваши действия, как нестабильные.</p>" +
                                "<p>Как видите, кредитная история — довольное хрупкое, но очень важное для вас обстоятельство. Самый главный принцип здесь прост: берите кредит, который сможете оплатить, оплачивайте его вовремя и будьте счастливы.</p>" +
                                "<p>Ведь представьте, какая досада, если вам не одобрят ипотеку на квартиру мечты, потому что вы просрочили платеж на маленький кредит на телефон.</p>" +
                                "<p><strong><span class='green'>Как узнать свою кредитную историю?</span></strong></p>" +
                                "<p>Проверить свой кредитный рейтинг и получить полный отчёт можно через <a href='https://www.1cb.kz/' target='_blank'>сайт</a> или приложение (<a href='https://play.google.com/store/apps/details?id=kz.crystalspring.cs_1cb&hl=ru&gl=US' target='_blank'>Android</a>, <a href='https://apps.apple.com/kz/app/1cb-kz-первое-кредитное-бюро/id933887897' target='_blank'>iOS</a>) Первого кредитного бюро. Запрос раз в год — бесплатно, далее нужно будет оплатить 100 и 400 тенге соответственно.</p>" +
                                "<h2>Погашаем кредит досрочно</h2>" +
                                "<p>Вы уже в курсе, как работают банковские кредиты. И, возможно, уже взяли заём. Или даже не один. Вы читаете этот урок, потому что хотите узнать методы погашения и выстроить свою стратегию, а может, причина в психологическом дискомфорте от нескончаемых обязательств по платежам. Вне зависимости от мотива, начало уже положено, а значит, будем разбираться, как правильно погашать кредиты и не переплачивать большие суммы.</p>" +
                                "<p>Стратегия — ваш лучший друг. И она должна начинаться с одного важного пункта — прекратить создавать новые долги. Покупки в рассрочку даже по самым выгодным условиям — 24 месяца и минимальный платёж — тоже дополнительная сумма поверх основного долга. </p>" +
                                "<p><strong><span class='green'>По какой стратегии погашать?</span></strong></p>" +
                                "<p>Мы советуем закрывать кредиты в пользу срока, а не в пользу уменьшения ежемесячного платежа — именно такой выбор встанет перед вам при досрочном погашении. Да, платить меньше в следующем месяце — заманчиво, но лучше уменьшить срок и вместе с тем переплату, а через несколько таких погашений узнать, что осталось платить всего-то два месяца вместо 36-ти.</p>" +
                                "<p>Но всегда бывают обстоятельства, где надо сделать исключения. Объясняем:</p>" +
                                "<p><strong><span class='green'>Когда уменьшать срок:</span></strong> долг обесценивается, когда доход увеличивается, обгоняя рост цен. То есть постепенно кредит занимает меньшую часть бюджета. Скажем, сегодня вы отдаёте 100 000 тенге ежемесячно — это 50% дохода, а через два года долг будет отнимать уже 25%, потому что сумма зафиксирована сегодняшним днём. В таком случае, погашая кредит, выбирайте стратегию «уменьшить срок»‎. Не надо расслабляться, даже если платеж вы уже не замечаете. Ваша цель — закрыть кредит с выгодой для себя. А это значит одно — с меньшей переплатой.</p>" +
                                "<p><strong><span class='green'>Когда уменьшать платеж:</span></strong> долг будет дорожать с ростом инфляции, если доход останется на текущем уровне или увеличится незначительно. Это значит, что расходы на кредит будут сильно ощутимы, так как расходы на жизнь станут больше.</p>" +
                                "<p>Скажем, сегодня на кредит, коммунальные платежи, продукты и транспорт уходит 1/3 часть бюджета, а через три года удорожание жизни поменяет цифры на 2/3. Если будущее неизвестно и вы переживаете из-за финансовой ситуации, то закрывать кредит нужно по стратегии «уменьшая ежемесячный платёж»‎.</p>" +
                                "<p><i>Запоминаем: при уменьшении срока действующего кредита уменьшается общая сумма переплаты, а при выборе уменьшения ежемесячного платежа — снижается нагрузка на бюджет.</i></p>" +
                                "<p><strong><span class='green'>Погашайте досрочно, если есть такая возможность</span></strong>. Выплатив половину долга, кажется, что нет смысла закрывать кредит, ведь срок уменьшился. Чтобы не обманываться, зайдите в детали своего кредита и посмотрите номинальную ставку, сколько вы уже переплатили банку и сколько ещё предстоит. Действует как пощечина. Помните, что досрочное погашение кредита тоже принесёт банку прибыль, просто сумма будет меньше.</p>" +
                                "<blockquote>" +
                                "<p>Рассмотрим на примере: вы взяли в банке 500 тысяч тенге на два года, переплата за весь срок составит 276 740 тенге, если закроете кредит за три месяца, фактически оплатите банку 45 247 тысяч тенге процентов. Общая сумма составит 545 247 тысяч тенге. Банк получит меньше, вы оплатите чуть больше одолженной суммы, но все равно останетесь в плюсе. Если продолжите платить меньше каждый месяц, в итоге выплатите всю сумму переплаты в 276 тысяч тенге, а вместе с долгом 776 тысяч тенге.</p>" +
                                "</blockquote>" +
                                "<p>Если есть свободные деньги, внесите сумму сразу, при этом мы не рекомендуем отдавать последние деньги. Копить отдельно под матрасом на досрочное погашение тоже не стоит — пока вы будете откладывать, банк будет начислять проценты, которые съедят часть ваших денег. Также велика вероятность потратить деньги не по назначению, а на минутные слабости, тем самым кредит останется неизменным.</p>" +
                                "<h2>Используем таблицу</h2>" +
                                "<p>В нашей таблице откройте страницу Кредиты. </p>" +
                                "<img src='"+require('@/assets/images/visuals/img-4.png')+"'/>\n" +
                                "<ol>" +
                                "<li>Выпишите все ваши кредиты и рассрочки в порядке убывания процентной ставки.</li>" +
                                "<li>Пропишите все детали каждого кредита — сумма, срок, сколько осталось оплатить и ежемесячный платеж.</li>" +
                                "<li>Оцените свою финансовую ситуацию и выберете стратегию по погашению кредитов.</li>" +
                                "<li>Если сейчас вы не можете закрывать досрочно, платите, как есть. Если у вас есть деньги, которые можно использовать, подумайте о цели “Закрыть кредиты с большой процентной ставкой досрочно”. (Как ставить и достигать финансовые цели мы разобрали в прошлом уроке).</li>" +
                                "<li>В целом такая таблица просто полезна для понимания полной картины ваших кредитных отношений. Сколько вы заняли, сколько должны и когда вернуть. Бывают ситуации, что нет возможности быстрее выйти из такой ситуации, но главное — теперь вы знаете, куда стремиться.</li>" +
                                "</ol>" +
                                "<h2>Кредит есть, а денег нет даже на ежемесячный платёж. Что делать?</h2>" +
                                "<p>Если вы входите в число казахстанцев с кредитами и попали в ситуацию, в которой не знаете, на какие средства закрывать ежемесячный платёж — никто не может помочь, работы нет или любая другая весомая причина, поступите следующим образом: обратитесь в банк и честно расскажите о ситуации, взамен учреждение предложит релевантные варианты. Обычно их несколько: взять кредитные каникулы, рефинансировать заём и выплачивать меньше или обратиться к поручителю.</p>" +
                                "<p><strong><span class='green'>Реструктуризация</span></strong> — скорее всего, первое, что предложит вам банк. Ваш кредит остаётся, но меняются условия выплаты: увеличивается срок кредита, уменьшается ежемесячный платёж, дату можно поставить на конец месяца или наоборот. Чтобы сделать реструктуризацию, обычно нужно собрать необходимый пакет документов и написать заявление. </p>" +
                                "<p><strong><span class='green'>Рефинансирование</span></strong> — новый заём, который перекрывает все имеющиеся и предлагает другие условия: уменьшение ежемесячного платежа за счёт увеличения срока кредита, изменение даты оплаты и возможность снизить процентную ставку.</p>" +
                                "<p>Объясняем на двух примерах, когда выгодно рефинансироваться:</p>" +
                                "<ol>" +
                                "<li>" +
                                "<p>У вашего кредита высокая процентная ставка, а другой банк предлагает за ту же сумму платить меньше процентов.</p>" +
                                "<p>Например, ставка по вашему текущему кредиту — 45%, а после рефинансирования она станет 33%. Это значит, что за одну и ту же сумму кредита в разных банках переплата будет разной.</p>" +
                                "</li>" +
                                "<li>" +
                                "<p>У вас несколько кредитов с разными условиями.</p>" +
                                "<p>Например, сумма ежемесячного платежа по трем кредитам выходит 150 тысяч тенге. Если вы обменяете три кредита на один — то есть рефинансируетесь — ваш ежемесячный платеж может стать около 100 тысяч тенге. Этот вариант подходит, если по разным причинам кредит стал неподъемным.</p>" +
                                "</li>" +
                                "</ol>" +
                                "<p>Смотрите не только на процентную ставку и сумму, которую будете выплачивать.  Учитывайте все условия: обязательная страховка, комиссия за обслуживание, запрет досрочного погашения и так далее.</p>" +
                                "<p><i>Рефинансируйтесь, уменьшая ежемесячный платёж, если хотите снизить кредитную нагрузку. Рефинансируйтесь на меньший срок, если хотите закрыть кредит быстрее.</i></p>" +
                                "<p><strong><span class='green'>Кредитные каникулы</span></strong> — способ на какое-то время поставить на паузу обязательства по выплате кредита. В законодательстве Казахстана термин не закреплён, но это не отменяет такой возможности. На период кредитных каникул никакие штрафы за неуплату не начисляются, а кредитная история так и останется в прежнем виде. В Казахстане к кредитным каникулам активно обращались в 2020 году в период пандемии коронавируса. Правительство также призывало банки ввести их, как вариант антикризисных мер, в итоге отсрочка <a href='https://kapital.kz/finance/85796/utverzhden-poryadok-predostavleniya-kreditnykh-kanikul.html' target='_blank'>была введена по всем выплатам</a> сумм основного долга и вознаграждения на 90 дней — с 16 марта по 15 июня 2020 года.</p>" +
                                "<p>К отсрочке прибегают не часто, и вам необходимо доказать, что дохода нет и в ближайшее время точно не будет. Все данные необходимо предоставить в банк и ждать ответ — одобрение или отказ — в течение 15-30 дней.</p>" +
                                "<p><strong><span class='green'>Помощь поручителя</span></strong> — обращение за помощью к человеку, который будет исполнять кредитные обязательства заемщика в случае, если тот не имеет такой возможности. Поручительство — это не формальность, а гарантия для банка, поэтому если вы не можете выплатить кредит, то банк обратится к вашим доверенным лицам и они будут нести полную ответственность за ваш долг.</p>" +
                                "<p>Следующий важный шаг, который необходимо сделать — искать работу, подработку, стажировку, чтобы скорее найти рабочее место и стать платежеспособным. Возможностей много, главное — не опускать руки и не идти на отчаянные поступки. Если самостоятельные поиски не увенчались успехом, вы можете зарегистрироваться для поиска работы через <a href='https://egov.kz/cms/ru' target='_blank'>eGov</a>, либо на сайте <a href='https://www.enbek.kz/ru' target='_blank'>электронной биржи труда</a>. В течение одного дня вам должны предложить подходящие вакансии из местного Центра занятости населения. Если и так работа не найдётся — вам присвоят статус безработного и назначат пособие.</p>" +
                                "<p><strong><span class='green'>Банкротство физических лиц</span></strong> — это не кредитная амнистия при которой нет никаких последствий. Объявляя себя банкротом, вы на следующие три года будете под пристальным вниманием кредиторов с проверкой всех счетов и доходов, возможно, часть вашего долга будет списана за счёт имущества. А в течение пяти лет не сможете брать новые кредиты. Банкротство — последний шаг, когда уже других вариантов выхода не видится. </p>",
                        },
                        dictionary: [
                            {
                                id: 1,
                                name: 'Скоринг',
                                description: 'система проверки заёмщика по большинству критериев, которая помогает банкам и крупным финансовым организациям проследить его поведение и платежеспособность. Основу прогноза составляют математический анализ и статистика.'
                            },
                            {
                                id: 2,
                                name: 'Залогодатель ',
                                description: 'лицо, которое отдаёт имущество в залог на определенное время.'
                            },
                            {
                                id: 3,
                                name: 'Поручитель',
                                description: 'лицо, которое гарантирует банку платежеспособность заёмщика и берёт на себя ответственность за кредитные обязательства, если тот по разным причинам не сможет их выполнять.'
                            },
                            {
                                id: 4,
                                name: 'Созаёмщик',
                                description: 'лицо, которое берёт и несёт равную по договору с другим заёмщиком ответственность за кредит.'
                            },
                            {
                                id: 5,
                                name: 'Кредитный рейтинг',
                                description: 'показатель кредитоспособности заёмщика. Помогает банкам понять, насколько безопасно доверять свои деньги конкретному лицу.'
                            },
                            {
                                id: 6,
                                name: 'Кредитная история',
                                description: 'досье заёмщика, хранит в себе все данные о добросовестном или ненадлежащем исполнении вами ваших кредитных обязательств.'
                            },
                            {
                                id: 7,
                                name: 'Дифференцированный платёж',
                                description: 'погашение неравными платежами в течение всего срока кредита, основной долг делится на равные платежи с учётом общего срока, ежемесячно проценты добавляются на убывающий остаток.'
                            },
                            {
                                id: 8,
                                name: 'Аннуитетный платёж',
                                description: 'погашение равными платежами в течение всего срока кредита, большая часть фиксированного платежа в начале уходит на процентное погашение, только потом на уплату основного долга.'
                            },
                            {
                                id: 9,
                                name: 'Потребительский кредит',
                                description: 'деньги, которые банк выдаёт на личные нужды. Обычно они краткосрочные и имеют лимит по сумме.'
                            },
                            {
                                id: 10,
                                name: 'Целевой кредит',
                                description: 'деньги, которые банк даёт на конкретный продукт. Обычно это ипотека, авто или потребности бизнеса.'
                            },
                            {
                                id: 11,
                                name: 'Переплата по кредиту',
                                description: 'общая сумма всех расходов сверх денег, полученных в долг.'
                            },
                            {
                                id: 12,
                                name: 'Стратегия',
                                description: 'план действий на будущее для достижения конкретных целей.'
                            },
                            {
                                id: 13,
                                name: 'Рефинансирование',
                                description: 'оформление нового кредита, который оплачивает все имеющиеся. С его помощью можно снизить ежемесячный платёж и получить выгодные условия пао ставке.'
                            },
                            {
                                id: 14,
                                name: 'Реструктуризация',
                                description: 'изменение и облегчение условий кредита для заёмщиков, которые по разным причинам не могут выплачивать его на прежних условиях.'
                            },
                            {
                                id: 15,
                                name: 'Безработный',
                                description: 'лицо, которое не имеет работы и заработка, зарегистрировано в государственном органе как человек, находящийся в поиске и готовый приступить к подходящей работе.'
                            },
                            {
                                id: 16,
                                name: 'Пособие',
                                description: 'денежные выплаты от государства при разных ситуациях: беременность, травмы, потеря работы.'
                            },
                        ],
                        tests: [
                            {
                                id: 1,
                                question: 'Какой кредит вы выберите для покупки автомобиля?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Возьму потребительские беззалоговые до 7 миллионов, расплачусь сразу на месте с продавцом без лишних бумаг и проверки',
                                        result: 'Такой вид кредита имеет ставку от 40 до 50%, вы переплатите большую сумму и погасить сможете тоже не быстро.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Одолжу у знакомого первоначальный взнос, а остальное оформлю в обычный кредит в банке',
                                        result: 'Так вам нужно будет платить двойную сумму — вашему знакомому и банку, если средств нет на накопление первоначальной суммы, стоит задуматься, не станет ли кредит неподъемной нагрузкой на ваш кошелек.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Подам заявку на льготный автокредит, если получится пройти регистрацию на портале, то куплю автомобиль по этой программе',
                                        result: 'На портале Autonesie.kz вы можете подать заявку на программу кредитования, если пройдете — сможете купить авто под 4% годовых.',
                                        correct: true,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Буду копить по 10% от дохода, в итоге возьму за наличные',
                                        result: 'Это хорошая идея, если вы планируете взять авто через пять-шесть лет. Но есть вероятность, что стоимость сильно подорожает за годы.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 2,
                                question: 'У вас есть кредит в 250 тысяч тенге и ещё один в 400 тысяч тенге. Какой погасите в первую очередь?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Конечно, 400 тысяч тенге',
                                        result: 'Сумма может быть больше, но процентная ставка меньше. И наоборот. Изучите детали и выбирайте тот, что обходится вам дороже.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Конечно, 250 тысяч тенге',
                                        result: 'Сумма может быть меньше, но процентная ставка больше. И наоборот. Изучите детали и выбирайте тот, что обходится вам дороже.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Тот, у которого процентная ставка выше',
                                        result: 'Правильно! У 250 тысяч переплата может быть больше, чем у 400 тысяч. Также играют роль оставшийся срок выплат и сумма, которая у вас на руках для погашения.',
                                        correct: true,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Тот, на который хватит денег',
                                        result: ' Вы можете накопить 250 тысяч тенге и решить закрыть тот, по которому осталось платить ровно 250 тысяч. Но по условиям 400 тысяч тенге может обходиться вам дороже, например, его ставка на 20% выше. Тогда лучше погасить частично кредит в 400 тысяч тенге.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 3,
                                question: 'Вы хотите досрочно погасить кредит, вам предлагают уменьшить сумму или срок, что выберете?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Уменьшу сумму, приятно будет платить меньше',
                                        result: 'Это временный комфорт — видеть меньше сумму, но вы все равно будете оплачивать кредит тот же срок, а переплата не сильно изменится.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Уменьшу срок на несколько месяцев',
                                        result: 'Со сроком уменьшится и переплата, это верная стратегия. Но если ваш кредит неподъемный, то лучше погашать суммой, и так несколько раз.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Сумму, если кредит неподъемный и/или срок, чтобы быстрее закрыть кредит',
                                        result: 'Всегда смотрите на условия кредита. Часто советуют выбрать срок вместо суммы, но исключения тоже есть: если вам не хватает денег на жизнь и кредит съедает большую часть заработка, уменьшайте сумму. Ведь если у вас цель — избавиться от кредитов, то вы на следующие накопления можете уменьшить срок или снова сумму, и так в итоге погасить полностью.',
                                        correct: true,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Выберу наугад',
                                        result: 'Лучше уделить время и разобраться, сколько сможете сэкономить при каждом варианте и какой подходит вам.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 4,
                                question: 'Кредит есть, а денег нет. Как поступить?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Попросить в долг — у коллег, друзей, семьи',
                                        result: 'Пока это единственный выход, если речь про кредит до зарплаты. Попробуйте сместить дату платежа, если к текущей не остается денег.',
                                        correct: true,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Взять новый кредит для старого кредита',
                                        result: 'А потом еще кредит для погашения двух кредитов. Это верный путь попасть в кредитную яму.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Не буду платить, а что еще делать?',
                                        result: 'Это не выход, вам будет начисляться штраф, кредитная история будет испорчена, а еще — вам все равно нужно оплатить это обязательство. ',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Объявлю себя банкротом',
                                        result: 'Это не выход, процедура банкротства требует доказательств, что вы окончательно потеряли доход, а еще на годы лишает вас новых возможностей — например, других (выгодных) кредитов или сделок по работе, так как компании могут проверять вас при приеме на работу.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 5,
                                question: 'При каких условиях вам не ободрят кредит?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Есть уже имеющийся кредит',
                                        result: 'Наличие кредита никак не влияет на кредитную историю и одобрение. Банки будут смотреть на другие показатели, главное — чтобы нагрузка от кредитов не превышала половину от официального дохода.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Политические взгляды отличаются от общепринятых',
                                        result: 'Вы можете придерживаться разных взглядов, верований и убеждений. На кредитную историю это никак не повлияет.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Нестабильное кредитное поведение',
                                        result: 'Если вы вовремя не оплачиваете платежи, одновременно отправляете запросы в разные банки, после отказа запрашиваете новый кредит — ваши действия будут задокументированы и послужат ориентиром для банков. Кредитная история будет испорчена.',
                                        correct: true,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Зарплата ниже среднего',
                                        result: 'Вам одобрят заем в том размере, который будет для вас подъемным. Уровень зарплаты влияет на одобрение, но не отменяет его.',
                                        correct: false,
                                    }
                                ],
                            },
                        ],
                    },
                    {
                        id: 5,
                        number: 5,
                        name: 'Выбираем фининструменты',
                        slug: 'vybiraem-fininstrumenty',
                        duration: '21 мин',
                        bgImage: require('@/assets/images/lessons/lesson-5.png'),
                        content: {
                            shortDescription: "<h2>Достигаем цели быстрее: Какие есть инструменты?</h2>\n" +
                                "<p>В этом уроке рассказываем, как не запутаться в способах сохранить и преумножить накопления.</p>",
                            shortList: "<h2>Что вы узнаете:</h2>\n" +
                                "<div><p>Какие есть инструменты</p></div>\n" +
                                "<div><p>В чем сила и слабость каждого из инструментов</p></div>\n" +
                                "<div><p>Как сохранить накопления даже в периоды нестабильности</p></div>",
                            description: "<h2>Какие инструменты есть?</h2>" +
                                "<p>Казалось бы, зачем такое разнообразие финансовых инструментов — это только запутывает и устрашает. Хочется простого человеческого “положить под матрас и копить”, но финансовый мир диктует свои правила.</p>" +
                                "<p>Каждый из инструментов подходит под разные виды финансовых целей и имеет свои преимущества и недостатки. Давайте разбираться.</p>" +
                                "<h2>Наличные</h2>" +
                                "<p>Деньги в своей физической форме являются самой простой, наиболее распространенной и надежной формой платежа. Это всем известные бумажные деньги.</p>" +
                                "<p><strong><span class='green'>Преимущества</span></strong></p>" +
                                "<p>Как вы уже знаете, это самый ликвидный финансовый инструмент, так как не требует дополнительного времени для конвертацию. Нужны деньги — берете наличные и расплачиваетесь. Это, несомненно, самый большой их плюс.</p>" +
                                "<p><strong><span class='green'>Недостатки</span></strong></p>" +
                                "<ul>" +
                                "<li>А вот самый большой недостаток наличных — их неумение создавать новые наличные, а значит, бороться с инфляцией. Со временем деньги, спрятанные под матрасом, начнут терять свою ценность и покупательскую способность. Например, только в 2022 году инфляция в Казахстане составила 20,3%. Это значит, что на 100 000 тенге в 2022 году вы смогли купить на 20,3% продуктов меньше, чем в 2021. Инфляция съела ваши сбережения без каких-либо сожалений, а вы остались вроде бы с теми же купюрами, но с меньшими деньгами.</li>" +
                                "<li>Еще один вопрос для беспокойства — безопасность. Где бы вы ни держали наличные, всегда есть вариант потери или кражи. Согласитесь, потерять в сбережениях сначала из-за инфляции, а потом в результате кражи — очень грустное развитие событий.</li>" +
                                "</ul>" +
                                "<p><strong><span class='green'>Для каких целей подходит</span></strong></p>" +
                                "<p>В наличных можно держать часть подушки безопасности на случай непредвиденных событий: если не будет электричества, связи и интернета. В такие моменты вас обеспечат только физические деньги. Самый оптимальный размер подобных сбережений — 30% от общей суммы подушки. Это та сумма, которая придет на помощь и справится со своей задачей, но и лишиться ее в случае потери не станет для вас большим ударом.</p>" +
                                "<h2>Депозиты</h2>" +
                                "<p>Привычный и довольно эффективный способ хранить деньги. Вы кладете деньги в банк, а он выплачивает вам вознаграждение за пользование вашими деньгами. Так ваши деньги начинают работать и даже зарабатывать — вы получаете проценты, которые позволят не потерять большую часть ваших сбережений.</p>" +
                                "<p>Депозиты обычно делятся на три типа:</p>" +
                                "<p><strong><span class='green'>Нельзя снимать и пополнять</span></strong></p>" +
                                "<p>У таких депозитов, как правило, наиболее высокий процент вознаграждения. Их невозможно пополнять или снимать сумму, пока не кончится срок хранения вклада. Вы положили 100 000 тенге на год, значит, через год получите ваши 115 700 тенге уже вместе с вознаграждением. Такие депозиты еще называют сберегательными.</p>" +
                                "<p><strong><span class='green'>Можно пополнять, но нельзя снимать</span></strong></p>" +
                                "<p>Проценты по вознаграждениям у них тоже довольно высокие и при этом сумму вклада можно увеличивать. Снять, опять же, не получится. Такой вид депозита — хороший вариант для накопления на финансовые цели, кроме подушки. Снять не сможете, зато пополнять — пожалуйста.</p>" +
                                "<p><strong><span class='green'>Можно и снимать, и пополнять</span></strong></p>" +
                                "<p>Самый популярный тип, потому что дает свободу: можно класть, пополнять и снимать в любое время. Минус — более скромные ставки по вознаграждениям.</p>" +
                                "<p><strong><span class='green'>Преимущества депозитов</span></strong></p>" +
                                "<p><strong><span class='green'>— Борьба с инфляцией</span></strong></p>" +
                                "<p>Депозиты благодаря вознаграждению покрывают инфляцию. Если не полностью, то большую часть точно. Например, процентная ставка по депозитам в Казахстане сейчас — 15,7%, а инфляция — 20,3%. То есть инфляция полностью не покрыта, но очень к этому близка.</p>" +
                                "<p><strong><span class='green'>— Чудо сложного процента</span></strong></p>" +
                                "<p>При открытии депозита вам дадут выбор: зачислять процентное вознаграждение на депозитный счет или выбрать отдельную карту или счет для получения процентов от банка.</p>" +
                                "<p>Если вы выберете зачисление на депозитный счет, вы подключите еще один финансовый инструмент — капитализацию. То есть теперь с каждым последующим месяцем прибыль будет рассчитываться не только от суммы пополнения, но от суммы пополнения и суммы вознаграждения. А это значительно увеличивает скорость и сумму накоплений.</p>" +
                                "<p>Давайте рассмотрим на примере.</p>" +
                                "<blockquote>" +
                                "<p>Вы открыли депозит со ставкой вознаграждения в 15%. Первоначальный взнос — 250 000 тенге, ежемесячное пополнение — 150 000 тенге.</p>" +
                                "<p>Первый месяц — вы получаете вознаграждение 3 082 тенге и оставляете его на депозитном счете. Докладываете 150 000 тенге. Теперь на депозите у вас 403 082 тенге.</p>" +
                                "<p>Второй месяц — вы получаете вознаграждение 4 969,5 тенге. Докладываете 150 000 тенге. Теперь у вас на депозите 558 051, 50 тенге.</p>" +
                                "<p>Кажется, что разница мала (и вообще существует ли?), и так и есть — но только в краткосрочном периоде.</p>" +
                                "<p>Спустя год разница станет заметной:</p>" +
                                "<p>Если бы вы откладывали без капитализации, накопили бы 2 061 250 тенге. А с капитализацией — 2 185 000 тенге. Разница 124 тыс. тенге.</p>" +
                                "<p>Спустя пять лет: 9 801 250 тенге без — против 10 465 000 тенге с капитализацией. Разница уже 663 750 тенге. И эта разница началась с тех 3 тысяч тенге вознаграждения первого месяца.</p>" +
                                "</blockquote>" +
                                "<p>Вот оно чудо и объяснение сложного процента — реинвестировать прибыль, которую получаете, чтобы получить еще большую прибыль. Деньги зарабатывают деньги, а потом деньги, которые были заработаны, тоже начинают зарабатывать. </p>" +
                                "<p>И скажите, что это не чудо?</p>" +
                                "<p><strong><span class='green'>— Страхование вкладов</span></strong></p>" +
                                "<p>Депозиты — довольно безопасный финансовый инструмент, потому что банковские вклады застрахованы «Казахстанским фондом гарантирования депозитов». Все банки, получившие от Национального Банка РК лицензию на прием депозитов, открытие и ведение банковских счетов физических лиц, обязательно становятся участниками системы гарантирования депозитов. А значит депозит, размещенный в таком банке, находится под защитой КФГД. Возмещаются суммы:</p>" +
                                "<ol>" +
                                "<li>20 млн тенге — по сберегательным депозитам в тенге;</li>" +
                                "<li>10 млн тенге — по карточкам, счетам и другим депозитам в тенге;</li>" +
                                "<li>5 млн тенге — по карточкам, счетам и депозитам в иностранной валюте.</li>" +
                                "</ol>" +
                                "<p>То есть даже если ваш банк потеряет лицензию, ваши деньги будут защищены и выплачены вам в этих пределах. Иметь такую уверенность в своих сбережениях приятно, тут не поспоришь.</p>" +
                                "<p><strong><span class='green'>Недостатки депозитов</span></strong></p>" +
                                "<p><strong><span class='green'>— Слабы в долгосрочном горизонте</span></strong></p>" +
                                "<p>Как мы написали ранее, при инфляции в 20,3% процентная ставка по депозитам — 15,7%. Это значит, что в долгосрочном варианте капитал все-таки будет ослабевать. Причем чем дальше в лес — тем страшнее и сильнее будет становится эффект инфляции.</p>" +
                                "<p><strong><span class='green'>— Низкая ликвидность некоторых видов депозитов</span></strong></p>" +
                                "<p>Сберегательные депозиты неликвидны, так как вы не получите быстрый доступ к деньгам, не потеряв при этом процентного вознаграждения. Однако это не минус, а скорее призыв к более внимательному подходу выбора депозитов. Кладите на сберегательные депозиты деньги, которые не понадобятся вам срочно, и у вас не будет проблем с доступом.</p>" +
                                "<p><strong><span class='green'>Для каких целей подходит:</span></strong></p>" +
                                "<ol>" +
                                "<li>" +
                                "<h2>Подушка</h2>" +
                                "<p>Депозиты идеально подходят под подушку безопасности. Во-первых, могут обеспечить быстрым доступом. Во-вторых, застрахованы и обладают низким риском. А это как раз то, что нам нужно, когда мы говорим о деньгах на “всякий пожарный” — это те деньги, которые вы не должны потерять в погоне за большей прибылью.</p>" +
                                "</li>" +
                                "<li>" +
                                "<h2>Среднесрочные и долгосрочные цели</h2>" +
                                "<p>Депозиты — отличный вариант для долгосрочных накоплений, которые вы не готовы потерять. Например, копить на первоначальный взнос квартиры. Такая цель займет у вас 2-3 года. За это время вы не потеряете свои сбережения на инфляции, но и рисковать с таким вкладом, вы, скорее всего, не захотите. Идеально.</p>" +
                                "</li>" +
                                "</ol>" +
                                "<h2>Фондовый рынок</h2>" +
                                "<p>Пожалуй, самый интересный из всех инструментов. Риск, хайп, большая прибыль, большие потери, адреналин, убытки, эйфория. Все эти слова могут описать фондовый рынок и все будут правдивыми.</p>" +
                                "<p>Фондовый рынок многообразен. Его доходность варьируется от 2% до 500% в зависимости от инструмента и правильных решений. Уоррен Баффет заработал миллионы, а Илон Маск терял и снова зарабатывал.</p>" +
                                "<p>Но как бы привлекателен не был мир акций, не все могут позволить себе в него зайти. То есть зайти в него легко, и так же легко прогореть. Погружаться надо постепенно и максимально подготовленными.</p>" +
                                "<p>Например, нельзя заходить на рынок с последними сбережениями. Сначала накопите подушку безопасности, и только потом думайте о рискованных позициях. Вы даже можете поставить себе цель — накопить подушку, а потом накопить деньги на изучение фондового рынка.</p>" +
                                "<p>Второе, что нужно сделать перед первыми инвестициям, еще раз поглубже себя узнать. А именно — вашу терпимость к риску, отношение к потерям. Чем больше доходность, тем больше риск. Сколько можете выиграть, столько можете и проиграть — правило инвестиций, к которому нужно быть действительно готовыми. Это еще раз к правилу о последних деньгах. Зайти с последим миллионном и потерять даже 20% может сильно ударить по бюджету и лишить необходимых вещей.</p>" +
                                "<p>Рынок многообразен и может предложить разные инструменты под разные запросы. Есть облигации, ETF, ПИФы, акции, деривативы, опционы, IPO. И каждый из них несет свою доходность и свой риск.</p>" +
                                "<p>Для новичка подойдут первые четыре инструмента. Давайте остановимся на каждом из них.</p>" +
                                "<h2>Облигации</h2>" +
                                "<p>Если простым языком, это долговые расписки. Компании и даже государства выпускают их, когда нуждаются в дополнительном финансировании. Вы же зарабатываете на процентах — покупаете долговую расписку с указанием срока и суммы, которую вернет компания по истечению срока.</p>" +
                                "<p>Облигации обычно предлагают до 5% доходности в долларах. Они обладают наименьшим риском (если компания не выплатит обязательства, то будет объявлен дефолт) и обычно более долгосрочны. Однако даже такая доходность превысит доходность банковских депозитов в валюте.</p>" +
                                "<h2>ETF и ПИФы</h2>" +
                                "<p>Еще один вариант более надежного инструмента инвестирования. ETF и ПИФы — специальные фонды, которые создают управляющие компании. Это работает так: компания сама собирает портфель из акций, а клиенты покупают акции управляющей компании. Получается, что купив всего одну акцию, можно получать прибыль от всех компаний портфеля.</p>" +
                                "<p>Например, есть SPY — это ETF на S&P 500, куда входят 505 крупнейших компаний фондовых бирж США. Вы будете обладать одной акцией, но ее успешность будет зависеть от всех этих компаний вместе взятых. Из-за такого деления ответственности и снижается риск вложений: какие-то компании будут расти, а какие-то падать, но друг друга усреднять.</p>" +
                                "<p>Такие вложения предлагают до 10-15% доходности и столько же риска.</p>" +
                                "<h2>Акции</h2>" +
                                "<p>Самый рискованный инструмент из этого списка. Покупая акцию компании, вы становитесь ее совладельцем в прямом смысле этого слова — будете получать ее прибыль и терпеть ее убытки. Если бизнес компании будет расти, будут расти и ваши акции. А если компания захочет делиться прибылью, она еще и выплатит вам, как совладельцу, дивиденды.</p>" +
                                "<p>Акции предлагают до 30% прибыли, но и риск здесь намного выше. Поэтому при выборе акций очень важно разобраться в бизнесе компании: вы должны понимать, от чего он будет процветать, а в каких условиях проседать.</p>" +
                                "<p>В целом инвестиции предлагают большой выбор инструментов, и опытные инвесторы знают, как их использовать и собирать в правильные портфели. В зависимости от аппетита и отношению к риску, некоторые инвесторы предпочитают консервативный подход и большую часть портфеля отдают на облигации и ETF, а некоторые готовы рисковать и покупают акции.</p>" +
                                "<p><strong><span class='green'>Преимущества инструментов фондового рынка</span></strong></p>" +
                                "<p><strong><span class='green'>— Высокая доходность</span></strong></p>" +
                                "<p>Доходность фондового рынка не только покрывает инфляцию, но и далеко ее обгоняет. Даже средний инструмент в виде ПИФов и ETF может стать отличным помощником в борьбе с инфляцией. Это именно та причина, почему более 50% населения США обязательно инвестируют свою сбережения.</p>" +
                                "<p><strong><span class='green'>Недостатки инструментов фондового рынка</span></strong></p>" +
                                "<p><strong><span class='green'>— Высокий риск</span></strong></p>" +
                                "<p>В отличие от депозитов, вклады на фондовом рынке не защищены никаким страхованием. Если рынок обвалится, обвалятся и ваши сбережения. Хорошая новость в том, что рынок падает и восстанавливается снова. Пока какой-то инструмент находится в просадке, поднимается другой, и так по кругу.</p>" +
                                "<p><strong><span class='green'>— Ресурсозатратность</span></strong></p>" +
                                "<p>В отличие от депозитов, в которых положил деньги и особо не переживаешь, на фондовом рынке нужно держать руку на пульсе: читать новости, корректировать портфель, пересматривать стратегию, находить новые компании и возможности, анализировать. Это занимает ресурсы времени и сил. Однако если приноровиться, то выгода, конечно, оплатит каждую потраченную секунду. Некоторые инвесторы проводят ревизию портфеля раз в год, но для такой роскоши нужные глубокие познания.</p>" +
                                "<p><strong><span class='green'>— Невысокая ликвидность (зависит от инструмента)</span></strong></p>" +
                                "<p>Если деньги понадобятся в моменте, фондовый рынок может находиться не в лучшем его состоянии, и продавать активы и выводить деньги может быть не самой приятной идеей. А даже если решите продавать, нужно будет продать, вывести деньги на счет, провести конвертацию (опять же учитывать курс). Итог — затраты на время и риск потерять часть вложений.</p>" +
                                "<p><strong><span class='green'>Для каких целей подходит</span></strong></p>" +
                                "<p><strong><span class='green'>— Для долгосрочных и среднесрочных целей</span></strong></p>" +
                                "<p>Инфляция не съест ваши накопления и при удачной стратегии вы сможете накопить намного больше и быстрее, чем на депозите. Копить на пенсию и подготовить капитал для детей — отличный пример долгосрочной цели для фондового рынка. Главное — подобрать хорошую стратегию и правильно ей следовать. Однако нужно учитывать, что прибыль вы сможете только спрогнозировать и никто не даст вам точной суммы к точному сроку. Нужно проводить ревизию портфеля и принимать решения в зависимости от состояния рынка.</p>" +
                                "<p>Для краткосрочных целей нежелательно использовать рынок из-за его волатильности — настроения на рынке могут меняться очень быстро, а значит, и стоимость ваших активов. Здесь лучше обратиться к менее рисковым инструментам.</p>" +
                                "<h2>Валюта</h2>" +
                                "<p>Инвестиции в валюту — простой на первый взгляд фининструмент, который на самом деле скрывает подводные камни. Основные валюты для вкладов у казахстанцев: доллар и евро. Валюту обычно покупают, чтобы выиграть через время на курсовой разнице. Купил доллары за 450 тенге — продал за 470.</p>" +
                                "<p><strong><span class='green'>Преимущества</span></strong></p>" +
                                "<ul>" +
                                "<li>Сбережения в валюте помогают сберечь накопления от девальвации — это тот случай, когда валюта начинает стоить меньше в отношении другой более сильной валюты, часто доллара США. Если вы купите тысячу доллар за 450 000 тенге, а завтра тенге подешевеет и доллар станет стоить 470, вы сохраните свою тысячу, которую купили 450. Таким образом сработает защита от девальвации.</li>" +
                                "</ul>" +
                                "<p><strong><span class='green'>Недостатки</span></strong></p>" +
                                "<ul>" +
                                "<li>Если вы купили валюту и держите ее в наличных деньгах, не инвестируя, на протяжении долгого срока вы все-таки потеряете часть накоплений, но уже от инфляции. Потому что даже доллар дешевеет и теряет свою покупательскую способность. То есть то, что вы могли купить на тысячу долларов, через пять лет может стоить две.</li>" +
                                "<li>Спекулировать на разнице курса, то есть постоянно покупать и продавать валюту, не стоит, если вы не понимаете макро- и микроэкономику. А иногда и этих знаний может быть недостаточно, потому что случаются непредвиденные события, рушащие курс. Такие спекуляции — большой риск. Помните, чем больше выгода, тем больше потенциальный убыток.</li>" +
                                "</ul>" +
                                "<p><strong><span class='green'>Для каких целей подходит</span></strong></p>" +
                                "<p>Валюта подходит для сохранения части подушки безопасности — она поможет сохранить сбережения от девальвации.</p>" +
                                "<h2>Недвижимость</h2>" +
                                "<p>Недвижимость можно рассматривать не только как актив. Это ваш финансовый инструмент, который тоже может приносить прибыль. Многие считают этот инструмент одним из самых надежных, потому что можно не сомневаться в его принадлежности, и внешние факторы типа чьего-то распадающегося бизнеса (как в случае с акциями) не смогут на него повлиять.</p>" +
                                "<p><strong><span class='green'>Преимущества</span></strong></p>" +
                                "<ul>" +
                                "<li>" +
                                "<p>Это инструмент, в котором можно жить. То есть он не только сберегает ваши деньги, но и дает вам кров над головой, чем не могут похвастаться ни один из вышеперечисленных вариантов.</p>" +
                                "</li>" +
                                "<li>" +
                                "<p>Его можно сдавать в аренду и получать дополнительную прибыль.</p>" +
                                "</li>" +
                                "<li>" +
                                "<p>Если взглянуть на стоимость квадратного метра жилья в Алматы (в соотношении с другими городами картина довольно схожая), то за 15 лет в тенге он вырос в цене на 120 %. Накопленная инфляция за весь этот период составила 125%. Получается, что рост, в целом, соответствовал общей инфляции.</p>" +
                                "<p>Это происходит, потому что недвижимость растет в цене при любой ситуации. Если инфляция растет, дорожают стройматериалы, дорожает недвижимость. Если инфляция спадает, кредитование становится более доступным, растет спрос, а вместе с ним и цена на недвижимость.</p>" +
                                "</li>" +
                                "</ul>" +
                                "<p><strong><span class='green'>Недостатки</span></strong></p>" +
                                "<ul>" +
                                "<li>Не самый ликвидный актив. Продажа квартиры может занять от недели до нескольких месяцев. Если деньги нужны срочно, квартира здесь не поможет в срок. Еще нельзя продать только часть квартиры. То есть если вам нужны 10 миллионов тенге, вам придется продать квартиру за 40 миллионов, использовать 10, а остальные 30 куда-то перераспределить. Это как минимум неудобно.</li>" +
                                "<li>Со сдачей в аренду можно прогадать: недобросовестные арендаторы иногда обходятся в двойную стоимость из-за дополнительных затрат на ремонт.</li>" +
                                "<li>Порог входа очень высокий. Даже если взять ипотеку, вам нужно накопить от 20% от стоимости жилья, а это порядка несколько миллионов тенге.</li>" +
                                "</ul>" +
                                "<p><strong><span class='green'>Для каких целей подходит</span></strong></p>" +
                                "<p>Недвижимость — и актив, и фининструмент, который может являться самоцелью. Он сохранит ваши сбережения и даже покажет прибыль.</p>" +
                                "<h2>Диверсификация</h2>" +
                                "<p>Ответ на вопрос из введения урока “Зачем так много фининструментов?” прост — они друг друга дополняют и работают в команде. Какой-то более надежный, но менее доходный, какой-то хорош в долгосроке, но совершенно бесполезен на короткой дистанции. А главное — они помогают вам не потерять свои сбережения.</p>" +
                                "<p>Помните пословицу “Не клади все яйца в одну корзину”? Тот же принцип работает с деньгами. Нельзя использовать один инструмент для всех ваших сбережений. Потому что при малейшем дуновении ветра есть вероятность потери полного или частичного доступа к ним.</p>" +
                                "<p>Представьте ситуацию: вы все деньги храните в наличных, но дом обокрали. Или вы положили на депозит, а банк потерял лицензию. Вам выплатят застрахованную сумму, но не сразу. А в то время, которое понадобится для выплат, может произойти сотня ситуаций, в которых вам понадобятся деньги.</p>" +
                                "<p>Чтобы не оказаться в такой ситуации, диверсифицируйте. То есть распределяйте яйца по разным корзинам. Например, держите подушку безопасности в наличных в тенге и в валюте, а еще на теньговом и долларовом депозите. Позвольте себе не только ощущать их прибыль, но и быть защищенными многослойной броней из разных инструментов.</p>" +
                                "<p><i>Теперь, когда вы знаете, как оценивать и планировать бюджет, ставить цели и использовать весь набор инструментов, приступим к следующему уроку — финансовому планированию. Там рассказываем, как собрать все данные воедино, упорядочить цели и отслеживать их достижение.</i></p>"
                        },
                        dictionary: [
                            {
                                id: 1,
                                name: 'Диверсификация',
                                description: 'разделение источника дохода на несколько разных.'
                            },
                            {
                                id: 2,
                                name: 'ETF | ПИФы',
                                description: 'специальные фонды, которые создают управляющие компании. Это работает так: компания сама собирает портфель из акций, а клиенты покупают акции управляющей компании.'
                            },
                            {
                                id: 3,
                                name: 'Сложный процент (капитализация)',
                                description: 'начисление процента не только на сумму вклада, но и на уже начисленное банком вознаграждение.'
                            },
                            {
                                id: 4,
                                name: 'Ликвидность',
                                description: '(в отношении активов) ценность, которая может быть быстро и с минимальными затратами обращена в деньги.'
                            },
                        ],
                        tests: [
                            {
                                id: 1,
                                question: 'Через год хочу сделать первоначальный взнос за квартиру. Часть денег есть, но как быстрее накопить?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Вложусь в акции',
                                        result: 'Этот инструмент не подойдет для срока в один год: риски перевешивают шанс накопить сумму.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Положу на депозит',
                                        result: 'В настоящих реалиях это наиболее выгодный и наименее рисковый вариант — суммы до 10 миллинов тенге застрахованы государством. А если это сберегательный вклад (депозит без возможности снятия), то до 20 миллионов тенге.',
                                        correct: true,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Пойду торговать валютой',
                                        result: 'Точно так же, как с инвестициями, риски могут оказаться выше, чем вы планируете. Помните: инвестировать в акции и торговать и на валютном рынке можно, только когда у вас за спиной есть подушка и нет серьезных обязательств.',
                                        correct: false,
                                    },
                                ],
                            },
                            {
                                id: 2,
                                question: 'Хочу попробовать фондовый рынок. С чего начать?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Закуплюсь самыми дорогими акциями. Они ведь наверняка хорошие.',
                                        result: 'Не всегда цена — показатель надежности и успешности компании. Например, Tesla и Netflix — акции недешевые, но за один год первые теряли почти 50% от стоимости, вторые — четверть за 1 один день.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Куплю акции компаний из нефтяного сектора',
                                        result: 'Вариант хороший, зачастую со стабильным доходом. Тем не менее держат весь портфель только в нефтянке — рискованно. И там бывают великие падения.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Куплю акции ETF фонда',
                                        result: 'Оптимальный вариант. Ведь такой фонд представляет собой уже готовый портфель из множества акций, но только в миниатюре. Даже если какая-то упадет, другая вырастет и нивелирует расхождение.',
                                        correct: true,
                                    },
                                ],
                            },
                            {
                                id: 3,
                                question: 'У меня есть крупная сумма. Что с ней делать?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Вложу все в акции',
                                        result: 'Повторимся: акции — рискованный актив. Если не хотите рисковать и потерять часть или сбережения полностью, не стоит использовать фондовый рынок как единственную площадку для хранения сбережений.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Куплю доллары',
                                        result: 'Наверное, это у нас, казахстанцев, в мышечной памяти — бегом скупать доллары. Но курс у нас плавающий: это значит, что тенге может упасть в цене, а может и подняться.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Раскидаю по-разному',
                                        result: 'Да, диверсификация — залог спокойствия при любом шторме. Старайтесь делить сбережения так, чтобы основная часть оставалась легкодоступной — например, лежала на депозите или в валюте. Не ставить акцент на акциях или драгоценных металлах — сконвертировать их в реальные деньги будет сложнее.',
                                        correct: true,
                                    },
                                ],
                            }
                        ],
                    },
                    {
                        id: 6,
                        number: 6,
                        name: 'Составляем финансовый план',
                        slug: 'sostavlyaem-finansovyj-plan',
                        duration: '18 мин',
                        bgImage: require('@/assets/images/lessons/lesson-6.png'),
                        content: {
                            shortDescription: "<h2>Составляем финансовый план — предсказатель вашего будущего</h2>\n" +
                                "<p>В предыдущих уроках вы учились правильной работе с финансами — составляли таблицы, считали уровень достатка, изучали финансовые инструменты. В заключительной части курса мы соберём все данные в один большой финплан.</p>" +
                                "<p>Расскажем, как составить реалистичные прогнозы, а главное — следовать установленному плану.</p>",
                            shortList: "<h2>Что будет в уроке:</h2>\n" +
                                "<div><p>Узнаете, зачем нужно составлять финплан, когда уже итак ясны все доходы, траты и определены цели.</p></div>\n" +
                                "<div><p>Поймёте, что даже нереальная на первый взгляд цель достижима, если расставить все детали по полочкам.</p></div>\n" +
                                "<div><p>Сможете адаптировать и скорректировать любой план, если дела по разным причинам изменились — повлияла экономика или вы отклонились от курса.</p></div>\n",
                            description: "<h2>Зачем вам нужен финансовый план</h2>\n" +
                                "<p>Представьте:</p>" +
                                "<p>Вы решили пойти на Кок-Жайляу и составляете план: выбрать начало и конец маршрута, собрать рюкзак, поставить будильник, зарядить телефон и пауэрбанк. Каждая часть плана важна и требует внимания, а все действия объединяет одна большая цель — успешный поход.</p>" +
                                "<p>Теперь представьте, что не до конца продумали содержимое рюкзака: накидали одежду, аптечку, еду, но забыли воду. Дойдёте ли вы до конца? Возможно. Но удовольствия будет мало, ведь без воды никак. В вашем плане есть трещина, которая повлияла и на другие моменты: испортилось настроение и сменился фокус внимания — теперь вы думаете, где бы найти воду, вместо того, чтобы наслаждаться отдыхом.</p>" +
                                "<p>То же применимо и к финансовому планированию: без учета всех деталей ваши цели могут отдаляться всё дальше, а реализация станет невыносимой.</p>" +
                                "<p>Ведь в целях, мы уверены, у вас глобальные пункты: получать пенсию не меньше 500 тысяч тенге, посвятить год кругосветному путешествию, купить частный дом в Алматы. Кажется, что реализовать все в это несбыточно и даже представить нельзя, сколько на это надо копить. Но не пугайтесь, для этого вы и читаете этот курс. Мы отсортируем по цветам, формам и картинке, и соберём всё воедино.</p>" +
                                "<h2>Собираем все данные</h2>" +
                                "<p>У нас для вас хорошие новости — ваш финплан уже готов! Ведь у вас уже есть практически все составные части — то, что вы записывали и высчитывали в предыдущих уроках. Осталось только собрать всё вместе, внести корректировки и время от времени сверяться, в том ли направлении вы идёте. Как это сделать — сейчас расскажем.</p>" +
                                "<p>Откройте в таблице первый лист — Моя анкета. Персонализируйте страницу и впишите свое имя и год рождения. Теперь это ваш финансовый дэшборд. Здесь самое важное, что вы должны о себе знать.</p>" +
                                "<img src='"+require('@/assets/images/visuals/img-5.png')+"'/>\n" +
                                "<p><strong><span class='green'>— Вспоминаем достаток</span></strong></p>" +
                                "<p>Обратите внимание на поля “Активы”, “Финобязательства”, “Достаток”. В первом уроке вы выписали все, что есть у вас сейчас: и активы, которые можно конвертировать в деньги, и обязательства, за которые вы должны заплатить. А в итоге вывели достаток — то, что останется у вас на руках, если вы решите выплатить все долги за счет ваших активов, конвертировав их в деньги. Если вы выполнили это задание, в вашей Анкете вы уже видите эти цифры.</p>" +
                                "<p>Ваша задача теперь — каждый месяц возвращаться к странице Мои активы и обновлять данные. Теперь у вас есть цели, в соответствии с которыми будут меняться числа в столбиках — появятся новые строчки в активах или начнут значительно уменьшаться суммы в обязательствах.</p>" +
                                "<p>Следить за изменениями на самом деле очень интересно — ведь это ваша жизнь, или по крайней мере, денежная ее часть.</p>" +
                                "<p><strong><span class='green'>— Расходы</span></strong></p>" +
                                "<p>Если вы прожили месяц с таблицей ведения бюджета, то вы знаете свой примерный месячный расход. Он появится в графе в поле “Расход в месяц”. Поле “Расход в год” тоже заполнится само, если вы заполнили лист Бюджет ГОД, в котором распланировали все расходы на ближайший год.</p>" +
                                "<p>Эти цифры полезны, потому что отрезвляют — ведь это минимум, который вы должны заработать. И ни копейки меньше.</p>" +
                                "<p>Продолжайте вести таблицу месячного бюджета, регулярно записывайте расходы и старайтесь их оптимизировать. Из месяца к месяцу вы должны лучше понимать свои траты и свое отношение к ним. Не забывайте про таблицу Бюджет ГОД. Планируйте расходы и обновляйте данные, а Ваша анкета сама подтянет оттуда сумму.</p>" +
                                "<p>Вы должны выйти на цифры и статьи расходов, с которыми вам действительно комфортно. Это долгая, но интересная работа.</p>" +
                                "<p><strong><span class='green'>— Доходы</span></strong></p>" +
                                "<p>Вы же помните, что в идеале сумма доходов должна покрывать сумму расходов? Если вы заполнили месячный бюджет, месячный доход появится в поле “Доход в месяц”, а таблица выведет ваш годовой доход. Сравните ее с графой “Расход в год”. Вас удовлетворяет эта разница?</p>" +
                                "<p>Отдельно подумайте, какой доход хотели бы иметь, и что, как вы считаете, для этого можно и нужно сделать. Лучше так и поделить список: «‎Можно»‎ и «‎Нужно»‎. В первую пойдет все то, за счет чего уже можно повысить свой доход, во вторую — те инструменты, которых еще нет, либо которые нужно освоить.</p>" +
                                "<p>Разберемся на примере.</p>" +
                                "<p>Вспоминаем нашего Данияра и его 400 000 тенге ежемесячного дохода. Его позиция — sales-менеджер в магазине, куда он устроился чуть меньше года назад.  В свободное время Данияр учится монтировать видео и катается на велосипеде.</p>" +
                                "<p><strong><span class='green'>Можно сейчас</span></strong></p>" +
                                "<ul>" +
                                "<li>Попросить повышения оклада, потому что: 1 — Данияр стабильно выполняет план продаж; 2 — лучше всех сдает тесты на знание продукции; 3 — не опаздывает и не пропускает смену без уважительных причин; 4 — инфляция за год составила 20 %.</li>" +
                                "<li>Попросить повышения до кассира, потому что: 1 — Данияр ответственнее других сотрудников; 2 — знает всю продукцию; 3 — лоялен, так как не ушел с места работы, несмотря на большую текучку</li>" +
                                "</ul>" +
                                "<p><strong><span class='green'>Нужно</span></strong></p>" +
                                "<ul>" +
                                "<li>Повысить уровень знаний в монтаже. А после — взяться за простые заказы и постепенно повышать уровень принимаемой ответственности, расширять клиентскую базу</li>" +
                                "<li>Монетизировать езду на велосипеде — начните доставлять заказы. Как говорится, приятное с полезным </li>" +
                                "</ul>" +
                                "<p>Лайфхак: учитывайте инфляцию, когда думаете, на сколько хотели бы увеличить свои доходы. Чтобы проследить, как инфляция сказывается на ваших финансах, сохраните чеки за покупки — сейчас их можно получить онлайн или оцифровать самостоятельно. Через год две одинаковые продуктовые корзины будут стоить по разному. Например, только за 2022 год инфляция составила больше 20%. Поэтому при планировании закладывайте на будущее больше, чем зарабатываете сейчас — будете видеть, что жизнь дорожает, а значит, нужно искать возможности для увеличения доходов.</p>" +
                                "<p><strong><span class='green'>— Ваши кредиты</span></strong></p>" +
                                "<p>В уроке о кредитах вы выписывали все ваши кредиты в порядке убывания процентной ставки. Если вы не поленились и прописали детали вместе с ежемесячным платежом, на дэшборде появится сумма вашего общего ежемесячного платежа.</p>" +
                                "<p>Корректируйте страницу Кредитов, если начнете закрывать финобязательства досрочно. Сумма платежа будет меняться в соответствии с вашими изменениями.</p>" +
                                "<p>На дэшборде также появится процент вашей кредитной нагрузки — сколько ежемесячный платеж по всем кредитам занимает от вашего дохода. Ваша задача: уменьшать эту цифру сначала до оптимальных 40%, а потом и до предпочтительных 10%.</p>" +
                                "<h2>Вписываем цели</h2>" +
                                "<p>А теперь — самое главное. В уроке про финансовые цели вы учились, как ставить и достигать финансовые цели, следя за их исполнением. А еще прописывали все, чего хотите достичь в жизни, и оценивали список по системе SMART. Пришло время вписать цели в вашу жизнь.</p>" +
                                "<ul>" +
                                "<li>Все цели, которые прошли проверку SMART, пропишите в графе Мои цели.</li>" +
                                "<li>Проставьте возле каждой сумму, а рядом пропишите, сколько уже накопили.</li>" +
                                "</ul>" +
                                "<p>Так вы вписали ваши цели в ваш финансовый план жизни. С удовольствием увеличивайте сумму, которая уже лежит у вас на сокровенном счете цели, и наблюдайте, как обе суммы будут уравниваться. Когда накопите, красьте в зеленый. Пусть это будет классным и красивым достижением вашей жизни.</p>" +
                                "<h2>Готово!</h2>" +
                                "<p>Мы же сказали, что ваш финплан готов? Вот он — перед вашими глазами. Вся информация о том, что вы имеете и что должны, с чем вам надо работать, и к чему стремиться. Ваша задача не бросать его, погрузиться в цифры, принять и понять то, что они говорят о вас.</p>" +
                                "<h2>Запасное окно: Что делать, если все пошло не по плану</h2>" +
                                "<ul>" +
                                "<li>" +
                                "<h2>Постоянно проверяйте план</h2>" +
                                "<p>Cледите за уровнем накоплений по каждой из цели, анализируйте свои траты и доходы. Честность с деньгами откроет окно, в котором можно увидеть свое финансовое будущее. Регулярность: как минимум раз в 2 недели</p>" +
                                "</li>" +
                                "<li>" +
                                "<h2>Проверяйте стоимость того, на что копите</h2>" +
                                "<p>Нестабильность рынков, дефицит, — все это влияет на конечную стоимость товаров и услуг. Нужно готовиться к тому, что финально необходимая сумма может отличаться от запланированной.</p>" +
                                "</li>" +
                                "<li>" +
                                "<h2>Учитывайте колебания инфляции. Пытайтесь составить прогноз</h2>" +
                                "<p>Если в предыдущем пункте события, предугадать которые чаще всего невозможно, то инфляция, к сожалению, вещь более-менее стабильная — она есть всегда. Поэтому перед тем как ставить цель, рассчитывайте примерный уровень инфляции. Актуальный уровень можно проверять <a href='https://new.stat.gov.kz/ru/' target='_blank'>здесь</a>.</p>" +
                                "</li>" +
                                "<li>" +
                                "<h2>Пересчитывайте, если что-то идет не по плану</h2>" +
                                "<p>Не страшно, если что-то пошло не по плану. Мы ведь не задачу по математике расписываем, а живем жизнь — без неожиданных ситуаций не обойтись. Поэтому не корите себя, а проведите перерасчет: если уменьшился доход, уменьшите сумму сбережения и увеличьте срок; если нужны деньги на ЧП и не хватает подушки — перенаправьте деньги.</p>" +
                                "</li>" +
                                "</ul>" +
                                "<p>Итак,</p>" +
                                "<p>только что вы изучили и проанализировали самые потайные комнаты ваших финансовых отношений, но теперь готовы строить те самые здоровые отношения с деньгами — честные, комфортные, приносящие радость.</p>" +
                                "<p>Давайте взглянем на нашего Данияра. Составив полный финансовый план,</p>" +
                                "<ol>" +
                                "<li>Он знает, сколько и на что тратит деньги. Он узнал, где у него есть слабости, и может начать над ними работать.</li>" +
                                "<li>Он имеет представление о предстоящих расходах на целый год — теперь он будет придерживаться своего плана и ему не будут приходить мысли добавить незапланированно большие покупки.</li>" +
                                "<li>Он знает, сколько зарабатывает, и понимает, сколько хотел бы зарабатывать в идеале. Теперь это понятные цифры, а не “было бы круто получать зарплату больше”.</li>" +
                                "<li>Он видит свою кредитную нагрузку и понимает, что ее нужно уменьшать. А еще у него есть понятная стратегия, как это делать.</li>" +
                                "<li>А главное, у него есть финансовые цели. Он понимает, чего хочет достичь через 2 года, 10 лет, и даже, когда ему стукнет 60. Он знаком с инструментами, которые помогут ему прийти в конечную цель. И теперь он осознает, что время может работать ему на руку — оно будет создавать ему дополнительных доход, если он умело применит свои знания.</li>" +
                                "</ol>" +
                                "<p>Собрав все детали, Данияр сделал колоссальный труд. Но в то же время, это только начало. Он построил плот, и теперь готов покорять океан.</p>",
                        },
                        dictionary: [
                            {
                                id: 1,
                                name: 'Личный финансовый план',
                                description: 'ваш лучший друг и помощник. В нем отображены ваши финансовые потоки, общий бюджет и финансовые цели. С ним вы знаете, сколько и когда понадобится денег.'
                            },
                            {
                                id: 2,
                                name: 'Сложный процент (капитализация)',
                                description: 'это когда процент вознаграждения вы не забираете себе, а снова вкладываете. Или автоматически вкладывает банк — обычно у клиента есть выбор: получать вознаграждение по капитализации на текущий счет или сохранять его на депозите.'
                            },
                            {
                                id: 3,
                                name: 'Достижимость цели',
                                description: 'важный параметр, который нужно определить с холодной головой на плечах. Иначе — риск разочароваться в себе, окружающих, а может, и во всем мире.'
                            },
                            {
                                id: 4,
                                name: 'Инвестиционный калькулятор',
                                description: 'инструмент, который поможет оценить и рассчитать потенциальную доходность ваших вложений. С его помощью можно определить срок достижения цели.'
                            },
                            {
                                id: 5,
                                name: 'Кредитный калькулятор',
                                description: 'инструмент, при помощи которого вы узнаете все параметры кредита и сможете определить, насколько выгодным будет заем для вас. Покажет размер ежемесячного платежа, процентную ставку, срок, сумму переплаты. Выгодно использовать для планирования досрочного погашения.'
                            },
                        ],
                        tests: [
                            {
                                id: 1,
                                question: 'У меня незапланированные траты. Что делать с финпланом?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Проигнорировать',
                                        result: 'Если мы говорим о крупной трате (например, лечение или ремонт автомобиля), закрыть на нее глаза не получится. В финансовом плане нужны будут изменения, иначе он перестанет работать на вас.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Взять заем',
                                        result: 'В секундной перспективе заем решит проблему, но позже создаст десятки других. Там и до кредитной зависимости недалеко.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Произвести перерасчет по сумме / статьям расходов / срокам достижения целей в финплане',
                                        result: 'Проведите перерасчет: если уменьшился доход, уменьшите сумму сбережения и увеличьте срок; если нужны деньги на ЧП и не хватает подушки — перенаправьте деньги. Главное — не расстраивайтесь: у вас есть все инструменты, а, значит, все получится!',
                                        correct: true,
                                    },
                                ],
                            },
                            {
                                id: 2,
                                question: 'У меня несколько целей. Как рассчитать сбережения для каждой?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Поделить по категориям',
                                        result: 'Да, то что нужно. Сперва разберите цели по системе SMART — то есть, установите правильный приоритет. Исходя из этого, будет проще понять, какая цель первостепенная, а какая в списке окажется последней.',
                                        correct: true,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Просто вычислить сумму для сбережений',
                                        result: 'Представьте, что у вас пять разных целей, а копилка одна. Цены постоянно меняются, преимущества инструментов утрачиваются, а некоторые цели и вовсе, может быть, отпадают. В таком бардаке разобраться будет сложно. Помните, одна цель — одна копилка.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Чего больше хочу — на то и откладываю',
                                        result: 'Кратковременный выброс гормонов счастья — это, конечно, приятно. Но не слишком рационально. Дайте деньгам играть на вас же: что-то положить на депозит, что-то — на подушку безопасности. Сперва будет тяжело, но в долгосроке вы только выиграете.',
                                        correct: false,
                                    },
                                ],
                            },
                            {
                                id: 3,
                                question: 'Я собрал финансовый план. Как мне следить за ходом достижения целей?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Вносить все траты и доходы в таблицу',
                                        result: 'Постоянно проверяйте план. Cледите за уровнем накоплений по каждой из цели, анализируйте свои траты и доходы. Честность с деньгами откроет окно, в котором можно увидеть свое финансовое будущее. Регулярность: как минимум раз в 2 недели. И не забывайте проверять стоимость вашей цели. Что-то могло упасть в цене, что-то поднятья, где-то могла вмешаться инфляция и т.д.',
                                        correct: true,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Просто откладывать каждый месяц нужную сумму',
                                        result: 'Завидуем вашей стабильности и дисциплине! Но могут быть нюансы. Например, в следующем месяце много праздников и походов в гости. Даже если вы все спланировали, реальность может оказаться другой. Или у вас выросли доходы, а вы не провели перерасчет по сумме, которую можете откладывать.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Проверять уровень трат',
                                        result: 'Это правильное действие, но оно должно работать вместе с другими. Ориентируясь только на свои траты, можно оптимизировать статьи расходов, но не видя полной картины следовать полноценному финплану будет сложно.',
                                        correct: false,
                                    },
                                ],
                            },
                        ]
                    },
                ],
            },
            {
                id: 2,
                name: 'Все, что нужно знать о кредитах',
                slug: 'credits',
                background: '#0029FA',
                payType: 'Бесплатный курс',
                duration: '1 час 10 минут',
                lessonCount: '4 урока',
                bgImage: require('@/assets/images/courses/course-2-promo-1.png'),
                promoImage: require('@/assets/images/courses/course-2-promo-1.png'),
                mobilePromoImage: require('@/assets/images/courses/course-2-promo-1.png'),
                mobilePromoCardRemHeight: '23',
                mobilePromoBackgroundColor: '#FFC8BC',
                content: {
                    description: "<div class='course-content-second__block-with-icon'>\n" +
                        "              <img src='"+require('@/assets/images/icons/finger-icon.png')+"'/>\n" +
                        "              <div>\n" +
                        "                  <p>Переплата по кредиту бывает больше, чем сама сумма кредита. Но мы их оформляем из-за доступности и потребности в быстрых деньгах. Все это приводит к закредитованности, долговым ямам и жизни в рассрочку — иногда отдавать банкам нужно даже больше, чем мы имеем на руках. И это грустная правда о текущей ситуации в Казахстане.</p>\n" +
                        "              </div>\n" +
                        "          </div>\n" +
                        "          <h3>Этот курс посвящен потребительским кредитам — самым популярным и самым проблемным займам казахстанцев.</h3>\n" +
                        "          <p>Мы попробовали дать нашим читателям полезный инструмент — в четырех простых уроках вы научитесь управлять эмоциями в отношении денег, узнаете составляющие кредитов и начнете правильно погашать их. А еще поймете, почему потребительский кредит — вам не друг.</p>\n" +
                        "          <div class=\"course-content-second__block-with-icon\">\n" +
                        "              <img src='"+require('@/assets/images/icons/lamp-icon.png')+"'/>\n" +
                        "              <div>\n" +
                        "                  <h3>Потребительский кредит</h3>\n" +
                        "                  <p>Это деньги, которые вы берете у банка на покупку товаров и услуг, оплаты медицинских счетов, путешествий, ремонта дома и других нужд для личного пользования.</p>\n" +
                        "              </div>\n" +
                        "          </div>\n" +
                        "          <div class=\"course-content-second__start\">\n" +
                        "              <a href='/ru/lessons/credits/psihologiya-zhane-aksha' class='course-content-second__start-btn'>Начать обучение</a>" +
                        "          </div>\n" +
                        "          <h2>Расскажем все о кредитах — от психологии до лайфхаков</h2>\n" +
                        "          <p>Что будет в уроках:</p>\n" +
                        "          <div class=\"course-content-second__block-with-img\">\n" +
                        "              <img src='"+require('@/assets/images/courses/course-2/img-1.png')+"'/>\n" +
                        "              <ul>\n" +
                        "                  <li>Влияние эмоций на наши финансовые решения</li>\n" +
                        "                  <li>Установки в отношении денег — правильные и не очень</li>\n" +
                        "                  <li>Кредитная зависимость — болезнь? А как ее лечить?</li>\n" +
                        "              </ul>\n" +
                        "          </div>\n" +
                        "          <div class=\"course-content-second__block-with-img\">\n" +
                        "              <img src='"+require('@/assets/images/courses/course-2/img-2.png')+"'/>\n" +
                        "              <ul>\n" +
                        "                  <li>Как устроена работа банков и за что их не любят</li>\n" +
                        "                  <li>Какими бывают кредиты для населения</li>\n" +
                        "                  <li>Из чего состоит потребительский кредит</li>\n" +
                        "              </ul>\n" +
                        "          </div>\n" +
                        "          <div class=\"course-content-second__block-with-img\">\n" +
                        "              <img src='"+require('@/assets/images/courses/course-2/img-3.png')+"'/>\n" +
                        "              <ul>\n" +
                        "                  <li>Все о досрочном погашении кредита</li>\n" +
                        "                  <li>Методы погашения кредита и их комбинация</li>\n" +
                        "                  <li>Что делать, если по разным причинам нет денег на оплату кредита</li>\n" +
                        "              </ul>\n" +
                        "          </div>\n" +
                        "          <div class=\"course-content-second__block-with-img\">\n" +
                        "              <img src='"+require('@/assets/images/courses/course-2/img-4.png')+"'/>\n" +
                        "              <ul>\n" +
                        "                  <li>Узнаете, как узнать, как банки видят вас</li>\n" +
                        "                  <li>Найдете рабочие лайфхаки по кредитам</li>\n" +
                        "                  <li>Мошенники хотят оформить кредит на ваше имя, как защитить себя</li>\n" +
                        "              </ul>\n" +
                        "          </div>\n" +
                        "          <h2>Для кого подойдет курс</h2>\n" +
                        "          <div class=\"course-content-second__block\">\n" +
                        "              <h3>Если у вас уже есть кредиты</h3>\n" +
                        "              <p>— узнаете, как погашать их так, чтобы сэкономить на процентах и даже — если денег на погашение нет; какой метод выбрать — в пользу срока или ежемесячного платежа; а еще посчитаете свои кредиты через наши простые примеры.</p>\n" +
                        "          </div>\n" +
                        "          <div class=\"course-content-second__block\">\n" +
                        "              <h3>Если у вас нет кредитов</h3>\n" +
                        "              <p>— узнаете, как работают банки и из чего состоит кредит. Расскажем подробно о составляющих — как срок, ставка, вид займа влияют на ваши условия и делают кредит таким дорогим. Знания будут полезны на будущее, если вам понадобится кредит (надеемся, что нет).</p>\n" +
                        "          </div>\n" +
                        "          <div class=\"course-content-second__block\">\n" +
                        "              <h3>Если вы собираетесь брать кредит</h3>\n" +
                        "              <p>— поймете психологию денег и сможете определить свои финансовые привычки, основанные на установках из детства. Полезно тем, кто собирается брать кредит, поскольку многие из них — эмоциональные решения. Спойлер — новый iPhone взамен предыдущей модели именно таким и является.</p>\n" +
                        "          </div>\n" +
                        "          <div class=\"course-content-second__block\">\n" +
                        "              <h3>Если решили никогда не иметь дел с кредитами</h3>\n" +
                        "              <p>— наш курс подходит даже для тех, кто не собирается их брать. Вы узнаете, какие сервисы нужно использовать, чтобы на вас невозможно было оформить кредит — как мошенниками, так и вами (то есть поставив официальный запрет самому себе). А еще в дополнение посмотрите на себя глазами банков.</p>\n" +
                        "          </div>\n" +
                        "          <h2>Примеры, инструкции, словарик и тесты</h2>\n" +
                        "          <p>Мы написали уроки так, чтобы они могли помочь каждому, кто хочет выстроить ясные отношения с деньгами. В тексте много детальных инструкций и примеров. А в конце каждого урока есть словарик с терминами и тест для закрепления знаний. Если вы будете внимательно читать, делать упражнения, вникать в термины, а потом еще и пройдете тест, вам не о чем переживать.</p>\n" +
                        "          <h2>Начнем?</h2>\n" +
                        "          <p>В курсе всего четыре урока, которые написаны простым языком и с понятными примерами. Здесь нет сложных терминов и бюрократических документов, обучение не займет у вас больше полутора часа. <i>А пользы принесет на всю жизнь.</i></p>\n" +
                        "          <p>Начать лучше по порядку — вы разберете эмоциональную сторону кредитов, научитесь понимать их устройство, поймете стратегии погашения и в конце узнаете дополнительные лайфхаки.</p>",
                },
                fakeLessons: [
                    {
                        number: 4,
                        name: 'Лайфхаки и сервисы',
                        duration: '13 мин',
                    }
                ],
                lessons: [
                    {
                        id: 1,
                        number: 1,
                        name: 'Психология и деньги',
                        slug: 'psihologiya-zhane-aksha',
                        duration: '20 мин',
                        bgImage: require('@/assets/images/visuals/course-2/lesson-1/bg.png'),
                        content: {
                            description: "<div>\n" +
                                "<p>В первом курсе мы часто упоминали Данияра 🙋‍♂️ — у него было немаленькая зарплата, но денег на большие дела все равно никогда не хватало. А проблема у Данияра была в его отношениях к деньгам — он их тратил быстро и бездумно: бары, ужины в дорогих заведениях, вещи не по возможностям. С таким тратами и с бОльшим доходом можно прогореть за полмесяца.</p>\n" +
                                "<p>Хоть и кажется, что высокий доход и низкие расходы — залог денежного благополучия, на деле все не так просто. И даже больших знаний в финансовой грамотности не всегда бывает достаточно. Иногда мы ведем бюджет, строим планы, и откладываем деньги, а в момент уныния тратим все на красивую и очень приятную вещь.</p>\n" +
                                "</div>\n" +
                                "<section>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-9.png')+"'/>\n" +
                                "<p>Большое значение в отношениях с деньгами имеет психология человека.</p>\n" +
                                "</section>\n" +
                                "<blockquote>\n" +
                                "<p>Поэтому прежде, чем научиться закрывать кредиты правильно, мы расскажем, как они сначала появляются у нас в голове, причем здесь ваше окружение, и немного вспомним ваше детство. </p>\n" +
                                "<p>Вполне возможно, что проблемы в отношениях с финансами, которые вылились в высокую кредитную нагрузку — не результат особенностей вашего характера, а просто набор привычек и установок, от которых можно избавиться. Потому что они были навязаны извне. Нужно только суметь их распознать и приложить немного усилий, чтобы изменить эти установки. И все получится 💚</p>\n" +
                                "</blockquote>\n" +
                                "<section>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-10.png')+"'/>\n" +
                                "<p>Чтобы разобраться детально, как формируются наши отношения с деньгами, этот урок мы подготовили с психологом сервиса терапии <a href='https://www.tanympro.kz/' target='_blank'>Tanym Pro</a> Кариной Муртазалиевой.</p>\n" +
                                "</section>\n" +
                                "<hr>\n" +
                                "<h3>Моргните, если узнаете себя в этих утверждениях.</h3>\n" +
                                "<ul>\n" +
                                "<li>" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-11.png')+"'/>\n" +
                                "<p>Я живу сегодняшний днем, неважно что будет завтра, главное — как я чувствую себя сейчас, и поэтому покупаю все, что хочу</p>" +
                                "</li>\n" +
                                "<li>" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-12.png')+"'/>\n" +
                                "<p>Если я чего-то хочу, я должен это получить сразу, ведь мне обязательно нужно</p>" +
                                "</li>\n" +
                                "<li>" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-13.png')+"'/>\n" +
                                "<p>Я не влияю на то, сколько зарабатываю. Это определяет тот, кто выше, и мои старания никак не важны, нет смысла прикладывать усилия и пытаться влиять на увеличение дохода</p>" +
                                "</li>\n" +
                                "<li>" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-14.png')+"'/>\n" +
                                "<p>Деньги — это зло, иметь много денег означает совершать плохие, приносящие вред другим поступки</p>" +
                                "</li>\n" +
                                "<li>" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-15.png')+"'/>\n" +
                                "<p>Деньги — большая ответственность, только подготовленные люди могут ее нести</p>" +
                                "</li>\n" +
                                "<li>" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-16.png')+"'/>\n" +
                                "<p>Деньги — залог дружбы/отношений/счастья. Без них меня не будут любить</p>" +
                                "</li>\n" +
                                "</ul>\n" +
                                "<p>Узнали? Да, вы не одиноки в таких убеждениях. И даже скажем больше — многие вокруг вас тоже так думают, но никогда вам в этом не признаются. Все мы заложники своих же установок, и это неспроста. Все началось (как обычно) еще в детстве. Сейчас все объясним.</p>\n" +

                                "<h2>Семья и деньги</h2>\n" +
                                "<p>Во многом наши отношения с финансами формируются из наблюдений в детстве и юношестве: как значимые для нас взрослые пользуются деньгами, как зарабатывают, тратят. Смотря на них, мы формируем свои первые установки и во многом так и ведем себя в будущем.</p>\n" +
                                "<h4>Вот пять простых жизненных примера:</h4>\n" +
                                "<ol>\n" +
                                "<li>Ребенок жил в семье с хорошим достатком, но при этом видел, как деньги сливаются на закрытие долгов брата из-за ставок. У него сформировалась негативная ассоциация: заработок больших денег связан с большим количеством дискомфорта и неожиданным риском их потери. Теперь у него не будет мотивации зарабатывать больше, потому что “все равно уйдет мимо” , ему придётся решать проблемы других, никакого удовольствия от этих денег не будет. Одни страдания.</li>" +
                                "<li>Ребенок рос в не самой обеспеченной семье, где считали каждую копейку. Есть вероятность, что в последствии он будет экономить и бояться больших расходов. Потому что сформировался страх и нежелание возвращаться в то неспокойное место и состояние, когда что-то не мог себе позволить.</li>\n" +
                                "<li>Ребенок наблюдает за тем, как деньги зарабатываются незаконным путём. У него складывается ощущение, что мир несправедлив, и он не сможет заработать больших денег, не обманывая и не идя на компромиссы с совестью. И, соответственно, даже не предпринимает попыток преуспеть финансово. Потому что в категориях “добро и зло”, деньги попали в последнюю. “Если я много заработаю, значит, я тоже буду плохим” — мысль, которая будет преследовать и блокировать финансовое развитие.</li>\n" +
                                "<li>Родители — отъявленные материалисты, и ребенок впитывает схожие ценности. Если семья подчеркивает потребительский стиль жизни, это будет оказывать влияние. “Все проблемы можно решить покупками, новыми вещами, так я буду справляться со сложностями” — установка, которая заставит искать утешения в трате денег.</li>\n" +
                                "<li>А если в семье ведут бюджет и спокойно его обсуждают, то у ребенка, вероятнее всего, тоже сложится внимательное отношение к деньгам.</li>\n" +
                                "</ol>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-1.png')+"'/>\n" +
                                "<p>Если вернуться к кредитам: ребенок видит, что родитель закрывает любую нехватку денег очередным кредитом, и впитывает в себя простое правило “не хватает — можно всегда взять”. Так научили родители (хоть и, конечно, не хотели), а значит, это правда. </p>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-2.png')+"'/>\n" +

                                "<p>Выходя из маленькой ячейки общества (семьи) в большую, новый взрослый (вчерашний ребенок) встречает новые стандарты и получает новую информацию о том, как надо пользоваться деньгами.</p>\n" +
                                "<h4>Общество начинает диктовать свои правила и установки:</h4>\n" +
                                "<ol>\n" +
                                "<li>Перед друзьями/коллегами стыдно, если у тебя нет чего-то, что есть у них</li>\n" +
                                "<li>Надо быть щедрым и помогать близким, даже если нет возможности</li>\n" +
                                "<li>Стыдно не сделать все по традициям, даже если на это нет средств</li>\n" +
                                "<li>К 30 годам должны быть квартира, машина, отпуск два раза в год</li>\n" +
                                "<li>Твой успех — это сколько денег у тебя на счету. С деньгами придет счастье, признание и любовь</li>\n" +
                                "<li>Мужчина должен обеспечивать семью и приносить большие деньги</li>\n" +
                                "<li>Женщина должна быть и успешной женой и матерью, и успешной на работе (но не слишком)</li>\n" +
                                "</ol>\n" +
                                "<p>В большинстве своем мы принимаем новые правила игры, потому что одна из ключевых потребностей человека — быть принятым. Если ты не подходишь под стандарты общества, значит, ты не принят. А в нашей голове быть непринятым — стыдно и больно. И очень одиноко.</p>\n" +
                                "<p>Начинают формироваться мысли о собственной несостоятельности и сильное чувство стыда — <span class='green'><i>«Как так, у всех получается, а у меня нет?»</i></span> Параллельно мы смотрим и учимся у окружения вокруг, подсматривая их опыт: родственники берут на свадьбу кредит, друзья берут в кредит новый телефон, коллега взял кредит, чтобы слетать в путешествие.</p>\n" +
                                "<p>В итоге получается, что с одной стороны есть установка быть не хуже других, потому что стыдно, больно и одиноко, а с другой стороны есть отлично работающий (с первого взгляда) инструмент, который помогает избежать эти неприятные переживания. Тем более так делают все, и у всех получается. Ведь никто не делится негативным опытом и не рассказывает, с какими проблемами сталкиваются после пяти неподъемных кредитов.</p>\n" +
                                "<p>На выходе мы имеем два сильнейших фактора, которые сформировали нас и наши отношения с деньгами. Первый — наша семья, и то, как она распоряжалась финансами и как о них говорила. Второй — наше желание быть частью общества, которое транслирует определенные ожидания, цели, ценности и инструменты для их достижения.<br><i>А мы так и живем — как нас научили и как сказали жить, чтобы оставаться своим.</i></p>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-3.png')+"'/>\n" +

                                "<div>\n" +
                                "<p>Еще один важный элемент в отношениях с деньгами — наши эмоции, импульсивность, а вернее неумение с ними справляться. То есть как быстро мы хотим закрывать появившееся желание несмотря на аргументы разума.</p>\n" +
                                "<p>Здесь очень сильную роль играет то, как мы относимся к состоянию неудовлетворенности. Если человек импульсивный и нетерпимый к состоянию дискомфорта, то, скорее всего, он будет искать быстрый способ перестать испытывать неприятные эмоции и улучшить самочувствие.</p>\n" +
                                "<p>И даже если оказывается, что денег на это сейчас нет, срабатывает подсмотренный алгоритм действий (спасибо окружению). Вот как это происходит:</p>\n" +
                                "</div>\n" +
                                "<div>\n" +
                                "<p>Коллега, с которым вы соревнуетесь за внимание начальника, покупает машину. На работу приходит радостный, его все поздравляют, начальник жмет руку и говорит поощрительную речь. Вы проживаете целую череду неприятных эмоций — ревность, зависть, грусть. Ваши эмоции говорят вам заглушить этот дискомфорт какой-нибудь покупкой, которая вернет вам внимание коллег и руководства. Вы тут же выбираете способ удовлетворения — выбираете покупку. Получая новинку, чувствуете вновь нашедшее вас спокойствие.</p>\n" +
                                "<p>На лицо навязанные ценности общества: <br>" +
                                "👜 счастье в вещах; <br>" +
                                "💰 успех и признание зависят от денег; <br>" +
                                "🤦‍♂️ стыдно быть хуже других.</p>\n" +
                                "<p>Но денег, возможно, на покупку не было. И на эмоциях вы взяли кредит или рассрочку, не подумав о последствиях.</p>\n" +
                                "<p><span class='green'><i>Установки навязало общество, эмоции надавили, а доступность кредита позволила сделать это быстро.</i></span></p>\n" +
                                "</div>\n" +
                                "<p>Самое грустное, что радость от такой покупки будет кратковременна — реальность настигнет с первым же ежемесячным платежом. А еще грустнее возможная перспектива — если вы не сможете оплачивать кредит, испортите кредитную историю, а потом не сможете оформить ипотеку или взять автокредит. То есть эмоции на пять минут могут испортить жизнь в долгосрочной перспективе.</p>\n" +

                                "<h2>Доступность и кредит</h2>\n" +
                                "<p>Конечно же, мы не можем обойти стороной такой фактор, как доступность кредита, и как это влияет на наше поведение. Потому что здесь тоже есть психологическая подоплека.</p>\n" +
                                "<p>Давайте снова взглянем на пример:</p>\n" +
                                "<div>\n" +
                                "<p>Данияр несколько месяцев назад взял крутые nike за 90 000 тенге в рассрочку, хотя его доходов и так хватало впритык. Тут появляются новые наушники, классные — с мощным шумоподавлением, стильным дизайном и вообще в таких уже весь инстаграм. Данияр не хочет чувствовать себя каким-то ущемленным ни секунды (дискомфорт) и открывает приложение банка (подсмотренный алгоритм): полминуты, пара кликов (доступность) и у Данияра кредит.</p>\n" +
                                "</div>\n" +
                                "<div>\n" +
                                "<p>Такой путь для нашего мозга — очень легкий, а поэтому удобный. Потому что мозг у нас нас самом деле ленивый. Он ищет удобные простые пути и не хочет перегружаться. С легким доступным кредитом не нужно ничего решать, думать, копить, и человек к такой системе привыкает. А о том, что ждет впереди, мозг думать не хочет — а там ежемесячные платежи, которые лишают предыдущего уровня жизни, и стресс от мысли, что денег может не хватить. И, скорее всего, не хватит.</p>\n" +
                                "<p>Ведь на самом деле нет никакой «кредитной зависимости» — такого заболевания в международной классификации болезней, которая бы меняла человека как алкогольная или наркотическая, нет. Но кредит выступает инструментом, <span class='green'><i>который позволяет быстро и легко удовлетворить импульсивные желания.</i></span></p>\n" +
                                "</div>\n" +
                                "<div class='with-background'>" +
                                "<p>В итоге человек имеет целый набор факторов, которые влияют на его финансовые решения: семья, общество и пришедшие от них установки, эмоции и желание мозга найти простые пути решений проблем.</p>" +
                                "</div>\n" +

                                "<h2>Как себе помочь</h2>\n" +
                                "<p>Теперь о хорошем — способах все наладить! Не все базируется на наследственности и среде. Важная часть — знания. А их легко приобрести.</p>\n" +
                                "<div>\n" +
                                "<p>Для начала советуем пройти наш первый курс: «Как управлять личными финансами». Там мы рассказали все о том, как управлять личными финансами. Там простая базовая информация, понятные примеры, таблица для учета бюджета и полезные инструкции.</p>\n" +
                                "</div>\n" +
                                "<a href='/ru/course/personal-finance/' class='course-link'>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-17.png')+"'/>\n" +
                                "<div class='course-link__text'>" +
                                "<p class='course-link__title'>Как управлять личными финансами</p>" +
                                "<p class='course-link__subtitle'>6 уроков • 2 часа 13 минут</p>" +
                                "</div>\n" +
                                "<p class='course-link__btn'>Перейти</p>\n" +
                                "</a>\n" +

                                "<h2>Дневник эмоций</h2>\n" +
                                "<div>\n" +
                                "<p>Мы только что разобрали, какие факторы влияют на наше финансовое поведение, и теперь будем учиться эти факторы между собой различать и с ними бороться.</p>\n" +
                                "<p>При принятии решений важно принимать во внимание все: причину поступка, эмоции, мысли, желания, реакции. Потому что только проанализировав все, можно принять по-настоящему взвешенное решение. Записывать весь анализ проще в дневник.</p>\n" +
                                "</div>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-4.png')+"'/>\n" +
                                "<p><i>Можно начертить в блокноте или собрать таблицу в гугл-доках или экселе. А можно использовать приложение для ведения дневника: Android — <a href='https://play.google.com/store/apps/details?id=com.cbt.mindhealthy' target='blank'>скачать</a> / iOS — <a href='https://apps.apple.com/us/app/%D0%B4%D0%BD%D0%B5%D0%B2%D0%BD%D0%B8%D0%BA-%D0%BA%D0%BF%D1%82-%D1%81%D0%BC%D1%8D%D1%80/id1644324300?l=ru' target='blank'>скачать</a></i></p>\n" +
                                "<p>Теперь, когда на руках есть дневник, разберемся, как его лучше заполнить. За пример возьмем следующую ситуацию:</p>\n" +
                                "<div>\n" +
                                "<p>Жена сказала, что хотела бы делать классные укладки. Муж сразу решил: «Я — мужчина, все в семье зависит от меня, я должен удовлетворять потребности жены, поэтому куплю ей Dyson».</p>\n" +
                                "</div>\n" +
                                "<div>\n" +
                                "<p>В <span class='green'>ситуацию</span> пишем: Жена намекнула, что хочет Dyson</p>\n" +
                                "</div>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-5.png')+"'/>\n" +
                                "<div>\n" +
                                "<p>В <span class='green'>мысли</span>: Хочу подарить, я должен дарить такие подарки. Тем более сейчас все их дарят своим женам, я не хуже других. Но денег на него сейчас нет. Почему она попросила сейчас, когда нет возможности. Почему у меня нет возможности, </p>\n" +
                                "</div>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-6.png')+"'/>\n" +
                                "<div>\n" +
                                "<p>В <span class='green'>эмоции</span>: разочарование, подавленность, злость</p>\n" +
                                "</div>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-7.png')+"'/>\n" +
                                "<div>\n" +
                                "<p>В <span class='green'>Реакции</span>:</p>\n" +
                                "<ul>\n" +
                                "<li>физиология: сердцебиение участилось, чувствую сжатость в мышцах</li>\n" +
                                "<li>поведение: мне не нравится так себя чувствовать, я могу взять подарок в рассрочку</li>\n" +
                                "</ul>\n" +
                                "</div>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-8.png')+"'/>\n" +
                                "<p>То есть в этой ситуации муж решает подействовать на эмоциях и закрыть дискомфорт, купив фен в рассрочку.</p>\n" +
                                "<p>Но можно поступить и по-другому: посмотреть на дневник и задаться вопросами.</p>\n" +
                                "<ol>\n" +
                                "<li>Жена намекнула — можно ли спросить у нее напрямую, какой фен она хочет и почему. Хочет ли она, чтобы его купил я? Когда она его хочет? Может ли она потерпеть? Хочет ли она именно эту дорогую модель? Или есть другие варианты?</li>\n" +
                                "<li>Мысли — что из этого навязанные мне мысли, а что — мои собственные ценности? Если я не куплю, я становлюсь хуже других? Если не куплю, плохой ли я муж? Зависит ли семья только от меня или жена тоже играет здесь равноправную партнерскую роль? Почему меня так триггерит отсутствие возможности купить фен в момент? Напоминает ли мне это что-то из детства?</li>\n" +
                                "<li>Эмоции — что я могу сделать со своими эмоциями? Могу ли поделиться с ними с женой? Могу ли прожить их, выплеснуть? Могу ли их принять?</li>\n" +
                                "<li>Что я могу сделать с реакцией? Как мне расслабить тело? Поможет ли сфокусироваться на дыхании и сделать спортивный перерыв?</li>\n" +
                                "<li>Что мне сделать с поведением? Какие будут последствия у выбранного мною поведения (краткосрочные и долгосрочные)? Какое поведение будет наиболее полезным в длительной перспективе? Я хочу взять рассрочку, но могу ли я позволить себе еще одну, не сядет ли она большой нагрузкой? Стоит ли такая покупка годичной нагрузки на наш бюджет? Есть ли у меня другие цели, на которые я мог бы откладывать эти деньги? </li>\n" +
                                "</ol>\n" +
                                "<p>Задавая себе вопросы, вы анализируете свое поведение, мысли, эмоции и чувства. Возможно, вы все равно придете к выводу, что первый позыв верный, но скорее всего вы задумаетесь и придете к другому решению. В любом случае проводить анализ своих больших финансовых решений стоит не только с прагматичной точки зрения, но и эмоциональной — о чем говорит эта покупка для вас, что вы ей решаете? Какие потребности пытаетесь удовлетворить с ее помощью и есть ли более эффективные способы это сделать?</p>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-18.png')+"'/>\n" +

                                "<p>Еще немного о негативных эмоциях.</p>\n" +
                                "<div>\n" +
                                "<p>Очень важно осознать, что зачастую фундаментальная проблема — попытка избежать любого дискомфорта. Хотя нормально понимать и признавать, что иногда я чего-то не могу и чего-то у меня нет. Не нужно размышлять в духе «если у меня нет 14-го iPhone, а у всех есть, значит, я ничего не добился в жизни». Это набор несвязанных друг с другом понятий.</p>\n" +
                                "<p>И второе — нужно принимать грусть, а не избегать ее. Чувствовать неприятные переживания — нормально. Они пришли и уйдут через какое-то время. Конечно, это достаточно сложное действие — понимать, что мои мысли — это просто мысли, а не факты реальности. Но если проследить за их истоками, то, скорее всего, окажется, что они попросту навязаны извне.</p>\n" +
                                "</div>\n" +
                                "<p>Вам не нужна последняя модель телефона, если вы не можете себе ее позволить. Это не значит, что вы неудачник. Это всего лишь значит, что сейчас вы не можете себе ее позволить.</p>\n" +
                                "<p>Вам не надо брать кредит на празднование какого-то события в вашей жизни, даже если оно важное и значимое. Это не значит, что вы неспособны или не уделяете этому моменту достаточно внимания. Это всего лишь значит, что в текущих обстоятельствах вы выбираете свое здоровое финансовое и эмоциональное состояние.</p>\n" +
                                "<blockquote>\n" +
                                "<p>Ну и напоследок давайте вспомним «Правило 30 дней». Когда приходит мысль о покупке, вы даете ей повариться в голове 30 дней. Если по истечении этого срока вы понимаете, что вещь нужна и без нее никак — покупаете или копите на неё. А если нет — до свидания, она не сделает вашу жизнь лучше.</p>\n" +
                                "</blockquote>\n" +
                                "<div>\n" +
                                "<p><i>Финансово грамотный человек — человек, который умеет управлять и финансами, и эмоциями в отношении финансов.</i></p>\n" +
                                "</div>\n" +
                                "</div>",
                        },
                        recommendation: [
                            {
                                id: 1,
                                name: 'Талер Р. "Новая поведенческая экономика"',
                                link: 'https://ru.m.wikipedia.org/wiki/Новая_поведенческая_экономика',
                            },
                            {
                                id: 2,
                                name: 'Канеман Д. "Думай медленно, решай быстро"',
                                link: 'https://ru.m.wikipedia.org/wiki/%D0%94%D1%83%D0%BC%D0%B0%D0%B9_%D0%BC%D0%B5%D0%B4%D0%BB%D0%B5%D0%BD%D0%BD%D0%BE%E2%80%A6_%D1%80%D0%B5%D1%88%D0%B0%D0%B9_%D0%B1%D1%8B%D1%81%D1%82%D1%80%D0%BE',
                            },
                        ],
                        dictionary: [
                            {
                                id: 1,
                                name: 'Эмоции',
                                description: 'реакция человека на те или иные ситуации. Эмоции сопровождают человека на протяжении всей жизни и показывают отношение к существующим или возможным ситуациям.'
                            },
                            {
                                id: 2,
                                name: 'Деньги',
                                description: 'общий элемент, измеряющий стоимость и использующийся в купле-продаже. Деньги бывают бумажные и металлические, имеют высокую ликвидность.'
                            },
                            {
                                id: 3,
                                name: 'Дневник эмоций',
                                description: 'специальный дневник, который помогает определить причину поступка, эмоции, мысли, желания, реакции. Потому что только проанализировав все, можно принять по-настоящему взвешенное решение.'
                            },
                            {
                                id: 4,
                                name: 'Эмоциональная покупка',
                                description: 'это та, которая совершается под влиянием эмоций, а не рационального расчета. Например, новая куртка, купленная просто из желания “хочу такую, классная”, когда дома уже три таких — эмоциональная покупка.'
                            },
                            {
                                id: 5,
                                name: 'Установки',
                                description: 'склонности или стереотипы, которые влияют на восприятие и оценку мира вокруг. Психологические установки могут быть положительными или отрицательными и зависят от опыта, воспитания, образования и социокультурного контекста.'
                            },
                            {
                                id: 6,
                                name: 'Финансово грамотный человек',
                                description: 'человек, который умеет управлять и финансами, и эмоциями в отношении финансов.'
                            },
                        ],
                        tests: [
                            {
                                id: 1,
                                question: 'Зачем вести дневник эмоций?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Чтобы анализировать свое поведение и выявлять взаимосвязь между своими настроением, эмоциями, жизненными ситуациями и финансовыми решениями.',
                                        result: 'Дневник эмоций позволит вам понимать себя и находить связь между внутренними ощущениями и внешней реакцией. К примеру, плохое настроение = спонтанная покупка. Такая техника (запись) поможет осознать эмоции, проанализировать и переоценить их. А затем еще и поработать над ними через релаксацию или саморегуляцию, чтобы они не влияли на ваши финансовые решения и поступки.',
                                        correct: true,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Чтобы фиксировать свои доходы и расходы',
                                        result: 'Название Дневник эмоций говорит само за себя. К финансам оно тоже имеет отношение, но в первую очередь дневник нужен для записи своих ощущений и настроения, чтобы потом проработать их правильно.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Чтобы понять, что у вас хорошее настроение и теперь можно совершить новую покупку',
                                        result: 'Как раз наоборот. Дневник нужен для контроля эмоций и чтобы не совершать спонтанных эмоциональных покупок.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Чтобы понять, все ли существующие эмоции вы испытываете',
                                        result: 'Понимать свои эмоции — хорошо. Но это не соревнование, где нужно поставить галочки на все существующие эмоции в мире. В нашем случае дневник нужен для того, чтобы анализировать и осознать те эмоции, которые у вас есть сейчас и на их основе работать над своими финансовыми привычками.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 2,
                                question: 'Что нужно записывать в дневник эмоций?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Доходы и финансовые цели',
                                        result: 'Эмоции касаются внутренних переживаний и требуют отдельного разбора. Если смешать их — дневник эмоций и подсчеты финансов, то сфокусироваться и проанализировать свое поведение и эмоции не получится.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Ситуации/события (покупки или решения о покупке чего-либо), мысли, эмоции, реакции (тело и поведение)',
                                        result: 'Записывайте свои текущие эмоции — после финансовых трат или планирования таких трат. Может включать чувство радости, удовлетворения, тревоги, стресса или вины. Опишите в дневнике свои ожидания или мотивацию, которые были связаны с этой покупкой или событием — поможет понять, из-за чего хочется потратить деньги и поможет ли это вам.',
                                        correct: true,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Впечатления от прочитанных книг, просмотренных фильмов и прослушанной музыки',
                                        result: 'Рецензии можно написать на специальных сайтах, в СМИ или соцсетях. Дневник эмоций нужен для определения своих чувств, мыслей, событий и их взаимосвязи.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Маленькие радостные моменты жизни',
                                        result: 'Тоже хорошо, но заведите для этого отдельный дневник. В Дневнике эмоций нужно записывать не только положительные эмоции, но и отрицательные. Может включать чувство радости, удовлетворения, тревоги, стресса, вины и другие.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 3,
                                question: 'Вышел новый iPhone 15, хочу обновить свой смартфон 14 поколения. Какие эмоции/чувства диктуют это решение?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Страх',
                                        result: 'Еще рано страшиться, что ваша модель станет непригодной.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Вдохновение',
                                        result: 'Вдохновились потратить больше денег?',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Злость',
                                        result: 'Айфоны ближайших поколений не сильно отличаются между собой, вам не на что злиться в такой ситуации.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Удовлетворение от покупки',
                                        result: 'Новизна может приносить удовлетворение, но обычно это временный эффект.',
                                        correct: true,
                                    }
                                ],
                            }
                        ],
                    },
                    {
                        id: 2,
                        number: 2,
                        name: 'Берем потребительский кредит',
                        slug: 'potrebitelskij-kredit',
                        duration: '20 мин',
                        bgImage: require('@/assets/images/visuals/course-2/lesson-2/bg.png'),
                        content: {
                            description: "<div>\n" +
                                "<p>О психологии денег мы говорили в предыдущем уроке, в этом — расскажем о строении кредитов и научимся определять выгодные предложения от тех, которые могут вам навредить.</p>\n" +
                                "</div>\n" +
                                "<hr>\n" +
                                "<h3 style='color: #C3F53C;'>Непопулярное мнение: кредит = развитие экономики страны, а банки — не зло.</h3>\n" +
                                "<p>Кредит — неотъемлемая часть экономики страны и ее развития, иначе этого финансового инструмента попросту не было бы в нашей жизни.</p>\n" +
                                "<div>\n" +
                                "<p>Почему же тогда он часто воспринимается всеми как синоним зла? Отвечаем сразу — все дело в нашем опыте использования кредита, непонимании его строения и эмоциональной составляющей.</p>\n" +
                                "<p>На самом деле кредит — не зло и не добро, он не имеет настроения, намерений, цвета и оттенка. И только в зависимости от того, как используется, приобретает для каждого индивидуальные очертания — становится средством развития и роста или же очередной историей о долговом рабстве.</p>\n" +
                                "</div>\n" +
                                "<div>\n" +
                                "<p>Сам по себе кредит не представляет никакой угрозы вашему благополучию. В это сложно поверить, особенно однажды столкнувшись с его механизмом работы — когда нужно возвращать больше денег, чем было взято в долг. Но нужно понимать, что банк — не ваш родственник, чтобы по душевной доброте делиться деньгами за просто так. Банк — такая же компания, как и все другие. И чтобы оставаться на рынке, ему нужно тоже зарабатывать.</p>\n" +
                                "<p>Зарабатывает он на обороте денег: когда вы пользуетесь картой, оплачивая покупки — банк в плюсе; когда отдаете деньги на хранение, чтобы заодно приумножить свой капитал — банк в плюсе; когда одалживаете деньги, чтобы пополнить свои активы (квартиру, авто или вложения в бизнес) — банк в плюсе. Но это не односторонний процесс. Вы тоже всегда в плюсе. Работает это так: за покупки вы получаете кешбэки, за хранение начисляются вознаграждения, а кредитуясь, вы осуществляете свою цель раньше срока — приобретаете активы, товар или услугу за деньги банка сейчас, оплачивая потом.</p>\n" +
                                "</div>\n" +
                                "<p>Если кредиты пользуются популярностью, значит люди охотно берут займы и знают, что могут их выплатить (по крайней мере должны знать); а банки дают одобрение, так как видят наличие платежеспособности. Покупка товаров приносит продавцу заработок, а он, в свою очередь, тоже может пополнять свои услуги и продукты в кредит — так деньги всегда находятся в движении, развивая экономику.</p>\n" +
                                "<div>\n" +
                                "<p>Если бы банки выполняли только функцию хранения денег, они бы редко получали негатив. Хотя по сути ничем бы не отличались от охранной компании. Именно то, что они не просто держат ваш капитал, но и пускают его в оборот и зарабатывают на этом, делает их работу сложной, а вместе с этим важной и весомой.</p>\n" +
                                "<p>Можно обвинять банки в том, что они зарабатывают на людях и их потребностях, выдавая займы, а можно постараться понять структуру работы и относиться к кредитам рационально, рассматривать их только как инструмент достижения целей. Этому и будем учиться в нашем уроке.</p>\n" +
                                "</div>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/img-1.png')+"'/>\n" +

                                "<p>Когда речь заходит о кредитах, люди в первую очередь думают о своих целях — главное, чтобы найдутся деньги на покупку желаемого, и только потом об обязательствах. В этом нет ничего плохого, ведь кредит — всего лишь инструмент достижения целей. То есть его можно применять в правильно время и на правильную цель.</p>\n" +
                                "<div>\n" +
                                "<p>Если вы понимаете, что копить на вашу цель не вариант — это займет много времени, а именно скорость реализации сейчас имеет значения, о кредите стоит задуматься. Но и здесь надо уметь выбирать.</p>\n" +
                                "<p>Ваши обязательства будут зависеть от того, какой кредит вы выбираете. Для одной цели всегда есть несколько предложений: к примеру, покупая холодильник, вы можете обратиться к рассрочке, кредиту наличными или воспользоваться кредитной картой. Все они позволяют достигнуть цели, но не все станут правильным решением.</p>\n" +
                                "</div>\n" +
                                "<p>Смотрите сами:</p>\n" +
                                "<ul>\n" +
                                "<li>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/img-2.png')+"'/>\n" +
                                "<p>Кредит наличными — деньги, которые получаете на руки (без конкретной цели)</p>\n" +
                                "</li>\n" +
                                "</ul>\n" +
                                "<p>Ставка по таким кредитам обычно выше, а значит, и переплата будет больше. Соответственно, кредит вам не подходит.</p>\n" +
                                "<ul>\n" +
                                "<li>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/img-3.png')+"'/>\n" +
                                "<p>Кредитная карта — карта с деньгами банка, которыми вы можете воспользоваться в любой момент без необходимости подавать заявку на кредит при каждой покупке.</p>\n" +
                                "</li>\n" +
                                "</ul>\n" +
                                "<p>Вариант хороший, но не всегда подходящий. К примеру, ваша карта на 200 тысяч тенге, а холодильник стоит 350 тысяч тенге. Или другая ситуация: по сумме вы сошлись, но карта делит покупки только на три месяца, а вам было бы комфортно на шесть.</p>\n" +
                                "<ul>\n" +
                                "<li>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/img-4.png')+"'/>\n" +
                                "<p>Рассрочка — беспроцентная оплата по частям в течение нескольких месяцев.</p>\n" +
                                "</li>\n" +
                                "</ul>\n" +
                                "<p>Вот и ваш вариант. Рассрочку легко оформить на столько месяцев, сколько вам необходимо.</p>\n" +

                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/img-5.png')+"'/>\n" +
                                "<ul>\n" +
                                "<li>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/img-6.png')+"'/>\n" +
                                "<p>Потребительский кредит — деньги, которые вы берете у банка на покупку товаров и услуг, оплаты медицинских счетов, путешествий, ремонта дома и других нужд для личного пользования.</p>" +
                                "</li>\n" +
                                "</ul>\n" +
                                "<p>Если копнуть глубже, то большая часть кредитов — потребительские. То есть для людей и их личных целей. Тот же автокредит или ипотека. Но банки относят их к отдельной категории, так как для их оформления нужно больше документов, проверок и действий. А вот та часть потребительских кредитов, о которых мы будем говорить — это кредит наличными, рассрочки. Все то, что доступно даже студентам.</p>\n" +
                                "<blockquote class='lamp'>\n" +
                                "<p>В этом и заключается их опасность и проблема — в доступности.</p>\n" +
                                "</blockquote>\n" +

                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/img-7.png')+"'/>\n" +
                                "<p>Нужно усвоить одну самую главную мысль: любой кредит — это всегда про деньги в долг. Поэтому так важно выбирать правильный кредит с учетом ставки, переплаты и срока.</p>\n" +
                                "<div>\n" +
                                "<p>Потребительские кредиты можно разделить на категории в зависимости от наличия или отсутствия цели, продолжительности срока, и величины суммы. А еще они могут комбинироваться.</p>\n" +
                                "</div>\n" +
                                "<p>В зависимости от цели:</p>\n" +
                                "<div>\n" +
                                "<h2>Целевой кредит</h2>\n" +
                                "<p>Тут все понятно — есть конкретное назначение трат. Запрашивая кредит, вы ставите цель. Например, покупка смартфона. Выбираете магазин, а банк перечисляет деньги сразу на счет продавца. Это гарантирует, что деньги будут использованы точно по условиям — на покупку смартфона.</p>\n" +
                                "</div>\n" +
                                "<div>\n" +
                                "<h2>Нецелевой кредит</h2>\n" +
                                "<p>Противоположная ситуация. Вы берете кредит наличными и ни за что не отчитываетесь перед банком, тратите на свое усмотрение. Можете направить деньги на покупку смартфона, мебели, путевки, дивана, и никто не задаст никаких вопросов. Такие кредиты обычно самые невыгодные.</p>\n" +
                                "</div>\n" +
                                "<p>В зависимости от срока и суммы кредит может быть залоговым и беззалоговым — то есть нужно ли вам предоставить банку какую-то гарантию в виде, например, имущества.</p>\n" +
                                "<div>\n" +
                                "<h2>Залоговый кредит</h2>\n" +
                                "<p>Или заем с обеспечением. Такой кредит выдается на больший срок, обычно до 15-25 лет. На базе залога имущества кредиты могут быть практически любой величины, при условии, если оно способно покрыть запрашиваемую сумму займа. Обычно размер кредита составляет 50%-80% от стоимости залога. Так банк страхует себя на случай, если кредит не будет выплачен. </p>\n" +
                                "</div>\n" +
                                "<div>\n" +
                                "<h2>Беззалоговый кредит</h2>\n" +
                                "<p>Без залога (обеспечения) и без гарантий для банка. Поэтому срок и сумма таких кредитов ограничены. Обычно выдаются до 5 лет на сумму до 7 000 000 тенге. </p>\n" +
                                "</div>\n" +
                                "<p>В зависимости от наличия процентов:</p>\n" +
                                "<div>\n" +
                                "<h2>Кредит с процентами</h2>\n" +
                                "<p>Это про любой кредит. Механика проста: заемщик получает определенную сумму денег, но обязуется вернуть эту сумму и еще дополнительную сверху, которая называется процентами. Так банк зарабатывает на том, что дает вам в долг. А вы платите за то, что получили финансирование в момент нужды.</p>\n" +
                                "</div>\n" +
                                "<div>\n" +
                                "<h2>Кредит без процентов</h2>\n" +
                                "<p>Кредитом без процентов называется рассрочка. Это означает, что заемщик возвращает точно ту же сумму, которую он получил, без дополнительных процентов. Очень выгодный инструмент, которым очень важно не переборщить.</p>\n" +
                                "</div>\n" +
                                "<p>Как мы писали ранее, кредиты могут комбинироваться.</p>\n" +
                                "<p>То есть согласно классификации, рассрочка на телефон в магазине электроники — это беспроцентный, беззалоговый, целевой кредит. Вы не платите по нему дополнительные деньги (беспроцентный), не предоставляете гарантию банку в виде имущества или машины беззалоговый), и банк переведет средства сразу на счет продавца с конкретной целью — оплата покупки телефона (целевой).</p>\n" +
                                "<div>\n" +
                                "<p>А вот кредит наличными — это нецелевой, беззалоговый и процентный кредит. Вы так же не предоставляете гарантию банку, но банк выдаст деньги напрямую вам и не спросит, куда вы их потратите. А еще вы заплатите проценты сверху, причем большие. Но о процентах  дальше.</p>\n" +
                                "</div>\n" +

                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/img-8.png')+"'/>\n" +
                                "<p>Чтобы разбираться в предложениях по кредитам, важно знать не только их классификацию, но и составляющие — как и на что влияют сумма, срок, ставка и график платежей. Эти компоненты важны во всех видах кредита, но особенно в кредите наличными, потому что это самый невыгодный вариант среди всех.</p>\n" +
                                "<p>Остановимся на каждой детали по отдельности, а затем соберем одну общую картину.</p>\n" +

                                "<h3 style='color: #C3F53C;'>Ставка по кредиту</h3>\n" +
                                "<p>От ставки по кредиту напрямую зависит переплата — то есть какую сумму вы должны выплатить поверх той, что взяли в долг. Это своего рода стоимость денег, которые вы берете в долг. Например, вы взяли один миллион тенге в кредит наличными, а вернули полтора. Значит, 500 000 тенге сверху — это стоимость миллиона, который вы взяли.</p>\n" +
                                "<blockquote>\n" +
                                "<p>Процентная ставка — чуть ли не самый решающий компонент в кредитах. Когда банки пишут о годовой процентной ставке — вы автоматически должны прикинуть в уме сумму переплаты в виде указанного процента.</p>\n" +
                                "</blockquote>\n" +
                                "<blockquote>\n" +
                                "<p>Ставка зависит от разных факторов — от уровня инфляции, вида и цели кредита, наличия страховки, кредитного рейтинга заемщика и того, какую ключевую ставку установил Нацбанк. Когда Нацбанк снижает ставку, банки уменьшают проценты и по своим кредитным продуктам. Если ключевая ставка растет, банки поднимают и свои предложения.</p>\n" +
                                "</blockquote>\n" +
                                "<h3 style='color: #C3F53C;'>Окей, я знаю процентную ставку, как посчитать переплату?</h3>\n" +
                                "<p>К примеру, вы берете 500 000 тенге наличными под 34% годовых. Пользуемся формулой <span style='color: #C3F53C;'>сумма * процент / 100</span>, получаем 170 000 тенге — это ваша годовая переплата от процентной ставки. А если разделить 170 000 тенге на 12 месяцев, то получится 14 166 тенге — это ежемесячная переплата по данной ставке.</p>\n" +
                                "<h3 style='color: #C3F53C;'>Знаю ежемесячный платеж, но не знаю процентную ставку, как посчитать?</h3>\n" +
                                "<p>Умножьте сумму ежемесячного платежа на число месяцев (сколько по сроку будете оплачивать), а затем вычтите из полученного размер основного долга (на какую сумму вы взяли кредит). Разница это ваша переплата. Далее считаем по формуле: <span style='color: #C3F53C;'>сумму переплаты * 100 / сумму долга.</span></p>\n" +
                                "<div>\n" +
                                "<h2>Пропишем по отдельности наш пример:</h2>\n" +
                                "<p>500 000 — сумма кредита, основной долг</p>\n" +
                                "<p>34% — процентная годовая ставка</p>\n" +
                                "<p>12 месяцев — срок кредита</p>\n" +
                                "<hr>\n" +
                                "<p>500 000 тенге * 34% = 170 000 тенге (переплата)</p>\n" +
                                "<p>170 000 тенге/12 = 14 166 тенге (переплата в месяц)</p>\n" +
                                "<p>500 000 тенге/12 = 41 666 тенге (основной долг в месяц)</p>\n" +
                                "<p>14 166 тенге + 41 666 тенге =55 833 тенге (ежемесячный платеж = основной долг в месяц + переплата в месяц)</p>\n" +
                                "<p>170 000 * 100 / 500 000 = 34% (процентная ставка)</p>\n" +
                                "</div>\n" +
                                "<blockquote class='lamp'>\n" +
                                "<p>Если ваш кредит на несколько лет, а процентную ставку вы не знаете — вам необходимо вычислить переплату за один год и затем воспользоваться той же формулой: <span style='color: #C3F53C;'>сумму переплаты * 100 / сумму долга.</span></p>\n" +
                                "</blockquote>\n" +
                                "<h2>Пример такой:</h2>\n" +
                                "<p>Кредит на 3 000 000 тенге на 36 месяцев (3 года) с ежемесячным платежом 113 647. Какой будет ставка?</p>\n" +
                                "<div>\n" +
                                "<p>Берем ежемесячный платеж (113 647 тенге) и умножаем на весь срок (36 месяцев), получаем 4 091 292 тенге. Если ваш долг 3 000 000 тенге, то переплата будет 1 091 292 тенге.</p>\n" +
                                "</div>\n" +
                                "<div>\n" +
                                "<p>Вашу переплату 1 091 292 нужно разделить на три (это 3 года вашего кредита), получим 363 764 тенге переплаты в год. Теперь снова по формуле: 363 764 * 100 / 3 000 000 тенге, получим 12. Это и есть ваша процентная ставка.</p>\n" +
                                "</div>\n" +
                                "<p>Также банки в своих предложениях пишут две ставки по кредиту — <span style='color: #C3F53C;'>номинальная</span> и <span style='color: #C3F53C;'>эффективная</span>. Они отличаются тем, что в первом указывается ставка именно по кредиту, а во втором — ставка по кредиту с учетом всех дополнительных трат в виде страховки, обслуживания и так далее. При сравнении кредитных предложений более полезной является именно эффективная процентная ставка, поскольку она отражает реальную стоимость кредита для заемщика на протяжении одного года.</p>\n" +
                                "<blockquote class='lamp'>\n" +
                                "<p>Еще раз простое правило с процентной ставкой: чем выше ставка, тем больше переплата за пользование деньгами банка.</p>\n" +
                                "</blockquote>\n" +

                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/img-9.png')+"'/>\n" +
                                "<p>Сумма зависит от заемщика, то есть от вас — ваши запросы, платежеспособность, статус, кредитная история.</p>\n" +
                                "<blockquote>\n" +
                                "<p>Запросить вы можете сколько угодно в рамках выдаваемого кредита, но дадут ли вам такую сумму — вопрос к тому, насколько вам доверяет банк. Если до этого вы хорошо закрывали займы, у вас не было просрочек и ваша текущая кредитная нагрузка позволяет, то банк, скорее всего, одобрит кредит на всю сумму.</p>\n" +
                                "</blockquote>\n" +
                                "<p>Но дело даже не в том, чтобы получить как можно больше. Даже наоборот — как получить поменьше, ведь сумму придется отдавать, да еще и с процентами. </p>\n" +
                                "<h2 style='color: #FFFFFF;'>Вот несколько советов, которые помогут в этом вопросе:</h2>\n" +
                                "<ol>\n" +
                                "<li>\n" +
                                "<h2 style='color: #C3F53C;'>Берите только необходимую сумму</h2>\n" +
                                "<p>Старайтесь не выходить за пределы своих нужд. Запрашивая кредит наличными, посчитайте все необходимые траты. Например, деньги нужны на брекеты детям и ремонт крыши, а значит, дополнительно добавлять сумму на покупку смартфона не нужно.</p>\n" +
                                "<p>Избегайте также сверхвыгодных предложений: банк предлагает в кредит 4 миллиона без залога, а вам нужно три — не старайтесь ухватить больше необходимого. Всегда помните: за каждый тенге вы оплачиваете еще тенге сверху.</p>\n" +
                                "</li>\n" +
                                "<li>\n" +
                                "<h2 style='color: #C3F53C;'>Рассмотрите все предложения</h2>\n" +
                                "<p>План такой: если это кредит наличными — вы смотрите все банки и примеряете на себя их условия (особенно смотрите на ставку). Если же вы берете целевой кредит — ищите магазин с самым выгодным предложением по соотношению стоимости и качества или самое выгодное предложение от банка. Обычно магазины сотрудничают с несколькими банками, и вы сможете подобрать для себя самую выгодную сделку.</p>\n" +
                                "</li>\n" +
                                "</ol>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/img-10.png')+"'/>\n" +
                                "<p>Это самая гибкая часть кредита, которая меняет и сумму ежемесячного платежа, и переплату, а в некоторых случаях и процентную ставку — то, сколько времени вы будете возвращать банку ваш долг.</p>\n" +
                                "<h2 style='color: #FFFFFF'>Как и на что влияет срок</h2>\n" +
                                "<table>\n" +
                                "<tr>\n" +
                                "<td>Срок и ежемесячный платеж</td>\n" +
                                "<td>Чем меньше срок, тем больше ежемесячный платеж.</td>\n" +
                                "</tr>\n" +
                                "<tr>\n" +
                                "<td>Срок и переплата</td>\n" +
                                "<td>Чем меньше срок, тем меньше переплата.</td>\n" +
                                "</tr>\n" +
                                "<tr>\n" +
                                "<td>Срок и процентная ставка</td>\n" +
                                "<td>В некоторых случаях на меньший срок дают меньшую процентную ставку.</td>\n" +
                                "</tr>\n" +
                                "</table>\n" +
                                "<h2 style='color: #FFFFFF'>Какой определить срок</h2>\n" +
                                "<p>Как вы видите, для любого потребительского кредита выбирать лучше небольшой срок, чтобы он не стоил вам в два, а то в три раза дороже.</p>\n" +
                                "<div>\n" +
                                "<p>И обязательно нужно принимать во внимание, на что вы берете кредит. К примеру, если вы покупаете быстроустаревающий товар, то брать точно нужно на небольшой срок, чтобы избежать платежей за то, что уже потеряло свою ценность и необходимость.</p>\n" +
                                "</div>\n" +
                                "<p>Другое дело — ваша кредитная нагрузка. Срок будет увеличивать или уменьшать ежемесячный платеж. Берите так, чтобы вы смогли выплачивать сумму и при этом не садились в долги, чтобы закрывать другие долги. В таком случае можно подумать о более длинном сроке, хоть это и невыгодно. На это время вы сможете поправить свою ситуацию и выплачивать быстрее.</p>\n" +
                                "<hr>\n" +
                                "<p>В итоге, чтобы взять потребительский кредит надо понимать три вещи — ставку, срок и сумму, а также то, как они друг на друга влияют. Теперь, зная просчеты, вы сможете сравнивать предложения банков самостоятельно, выбирая для себя самый лучший вариант.</p>\n" +
                                "<div>\n" +
                                "<p>А если игнорировать хотя бы одну составляющую в кредитах, можно запросто влезть в кредитную яму. Об этом и поговорим в следующем уроке.</p>\n" +
                                "</div>\n"
                        },
                        dictionary: [
                            {
                                id: 1,
                                name: 'Потребительский кредит (или кредит наличными)',
                                description: 'деньги, которые вы берете у банка на личные нужды: покупка товаров, ремонт дома, оплату лечения и других нужд личного пользования. В таком кредите нет необходимости в целевом подтверждении.'
                            },
                            {
                                id: 2,
                                name: 'Рассрочка',
                                description: 'способ покупки, при котором платеж делится на равные части на протяжении оговоренного срока.'
                            },
                            {
                                id: 3,
                                name: 'Целевой кредит',
                                description: 'кредит с конкретным назначением денег. Это чаще всего покупка авто, квартиры, товаров и так далее.'
                            },
                            {
                                id: 4,
                                name: 'Нецелевой кредит',
                                description: 'деньги, за которые вам не нужно отчитываться перед банком. Это потребительский кредит.'
                            },
                            {
                                id: 5,
                                name: 'Залоговый кредит (или кредит с обеспечением)',
                                description: 'кредит, для которого необходимо предоставить залог (гарантию), обычно это имущество. У таких кредитов больше срок, больше сумма и меньше процентная ставка.'
                            },
                            {
                                id: 6,
                                name: 'Беззалоговый кредит',
                                description: 'кредит без обеспечения. Для таких кредитов не нужно дополнительно предоставлять залог при оформлении, а еще у них меньше сумма, срок и минимальное количество документов.'
                            },
                            {
                                id: 7,
                                name: 'Процентная ставка',
                                description: 'стоимость кредита для клиента. Чем она выше, тем больше переплата.'
                            },
                        ],
                        tests: [
                            {
                                id: 1,
                                question: 'Какой кредит предлагает банк?',
                                hint: 'Возьмите быстрый кредит наличными до 7 миллионов тенге на личные нужды. Без залога, сроком до 5 лет под 35% годовых.',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Это нецелевой кредит',
                                        result: 'Банк не указал назначение трат — это нецелевой кредит. Кроме того, по условиям это наличные деньги на личные нужды, значит, кредит потребительский, а в зависимости от обеспечения кредит будет беззалоговым.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Это беззалоговый кредит',
                                        result: 'Банк не требует обеспечения (залога). По условиям вы получаете деньги и можете потратить их на свое усмотрение — значит, кредит также потребительский беззалоговый.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Это потребительский кредит',
                                        result: 'Правильно, но еще кредит будет беззалоговый нецелевой.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Это потребительский беззалоговый нецелевой кредит',
                                        result: 'Это потребительский кредит, но по дополнительным условиям он еще и нецелевой беззалоговый.',
                                        correct: true,
                                    }
                                ],
                            },
                            {
                                id: 2,
                                question: 'Вам нужно оплатить услуги ортодонта в стоматологии, но зарплата не скоро. Как поступите?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Оплачу в рассрочку',
                                        result: 'Это хорошая идея, платеж будет поделен на несколько месяцев. Если клиника предлагает такой вид оплаты, то это самый подходящий вариант. Если отсутствует такая возможность, вам подойдет второй вариант — попросить в долг.',
                                        correct: true,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Попрошу в долг',
                                        result: 'Это тоже хороший вариант, но имеет свои подводные камни. Во-первых, вам нужно отдать долг с зарплаты, а если сумма большая, то нагрузка на бюджет будет ощутимой. Во-вторых, друг или коллега могут попросить деньги обратно раньше срока, и необходимо будет искать еще варианты найти деньги до зарплаты. Выбирайте этот вариант, только если клиника не предлагает рассрочку.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Возьму кредит наличными',
                                        result: 'Только в крайнем случае, если первые два не подходят. Помните, что с кредитом наличными вы заплатите больше, чем стоило лечение.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Отложу лечение',
                                        result: 'Возможно, в будущем лечение обойдется еще дороже — состояние зубов станет хуже, услуги станут дороже.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 3,
                                question: 'Вы берете 700 000 тенге наличными под 37% годовых. Какой будет переплата за месяц?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: '295 тысяч тенге.',
                                        result: '700 000 тенге * 37% / 100 = 295 000 тенге. Это вы посчитали переплату за год.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: '45 583 тенге',
                                        result: 'Мы не знаем, как вы получили такой ответ 🙂',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: '21 583 тенге',
                                        result: '700 000 тенге * 37 / 100 = 295 000 тенге. 295 000 / 12 месяцев = 21 583 тенге. Это правильный подсчет, сумму за весь срок вы умножили на процент за весь срок, а затем разделили на 12 месяцев.',
                                        correct: true,
                                    },
                                    {
                                        id: 4,
                                        answer: '82 917 тенге',
                                        result: '700 000 тенге * 37% / 100 = 295 000 тенге. 295 000 тенге + 700 000 тенге / 12 месяцев. Так вы посчитали ежемесячный платеж.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 4,
                                question: 'Какой кредит будет для вас дорогим?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: '500 000 тенге под 42% годовых на два года',
                                        result: 'Переплата за весь срок 420 000 тенге. Это самый дорогой кредит среди перечисленных за счет срока и ставки.',
                                        correct: true,
                                    },
                                    {
                                        id: 2,
                                        answer: '500 000 тенге под 28% годовых на три года',
                                        result: 'Переплата за весь срок 420 000 тенге, как и в первом варианте. Но тут срок больше, поэтому это не самый дорогой кредит среди перечисленных.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Айфон за 500 000 тенге в рассрочку на год',
                                        result: 'Здесь нет процентов, это выгодный кредит.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: '500 000 тенге под  42% годовых на 12 месяцев',
                                        result: 'Переплата за весь срок 210 000 тенге. Это не самый дорогой кредит среди перечисленных. Так как сумма и срок одинаковые (как в первом варианте), но платить меньше по сроку, то и переплата будет меньше.',
                                        correct: false,
                                    }
                                ],
                            }
                        ],
                    },
                    {
                        id: 3,
                        number: 3,
                        name: 'Как закрыть потребительский кредит выгодно',
                        slug: 'kak-zakryt-potrebitelskij-kredit-vygodno',
                        duration: '17 мин',
                        bgImage: require('@/assets/images/visuals/course-2/lesson-3/bg.png'),
                        content: {
                            description: "<h3 style='color: #C3F53C;'>Микрокредиты незаметно переходят в макродолги</h3>\n" +
                                "<div>\n" +
                                "<p>У вас есть кредит и желание закрыть его побыстрее, а у нас — целый урок, посвященный этой теме. Объединим наши цели и избавимся от тревожности в отношении денег: посмотрим реальную картину долгов, спланируем закрытие и очередность, а еще узнаем план действий на случай, если возможности закрыть кредит не предвидится. Начнем с важного — ответа, почему вам нужно закрывать кредиты досрочно, даже те, которые не сильно сказываются на бюджете.</p>\n" +
                                "</div>\n" +
                                "<ol>\n" +
                                "<li>\n" +
                                "<p>Кредит — деньги в долг, за возможность пользования которыми вы платите проценты. Это должно стать вашей первой мотивацией для погашения раньше срока — ведь так вы оплачиваете только за фактическое время пользования кредитом. Проценты уже включены в ваш ежемесячный платеж и показаны общей суммой, поэтому часто остаются незаметными для нас.</p>\n" +
                                "</li>\n" +
                                "</ol>\n" +
                                "<div>\n" +
                                "<h2>К примеру,</h2>\n" +
                                "<p>вы отдаете за кредит 50 000 тенге ежемесячно, из них 35 тысяч — основной долг, а оставшуюся часть составляют проценты. Вам осталось платить ровно шесть месяцев, если вы сегодня закроете кредит досрочно, то сэкономите 90 тысяч тенге. Проценты в этом примере — 15 тысяч тенге в месяц, ваш каждый день — 0,5% от 15 000 тенге. Это 500 тенге. Чем дольше вы тянете с полным досрочным погашением, тем больше денег сверху отдаете банку.</p>\n" +
                                "</div>\n" +
                                "<ol style='counter-reset: number-counter 1'>\n" +
                                "<li>\n" +
                                "<p>Жизнь в долг может также влиять на наше психологическое состояние. Исследователи из Rutgers University Study видят связь между уровнем депрессии и количеством долгов. По их утверждениям, чем больше у нас долговых обязательств, тем чаще мы испытываем стресс.</p>\n" +
                                "</li>\n" +
                                "<li>\n" +
                                "<p>Еще одна причина — освобождение финансов для других целей. Как только кредит будет погашен, деньги, которые ранее уходили на выплаты по кредиту, станут доступны для других финансовых целей, таких как инвестирование, накопления или личные покупки.</p>\n" +
                                "</li>\n" +
                                "<li>\n" +
                                "<p>За маленьким долгом всегда следует более крупный. Так люди незаметно переходят из очередной покупки в кредит в долговую ловушку.</p>\n" +
                                "</li>\n" +
                                "</ol>\n" +
                                "<div>\n" +
                                "<h2>К примеру,</h2>\n" +
                                "<p>Вы взяли смартфон, а затем срочно пригодились деньги на ремонт, а через время к ним прибавилось колье в рассрочку в подарок жене. Все это теперь забирает половину вашего заработка. Чтобы не нагромождать себя долгами и не переходить грань, когда кредит пригодится для погашения другого кредита, важно заранее планировать погашение.</p>\n" +
                                "</div>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-1.png')+"'/>\n" +
                                "<p>По закону, если вы взяли кредит в банке на личные цели, то имеете право погасить заем раньше срока — частично или полностью. Банк не может вам препятствовать в этом.</p>\n" +
                                "<div>\n" +
                                "<p>При этом для отдельных видов займов все же могут существовать ограничения по ПДП и ЧДП (полное досрочное погашение и частичное досрочное погашение) — это предпринимательские кредиты или кредиты, в которых прописаны временнЫе ограничения по договору. Подписывая документ, клиент принимает эти условия.</p>\n" +
                                "</div>\n" +
                                "<p>Для потребительских кредитов обычно нет условий, запрещающих погашать долг раньше срока. Если вы захотите досрочно закрыть кредит, то оплатите только фактическое время пользования деньгами.</p>\n" +
                                "<div>\n" +
                                "<p>Вы взяли кредит наличными на личные нужды, некоторые планы поменялись и часть денег осталась свободной. С зарплаты решили добавить использованную сумму и вернуть банку долг в полном объеме. В таком случае оплатите только ту сумму, которую брали и дополнительно проценты за столько дней, сколько пользовались кредитом. Это может быть даже один день. К примеру, кредит 200 тысяч тенге на 12 месяцев под 34% годовых. Это значит, что ваши проценты — 2,8% в месяц и 0,09% в день. Если по нашему сценарию вы закрываете кредит на следующий день, то вернете только 200 188 тенге.</p>\n" +
                                "</div>\n" +
                                "<p>Если банк нарушает ваши права и начисляет штраф за досрочное погашение, который не предусмотрен договором, вы вправе обратиться с заявлением в Агентство по финансовому регулированию и развитию финансового рынка РК.</p>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-2.png')+"'/>\n" +
                                "<p>Банк будет запрашивать досрочное погашение только в том случае, если будут нарушаться условия кредитного договора. В самом начале вам позвонят из банка, чтобы узнать причину несоблюдения оговоренных условий. Например, отсутствие страховки для ипотечного жилья или нецелевое использование средств — это когда вы взяли деньги на рефинансирование кредитов, но потратили их на личные нужды. Для потребительских кредитов, о которых мы продолжаем говорить в этом уроке, чаще всего это неуплата долга.</p>\n" +
                                "<p>Один просроченный платеж не будет основанием для таких требований, но вот когда есть просрочки на несколько месяцев, то кредитные деньги попросят вернуть. Обычно через суд. Во всех остальных случаях — если оплатили досрочно, если внесли раньше срока платеж, если выехали из страны — банк не станет вмешиваться и просить деньги обратно.</p>\n" +
                                "<div>\n" +
                                "<p>У вас есть кредит, но вы потеряли работу и доход. При этом так же оплачиваете ежемесячные платежи — в полном объеме и в срок с помощью личных накоплений. В таком случае банк не станет отзывать кредит и считать вас ненадежным заемщиком. Ведь ваши обязательства выполняются. При этом ситуация может повлиять на ваш кредитный рейтинг, но это важно для нового кредита, а не текущего.</p>\n" +
                                "</div>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-3.png')+"'/>\n" +
                                "<p>Для того, чтобы составить план погашения, вам нужно знать детали своих кредитов и определить их очередность с учетом своих доходов. Начнем по порядку.</p>\n" +
                                "<h3 style='color: #C3F53C;'>Все ваши кредиты</h3>\n" +
                                "<p>Если у вас уже есть несколько кредитов, определите их приоритеты: планируйте досрочное погашение сначала для долгов с более высокими процентными ставками, так как это поможет сэкономить больше денег на процентах.</p>\n" +
                                "<p>Вторым делом выпишите отдельно кредиты со всеми деталями в следующем порядке:</p>\n" +
                                "<div>\n" +
                                "<h2>К примеру,</h2>\n" +
                                "<p>Кредит 1 с основным долгом в 160 000 тенге, осталось платить 6 месяцев, переплата в месяц 12 тысяч тенге.</p>\n" +
                                "<p>Кредит 2 с основным долгом в 80 000 тенге, осталось платить 4 месяца, переплата в месяц 6 тысяч тенге.</p>\n" +
                                "</div>\n" +
                                "<p>Сейчас вы определили только текущий баланс кредитов. Пока что это просто данные, теперь проанализируем их, опираясь на переплату в месяц и остаток долга. Переплата покажет, какой кредит вам обходится дороже.</p>\n" +
                                "<h3 style='color: #C3F53C;'>Как погашать</h3>\n" +
                                "<p>Представим, у вас на руках ровно 80 000 тенге и вы решаете: закрыть досрочно полностью Кредит 2 или направить деньги на частичное погашение Кредита 1.</p>\n" +
                                "<div>\n" +
                                "<h2>Погашаем Кредит 1</h2>\n" +
                                "<p>Выбираем погашение в пользу срока, чтобы сэкономить больше на процентах. Банк пересчитал ваш кредит (отнял от основной суммы 160 000 тенге 80 000 тенге) и теперь у вас: 80 000 основной долг и три месяца платежей. Так как в месяц по процентам вы отдавали 12 тысяч тенге, то вы будете в плюсе на 36 000 тенге (12 000 х 3 месяца).</p>\n" +
                                "<hr>\n" +
                                "<p>Что остается по двум кредитам: 80 000 тенге по Кредит 1 и 80 000 тенге по Кредит 2, в общем это 160 000 тенге. Переплата (12 000 х 3 месяца) Кредита 1 равна 36 000 тенге, а Кредита 2 (6 000 х 4 месяца) — 24 000 тенге. В общем по процентам двух кредитов вы от даете банку 60 000 тенге.</p>\n" +
                                "</div>\n" +
                                "<div>\n" +
                                "<h2>Погашаем Кредит 2</h2>\n" +
                                "<p>Свободные 80 000 тенге вы направляете на погашение Кредита 2, так как основной долг равен вашей сумме на погашение, то кредит вы закрываете полностью. Вы будете в плюсе на 24 000 тенге.</p>\n" +
                                "<hr>\n" +
                                "<p>У вас остается Кредит 1 и такие же 160 000 тенге основного долга (как в первом примере), но переплата по нему без изменений — 72 000 тенге за шесть месяцев. То есть даже если у вас будет один кредит вместо двух, то вы отдаете больше денег, чем если бы оставили два кредита и уменьшили долг невыгодного из них. В этом варианте вы проигрываете на 12 тысяч тенге, чем если бы выбрали погашение части долга Кредита 1.</p>\n" +
                                "</div>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-4.png')+"'/>\n" +
                                "<p>Выбор между уменьшением срока кредита или уменьшением суммы ежемесячного платежа зависит от вашей финансовой ситуации, личных целей и приоритетов.</p>\n" +
                                "<ul>\n" +
                                "<li>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-5.png')+"'/>\n" +
                                "<p>Помните, что частично-досрочное погашение возможно с минимальной суммой ежемесячного платежа.</p>\n" +
                                "</li>\n" +
                                "</ul>\n" +
                                "<h3 style='color: #C3F53C;'>Сокращение срока кредита</h3>\n" +
                                "<p>Всегда по возможности старайтесь выбирать досрочное погашение в пользу срока. Так вы экономите на процентах. Расскажем на примере:</p>\n" +
                                "<div>\n" +
                                "<p>Ваш долг 300 000 тенге на 6 месяцев, общая переплата — 36 тысяч тенге, вы оплачиваете первый ежемесячный платеж, а затем решаете досрочно погасить часть — 100 000 тенге. Выбираете в пользу срока. Вам останется платить всего 3 месяца, а на процентах вы сэкономите 12 тысяч.</p>\n" +
                                "</div>\n" +
                                "<p>Оставшиеся месяцы вы будете вносить те же 56 тысяч тенге, так как срок уменьшился, а платеж остался прежним.</p>\n" +
                                "<h3 style='color: #C3F53C;'>Сокращение ежемесячного платежа</h3>\n" +
                                "<p>Это тоже хорошее решение, хотя мы советуем выбирать срок. Этот вариант подойдет, если у вас временные финансовые трудности, а ежемесячные платежи забирают больше половины дохода. Так вы снижаете финансовую нагрузку и можете направить деньги на другие обязательные категории.</p>\n" +
                                "<div>\n" +
                                "<p>С теми же условиями, как в предыдущем примере, если вы направите 100 000 тенге на уменьшение суммы ежемесячного платежа, то теперь следующие 5 месяцев будете вносить по 36 тысяч тенге. При этом на процентах вы не сильно сэкономите — всего 6 тысяч тенге.</p>\n" +
                                "</div>\n" +
                                "<p>Так сумма станет меньше, а срок останется неизменным.</p>\n" +
                                "<h3 style='color: #C3F53C;'>Комбинация методов и экономия на процентах</h3>\n" +
                                "<ol>\n" +
                                "<li>\n" +
                                "<p>Вы можете совмещать варианты: в первый месяц сократить ежемесячный платеж, а затем выбирать уменьшение срока, как только появятся дополнительные деньги на погашение. И наоборот. В любом случае вы останетесь в плюсе.</p>\n" +
                                "</li>\n" +
                                "<li>\n" +
                                "<p>При этом есть вариант, как досрочное погашение в пользу ежемесячного платежа может дать такой же результат, как если бы вы выбрали уменьшение срока.</p>\n" +
                                "</li>\n" +
                                "</ol>\n" +
                                "<div>\n" +
                                "<h2>Рассказываем, как это работает</h2>\n" +
                                "<p>И снова наш пример с 300 тысячами. Сперва вы оплатили ежемесячный платеж, затем 100 000 тенге закрыли часть кредита досрочно, уменьшив платежи с 56 тысяч до 36 тысяч тенге. Дальше, если вам будет комфортно оплачивать прежнюю сумму в 56 тысяч тенге, пусть и в деталях указано 36 тысяч, то в итоге вы сравняете переплату как при варианте с уменьшением срока. Эти 20 тысяч тенге разницы будут как бы закрывать часть за крайние месяцы, пока вы так же оплачиваете текущие.</p>\n" +
                                "</div>\n" +
                                "<ol style='counter-reset: number-counter 2'>\n" +
                                "<li>\n" +
                                "<p>Как еще можно поступить? Снижать ежемесячный платеж любой доступной суммой в любое время (при этом в день можно погашать частично-досрочно лишь раз), а затем, когда общий долг будет как ежемесячный платеж (то есть около 56 тысяч, поделенных на 4 оставшихся месяца), закрыть полностью.</p>\n" +
                                "</li>\n" +
                                "</ol>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-6.png')+"'/>\n" +
                                "<p>Не все кредиты нужно погашать досрочно. Часто многое зависит от ставки, вида займа и суммы долга. Именно за этим во втором уроке курса мы разбирали, как составляющие кредита могут менять условия и делать кредит для вас выгодным и не очень. А еще большую роль играет ваше текущее положение дел — финансовое, личное, семейное и так далее.</p>\n" +
                                "<p>Выборочно разберем некоторые варианты, когда вам не нужно погашать кредит:</p>\n" +
                                "<h3 style='color: #C3F53C;'>Кредит под низкий процент</h3>\n" +
                                "<p>Такие есть, часто это кредиты с госпрограммами. Например, ипотека 7-20-25 с фиксированной ставкой. Но для потребительских кредитов низкая ставка — редкость. В этом уроке мы не будем разбираться в деталях других кредитов и том, как они работают (для этого в будущем будет другой курс), но вариант оставим как наглядный пример.</p>\n" +
                                "<p>Если процентная ставка по кредиту относительно низкая, а инфляция высокая, то досрочное погашение может не приносить столько выгоды, как сохранение денег и их инвестирование во что-то, что приносит более высокую доходность. Однако стоит помнить, что инвестиции всегда сопряжены с рисками, и не всегда возможно гарантировать доход.</p>\n" +
                                "<h3 style='color: #C3F53C;'>Есть свободные средства на погашение, но кредит вполне подъемный</h3>\n" +
                                "<div>\n" +
                                "<p>В варианте, если ваш кредит забирает всего 10-20% вашего заработка, можно забыть о процентах. Да, это противоречит всем курсам о кредитах, где твердят о важности погашать их как можно быстрее. Но если кредит вам не мешает, а финансовое будущее стабильно, то свободные средства можно направить на более доходный вариант. Самый простой и без рисков — вклады.</p>\n" +
                                "</div>\n" +
                                "<p>Через вклады вы можете компенсировать проценты кредитов.</p>\n" +
                                "<h3>Вот вариант примера через беспроцентный кредит — рассрочку:</h3>\n" +
                                "<div>\n" +
                                "<p>Вы купили гаджеты в рассрочку — смартфон и дайсон, общая сумма получилась 1 200 000 тенге. По сроку — 12 месяцев, а ежемесячный платеж равен 100 000 тенге.</p>\n" +
                                "<p>Одновременно с этим на вашем депозите (вклад в банке) лежат эти же 1 200 000 тенге сроком на один год под 15%. Значит по депозиту в месяц будет поступать 14 тысяч тенге с небольшим. За год вы заработаете 170 000 тенге.</p>\n" +
                                "<p>Если ежемесячный платеж не отнимает половину заработка, то через 12 месяцев вы выплатите весь кредит своими средствами, а еще получите свои деньги на вкладе с вознаграждением. <span style='color: #C3F53C;'>А если это кредит с процентами — проценты депозита будут уменьшать проценты кредита.</span></p>\n" +
                                "</div>\n" +
                                "<h3 style='color: #C3F53C;'>Это ваши последние деньги, а зарплата не скоро</h3>\n" +
                                "<p>Не стоит отдавать последние деньги на досрочное погашение кредита, даже если наличие долга доставляет вам психологический дискомфорт. И вот почему:</p>\n" +
                                "<div>\n" +
                                "<h2>К примеру,</h2>\n" +
                                "<p>вам поступила зарплата и вы решили все средства отправить на досрочное погашение текущего кредита. Теперь у вас нет денег, а жить на что-то нужно. Если возникнут экстренные ситуации — медицинские расходы, ремонт автомобиля, потеря работы — люди часто прибегают к кредитным деньгам. То есть вы никак, возможно, не измените свое положение. В таких случаях наличие финансового резерва может быть критически важным, чтобы справиться с финансовыми трудностями без увеличения долга.</p>\n" +
                                "</div>\n" +
                                "<p>Если у вас появились свободные деньги, отложите их на специальный депозит. Это будет ваша подушка безопасности. Она поможет, если вдруг произойдут непредвиденные ситуации, требующие финансов. А еще с депозита будут поступать вознаграждения за хранение, что увеличит ваши накопления.</p>\n" +
                                "<ul>\n" +
                                "<li>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-7.png')+"'/>\n" +
                                "<p>Подушка безопасности — деньги на специальном счете в сумме как минимум трех месяцев ваших расходов.</p>\n" +
                                "</li>\n" +
                                "</ul>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-8.png')+"'/>\n" +
                                "<p>Первым делом попробуйте взять в долг у близких — это первый вариант, так как в нем нет процентов и ежемесячных платежей. При этом вам нужно обязательно обговорить сумму и срок возврата, чтобы избежать недопониманий.</p>\n" +
                                "<p>Обратитесь в банк, если вариант одолжить у друзей и родственников отпадает. Вам предложат либо реструктурировать кредит, либо рефинансировать его.</p>\n" +
                                "<ul>\n" +
                                "<li>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-9.png')+"'/>\n" +
                                "<p>Реструктуризация — изменение условий текущего кредита в банке. Эта мера применяется к тем, кто не способен в текущий момент и ближайшем будущем обслуживать свой долг. Обычно заемщики находятся в состоянии дефолта.</p>\n" +
                                "</li>\n" +
                                "</ul>\n" +
                                "<p>Пример реструктуризации:</p>\n" +
                                "<div>\n" +
                                "<p>Вы оплачивали ежемесячно 150 000 тенге, впереди еще два года таких платежей. Ваше финансовое положение изменилось, и теперь денег на закрытие платежей не хватает. Вам нужно обратиться в банк за реструктуризацией: так вам уменьшат платежи за счет прибавления срока кредита. Не очень выгодно, но ситуацию спасает.</p>\n" +
                                "</div>\n" +
                                "<p>Для реструктуризации необходимо будет подтвердить, что у вас нет возможности выплачивать кредит. Для этого вам нужно прийти в отделение банка, написать заявление на реструктуризацию и предоставить, к примеру, справку о доходах за последние 6 месяцев. Или иногда это может быть медицинское заключение, а иногда — судебное. Все зависит от факторов, которые привели к невозможности выплачивать кредит в том объеме, который был изначально. Дальнейшие действия будут озвучены сотрудником банка.</p>\n" +
                                "<ul>\n" +
                                "<li>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-10.png')+"'/>\n" +
                                "<p>Рефинансирование — это оформление нового кредита для погашения имеющихся, обычно на выгодных для заемщика условиях.</p>\n" +
                                "</li>\n" +
                                "</ul>\n" +
                                "<p>Пример рефинансирования:</p>\n" +
                                "<div>\n" +
                                "<p>По разным причинам у вас накопилось несколько кредитов, которые сильно влияют на кошелек. Они все с разными условиями — ставки, ежемесячные платежи, сроки отличаются. Рефинансируясь, вы берете новый кредит в сумме или больше имеющихся, они суммируются и будут считаться по одним условиям — обычно более выгодным, чем раньше.</p>\n" +
                                "</div>\n" +
                                "<h3>Кредит 1</h3>\n" +
                                "<p>Основным долгом в 250 000 тенге, осталось платить 6 месяцев, переплата в месяц 12 тысяч тенге.</p>\n" +
                                "<h3>Кредит 2</h3>\n" +
                                "<p>Основным долгом в 80 000 тенге, осталось платить 4 месяца, переплата в месяц 6 тысяч тенге.</p>\n" +
                                "<h3>Кредит 3</h3>\n" +
                                "<p>Основным долгом в 450 000 тенге, осталось платить 13 месяцев, переплата в месяц 8500 тенге.</p>\n" +
                                "<div>\n" +
                                "<p>Вы рефинансировали эти кредиты в новом банке и теперь у вас один кредит вместо трех с общей суммой долга 780 000 тенге на срок 12 месяцев, а переплата в месяц — 14 000 тенге. Банк дал вам более выгодные условия, чем были с тремя кредитами. Общая переплата за три кредита, если бы вы выплачивали их без досрочного погашения, 206 500 тенге, а с новым кредитом — 168 000 тенге. Сумма долга осталась прежней, а срок меньше на один месяц.</p>\n" +
                                "</div>\n" +
                                "<p>Есть еще один вариант — банкротство, но мы его не предлагаем как решение ваших проблем. Смотрите сами:</p>\n" +
                                "<ul>\n" +
                                "<li>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-11.png')+"'/>\n" +
                                "<p>Подача заявки возможна только в случае жизненных обстоятельств, не позволяющих выплачивать долг перед банками;</p>\n" +
                                "</li>\n" +
                                "</ul>\n" +
                                "<ul>\n" +
                                "<li>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-12.png')+"'/>\n" +
                                "<p>У должника не может быть имущества в собственности;</p>\n" +
                                "</li>\n" +
                                "</ul>\n" +
                                "<ul>\n" +
                                "<li>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-13.png')+"'/>\n" +
                                "<p>По текущему кредиту отсутствует погашение в течение 12 последних месяцев.</p>\n" +
                                "</li>\n" +
                                "</ul>\n" +
                                "<p>За 12 месяцев вы можете изменить свое финансовое положение, и если вы читаете этот урок — вы уже на пути. А еще пять лет после вы не сможете оформлять новые кредиты — так можно упустить важные и полезные кредитные предложения, например, жилье по программе 7-20-25 или бизнес-кредиты.</p>\n" +
                                "<ul>\n" +
                                "<li>\n" +
                                "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-14.png')+"'/>\n" +
                                "<p>Помните, что когда ситуация наладится, должнику необходимо будет погасить долг. К примеру, если у вас появится собственность или заработок, вы обязаны за счет них вернуть деньги банку.</p>\n" +
                                "</li>\n" +
                                "</ul>\n" +
                                "<p>Следующий важный шаг, который необходимо сделать — искать работу, подработку, стажировку, чтобы скорее найти рабочее место и стать платежеспособным. Возможностей много, главное — не опускать руки и не идти на отчаянные поступки. Если самостоятельные поиски не увенчались успехом, вы можете зарегистрироваться для поиска работы через <a href='https://egov.kz/cms/ru' target='_blank'>eGov</a>, либо на сайте <a href='https://www.enbek.kz/ru' target='_blank'>электронной биржи труда</a>.</p>\n" +
                                "<div>\n" +
                                "<p>В течение одного дня вам должны предложить подходящие вакансии из местного Центра занятости населения. Если и так работа не найдётся — вам присвоят статус безработного и назначат пособие.</p>\n" +
                                "</div>\n"
                        },
                        dictionary: [
                            {
                                id: 1,
                                name: 'Полное досрочное погашение кредита',
                                description: 'погашение всей имеющейся перед банком кредитной задолженности раньше установленного срока.'
                            },
                            {
                                id: 2,
                                name: 'Частичное досрочное погашение кредита',
                                description: 'погашение части имеющейся перед банком кредитной задолженности раньше установленного срока.'
                            },
                            {
                                id: 3,
                                name: 'Просрочка',
                                description: 'нарушение сроков исполнения обязательств. За просрочку должнику начисляется пеня.'
                            },
                            {
                                id: 4,
                                name: 'Рефинансирование',
                                description: 'это оформление нового кредита для погашения имеющихся, обычно они на невыгодных для заемщика условиях.'
                            },
                            {
                                id: 5,
                                name: 'Реструктуризация',
                                description: 'изменение условий действующего кредита, обычно уменьшение срока и/или ежемесячного платежа. Для тех, кто оказался в сложной ситуации.'
                            },
                        ],
                        tests: [
                            {
                                id: 1,
                                question: 'У вас три кредита, по всем проценты выше 40. Как поступите?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Рефинансирую их и сделаю как один',
                                        result: 'Все правильно, вы объедините три кредита, а еще можете снизить ставку. Это выгодно, так как ваш ежемесячный платеж будет меньше.',
                                        correct: true,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Возьму один большой кредит и закрою как минимум два из них',
                                        result: 'Вероятно, ваш новый кредит — потребительский. А значит, процент по нгему будет таким же высоким. Вы только увеличиваете свой долг.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Пожалуюсь акимату',
                                        result: 'Получая заемные деньги от банка, вы соглашаетесь со всеми условиями. Акимат предложит обратиться в банк.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'У всех есть кредит, как-нибудь выплачу',
                                        result: 'Если посчитаете переплату по всем трем кредитам, то станет понятно — вы отдаете большие деньги за пользование кредитом. Старайтесь погасить досрочно или изменить условия через рефинансирование или реструктуризацию.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 2,
                                question: 'Какой кредит можно погасить досрочно?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Какой банк разрешит',
                                        result: 'У банка могут быть свои условия для разных кредитов, но по закону вы можете погасить любой кредит досрочно частично или полностью. Обычно условия по срокам ЧДП и ПДП прописываются для бизнес-кредитов, а к потребительским их не может быть.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Тот, по которому оплачено как минимум шесть месяцев платежей',
                                        result: 'У некоторых банков на некоторые виды кредитов есть такие условия, но это на отдельные виды кредитов. По потребительским кредитам таких условий обычно нет.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Любой',
                                        result: 'Правильно, по закону вы можете досрочно погасить любой кредит. И банк не может мешать вам в этом.',
                                        correct: true,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Ипотеку, автокредит и кредит наличными',
                                        result: 'Это все потребительские кредиты и его виды. Но это не весь список кредитов, вы можете погасить любой (при условии, если договором не прописано противное).',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 3,
                                question: 'У вас накопилось долгов на 1 500 000 тенге, а возможности выплачивать кредит нет. Как поступите?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Возьму еще один кредит на 2 000 000 и погашу имеющиеся. Первый платеж по новому кредиту обычно на следующий месяц, поэтому выиграю себе время и дополнительно 500 тысяч тенге. Мой план готов!',
                                        result: 'План, к сожалению, провальный. Вы только увеличили свой долг, даже если отсрочили новый платеж на месяц. Плюс велика вероятность, что банки не одобрят вам такую сумму, если вы не можете справиться с имеющимися обязательствами.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Обращусь в банк и попрошу реструктуризировать или рефинансировать кредиты в зависимости от условий текущих займов и предложений банка.',
                                        result: 'Это самый первый вариант в такой ситуации. Вы можете уменьшить ежемесячный платеж через реструктуризацию кредита или процентную ставку через рефинансирование. Для начала вам нужно проанализировать имеющиеся кредиты.',
                                        correct: true,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Скажу, что я банкрот и просто не буду платить, все так делают.',
                                        result: 'Это самый крайний вариант. У статуса банкротства есть и свои подводные камни: запрет на новые кредиты в течение 5 лет (вы можете упустить по-настоящему выгодные предложения), погашение долга за счет имущества и многое другое. А еще вам нужно в течение 12 месяцев не иметь возможности выплачивать долг, за такое время финансовое положение может измениться, поэтому делать ставку на банкротство не стоит.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Буду скрываться от банка, коллекторов, полиции. Ну раз выдали кредит, пусть сами отвечают.',
                                        result: 'Обязательства необходимо выполнять. Если ваше финансовое положение усложнилось, не стесняйтесь обратиться в банк и рассмотреть варианты, как поступить с кредитом. А еще вы можете откликаться на вакансии и проходить собеседования, либо зарегистрироваться для поиска работы на сайте электронной биржи труда.',
                                        correct: false,
                                    }
                                ],
                            },
                        ],
                    },
                ],
            }
            ],
            kz: [
                {
                id: 1,
                name: 'Баршаға арналған қаржылық сауат',
                slug: 'personal-finance',
                background: '#0029FA',
                payType: 'Тегін курс',
                duration: '2 сағат 10 минут',
                lessonCount: '6 сабақ',
                bgImage: require('@/assets/images/course-header-image.png'),
                promoImage: require('@/assets/images/courses/course-promo-1.png'),
                mobilePromoImage: require('@/assets/images/courses/course-promo-mobile-1.png'),
                mobilePromoCardRemHeight: '28.8125',
                mobilePromoBackgroundColor: '#FFC8BC',
                content: {
                    welcomeText: [
                        {
                            icon: '👋',
                            content: "<h2>Жеке қаржымызды қалай басқарамыз?</h2>" +
                                "<p>Бұл курс — қаржылық сауатқа арналған тегін курс. Ол қалай ақша табу керегін, оны қалай жұмсау, жинау қажеті мен болашақта ақшасыз қалмаймын ба деп қорықпауды үйретеді.</p>",
                        },
                        {
                            icon: '💸',
                            content: "<h2>Қаржылық сауат</h2>" +
                                "<p>ақшаға келгенде басқа біреуге тәуелді болмаудың ең негізгі әрі маңызды амалы.</p>",
                        },
                    ],
                    blockquote: '<h2>Мысалға сүйеніп көрейікші:</h2>' +
                        '<p>Қазір сіз 25-тесіз. 60 жасқа дейін жұмыс істегеннен кейін жер шарын аралағыңыз келеді, жұмыс дегенді ұмытқыңыз келеді. Оған дейін әлі 30 жылыңыз бар. Енді сіз сол үшін депозит ашып, айына 40 000 теңге салып отырам деп шештіңіз. Пайыздық мөлшерлеме қазіргідей 15% болады десек, 30 жылдан кейін сіз 601 804 387 теңге жинап үлгересіз. Күшті ғой, иә?</p>' +
                        '<p>Депозитке тұрақты түрде ақша салып тұру — қаржылық сауаты бар адам қабылдайтын шешімдердің бірі. Ондай шешім көп: қауіпсіздік жастығы, несиені дұрыс пайдалану, инвестиция, әртараптандыру, инфляциядан қорғану және т.б.</p>' +
                        '<p>Осының кез келгенін пайдалана білсеңіз, тұрмысыңызды біршама жақсарта аласыз деген сөз. Несиесіз өмір сүру, оқу ақысын төлеу және пәтер алу қол жетпес арман көрінбеуі керек. Ал бұл курс — соған жетуге біраз болса да көмектесетін нұсқаулық. </p>',
                    advantages: {
                        title: 'Ақша жұмсауды, жинауды жете үйрететін алты сабақ дайындадық',
                        subtitle: 'Курстан үйренеріңіз:',
                        content: [
                            {
                                title: 'Қазір жағдайыңызды анықтайсыз',
                                subtitle: 'Қаржылық жағдайыңызға зер саламыз, проблема бар-жоғын анықтаймыз. Бар болса, олардан қалай құтылуға болатынын келер сабақтардың көмегімен түсіндіреміз.',
                            },
                            {
                                title: 'Ақша басқаруды үйренесіз, қайда кетіп жатқанын түсінесіз',
                                subtitle: 'Мына сабақта қажетсіз шығын не екенін, қалай анықтауға болатынын, жиған-тергеніңізді қадағалауды үйренеміз.',
                            },
                            {
                                title: 'Мақсат қоюды үйренесіз',
                                subtitle: 'Қаржылық мақсат туралының бәрін осы сабақтан білесіз: қалай мақсат қоямыз, оған қалай жетеміз.',
                            },
                            {
                                title: 'Несие алуды, оны дұрыс жабуды үйренесіз',
                                subtitle: 'Несиені мақсатқа жетудің бір амалы ретінде пайдалануды түсіндіреміз. Несиенің қандай түрі жақсы, оны ертерек жабу үшін не істеу керегін ұғасыз.',
                            },
                            {
                                title: 'Қаржылық амалдың ыңғайлысын таңдайсыз',
                                subtitle: 'Көптеген қаржылық амалдардың ең мықтысын таңдап, қайсын қандай жағдайда қолданған дұрыс екенін анықтаймыз.',
                            },
                            {
                                title: 'Болашаққа қаржылық жоспар құрасыз',
                                subtitle: 'Қолда бар мәліметті (кестеге жазылған) пайдаланып, болашаққа қалай жоспар құру керегін айтып береміз.',
                            },
                        ],
                    },
                    description: [
                        "<h2>Қашан бастаған жөн? </h2>" +
                        "<p>Қазір. Жасыңыз 18-ден асқан болса, бүгін бастаңыз. Ештен кеш жақсы. Ал 18-ге толмаған болсаңыз, тіпті керемет болады, себебі ересек болуға барынша жақсы дайындалуға уақытыңыз бар. Неғұрлым ерте бастасаңыз, соғұрлым жақсы болады.</p>" +
                        "<p>Жұртқа белгілі факт: адам жас кезінде табысын барынша арттырып, орда бұзар шағында жетістік шыңына жетеді, ал қартайғанда бұл қабілеті төмендей бастайды.</p>" +
                        "<p>Әлбетте, біреу алпысқа жетіп те марафонға қатысып, жүгірем дейді, бірақ мұндай жаста көбі өзіне тоқтау айтып, демалғысы келеді. Бұл дегеніміз — адам қарт шағында уайым-қайғыдан арылып, өмірінен ләззәт алғысы келеді. Қыс қамын жаз ойла демекші, мұны күш-қуат тасып тұрғанда ойлап, қамын жасап алған дұрыс. </p>",

                        "<h2>Курсты қалай оқу керек? </h2>" +
                        "<p>Өзіңіз білесіз. Бірнеше сағатта бітірем десеңіз де, күніне бір сабақ оқимын десеңіз де мархабат. Қалауыңыз білсін. Айтпақшы, әр сабаққа 30 минуттай уақыт кетеді.</p>" +
                        "<p>Сабақты бөліп оқысаңыз, әлдеқайда тиімді болмақ. Себебі әр сабақта қаржыға қатысты деректерімізді кестеге енгізіңіз деп ұсынамыз. Бірінші сабақты ашқанда оны көшіріп алыңыз да, келесі сабақтарда қолданыңыз. Алтыншы сабақты аяқтағанда өзіңізді одан сайын тани түсетініңізге сенімді болыңыз. </p>",

                        "<h2>Мысалдар, нұсқаулықтар, сөздік пен тесттер</h2>" +
                        "<p>Біз сабақтарымызды әркім игілігін пайдалансын, әркім ақша жұмсауды, ақша жинау тәсілдерінен хабардар болсын деп жасадық. Әр сабақты түрлі нұсқаулық, мысал мен терминдерді түсіндіретін сөздік қосып дайындадық. Мұқият оқысаңыз, тапсырмаларды дұрыс орындасаңыз, терминдердің мағынасын дұрыстап ұғып, тесттен өтсеңіз, ештеңеге алаңдамасаңыз болады деген сөз.</p>",

                        "<h2>Бастаймыз ба?</h2>" +
                        "<p>Эвереске шығайық деген сияқты естілуі мүмкін, бірақ шындығында біздің курстан өтсеңіз, өзіңізге баға күннен күнге өсіп жатқан мына өмірде батып кетпей, қалқып жүруге көмектесетін сал жасайсыз.</p>",
                    ]
                },
                lessons: [
                    {
                        id: 1,
                        number: 1,
                        name: 'Актив санаймыз',
                        slug: 'aktiv-sanaimyz',
                        duration: '17 мин',
                        bgImage: require('@/assets/images/lessons/lesson-1.png'),
                        content: {
                            shortDescription: "<h2>Актив санау: өзіңізде қанша ақша барын қалай есептейсіз?</h2>\n" +
                                "<p>Бірінші сабақта қанша ақшаңыз барын есептейміз. Жо-жоқ, бұл банк қолданбасын ашып, қанша ақша қалғанын есептеп қана қоямыз деген сөз емес. Керісінше бәрін санауды үйренеміз — жиған-тергеніңіз, қарыз, несие, шоттар, мал, көліктер мен пәтер. Осылай қаншалықты көп қаражатыңыз барын тексереміз, көз жеткіземіз.</p>",
                            shortList: "<h2>Сабақтан не үйренеміз?</h2>\n" +
                                "<div><p>Байлық қандай көрсеткішкерге қарап есептелетіні мен неліктен жоғары жалақы жақсы әл-ауқатқа кепіл бола алмайтынын түсінеміз.</p></div>\n" +
                                "<div><p>Құндылықтарыңыз, байлығыңыз бен міндеттеріңізге бір зер саласыз.</p></div>\n" +
                                "<div><p>Қазіргі қаржылық статусыңыз қандай екенін анықтай аласыз, болашағыңызға керек ақыл-кеңес аласыз.</p></div>",
                            description: "<h2>Активтеріңізді не үшін есептеуіңіз керек?</h2>\n" +
                                "<p>Есептеу мен сараптау — бұлақтың басы ғана. Қолыңызда қанша қаржы барын білмей қойған мақсатқа жету қиын.</p>\n" +
                                "<p>Қазақстан азаматтарының көбінің ақшамен қарым-қатынасын тұрақты деп айта алмаймыз. Адам айына нақты қанша табыс алатынын біле алады (көбіне бұл тек жалақы), бірақ оның қайда кетіп жатқанын екінің бірі түсіне бермейді. Мұндай жағдай — адамға алаң, мақсат қоюға кедергі болады. Ай аяғында түк қалмайтын болса, ақша жинаудың не керегі бар деген сұрақ туады.</p>\n" +
                                "<p>Өзіңізде қанша ақша барын нақтылап алмасаңыз, болашағым бұлыңғыр деп алаңдай бересіз, беймаза боласыз. Оның үстіне, қазір баға күннен күнге өсіп жатыр, шығын көбейді. Соның кесірінен ақша жинау қол жетпес мақсатқа айналуы мүмкін.</p>\n" +
                                "<p>Демек есептейміз. Іске кірісер бұрын мұның қандай жағымды тұстары барын айта кетейік:</p>\n" +
                                "<ul>\n" +
                                "<li>Есептеу жоспарлау емес. Несиемді қай ақшаға жабам, мына қарызды қалай қайтарам, үнемдеу үшін неден бас тартуым керек деп бас қатырудың керегі жоқ. Сіз бар-жоғы өзіңізде не барын есептеп алуыңыз қажет. Осылай қаржылық жағдайыңыз жалпы қандай деңгейде екенін білесіз.</li>\n" +
                                "<li>Жалақыңыз жоғары не төмен болғаны ақшаңызды басқара алуыңызға әсер етпейді: біздің курсты бітіргеннен кейін табысыңызды жалақыңыз қанша болса да жақсы басқаратын боласыз. Біреулер болады, айына аз ақша тапса да, пәтер, көлікке ақша жинап қояды, несиесін мерзімнен бұрын жауып жатады, анда-санда шетелге шығып тұрады. Мамаларымыз үнемі айтатын жұрттың баласы ғой:) Ал біреулері бірнеше есе көп табыс тапса да, ақшасы қолында тұрмайды, қаржысын басқара алмайды. Табысы көбейіп, лауазымы жоғарласа да, үнемі экономика, жағдайым нашарлап бара жатыр деп налиды.</li>\n" +
                                "</ul>\n" +
                                "<p>Мысалға қарайық:</p>\n" +
                                "<blockquote>\n" +
                                "<p>Думан — кішігірім фирмадағы маркетиң бөлімінің бастығы, айына 500 000 теңге тауып жүр. Мұндай жалақы Қазақстанда орташа жалақыдан жоғары саналады. Бірақ оның қаржылық жағдайы тұрақсыз. Қалай сонда десеңіз, жауабы мынадай: Адам жоғары жалақы алса, әл-ауқаты жақсы деген ой туады. Алайда оның өміріне, ақшаны қалай жұмсайтынына зер салсақ, Думанның қаржы жию, актив, ақша қайда кетіп жатқаны туралы түсінігі жоғын көреміз. Думан жалға алған пәтерінде тұрып жатыр, ай сайын ол үшін 250 мың теңге береді екен; жұмысына таксимен барып келеді, оған кететін ақша тәулік мезгіліне, ауа райы мен жолдағы кептеліске байланысты. Орташа есептесек, Думан таксиге әр айда 50 мың теңге береді. Бұған аяқ астынан болатын жағдайларға кететін шығынды да қосайық. Сөйтсек, Думан «жалақыдан жалақыға дейін» ұстанымына сай өмір сүреді. Әлдебір себеппен жұмысынан айырылып қалса, ең қажет деген нәрселердің, мысалы, үйінің ақысын төлей алмай қалады.</p>\n" +
                                "</blockquote>\n" +
                                "<p>Сіздің де жағдайыңыз сондай болса, жабырқаудың қажеті жоқ. Ақша басқаруды үйрену сіз ойлағандай қиын нәрсе емес. Сабақ біткенде қазіргі қаржылай жағдайыңыз қандай екенін, оның деңгейін арттыру үшін қайтпек керегін түсінесіз.</p>\n" +
                                "<h2>Активтер мен қаржылық міндеттер</h2>\n" +
                                "<p>Активтер мен қаржылық міндеттер есепшілер ғана түсінетін сөз сияқты естіледі. Алаңдамаңыз, біздің есептейтініміз ауыр жұмыс емес.</p>\n" +
                                "<p>Актив дегеніміз — ақшалай, ақшалай емес жиған-тергеніңіз: жылжымайтын (өлі) мүлік, көлік, депозитте жатқан ақша, қолда бар ақша, алтын мен қымбат тастар.</p>\n" +
                                "<p>Істі оңайлату үшін бәрін ақшалай есептейміз. Біріншіден, бұлай санаған ертең бюджет жоспарлағанда керек болады, екіншіден, осылағай есептеген әлдеқайда оңай.</p>\n" +
                                "<h2>Активтің қандай түрлері бар?</h2>\n" +
                                "<p>Жаңа айтқанымыздай, актив деген қазір не болашақта ақшаға айналдыруға болатын мал-мүлік. Мысалы, пәтерді, яғни жылжымайтын мүлікті ақшаға сатуға болады. Алайда OLX-та сатуға келетін ескі дүниеңіз бен ұсталған техникаңызды актив деп санауға асықпаңыз. Себебі уақыт өте келе заттың сапасы мен құны төмендейді. Оны сатқанда бағасының бір бөлігін қайтарып ала аласыз, осылай аз да болса қалтаңызға ақша түседі, активіңізді толтырасыз. Бірақ бұл қолма-қол ақша ғана. Актив — адамның ертеңіне деген сенімін арттыратын, қандай да бір пайда беретін бағалы дүние. Оның екі түрі бар: пайдалы және негізгі актив. Пайдалы актив ақша табуға көмектеседі, ал негізгісі капитал көбейтпейді, тіпті кейде оған шығындалуыңыз мүмкін.</p>\n" +
                                "<p>Пайдалы активке инвестиция құю — дұрыс шешім, бірақ негізгі активті де назардан тыс қалдырмау керек. Көбі өзі қолданатын активтерді табыс әкелмейтін әрі шығын деп ойлайды. Міне, осындайдан «үй алма, артық шығын ғой», «көлік алып не істейсің, ақша ғой» деген ақыл-кеңес шығады.</p>\n" +
                                "<p>Бірақ пәтер — актив, тіпті оны жөндеуге және коммуналдық қызметіне тиын жұмсасаңыз да, актив болып қала береді. Әркез жасырын жатқан пайданы ойлаңыз. Мысалы, бұған дейін пәтер жалға алып келдіңіз, коммуналдық қызметтің ақысын төледіңіз, бірақ оған қоса онда тұру үшін ақша шығардыңыз. Міне, осы ақыны пәтер алғаннан кейін төлемейтініңіз — жасырын пайда. Пәтерді ипотекаға алсаңыз да, ақшаны өз үйіңізге жұмсап жатырсыз, ол кеткен ақшаны кейін ақша қылып қайтарып ала аласыз. Активтердің бас қағидасы — осы.</p>\n" +
                                "<p>Кез келген актив негізгі, кез келген негізгі актив пайдалы бола алады.</p>\n" +
                                "<p>Қарапайым мысалға сүйенейік:</p>\n" +
                                "<div>" +
                                "<h2>Пайдалы актив</h2>\n" +
                                "<p>2 миллион теңгеге өзіңіз тұрып жатқан үйдің автотұрағынан орын алдыңыз, бірақ оған қояр көлігіңіз жоқ. Ол орынды көршіңіз айына 20 мың теңге төлеп жалға алып отыр. Инвестиция шығынның орнын үш жылдан аса уақыттан кейін ғана толтырады, кейін таза пайда әкеле бастайды. Кеткен ақша артығымен қайтып жатыр, пайдалы актив деген осы.</p>\n" +
                                "</div>" +
                                "<div>" +
                                "<h2>Негізгі актив</h2>\n" +
                                "<p>Сіз көлік сатып алдыңыз. Тұрақтан орын жалға алып жүрсіз, күн сайын бензин аласыз, анда-санда техникалық қызмет көрсететін жерлерге апарып қаратасыз.</p>\n" +
                                "<p>Көліктен пайда алмақ түгілі, оның үстінен тағы ақша беріп жатырсыз. Бірақ бұл көліктің арқасында таксиге ақша шығармайсыз. Көлікті сатып, орнына қомақты ақша ала аласыз. Осы негізгі актив деген.</p>\n" +
                                "</div>" +
                                "<div>" +
                                "<h2>Негізгі активке айналған пайдалы актив</h2>\n" +
                                "<p>Мысал манағыдай: 2 миллион теңгеге алынған, кейін жалға берілген орын. Оны жалға алып отырған көршіңіз келісімшартты үзіп, орыннан табыс түспейтін болды. Сөйтіп, сіз жалға берем деп жаңа хабарландыру бересіз, бірақ ешкім қызықпады. Ол орынды енді өзіңіз пайдалана бастаңыз. Актив уақытша негізгі активге айналды.</p>\n" +
                                "</div>" +
                                "<div>" +
                                "<h2>Пайдалы активке айналған негізгі актив</h2>\n" +
                                "<p>Өз көлігіңізбен жүріп, курьер не жеке тасушы болып істей бастадыңыз. Шығын сол баяғыдай, бірақ табыс таба бастадыңыз. Көлігіңізді өз қажетіңізге ғана қолданбай, табыс көзіне айналдырдыңыз. Құйылған ақша ақша бере бастады, негізгі актив біртіндеп пайдалы актив болды.</p>\n" +
                                "</div>" +
                                "<p>Жалпы, қолда бар құнды дүниенің бәрі қосымша күтім мен ақша қажет етсін-етпесін, табыс әкелсін-әкелмесін бәрібір актив.</p>\n" +
                                "<h2>Қаржылық міндеттер мен қарыз</h2>\n" +
                                "<p>Енді қаржылық міндеттерге келейік. Оның қаншалықты көп екенін есептейік. Қаржылық міндеттер — не үшін қарызға ақша алғаныңыз. Ең қарапайым мысал — несие. Аяқ астынан кеткен шығынның орнын толтыру үшін несие алуға тура келді делік, енді сіз банкке артығымен қарыздарсыз. Сіз беруіңіз керек ақша — сіздің міндетіңіз.</p>\n" +
                                "<p>Әдетте мұндай төлемдерге жалақыңыздың көп бөлігі кетеді, сөйтіп, ақша жинау мүмкіндігіңіз азая түседі. Иә, достарыңызға, әке-шешеңізге берешегіңіз де міндет саналады. Мұндай жағдайда пайыз төлемесеңіз де, несиедегідей мерзімі мен белгілі бір сомасы бар. Оны да есепке алу керек. Енді кестеге оралайық. Онда біз екі баған толтырамыз, осылай активіміз бен міндеттіміз қаншалықты көп екенін көреміз.</p>\n" +
                                "<h2>Әл-ауқатыңызды есептеу</h2>\n" +
                                "<p>Есептеу оңай, бірінші сыныптың математикасы сияқты. Сіздің әл-ауқатыңыз — актив пен қаржылық міндетіңіздің айырмасы. Бұл — қаржылық еркіндігіңіздің деңгейін көрсететін көрсеткіш. Шыққан сома оң болса жақсы. Бірақ айырма теріс таңбалы болса, яғни активіңіз міндетіңізден аз болса, мұны ретке келтіруіңіз қажет.</p>\n" +
                                "<h2>Қалай есептейміз?</h2>\n" +
                                "<p><a href='https://docs.google.com/spreadsheets/d/1Ms0RdUXuLOt1TRzTbjobODS7-0OCSMruj_aoJdg90lI/copy' target='_blank'>Google Doc</a> кестесі сізге серік болады. Онда әр сабаққа арнап нұсқаулығы бар үлгі кесте дайындадық. Сіздің міндетіңіз — оны Драйвқа жазып алып, көрсеткіштеріңізді енгізу. Әр сабақта сізге осы бөлігін, парағын былай толтырыңыз деген нұсқаулық беріп отырамыз.</p>\n" +
                                "<p>Осы сабақта <a href='https://docs.google.com/spreadsheets/d/1Ms0RdUXuLOt1TRzTbjobODS7-0OCSMruj_aoJdg90lI/copy' target='_blank'>Менің активтерім</a> парағын ашамыз.</p>\n" +
                                "<img src='"+require('@/assets/images/visuals/kz/img-1.png')+"'/>\n" +
                                "<p>Көріп тұрғаныңыздай, сол жақта біз активтерімізді, ал оң жақта міндеттерімізді тіздік. Енді соған қарап өз кестеңізді толтырыңыз. Депозиттегі ақша, тығып қойған, шоттағы ақшаны бірден жазыңыз. Ал пәтер мен көлік — өтімсіз актив, яғни оларды ақшаға айырбастау үшін біраз уақыт керек: сатамын деп хабарлайсыз, алатын адаммен келісесіз, құжат рәсімдеу, тағы басқа. Олардың бағасын қазіргі нарық бағасына қарап қойыңыз. Ол үшін анализ жүргізіңіз: сіздің көлікке не пәтерге ұқсайтын мүлікті қанша адам алғысы келеді деген сұраққа жауап іздеңіз. Мұндай активтердің бағасы үнемі өсіп отырады, сондықтан баға қояр алдында біраз ойланыңыз.</p>\n" +
                                "<p>Қарызға берген ақшаңыз да актив саналады. Досыңызға келер айға дейін 100 мың теңге бердіңіз бе? Кестенің сол жағына енгізіңіз.  Екінші кестеге барлық қаржылық міндетіңізді жазамыз. Банктен несие алған болсаңыз, пайыз қосылған толық соманы емес, негізгі қарызды енгізіңіз.</p>\n" +
                                "<p>Негізгі қарыздың сомасын арнайы калькулятор арқылы немесе несиенің егжей-тегжейі жазылған жерден біле аласыз. Ең оңайы — банк қолданбасындағы «Мерзімінен бұрын жабу» функциясын ашыңыз. Байқасаңыз, екі кестеде де «Ипотекаға алынған пәтер» деген пункт бар. Сіз банкке ақша төлеп отырсыз, бұл несие саналады, бірақ бір жағынан төлеп қойған ақшаңыз біртіндеп активіңізді толтырып жатыр.</p>\n" +
                                "<p>Активтерім деген жерде пәтердің нарықтағы бағасын жаздық — 22 миллион теңге, ал міндетіңізге 10 миллион деп жаздық, яғни пәтерді сатсаңыз, 22 миллион аласыз, оның 10 миллионын ипотеканы жабу үшін банкке бересіз. Өзіңізде 12 миллион теңге қалады.</p>\n" +
                                "<p>Кестені түгелдей толтырғаннан кейін, ол байлығыңызды есептеп береді: актив сомасынан міндеттер сомасын алады. Шыққан сома — бар қарызды иелігіңіздегі мүлікті пайдаланып жабам дегенде қолыңызда болатын ақша.</p>\n" +
                                "<p>Енді мына тапсырманы орындаңыз: дәулетіңіздің сомасын ай сайынғы шығын сомасына бөліңіз. Мысалы, біздің кестеде байлық сомасы 13 миллион теңге болды. Ал ай сайынғы шығынымыз 400 000 теңге дейік. 13 миллион\\400 000 теңге = 32,5 ай немесе 2 жыл 7 ай. Біз барлық берешегімізді жабу үшін бүкіл активімізді пайдалануға мәжбүр болған жағдай туындаса, ақшамыз осынша уақытқа жетер еді. Негізі бұл есептеу тәсіліміз қате болуы мүмкін, себебі шығын біз ойлағандай көп не аз болуы ғажап емес немесе табысымыз артуы мүмкін. Біздің актив — қандай жағдай болсын тіршілік етуге (аштан өлмеуге, тұрып жатқан жердің ақысын төлеуге) көмектесуге тиіс нәрсе. Міне, сондықтан оны үнемі арттырып отыру керек, ал қарыздардан құтылу қажет.</p>\n" +
                                "<h2>Өз жағдайыңызға баға береңіз</h2>\n" +
                                "<p>Активтер мен міндеттеріңізді жаздық, байлығымыз қанша екенін білдік. Енді қорытындыға қарап, жалпы жағдайыңызға баға беріңіз.</p>\n" +
                                "<div>" +
                                "<h2>Активтеріңізден міндетіңіз әлдеқайда көп</h2>\n" +
                                "<p>Бұл —  несиеңіз бен қарыздарыңыз шынымен активіңізден көп немесе мүлдем активіңіз жоқ деген сөз. Қорытынды — мынау: қарыз одан сайын көбейіп жатыр, негізгі актив жинауға мүмкіндік болмай тұр.</p>\n" +
                                "<p>Уайымдамаңыз, мұны шешуге болады. Жаңа ғана қай мәселемен күресу керегін білдік: активіңізді толтыру тәсілін табу, көбейту, қаржылық әдетіңіздің себебін анықтау, зерттеу, әсіресе төртінші сабақты дұрыстап ұғып алу, яғни қалай несие алып, қалай жабу керек екенін үйрену.</p>\n" +
                                "</div>" +
                                "<div>" +
                                "<h2>Қарыз-несиеңіз бен активіңіз бірдей немесе шамалас</h2>\n" +
                                "<p>Қарыз бен несиеңіз бар, бірақ анда-санда табысыңыздың бір бөлігін жинап жүрсіз. Қажет болғанда ғана пайдаланасыз.</p>\n" +
                                "<p>Бір жалақыға қарап күн көрген қиын. Сондықтан ендігі сабақтарды жақсылап ұғып алып, қаржы саласындағы сауатыңызды арттырғаныңыз жөн. Сақтанғанды сақтайды.</p>\n" +
                                "</div>" +
                                "<div>" +
                                "<h2>Міндеттеріңізден активіңіз көп</h2>\n" +
                                "<p>Актив бар, бірақ берешек, несие де бар. Алайда олар қалтаңызға көп салмақ түсірмейді. Ақшаңыз барлығына жетеді, керек болып қалар деп те жинап жүрсіз.  Жарайсыз, қаржылай жағдайыңызды ретке келтіргенсіз екесіз, бірақ білгенің бір тоғыз, білмегенің тоқсан тоғыз демекші, әлі де үйренеріміз бар. Барлық сабақтан өтіп, қай салада кемшілігіңіз барын біліңіз. Кейін қаржы жағдайыңызға тағы бір зер саласыз, сосын үйренгеніңізді пайдаланып, оны одан ары дамытыңыз.</p>\n" +
                                "</div>" +
                                "<div>" +
                                "<h2>Активтеріңіз қарызыңыздан әлдеқайда артық — қаржылық тәуелсіздік</h2>\n" +
                                "<p>Несиені қаржы құралы ретінде қолданып жүрсіз, өзге жағдайларда керек қылмайсыз. Сақтанғансыз, қауіпсіздік жастығыңыз бар, пассив түсіп тұратын табысыңыз бен тұрақты кірісіңіз бар. Керемет нәтиже. Бірақ солай екен деп курсты аяқтамай қалмаңыз. Біз сізге пайдалының бәрін ендігі сабақтарда айтпақпыз. Ал сіз білгеніңізді бір қайталап аласыз, кей қателескен жеріңізді түзейсіз.</p>\n" +
                                "</div>",
                        },
                        dictionary: [
                            {
                                id: 1,
                                name: 'Актив',
                                description: 'иелігіңіздегі қазір немесе келешекте табыс әкелетін кез келген мүлік'
                            },
                            {
                                id: 2,
                                name: 'Өтімділік (активке қатысты)',
                                description: 'тез әрі көп шығын келтірмей ақшаға айырбастауға болатын мүлік'
                            },
                            {
                                id: 3,
                                name: 'Өтімсіздік (активге қатысты)',
                                description: 'тез әрі көп шығын келтірмей сатылмайтын мүлік'
                            },
                            {
                                id: 4,
                                name: 'Несие жүктеме',
                                description: 'клиенттің банкке берешегі. Қарыз алғалы тұрған адамның бюджеті қандай екенін бағалауға, қосымша қарыз төлейтін жағдайы бар-жоғын анықтауға көмектеседі.'
                            },
                            {
                                id: 5,
                                name: 'Жалақы индексациясы',
                                description: 'еңбекақының инфляцияға қарап өсуі.'
                            },
                            {
                                id: 6,
                                name: 'Әл-ауқат деңгейі',
                                description: 'шығын мен кірістің айырмасы.'
                            },
                        ],
                        tests: [
                            {
                                id: 1,
                                question: 'Қаржылық жағдайымызға неге баға беруіміз керек?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Достарыма күшті екенімді көрсету үшін',
                                        result: 'Мақсатыңыз күшті екеніңізді дәлелдеу болса, мархаббат, дәлелдей беріңіз. Оның әбестігі жоқ. Бірақ баға бергеннен кейінгі нәтиже көңіліңізден шықпаса не болмақ?',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Сөздік қорыма түрлі термин қосам: актив, қаржылық міндет, өтімділік, тағы басқасы.',
                                        result: 'Қаржыға қатысты түрлі терминді білген де жақсы, бірақ мақсатыңыз оған байланып қалмауы керек.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Беймаза болғым келмейді',
                                        result: 'Мазасыздықтан арылған да жақсы. Себебі ол — ақшаны қалай болса солай жұмсауға түрткі болар факторлардың бірі, бірақ әл-ауқатыңыздың деңгейін анықтауға мазасыздықтан арылудан басқа факторлар себеп болуға тиіс.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Осының бәрі үшін',
                                        result: 'Әл-ауқатыңыздың деңгейін біліп алсаңыз, ақшаға келгенде аз алаңдайсыз, терминдерді шатастырмайсыз, бастысы — оны ендігі мақсат пен жоспарлар үшін қажетіңізге жаратасыз.',
                                        correct: true,
                                    }
                                ],
                            },
                            {
                                id: 2,
                                question: 'Мынаның қайсын ақшаға тез әрі көп шығынсыз айырбастауға болады?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Антиквариат пен өнер туындылары',
                                        result: 'Оларды өткізем деп ақша мен уақыт жұмсайсыз. Мысалы, сатып алушы табу керек, одан кейін заттар түпнұсқа екенін дәлелдеп, алған адамға жіберуіңіз керек.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Валюта',
                                        result: 'Жоғары өтімділік — активті ақшаға барынша көп шығынсыз әрі айырбастау деген сөз. Валюта айырбастау тез өтетін процесс. Оны қазір онлайн да, жақын жердегі айырбастау пунктіне барып айырбастауға болады.',
                                        correct: true,
                                    },
                                    {
                                        id: 3,
                                        answer: '500 қойы бар шаруашылық',
                                        result: 'Оларды базарға барып сатуға не өндіріске тапсыруға болады. Оларға сұраныс әрқашан бар, бірақ жақсы пайда көру үшін оларды бағу керек, баптау керек. Уақыт та, ақша да кетеді.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Жер үй',
                                        result: 'Оны сату үшін уақыт, тіпті кейде бір айдан аса уақыт керек. Демек өтімділігі төмен.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 3,
                                question: 'Сіздің ипотекаға алған пәтеріңіз бар. Оның бағасы — 36 миллион теңге. Қарызыңызды өтеуге әлі екі жыл бар, қалған берешегіңіз — 6 миллион теңге + пайыз. Активтер кестесіне қай соманы жазасыз?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: '36 миллион',
                                        result: 'Дұрыс айтасыз, активтер кестесіне толық соманы жазамыз, мейлі өтелмеген сома болса да. Бұл амал қаржылық жағдайыңызды дұрыс бағалауға көмектеседі.',
                                        correct: true,
                                    },
                                    {
                                        id: 2,
                                        answer: '30 миллион',
                                        result: '30 миллион — төленген сома. Сондықтан активтер кестесіне толық соманы жазуыңыз керек.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: '6 миллион',
                                        result: 'Бұл — қарыздың қалған бөлігі, оны міндеттер кестесіне енгізесіз.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: '42 миллион',
                                        result: 'Сіз пәтердің бағасына қалған қарыздың сомасын қосқан сияқтысыз. 36 миллион теңгені активтер қатарына қосасыз, ал 6 миллион теңгені міндеттерге',
                                        correct: false,
                                    }
                                ],
                            }
                        ],
                    },
                    {
                        id: 2,
                        number: 2,
                        name: 'Бір ай мен бір жылға бюджет жоспарлаймыз',
                        slug: 'bir-ai-mien-bir-zhylga-biudzhiet-zhosparlaimyz',
                        duration: '23 мин',
                        bgImage: require('@/assets/images/lessons/lesson-2.png'),
                        content: {
                            shortDescription: "<p>Бюджет мемлекет пен компанияларға ғана қатысты нәрсе емес. Ақша бар әр істің өзі бюджет. Осы сабақта есеп-қисап жүргізген қорқынышты емес екенін, керісінше, оңай екенін көрсетеміз. Бүгін қаражатыңыз қайда текке кетіп жатқанын білесіз, жиған-тергеніңізді қадағалау мен алар затыңызға ақша жинауды үйренесіз.</p>",
                            shortList: "<h2>Сабақтан үйренеріңіз:</h2>\n" +
                                "<div><p>Ақшаңызды дұрыс жұмсап жүрсіз бе, жоқ па, түсінесіз. Егер қалай болса солай жұмсап жүрсеңіз, қаражатыңызды басқаруды, орнымен жұмсап жүрсеңіз, осы қалпыңызды жақсартуды үйренесіз.</p></div>\n" +
                                "<div><p>Бюджеттің еш қиыны жоғын, оп-оңай нәрсе екенін көресіз. Ал біз қалай есеп-қисап жүргізу керегін айтамыз.</p></div>\n" +
                                "<div><p>Кесте сызудың қажеті жоқ, біз дайынын береміз. Оны ай не жылға бөліп жүргізуді үйретеміз.</p></div>\n",
                            description: "<h2>Бюджет. Өзіме өзім банкпін</h2>\n" +
                                "<p>Ақша санау неліктен қызық екенін түсіндіреміз.</p>\n" +
                                "<p>Бюджет, есеп-қисап, шығын, есептеу онсыз да қиын-қыстау әлемде адамның жынына тиетін триггер нәрсе болып көрінеді. Бірақ олай емес. Сауатты жүргізілген бюджетті мүмкіндік, яғни ақшамызды дұрыстап жұмсау, өз қажетімізді алу мүмкіндігі.</p>\n" +
                                "<p>Бюджет жүргізу дегеніміз — не нәрсеге көп ақша жұмсауға мәжбүр екеніңізді біліп қана қоймай, бір ай, бір жылда үлкен ақша добына айналатын ұсақ шығындарға не себеп екенін де білеміз.</p>\n" +
                                "<p>Бір сәт шыншыл болайық — ақшаңызды дұрыс жұмсап жүрсіз бе, бұдан көп ақшаға ие болсаңыз, жөнді жұмсай алар ма едіңіз, анықтап алайық.</p>\n" +
                                "<p>Мұның жауабын осы белгілер береді:</p>\n" +
                                "<ul>" +
                                "<li>Ай аяғында жинағаныңыз — кірісіңіздің 5%-ы. Өкінішке қарай, бұдан артық жинай алмайсыз, кейде 5 пайыз да бір жетістік боп көрінеді.</li>" +
                                "<li>Кірістің жартысын айдың бірінші аптасында құртып тастап жүрсіз. Айдың екінші жартысында қанша ақша қалды екен деп тіршілік етесіз. Бірақ қарыз сұрайтындай жағдайыңыз қиын емес, бұл да жақсы.</li>" +
                                "<li>Бір айда ақшаңызды қалай болса солай жұмсайсыз. Бүгін 15 000 теңге, ертең 3 000, ал бүрсігүні тағы 15 000 теңге жұмсайсыз. Бұл қылығыңыздан арылмақ түгілі, қадағалай да алмайсыз.</li>" +
                                "<li>Ай сайын сіз ендігі айда қайтарам деп қарыз аласыз, уақытылы бересіз, бірақ ай аяғында тағы аласыз. Шығар есігі жоқ айналсоқ.</li>" +
                                "</ul>" +
                                "<p>Осы белгілердің кем дегенде бірі сіздің бойыңыздан табылатын шығар. Бұдан не рақат, не тыныштық көрмейсіз, мазасыздық айдан айға артып бара жатыр. Бірақ сізге жақсы жаңалығымыз бар: бұл әдеттен құтылуға болады! Қазір соның бірінші тәсілін көрсетеміз. Ол — бюджет жүргізу.</p>\n" +
                                "<p>Ол үшін:</p>" +
                                "<ol>" +
                                "<li>Кірісімізді есептейміз — айына қанша тауып жүргеніңізді анықтаймыз.</li>" +
                                "<li>Енді шығынды есептейміз — ақшаны неге жұмсап жатқаныңызды түсінеміз.</li>" +
                                "<li>Шығындарды бір талдап шығамыз — қайсынан бас тартуға немесе қайсының бюджетін қысқартуға тура келетін анықтаймыз.</li>" +
                                "<li>Ендігі айларға, кейін бір жылға жоспар құрамыз. Күтпеген шығынға жол бермейміз.</li>" +
                                "</ol>" +
                                "<p>Кеттік!</p>" +
                                "<h2>Бірінші. Нені есептейміз?</h2>" +
                                "<p>Әуелі ақша қайдан келіп, қайда кетіп жатқанын жазып шығамыз. Дым қиындығы жоқ жаттығу боп көрінеді, бірақ оның нәтижесі өте маңызды: өзіңіз туралы тың ақпарат аласыз — қайда барып жүрсіз, не жеп, не киіп жүрсіз, не нәрсеге мол ақша беріп жүрсіз, ал неге келгенде үнемдеп қаласыз.</p>" +
                                "<p>Мысалы, өзіңізге келгенде ақшаңызды үнемдегіш болып қалатын сияқты көрінетін еді, бірақ есеп-қисап жүргізгеннен кейін олай емесін байқайсыз. Мәселен, дүйсенбі күндері тәтті алып жейді екенсіз, сенбі күндері үйден шықпай, демалып жатасыз. Демек, өзіңізге жиі көңіл бөліп жүрсіз. Кіріс-шығынды есептегеннен кейін осындай инсайттар көп болады, өзіңіз туралы біраз жаңа мәлімет береді. Сіздің бас міндетіңіз — соларға қарап жағдайыңызды түзеу.</p>" +
                                "<p>Сонымен, бар ақшаңызды санаймыз: негізгі табыс, қосымша табыс, актив және пассив табыс, міндетті шығын, міндетті емес шығындар. Қазір оның түрін-түрін ажыратамыз:</p>" +
                                "<p><strong><span class='green'>Кіріс</span></strong></p>" +
                                "<ul>" +
                                "<li>Негізгі табыс — тұрақты түрде түсіп тұратын ақша, бюджетіңіздің тірегі. Мысалы, ай сайынғы жалақы.</li>" +
                                "<li>Қосымша табыс — шәкіртақы, қаламақы, штаттан тыс жұмыс.</li>" +
                                "</ul>" +
                                "<p>Мысалы, жалақыңыз 450 000 теңге (картаңызға түсетін ақша), ал қосымша жалақыңыз — 100 000 теңге. Демек ай сайынғы кірісіңіз — 550 000 теңге. Ақша жұмсарда осы цифрдан аспауыңыз керек, яғни шығыныңыз кірісіңізден кем болуы қажет.</p>" +
                                "<p><strong><span class='green'>Шығын</span></strong></p>" +
                                "<p>Шығын жағын есептеу енді қиынырақ болады, себебі күн сайын ақша жұмсап жүрміз. Оның бәрін есте сақтау мүмкін емес. Сондықтан бізге есеп-қисап керек.</p>" +
                                "<p>Назар аударуымыз керек бірнеше шығын бар. Неге десеңіз, назар аудармасақ, ай аяғында тыныштығымыз бұзылуы мүмкін. Әдетте шығынның төрт түрі болады:</p>" +
                                "<ul>" +
                                "<li>Міндет шығындар деген — қарыздар: несие, бөліп төлеу, ипотека, тағы басқа.</li>" +
                                "<li>Керек шығындар — коммуналдық қызмет, пәтерақы, азық-түлік. Тіршілігіңізге маңызды нәрселер.</li>" +
                                "<li>Болашаққа арналған шығындар — бюджетіңізге кіруге тиіс жиған-тергеніңіз. Біз дәл осы ақшаны жинауға әрі сақтауға ұмтылуымыз керек.</li>" +
                                "<li>Күн сайынғы шығындар: транспорт, сырттан тамақ ішу, кофе, ойын-сауық, мейраманаға бару, сыйлықтар, тағы басқа.</li>" +
                                "</ul>" +
                                "<p>Шығындарды санаттарға бөлсек, бізге не маңызды екенін түсіне аламыз. Жалақыны алған бойда маңызына қарай бөлуді үйреніңіз.</p>" +
                                "<p>Әуелі міндет шығындар кетеді. Несиелер, бөліп төлеу, қарыздарыңыз бірінші төленуі керек, кешіктіріп төлесеңіз, жазаңыз ретінде үстінен тағы ақша төлейсіз, несие тарихыңыз бүлінеді. Екінші санат — қажет шығындар. Коммуналдық қызмет, пәтерақы, азық-түлік — өміріңізге нағыз қажет заттар. Жұмысыңыз алыста болса, барып келуге уақыт пен қомақты ақша жұмсау керек болса, бұл бөлімге транспортты да қосыңыз.</p>" +
                                "<p>Үшінші санат болашаққа арналған шығын болуы керек. Себебі қалғанын алып қалам деген қағидаға сай жүрсеңіз, ешқашан дұрыс ақша жинай алмайсыз. Ол ақшаны уақыт өте келе құртып қоюыңыз мүмкін. Сондықтан осы қаржы ойынымызға pay yourself first қағидасын енгіземіз, яғни ақша түскен бойда оның бір бөлігін болашағымызға арнап сақтап қоямыз. Осы санаттан қалған ақша келесі жалақыға дейін қалағанымызға жұмсай алатын бюджетіміз болады: тамақ ішу, мейрамхана, бар, такси, кофе, жеткізу қызметі.</p>" +
                                "<p>Қиын сияқты көрінетінін біліп тұрмыз, бірақ бәрін бір кестеге түсіргеннен кейін тапсырма әлдеқайда оңай екеніне көзіңіз жетеді.</p>" +
                                "<h2>Екінші. Қалай есептеп отырамыз?</h2>" +
                                "<img src='"+require('@/assets/images/visuals/kz/img-10.png')+"'/>\n" +
                                "<p><a href='https://docs.google.com/spreadsheets/d/1Ms0RdUXuLOt1TRzTbjobODS7-0OCSMruj_aoJdg90lI/copy' target='_blank'>Біздің кестені</a> қолданғаныңыз абзал. Көрсеткіштерді күн не апта аяғында енгізгеніңіз жөн болар, кешке не демалыста ақша қайда кеткенін есіме түсіре алмаймын десеңіз, телефондағы арнайы қолданбаларға (Monefy, Wallet) не қойын дәптерге шығындарыңызды түртіп отырыңыз. Кешке не демалыста соның бәрін біріктіріп, кестеге түсіріңіз. Әрине, ақша жұмсағаннан кейін бірден кестені ашып, мынандай қаражат кетті деп түртіп отыра алмаймыз. Десе де кесте — өте маңызды құрал. Оған мыналар себеп:</p>" +
                                "<blockquote>" +
                                "<p>Данияр 500 000 теңге жалақы алды. Әуелі пәтерақысы мен несиесін төледі. Оған 220 000 теңге кетті. Қалған 280 000 теңгені Данияр картасында қалдырды да, ай аяғында қалатын ақшаны депозитке саламын деп шешті. Басында 30 мың теңге қалуы керек деген ой келеді. Данияр күн сайын дәмханаға барады, жаяу жүргісі келмесе, таксимен жүреді, балансына бірнеше рет ақша аударды, себебі тарифі 30 күнге емес, 20-ақ күнге жетті. Ай аяғында ақша таусылуға жақын. Тек коммуналдық қызметке жетеді. Қалған ақша қайда кетті, ол жағы беймәлім. Бірақ есептеп көрсек, Данияр ақшаны мыналарға жұмсаған: 15 стақан кофе (12 000 теңге), 10 рет таксиге мінген (6 000 теңге), қосымша тарифтің құны — бірнеше мың теңге. Бұған қарап бір түйгеніміз — бюджетті ретке келтіру керек. Жиі жүрек жалғауды, кофе ішуді қоюы керек, таксиге емес, автобус я метроға мінуі қажет немесе жаяу жүруі керек, телефон тарифін ауыстыруы керек.</p>" +
                                "</blockquote>" +
                                "<p>Данияр осылай есеп-қисап жүргізсе, ақша қайда кетіп жатқанын түсінер еді. Кесте қиын әрі қорқынышты боп көрінгенмен, оның пайдасы зор. Сеніңіз.</p>" +
                                "<h2>Кестені қалай пайдаланымыз?</h2>" +
                                "<p>Кестені толтыра бастайық.</p>" +
                                "<ol>" +
                                "<li>Кіріс қанша екенін, яғни айына қанша тауып жүргенімізді жазамыз: жалақы, шәкіртақы.</li>" +
                                "<li>Тұрақты шығындар бағанына Міндет және Қажет шығындарды енгіземіз: несие, бөліп төлеу, коммуналдық қызмет, пәтерақы.</li>" +
                                "<li>Жинақ бағанына айына ақшаңыздың неше пайызын жинап қоя алатыныңызды жазыңыз. Кесте өзі-ақ оны ақша қылып есептеп береді, тіпті айына осы пайызды тұрақты түрде жинап тұрсаңыз, кесте бір жылда қанша жинайтыныңызды болжап береді. Әлі ақша жинау әдетін қалыптастырмаған болсаңыз, кішкентай пайыздан бастаңыз. Басында 5% жинасаңыз да, жеңіс. Кейін кесте қандай осал тұсыңыз барын көрсетеді. Соған қарап, ақшаны қалай жұмсау керегін, пайызды қалай көбейтуге болатынын түсінесіз.</li>" +
                                "<li>Жинақтың астына Басқа тұрақты шығындарды енгізсеңіз болады. Спортқа жазылым, үй жинау, музыка қолданбасы, өзге де сервиске жазылымдар. Олардың ақысы белгілі бір сома болғаны бюджет есептеуге ыңғайлы болады.</li>" +
                                "<li>Шығындар мен кірістерді енгізгеннен кейін кесте бір күннің бюджеті мен бір айдың бюджетін есептеп береді. Осы сомадан аспауға тырысыңыз. Ақшаңызды бюджетке сай жұмсасаңыз, келесі жалақы алғанға дейін ешкімнен қарыз, несие алмай өмір сүресіз. Мұның өзі — бір жетістік.</li>" +
                                "<li>" +
                                "<p>Оң жақтағы бағанда тек шығындарды жазыңыз. Онда шығын санаттарын шатастырып алмаған жөн. Мысалы, қызмет не заттың ақшасын аударып жіберсеңіз, оны да шығын қатарына жатқызыңыз, бөлек бағанға жазбаңыз. Себебі қай ақша қайда кеткенін біле алмай басыңыз қатады.</p>" +
                                "<p>Күні бойы жұмсап жатқан сомаңызды телефондағы Ескертпеге не арнайы қолданбаға түсіріп жүріңіз. Бастысы — сома мен санат. Мысалы, таңертең мінген такси — 700 теңге, түскі ас — 1 800 теңге, кофе — 900 теңге. Кейін кешке осы жазғандарыңызды кестеге енгізіңіз.</p>" +
                                "<img src='"+require('@/assets/images/visuals/kz/img-9.png')+"'/>\n" +
                                "</li>" +
                                "<li>Ай аяғында күнделікті шығындардың құрылымына қараңыз: кесте қай шығын санатына ақшаңыз көп кететін пайыз қылып көрсетіп береді.</li>" +
                                "<li>Ай аяғында ақша қалатын болса, алдыңғы айдың қалдығы ретінде келесі айға қосылады.</li>" +
                                "</ol>" +
                                "<h2>Бір айдың есеп-қисабын жүргізгеннен кейін алдымызға үш сценарий шығады.</h2>" +
                                "<ol>" +
                                "<li>Ең тәуір сценарий — табысыңыз шығыныңыздан көп.</li>" +
                                "<li>Онша емес сценарий — қанша тапсаңыз, сонша құртасыз.</li>" +
                                "<li>Керек емес сценарий — шығыныңыз табысыңыздан көп.</li>" +
                                "</ol>" +
                                "<p>Кесте есептеп берген нәтижені ең тәуір сценарий деуге келсе, шығындыраңызды бір шолып, неде үнемдеуге болатынын білу артық етпейді. Нәтижеде кейінгі екі сценарийдің белгілері бар болса, дабыл қағу керек деген сөз. Иә, онша емес сценарийде қатты алаңдаудың қажеті жоқ шығар, десе де бюджетті ретке келтіру керек. Қалай десеңіз? Кестеге қараймыз, нені қолға алуымыз керегі сол жерде жазылған.</p>" +
                                "<p>Шығындарды ретке келтіру үшін біраз жұмыстануымыз керек. Міндет шығындар жағы түсінікті болса, міндет емес шығындарды ретке келтіру біраз қиынға түседі. Себебі олардың кейбірі бізге қажет емес, қалтамызға салмақ түсіреді. Сол жағын қарайық.</p>" +
                                "<p><strong><span class='green'>Эмоциялық</span></strong></p>" +
                                "<p>Эмоциялық шығын — түкке керегі жоқ, мән-мақсатсыз алынатын зат немесе қызмет. Оның қажеті жоғын көп аңғара бермейміз, бірақ сыртқы факторлардың кесірінен, мысалы, жарнама, эмоцияның кесірінен байқамай ақша жұмсап қоюымыз мүмкін. Мәселен, бір блогерден атақты брендтің жаңа үтігін көрдіңіз делік, ол туралы пікірлер жақсы болғасын затты алдыңыз. Алайда ол үтік сізге керек емес, себебі жақсы істеп тұрған ескі үтігіңіз бар. Мақсатсыз алынған зат сізге қуаныш сыйлар-ау, бірақ ондай шығын көбейсе, тұрмысыңызға кері әсер етеді.</p>" +
                                "<p>Дүкенге кіріп, жеңілдікпен сатылып тұрған, бірақ керегі жоқ зат алсаңыз, мұныңыз эмоцияға беріліп ақша жұмсау деп аталады. Желтоқсанда баға ұшынып тұрғанда қысқы күрте алатын болсаңыз, мұныңыз дұрыс шешім болмас. Екі нұсқа та ерте ме, кеш пе қалтаңызға салмақ болады.</p>" +
                                "<blockquote>" +
                                "<p>Өткен айда Данияр өзіне 50 000 теңгенің сапалы күртесін сатып алды. Ол бұл күрте үшін біраз іс атқарды, киімге ақшам жетсін деп ай сайын 16 500 теңге ақша жинап отырды. Бірақ сауда орталығына барғанда Данияр әлгі күртені алмайды. Себебі бір атақты брендтің жаңа күртесіне көзі түседі. Данияр еш ойланбастан, сол күртені алды. Бағасы 50 000 емес, 80 000 теңге. Ол ақшаны ол жинаған жоқ, әп-сәтте бере салды. Бұл күрте оның бюджетіне зиян келтірді ме?</p>" +
                                "</blockquote>" +
                                "<p>Керек емес нәрсе алғыңыз кеп бара жатса, 30 күн қағидасын ұстаныңыз. Егер 30 күн өткеннен кейін сол нәрсені әлі алғыңыз кеп тұрса, еш ойланбастан аласыз немесе ақша жинайсыз. 30 күн тым көп десеңіз, бірнеше рет ойланыңыз. Жеті өлшеп, бір кесіңіз. Әйтеуір, «дәл қазір алу керек, әйтпесе болмайды» деген қағидадан жақсы.</p>" +
                                "<p>Қаржылық сауаты бар адам — ақшасын да, эмоциясын да басқара алатын адам.</p>" +
                                "<p><strong><span class='green'>Аяқ астынан болатын шығындар</span></strong></p>" +
                                "<p>Мұндай шығын атымен жоқ болса, қауіпсіздік жастығы деген де болмас еді. Адамның жынына ең қатты тиетін де осы ғой: мереке алдында тұмауратып қалу, жуынатын бөлмедегі тұрбаның жарылуы, бұзылып қалған компьютер, ақшамызды көзді ашып жұмғанша жұлып әкетер кез келген нәрсе. Бірақ зер салсақ, бұлардың кейбірінің алдын алуға болады, кейбіріне дайындалуға болар еді.</p>" +
                                "<p>Мысалы, денсаулығыңызды тексертіп жүріңіз, әсіресе мазалап жүрген органдарды қаратыңыз. Тұмауратып қалсаңыз немесе басқа вирус жұқтырсаңыз, қашан ауырғаныңызды есіңізге түсіріңіз. Сөйтіп келесі ауруды болдырмайсыз немесе алдын ала ақша жинап қоясыз. Компьютеріңізді де тексертіңіз. Жөндеу керек болса, кешіктірмей жөндеңіз, ондай қатты бұзылмаған болса, артынан жөндеңіз. Сантехникаңызды да сөйтіңіз: тексертіңіз, қараңыз, ақша жинаңыз.</p>" +
                                "<p><strong><span class='green'>Көрінбейтін шығын</span></strong></p>" +
                                "<p>Біздің басты мақсатымыз — жоспарланған бюджетке сай әрекет ету, ұсақ-түйек алам деп қалған ақшаны ай басында құртпауға талпыну.</p>" +
                                "<p>Тып-тыныш сөйтейік десек, бізге өзге нәрсе кедергі болмақ. Ол — жасырын немесе көзге көрінбейтін шығын. Бұлар: такси, жұмысты бастар алдында ішетін кофе, жүрек жалғау, темекі және т.б. Біздің білуімізше, оларға көп ақша кетеді.</p>" +
                                "<blockquote>" +
                                "<p>Мысал: Данияр ай аяғында 350 000 теңге алады. Жұмысқа 20 минутта жаяу жете алады, бірақ күнде такси шақырады да жатады. Жаяу жетуге болар еді, бірақ уақыт әбден таяғанда ғана тұрып, такси шақыруға мәжбүр. Кейін жұмыс алдында бұрынғы әдетіне басады: кеңсесінің алдындағы дүкеннен кофе алады. Оған 1 000 теңге береді. Данияр үйге жаяу барғанды да жақтырмайды. Сондықтан тағы такси шақырады. Енді есептеп көрейік.</p>" +
                                "<p>Таксиге күніне ~ 1 200 теңге жұмсау 21 рет жұмысқа бару келу үшін 25 000 теңге кетеді деген сөз. Кофеге тағы 21 000 теңге кетеді. Жалпы сома — 46 200 теңге. Даниярдың жалақысының 13,2 %-ы. Даниярға осы өмірі ұнаса, ештеңе дей алмаймыз, қалауы біледі. Бастысы, кестеден осы шығынды көріп Данияр қай шығынын ретке келтіру керегін түсінеді.</p>" +
                                "</blockquote>" +
                                "<p>Көрінбейтін жаумен күресу қиын. Бірақ бізге мыналар көмек болмақ — бір күнге бюджет белгілеу, шығын-кірістің бәрін кестеге түсіру және қалған ақшаңызды көрсететін «Тяжеловато» қолданбасын қолдану. Қолданбаға кестедегі «Бір айдың бюджеті» бағаны есептеп берген соманы енгізіңіз. Мысалы, 100 000 теңге. Қолданба бір күнгі шығыныңыз 3 333 теңгеден аспауы керек деген қорытынды шығарады. Енді сіздің міндетіңіз — осы нормадан аспау.</p>" +
                                "<p>Мұның еш қиындығы жоқ. Бір күнгі бюджетімізде қанша ақша барын білдік. Күн сайын күні бойы қанша жұмсағаңызды телефоныңызға, қосындысын қолданбаға енгізіп отырасыз. Ол сізге бүгін әлі қанша жұмсауға болатынын айтады. Ал сіз нормадан аспауға тырысасыз, кешке барлық шығыныңызды кестеге енгізесіз.</p>" +
                                "<p>Ұзақ жұмыстан қорықпаңыз. Бұл спорт залына барған сияқты, басында қиын нәрсе болып көрінеді, бірақ кейін денсаулығыңызды жақсартар әдетке айналады. Мұнда да солай.</p>" +
                                "<h2>Төртінші қадам. Бір жылдың бюджетін жасаймыз</h2>" +
                                "<p>Бір айдың бюджетін қалай жасау керегін түсіндік. Енді соған қарап 3 ай өмір сүріп көріңіз. Осы уақыт ішінде ақша не нәрсеге босқа кетіп жатқанын көріп, қаржылық әдетіңізді түзей бастайсыз.</p>" +
                                "<p>Ай бюджетін ретке келтіргеннен кейін одан да көп іс тындырғыңыз келеді. Мысалы, 365 күннің бюджетін жасағыңыз келеді. Сөйтіп, бір жыл бойы қанша құртатыныңызды, қанша жинайтыныңызды біліп жүресіз. Әрине, аяқ астынан болатын шығындар бар. Дегенмен сақтанғанды сақтайды демекші, бәріне дайын боламыз.</p>" +
                                "<p>Кестені ашамыз. Енді Бір жылдың бюджетін ашамыз.</p>" +
                                "<img src='"+require('@/assets/images/visuals/kz/img-2.png')+"'/>\n" +
                                "<p><strong><span class='green'>Кестеде:</span></strong></p>" +
                                "<ol>" +
                                "<li>Күнделікті шығындар бағанына ай сайынғы шығыныңыздың сомасын енгізіңіз.</li>" +
                                "<li>Міндет және аса қажет шығындарды енгізіңіз.</li>" +
                                "<li>Енді жоспарлаймыз. Бір жыл ішінде неге ақша жұмсамақ ойыңыз бар? Денсаулығыңызды түгелдей тексертуге ме? Демалысқа ма? Маусымдық киім-кешекке ме? Гуд. Енді оларды тиісті бағанға енгізіңіз. Мысалы, бір жылда бір рет демалғыңыз келеді, ал киім алуға екі рет барғыңыз келеді.</li>" +
                                "<li>Кесте жазғаныңызды есептеп, жылдық бюджетіңізді шығарып береді. Міне, бір жылда осынша ақша жұмсамақсыз.</li>" +
                                "<li>Ай сайын кестені ашып, шығынның нақты сомасын енгізіп отырыңыз.</li>" +
                                "</ol>" +
                                "<p>Неліктен мұндай кесте жасауға тиіспіз? Кесте тұрмысыңыздың халі мен шығыныңызды түсінуге көмектеседі. Көп сома шықса, қорықпаңыз. Бұл сомаға ыңғайлы өмір сүріп жүрсіз. Бұған қақыңыз бар.</p>" +
                                "<p>Ендігі сабағымыз қаржылық мақсаттар мен оларға жету жолдарына арналмақ. Кеттік пе?</p>"
                        },
                        dictionary: [
                            {
                                id: 1,
                                name: 'Пассив табыс көзі',
                                description: 'сізден белсенді әрекет талап етпейтін табыс. Мысалы, депозит пайызы, пәтерақы, акциядан түскен дивиденд.'
                            },
                            {
                                id: 2,
                                name: 'Pay yourself first',
                                description: 'әуелі болашағыңызға, содан кейін күнделікті шығыныңызға ақша арнау деген сөз. Терминнің авторы кім екені белгісіз. Біреулер инвестор Роберт Кийосаки дейді, кейбірі жазушы Наполеон Хилл дейді, бақса біреулер кәсіпкер Жорж Самюел Клейсон дейді.'
                            },
                            {
                                id: 3,
                                name: 'Эмоцияға беріліп зат не қызмет алу',
                                description: 'зат я қызметті қажет пе, қажет емес пе деп ойланбастан алу. Тағы бір киім, тағы бір қымбат сөмке, аш болмасаңыз да жүрек жалғау.'
                            },
                            {
                                id: 4,
                                name: 'Көрінбейтін шығындар',
                                description: 'бөліп-бөліп алғанда көп шығын болып көрінбейтін нәрселер. Оған ақша кетіп жатыр-ау деп ойланбайсыз. Мысалы, такси, жұмысты бастар алдында кофе ішіп алу, жүрек жалғау. Бір айда осыларға қанша жұмсағаныңызды есептесеңіз, қап, керегі жоқ деген ой келеді.'
                            },
                            {
                                id: 5,
                                name: 'Табыс бағаны',
                                description: 'сізге табыс әкелетін кез келген нәрсе: жалақы, шәкіртақы, дивиденд, сыйақы, депозит пайызы.'
                            },
                            {
                                id: 6,
                                name: 'Шығын бағаны',
                                description: 'кез келген шығыныңыз. Күнделікті және тұрақты (несие, бөліп төлеу, коммуналдық төлем).'
                            },
                        ],
                        tests: [
                            {
                                id: 1,
                                question: 'Бір айдың бюджетін есептегелі жатырсыз. Қай санатты ескересіз?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Бүкіл кіріс пен шығынды жазам, қалғанын алып қоям.',
                                        result: 'Жағдайыңызды толық түсіну үшін бұл жеткіліксіз болады. Оған қоса үнемдеймін, қолда қалғанын сақтап қоямын десеңіз, мақсатыңызға жетпейсіз. Ақша жинайтын болсаңыз, белгілі бір соманы ай басында алып қоюыңыз керек. Ал қанша ақша сақтап қоя алатыныңызды есептеу үшін айлық бюджетіңізді егжейлі есептеуге тиіссіз.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Жалақым мен негізгі шығынды санаймын. Қалғанын санап не істеймін? Ай сайын әртүрлі шығады ғой бәрібір',
                                        result: 'Іске жарамайтын тәсіл. Біріншіден, жалақыдан бөлек кіріс көзіңіз болуы мүмкін. Оларды міндетті түрде қосу керек. Депозиттен түсетін пайыз да табыс. Ал шығынның негізгісінен басқасын есептемесеңіз, ақша қайда кетіп жатқанын біле алмайсыз. Мысалы, тамаққа, жол мен дәрі-дәрмекке. Мұның бәрін кестеге қосу керек.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Бүкіл кірісім мен шығынымды жазам.',
                                        result: 'Иә! Ақшаға келгенде өзіңізге өтірік айтпаңыз. Сөйткенде ғана қаржылық жағдайыңызды дұрыс бағалай аласыз. Бюджет есептегенде мыналарды есептеңіз: — Міндет шығындар деген — қарыздар: несие, бөліп төлеу, ипотека, тағы басқа. — Керек шығындар — коммуналдық қызмет, пәтерақы, азық-түлік. Тіршілігіңізге маңызды нәрселер. — Болашаққа арналған шығындар — бюджетіңізге кіруге тиіс жиған-тергеніңіз. Біз дәл осы ақшаны жинауға әрі сақтауға ұмтылуымыз керек. — Күн сайынғы шығындар: жол, сырттан тамақ ішу, кофе, ойын-сауық, мейраманаға бару, сыйлықтар, тағы басқа.',
                                        correct: true,
                                    },
                                ],
                            },
                            {
                                id: 2,
                                question: 'Бір жылдың бюджетін есептеп шығарғым келеді. Қай айдың бюджетін мысал қылып алуым керек?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Кез келгенін',
                                        result: 'Болмайды. Сіз ай сайын бірдей сома жұмсамайсыз ғой. Бір айда мереке көп болады, бір айда ауырып, емге қомақты сома жұмсауыңыз мүмкін.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Шығыны ең көп айға сүйенем',
                                        result: 'Ең қиын айды мысал қылып алу оңтайлы шешім емес. Ондай күндерге дайын болу керек-ақ, десе де жоспар құрғанда пессимизм бізге тек кедергі болмақ. Сондықтан пессимист болғанша оптимист болайық.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Барлық айдың шығынына қарап есептеймін',
                                        result: 'Қай айдың шығыны көп екенін түсінсеңіз (мысалы, қарашада қысқы киім алу керек, ал желтоқсанда сыйлық аласыз, ал сәуірде шығын азайып, көбірек ақша жинауға болады), бір жылдың бюджетін есептеу оңай болады.',
                                        correct: true,
                                    },
                                ],
                            },
                        ]
                    },
                    {
                        id: 3,
                        number: 3,
                        name: 'Мақсат қоюды үйренесіз',
                        slug: 'maksat-koiudy-uirieniesiz',
                        duration: '20 мин',
                        bgImage: require('@/assets/images/lessons/lesson-3.png'),
                        content: {
                            shortDescription: "<h2>Мақсат: Карта, ақша, ипотека</h2>" +
                                "<p>Мақсат көздеуді әрі оған жетуді, сізге шынымен не керегін, ненің керегі жоғын түсінуді үйренеміз. Болашағыңызда қандай да бір абстракция болмауы керек.</p>",
                            shortList: "<h2>Не үйренесіз?</h2>\n" +
                                "<div><p>Алған білімды қаржыға қатысты мақсаттарға жету жолында қалай қолдану керегін;</p></div>\n" +
                                "<div><p>Қаржыға қатысты қандай мақсат барын, қайсы қажет екенін;</p></div>\n" +
                                "<div><p>Мақсатқа жетуге не кедергі екенін, тосқауылдардан қалай құтылу керегін;</p></div>\n" +
                                "<div><p>Діттегенге жету жолындағы прогресті қалай бағалау керегін, неде қателесуіміз мүмкін екенін.</p></div>",
                            description: "<h2>Қаржылық мақсат дегеніміз не?</h2>" +
                                "<p>Жыл сайын Forbes журналы әлемдегі ең бай адамдардың тізімін шығарады. Жефф Безос Илон Маскпен қайсымыздың ақшамыз көп деп жарысып жатқанда осындай күн қашан бұйырар екен деп отырғаннан басқа шара жоқ. Біз де солар сияқты болғымыз келеді: несие төлейтін күн жақындап қалды, пәтер ақысы, тамақ бағасы көтеріле ме деп алаңдамай, журнал бетіне шыққымыз-ақ келеді. Бірақ бай болу туралы қанша ақыл-кеңес алсақ алайық, бұл арманға жетелемейді. Оның себебі — мынау:</p>" +
                                "<p>Бай адам болу — арман. Абстракция, шексіз, қол жетпейтін нәрсе. Бай болғым келеді деген бір ғана ниет Швейцария банкінде ішінде миллион доллар жататын шот әпермейді, себебі ниетіңіздің мақсаты, жігері, нақты жоспары мен, шынын айтайық, әлдебір сипаттамасы жоқ.</p>" +
                                "<p>Бай болу деген не? Он, жүз миллион теңге ме? Пәтер не яхта алу ма? Әлде ел-жер кезу немесе білім алу ма? Қымбат киім мен әшекей бұйымдар ма? Әлде осының бәріне ие болғанда ғана бай бола аласыз ба? Енді осы жерде біз қаржылық мақсат деген не екенін түсінуіміз керек.</p>" +
                                "<p><strong><span class='green'>Қаржылық мақсат</span></strong> — ақшаның көмегімен қол жеткізгіңіз келген мақсат. Әр қаржылық мақсаттың құрамында мыналар болуға тиіс:</p>" +
                                "<ul>" +
                                "<li>Қалағаныңыз не?</li>" +
                                "<li>Ол қанша тұрады?</li>" +
                                "<li>Оған жету үшін қанша уақыт, қанша күш кетеді?</li>" +
                                "</ul>" +
                                "<p>Қауіпсіздік жастығы, зейнетақы қоры, үй не көлік алу, несие жабу қаржылық мақсатқа мысал бола алады.</p>" +
                                "<p>Қаржылық мақсат қоя білген өте маңызды. Осылай сіздің өміріңізде нақтылық пайда боалды. Мысалы, ақшаны ақша үшін емес, 7 миллион теңгенін Kia Rio-сын алу үшін жинау. Ол көлік неге керек? Балаңызды балабақшаға автобуспен емес, көлікпен апару үшін. Міне, сізде жігер, дедлайн, жоспар пайда болады.</p>" +
                                "<p>Егер ақшаңызды мақсат қоймай жинайтын болсаңыз, оны артығымен құртып қоюыңыз мүмкін немесе ойлағаннан аз жинайсыз немесе тіпті бірде-бір тілегіңізді орындай алмай қаласыз. Ал Илон Маск ары қарай күректеп ақша таба береді.</p>" +
                                "<h2>Мақсаттың қандай түрлері бар?</h2>" +
                                "<p>Қаржылық мақсатты санаттарға бөлу қажет. Ниет көп болуы мүмкін, сондықтан олардың әрбіріне қол жеткізу үшін уақытқа бөлуіңіз қажет. Бұлай істесеңіз, мақсатыңызды көз алдыңызға елестете аласыз, нақты қыласыз, соған қарап жоспар құрасыз. Мерзім белгілегенде болашағыңыз, не істейтініңіз нақтылана түседі, ал ісіңіз нәтижелі болады.</p>" +
                                "<p>Мақсаттың үш түрі бар:</p>" +
                                "<ul>" +
                                "<li>" +
                                "<h2>Қысқа мерзімді</h2>" +
                                "<p>Бұл — бірнеше ай ішінде (үш айдан бір жылға дейін) қол жеткізгіңіз келетін нәрсе. Ондай мақсатқа әдетте аз ақша кетеді. Мысалы, айфонның несиесін жабу, үй жөндеу, жиһаз алу. Көк диван немесе ыңғайлы кресло алсам екен деп армандап жүргеніңізге біраз болды ма? Қысқа мерзімді мақсаттың бір жақсысы — оған аз уақыт пен аз ақша қажет. Осы ерекшелік сізге сенім сыйлайды. Себебі ұзақ мерзімді мақсатқа жету үшін көбірек ақша мен уақыт керек.</p>" +
                                "</li>" +
                                "<li>" +
                                "<h2>Орташа мерзімді мақсат</h2>" +
                                "<p>Қысқа мерзімді мақсатқа максимум бір жылда жетуге болады, ал ұзақ мерзімді мақсатқа шамамен ондаған жыл кетеді. Ал орташа мерзімді мақсат бір жылдан он жылға дейінгі уақыт алатын нәрсе. Айтпақшы, кейде ұзақ мерзімді мақсатқа жетуді үйренем десеңіз, әуелі орташа мерзімдісін аяқтауыңыз керек. Мәселен, пәтердің бірінші жарнасына ақша жинау. Пәтер — ұзақ мерзімді мақсат, ал бірінші жарна — орташа мерзімді, көп ақша қажет етпейді. Осылай сіз прогресіңізге орташа мерзімді мақсатқа жетіп-жетпегеніңізге қарап баға бере аласыз. Бірақ кейде орташа мерзімді мақсат ұзақ мерзімді мақсатқа қатысты болмауы мүмкін. Мысалы, көлік алу немесе балаңыздың бірінші жылғы шығына ақша жинау.</p>" +
                                "</li>" +
                                "<li>" +
                                "<h2>Ұзақ мерзімді мақсат</h2>" +
                                "<p>Ондай мақсатқа көп ақша кетеді, оны жан-жақты ойластырып алуыңыз керек. Зейнетке шығу, үй не пәтер алу, балаңыздың жоғары оқуына қаржы беру — ұзақ мерзімді мақсатқа мысал. Ондай мақсаттарды жоспарлаған өте қиын, себебі мерзімі ұзақ, шығыны көп. Қанша кететінін ойлаудың өзі үрей туғызады. Себебі АҚШ-тың күшті деген университетінде бала оқытуға жылына 50 мың доллардан аса қаражат кетеді.</p>" +
                                "<p>Оған өте оңай мысал келтірейік. Бәріміз ерте ме, кеш пе зейнетке шығамыз. Оған ештеңе ете алмаймыз. Бірақ қашан, қалай шығатыныңыз, айына қанша алатынымызды жоспарлай аламыз. Зейнетке шыққаннан кейін айына 500 мыңдай теңге жұмсаңығыңыз келеді делік, ондай соманы шамамен 30 жыл бойы алып отырғыңыз келеді. 500 мың теңге = жылына 6 миллион теңге = 30 жылда 180 миллион теңге. Қомақты сома болып көрінеді, шынымен солай да. Бірақ! Зейнетке дейін әлі 34 жыл бар. Егер 34 жыл бойы зейнетке ай сайын 20 мың теңгеден ақша бөліп отырсаңыз, қазіргі мөлшерлемені ескергенде 259 миллиондай теңге жинайсыз. Жалпы айтқанда жоспар осындай, қате де болуы мүмкін. Себебі мөлшерлеме өзгеруі мүмкін, инфляцияны да ұмытпайық, бірақ бұл қалай мақсат көздеу керегін: сомасы қанша болатынын, қанша уақыт кететінін, қалай қол жеткізуге болатынын көрсететін мысал.</p>" +
                                "</li>" +
                                "</ul>" +
                                "<p>Бұлай категорияға бөлу өміріңізді нақтылай түседі. Бірақ бұл істің басы ғана. Енді оған жету жолдарын қарастыру қажет.</p>" +
                                "<h2>Мұратымызға қалай жетеміз?</h2>" +
                                "<p>Мақсатқа қанша кететінін есептеп, уақытқа бөлеміз. Шыққан қорытындыға қарап мақсатқа шынымен қол жеткізе аласыз ба, жоқ па, түсініп аламыз, жоспар құрамыз. Бұған арналған формула бар. Оны қаржы саласындағы ғана емес, жеке қарым-қатынасыңызда да қолдануға болады, яғни формулаға сай әрекет етсеңіз, ұтарыңыз мол болады.</p>" +
                                "<p>Сонымен жағдай былай: сіздің арманыңыз SMART болуы керек. Бұл біз білетін смарт емес, бес сөздің бас әрпінен құралған сөз: нақты (Specific), өлшенетін (Measurable), қол жететін (Achievable), өзекті (Relevant) және мерзімді (Time-bound). Қауіпсіздік жастығын жасағымыз келеді делік. Оның жолы SMART формуласын қолдансақ былай болмақ:</p>" +
                                "<p><strong><span class='green'>Нақты</span></strong></p>" +
                                "<p>Мен қауіпсіздік жастығын жасағым келеді, оған мен үш ай қысылмай тіршілік еткім келеді. Кестеге сүйенсем, айына мен 400 мың теңге жұмсап жүрмін.</p>" +
                                "<p>Демек менің жастығымда 1 200 000 теңге болуы керек.</p>" +
                                "<p><strong><span class='green'>Өлшенетін</span></strong></p>" +
                                "<p>Мен бұл соманың жартысын, яғни 600 000 теңгені жарты жылда жинағым келеді. Бұл дегеніміз айына 100 000 теңге немесе күніне 3 400 сақтап отыруым керек деген сөз.</p>" +
                                "<p><strong><span class='green'>Қол жететін</span></strong></p>" +
                                "<p>Ай сайын мен 80 000 теңге алып қаламын, яғни 100 000 теңге жинайтын халім жоқ. Бірақ бюджетті шолғаннан кейін алып тастауға болатын немесе үнемдейтін шығындар бар екен, соның арқасында мен айына 100 000 теңге жинай аламын. Осы мақсат — қол жететін мақсат.</p>" +
                                "<p><strong><span class='green'>Өзекті</span></strong></p>" +
                                "<p>Бұл аса маңызды пункт. Мұнда біз мақсат қоярда мұқият болуымыз керек. Өзіңізден сұраңыз, бұл мақсат сізді бақытты ете ме? Егер бұдан тұрмысыңыздың күйі мен деңгейі жақсарса, мақсат ұмтылуға тұрарлық. Ал бір минуттық ләззат беретін болса, ұзаққа созылмайтын болса, тағы бір ойланыңыз. Ол жастықты жинауға әліңіз келе ме? Біз айтқан жастық қаржылық тәуелсіздікке жетелейтін маңызды пункт. Тым болмағанда оның минимал сомасын жинасаңыз, психологиялық тұрғыдан өміріңіз жеңілдей түседі. Себебі сіз қадағалай алмайтын сыртқы факторлар өміріңізге әсер ете алмайды. Жастық сіздің арқа сүйер көмекшіңіз болады. Банкке, достарыңызға не ата-анаңызға ақша сұрап бармаймыз. Күшті емес пе? Сондықтан оған қол жеткізу үшін іске кірісу керек.</p>" +
                                "<p><strong><span class='green'>Мерзім</span></strong></p>" +
                                "<p>Бір жыл ішінде жастыққа 1 200 000 теңге салып, басқа қаржылық мақсаттарыма жету үшін жұмыстанғым келеді. Жыл сайын қаржылық мақсаттар үшін қорымды ұлғайта бергім келеді.</p>" +
                                "<p>Әрине, бірнеше мақсат қоюыңыз мүмкін. 5, 10, кете береді. Бірақ оларға жаңа көрсеткен формулаға сай баға берсеңіз, мүмкіндіктеріңіз бен әліңізді сыни тұрғыдан бағалай аласыз. Кейбірі артқы планға кетуі ғажап емес, кейбірінен мүлдем бас тартасыз, ал кейбіріне көп көңіл бөлесіз. Жоспар деген осы.</p>" +
                                "<h2>Қай мақсаттан бастау қажет?</h2>" +
                                "<p>Ешкім мына мақсат маңызды, ана мақсат маңызды емес деп айта алмайды. Әркімнің жағдайы әртүрлі, өзгеге кеңес болған ақыл сізге кеңес бола бермейді ғой. Бірақ әмбебап бірнеше мақсат бар. Олар баршаның игілігіне жарайды, жарамаса да, кедергі келтірмейді.</p>" +
                                "<p>1. <strong><span class='green'>Қауіпсіздік жастығы</span></strong></p>" +
                                "<p>Резерв қор, қауіпсіздік жастығы немесе қаржы жастық — қаржылық тәуелсіздік әперер маңызды қаржы құралы. Жалпы тілмен айтқанда бұл аяқ астынан болатын жағдайда, кезеңде қолдануға болатын сома. Оны пандемия кезінде, жұмыстан шығып қалғанда, жазатайым жағдай болғанда жұмсай аласыз.</p>" +
                                "<p>Есіңізде ме, пандемия басталғанда көбі жұмыстан шығып қалды. Дәл статистика келтірер болсақ, 4 миллион 200 мың Қазақстан азаматы 2020 жылғы наурыздан сәуірге дейін жұмыссыз отырды. Жұмыс жоқта несиені, бөліп төлеуді, ипотеканы кім төлейді. Мемлекет көмектесіп бақты: жәрдемақы берді, несие мерзімін ұзартты, бірақ төрт миллионды күйзеліске тыққан жағдайды жағымды деп айта алмайсың. Төлем төлейтін уақыт жақындай түсті, бірақ жұмыс табылған жоқ.</p>" +
                                "<p>Қауіпсіздік жастығыңын екі түрі бар: минимал және тұрақты. Минимал жастықтың сомасы — үш айдың шығыны, ал тұрақты түрі екі минимал жастықтан құралған жастық, яғни алты айдын шығыны. 12 айдың шығынына жететін сома жинауға болады, бірақ ол оңтайлы тәсіл емес. Себебі біздің басқа да қаржылық мақсаттарымыз бар, оларға да жету үшін әрекет етуіміз керек. Бір мақсатқа байлансаңыз, өзгесіне күш-қуатыңыз, жігеріңіз қалмайды.</p>" +
                                "<p>Бұл шығын деп отырғанымыз: міндет болған төлемдер — несие, қарыз, пәтерақы, тамақ, транспорт, т.б. Бұл шығынды азайтуға тырыспаңыз.</p>" +
                                "<p>Жастықты қайда сақтаймыз? Ол да маңызды мәселе. Қорыңызды сеніп тапсыратын құрал мынандай болуы керек: қауіпсіз, өтімді (ақшаңызды тез ала алуыңыз керек), инфляцияны тым болмағанда ішінара өтей алуы керек (себебі жиған ақшаңыз өскен бағаның кесірінен түк болмай қалмасын).</p>" +
                                "<p>Біз кеңес беретін тәсілдер:</p>" +
                                "<ol>" +
                                "<li>Қолма-қол ақша — ең өтімді тәсіл, ақшаңыз қолыңызда деген сөз. Қолма-қол ақша сән болудан қалып барады, бірақ мобайл байланыс не интернет жоқта сізге көмек болады. Бірақ бір-екі кемшілігі бар — өте қауіпсіз деп айта алмаймыз (біреу ұрлап кетуі мүмкін), уақыт өте келе құнынан айырылады. Алайда қиын-қыстау күнде бірінші көмек болатынын ескерсек, қағаз ақша — керек құрал.</li>" +
                                "<li>Депозит — қауіпсіз құрал, инфляциядан зардап шекпеуге көмектеседі, бірақ қолма-қол ақшаға қарағанда өтімсіз.</li>" +
                                "</ol>" +
                                "<p><i>(5-сабақта әр құралды жеке-жеке қарастырамыз. Артышылығы мен кемшілігін айтамыз).</i></p>" +
                                "<p>Осы айтқан тәсілдердің бәрін пайдаланғаныңыз жөн. Жиған ақшаның 30%-ы қолма-қол болсын, қалғанын депозитке салыңыз. Осылай интернет істемей қалмай ма, инфляция пайызды жеп қоймай ма деген жағдайлардан сақтанасыз. Жастық бірінші мақсат болғаны жақсы. Біріншіден, ол — келесі мақсаттарға жету үшін күш, сенім беретін ең маңызды мақсат. Екіншіден, тұрақсыз сыртқы әлем бізді бұрынғыдай көп билеп-төстей алмайды.</p>" +
                                "<p>2. <strong><span class='green'>Екінші мақсат — несиеңізді жабу</span></strong></p>" +
                                "<p>Мұны барлық қаржы сарапшысы айтар еді. Қорқынышты, орындалмас сияқты боп көрінеді, бірақ қаржылық жағдайыңызды жақсартып алсаңыз, несиелеріңізден біртіндеп құтыла берген абзал. Оған мыналар себеп:</p>" +
                                "<ol>" +
                                "<li>" +
                                "<p>Несиені қаншалықты ертерек жапсаңыз, пайызы соншалықты азаяды. Мөлшерлеме жоғары болса, артық төлем соншалықты жоғары болады.</p>" +
                                "<p>Несиеге 1 500 000 теңге алдыңыз делік, оны үй техникасына құртқыңыз келеді. Айына 94 900 теңге төлеп отырайын деп мерзімін екі жыл қылдыңыз. Техника үйіңізде, ай сайынғы төлемді төлей аласыз, ал екі жыл көзді ашып жұмғанша өте шығады. Дұрыс боп көрінеді. Бірақ мерзімнің кесірінен қанша артық ақша төлейтініңізді білесіз бе? 800 000 теңге, шамамен несиеңіздің жартысы. Оған себеп — пайыз, яғни сіз 1,5 миллион теңге алдыңыз, бірақ берешегіңіз 2,3 миллион теңге. Міне, несиеңізді осы себепті ертерек жабуыңыз қажет: пайызы аз шығады.</p>" +
                                "</li>" +
                                "<li>Төлейтін күнді өткізіп ала берсеңіз, несие үлкен мақсаттарға жетуге кедергі болады. Несие тарихыңызға дақ түссе, банк сізге ипотекаға не көлік алуға несие бермейді. Ал бұл сіздің тұрмысыңызды жақсартар активтер. Несие, қарызыңыз көп деп ипотека бермейміз десе де жаман, себебі сіз көп несие алдыңыз, ал банк көтере алмайды деп сізге несие бермей қояды.</li>" +
                                "<li>Сондай-ақ несие бюджетіңізге салмақ түсіреді. Өткен сабақтағы кестеден міндет төлемдерге табысыңыздың көп бөлігі кететінін байқаған шығарсыз. Ай сайын беріп отырған, банктің қалтасына кетіп жатқан қомақты қаражатты өзіңізге жұмсауға болар еді. Осы ауыртпашылықтан құтылған бойда ол ақшаны басқа қаржылық мақсаттарға жұмсай аласыз, яғни тұрмысыңыздың деңгейін арттыра аласыз.</li>" +
                                "</ol>" +
                                "<p>Көріп тұрғаныңыздай, несие төлеген тиімсіз. Әсіресе пайызы жоғары болса.</p>" +
                                "<p><i>(Несие ақысын қалай төлеу керегін ендігі сабақтарда егжей-тегжейлі айтамыз)</i></p>" +
                                "<p>Ал әзірге мына кеңеске құлақ түріңіз — әр несиеңізді саралаңыз. Ипотека немесе көлік алуға несие алу жақсы шешіп көрінуі мүмкін, себебі оған актив аласыз. Ол актив тұрмысыңызды жақсартады, шығыныңыз аязады, тіпті болашақта табыс әкелуі мүмкін. Ұсақ-түйек сатып алуға несие алмағаныңыз жөн. Мұндай несиелер көбіне жоғары пайызды болады. Ол зат уақыт өте келе құны төмендейді. Дұрысы, затты алу үшін қысқа мерзімді мақсат қойып, ақша жинап барып алған жөн.</p>" +
                                "<p>Несиені жабу және қауіпсіздік жастығын жасау. Қайсына басымдық беру қажет? Бір жағынан қарасақ, күтпеген жағдайлар сізді одан да көп қарызға батыруы мүмкін. Ал екінші жағынан қарасақ, жастықта ақша жиналғанын күткен қымбатқа шығады. Несиенің пайызы жиған ақшаңызды жей береді.</p>" +
                                "<p>Әрине, ең дұрысы — екеуін де істеу, бірақ екінің бірінің ондай мүмкіндігі жоқ. Онда жағдайыңызға баға беріңіз.</p>" +
                                "<ul>" +
                                "<li>Табысыңыз сізге қаншалықты сенім, тыныштық сыйлайды?</li>" +
                                "<li>Басқа жиған-тергеніңіз бар ма?</li>" +
                                "<li>Қарызыңыз көп пе?</li>" +
                                "<li>Қаржылай көмектесер адам бар ма?</li>" +
                                "</ul>" +
                                "<p>Жауаптарды тізгеннен кейін күш-қуатыңыз бен уақытыңызды қай арнаға бұруыңыз керегін түсіне аласыз.</p>" +
                                "<h2>Соңғы қадам. Бәрін есеп-қисапға жазып жүрейік.</h2>" +
                                "<p>Барлық шығынды есте сақтап жүре алмайсыз: тиімсіз шаруа. Егер мақсатқа жетем деп бел бусаңыз, тапсырмаңызды мұқият орындауыңыз керек.</p>" +
                                "<ol>" +
                                "<li>Осы өмірде жеткіңіз келген мақсат-мұратыңызды тізіңіз. Ұялмаңыз, қолымнан келмейді, қол жеткізе алмаймын деп қорықпаңыз. Мейлі, көп болсын. Орындауға дайынмын десеңіз, неге жазбасқа?</li>" +
                                "<li>SMART формуласын қолданыңыз.</li>" +
                                "<li>Қалауларыңызға басымдық беріп, тізімді ретке келтіріңіз. Жаңа айтқанымыздай, қазір не ауадай қажет болып тұрғанын, неге қол жеткізіуге болатынын түсінуіңіз қажет. Аса маңызды емес мақсаттарыңызды кейінге қалдырыңыз.</li>" +
                                "<li>Тез орындай аламын деген мақсатты таңдаңыз. Іске кірісетін күнді, сома мен мақсатқа жетуіңіз керек күнді белгілеңіз.</li>" +
                                "<li>Енді мұны <a href='https://docs.google.com/spreadsheets/d/1Ms0RdUXuLOt1TRzTbjobODS7-0OCSMruj_aoJdg90lI/copy' target='_blank'>кестеге түсірейік.</a></li>" +
                                "<img src='"+require('@/assets/images/visuals/kz/img-3.png')+"'/>\n" +
                                "</ol>" +
                                "<p>Өзіңіз үшін жаңа ғана белгілеген деректерді енгізіңіз:</p>" +
                                "<ul>" +
                                "<li>аяқталу мерзімі — сіз осы мақсатқа қол жеткізгіңіз келгенде</li>" +
                                "<li>оның сомасы</li>" +
                                "<li>және қандай құралды қандай кірістілікпен қолданасыз (алда қаржы құралдары бойынша сабақ, күмәндансаңыз, оны оқып шығыңыз)</li>" +
                                "</ul>" +
                                "<p>Соманы, мерзімді және мөлшерлемені көрсеткеннен кейін кесте сізге ай сайынғы төлемді береді. Дәл осы уақытта және ағымдағы кірістілікте мақсатқа жету үшін ай сайын қанша ақша жинау керек.</p>" +
                                "<p>Төмендегі кестелерде сіз әр мақсаттың жетістіктерін өзіңіз үшін бөлек жаза бастай аласыз. Мотивацияның керемет тәсілі-бұл сәттілікті бағалауға болатын кейбір белгілерді қою. Мысалы, жоспарға сәйкес қазан айына қарай 600 000 теңге шығуы тиіс. Содан кейін қазан айында кестеге оралып, бәрі сіздің жоспарыңызға сәйкес келетінін тексеріңіз.</p>" +
                                "<p>Кестеде айырмашылықтар көрінеді, бұл сізге жағдайға бейімделуге мүмкіндік береді. Егер сіз кезеңдердің біріне аз жинасаңыз, ай сайынғы төлемді көбейту немесе жинақтаудың соңғы мерзімін ұзарту туралы шешім қабылдауға тура келеді.</p>" +
                                "<p>Енді іске кіресеміз. Ай сайын қанша жинағаныңызды жазып отырыңыз. Жоспарға сай жинап отырсаңыз, тіпті керемет болады. Бірақ кейде аз, кейде артық жинауыңыз мүмкін. Кестеге қарап, қай жерде ағат кеткенін біліңіз. Егер бір кезеңге дейін аз жинасаңыз, шешімге келуіңіз керек. Не ай сайынғы соманы арттырасыз, не мерзімді ұзартасыз.</p>" +
                                "<p>Қалаған сома өзгергенде де осындай шешім қабылдауға тура келеді. Сіздің есептеуіңізше, үй жөндеуге 6 000 000 теңге қажет. Бірақ құрылыс материалдарының бағасы күрт өсуі мүмкін, демек 6 миллион емес, 8 миллион теңге қажет.</p>" +
                                "<p>Бірақ өзгерістерден сескенбеңіз. Бұл — өмір, ал қаражат — соның бір бөлігі, ол да өмір сияқты өзгеріп отырады. Бастысы, мұндай өзгерістерді еңсеру үшін амал-жоспарыңыз дайын тұрады.</p>"
                        },
                        dictionary: [
                            {
                                id: 1,
                                name: 'Қаржылық мақсат',
                                description: "ақшаның көмегімен жеткізіңіз келген мақсат. Әр қаржылық мақсаттың құрамында мыналар болуға тиіс:" +
                                    "<ul>" +
                                    "<li>Қалағаныңыз не?</li>" +
                                    "<li>Ол қанша тұрады?</li>" +
                                    "<li>Оған жету үшін қанша уақыт кетеді?</li>" +
                                    "</ul>" +
                                    "Қауіпсіздік жастығы, зейнетақы қоры, үй не көлік алу, несие жабу қаржылық мақсатқа мысал бола алады."
                            },
                            {
                                id: 2,
                                name: 'SMART',
                                description: 'мақсат қоярда қолданылатын формула.'
                            },
                            {
                                id: 3,
                                name: 'Қысқа мерзімді мақсат',
                                description: 'бірнеше ай ішінде (үш айдан бір жылға дейін) қол жеткізгіңіз келетін нәрсе. Ондай мақсатқа әдетте аз ақша кетеді. Мысалы, айфонның несиесін жабу, үй жөндеу, жиһаз алу.'
                            },
                            {
                                id: 4,
                                name: 'Орташа мерзімді мақсат',
                                description: 'Қысқа мерзімді мақсатқа максимум бір жылда жетуге болады, ал ұзақ мерзімді мақсатқа шамамен ондаған жыл кетеді. Ал орташа мерзімді мақсат бір жылдан он жылға дейінгі уақыт алатын нәрсе.'
                            },
                            {
                                id: 5,
                                name: 'Ұзақ мерзімді мақсат',
                                description: 'Ондай мақсатқа көп ақша кетеді, оны жан-жақты ойластырып алуыңыз керек. Зейнетке шығу, үй не пәтер алу, балаңыздың жоғары оқуына қаржы беру — ұзақ мерзімді мақсатқа мысал. Ондай мақсаттарды жоспарлаған өте қиын, себебі мерзімі ұзақ, шығыны көп.'
                            },
                        ],
                        tests: [
                            {
                                id: 1,
                                question: 'Қалай мақсат қоямыз?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Әр мақсатқа SMART жүйесіне қарай басымдық береміз',
                                        result: 'Иә, бұл өте дұрыс шешім. Бұл амал мақсатқа салғырт қарамауға, оның қолжетімділігі мен маңызын анықтауға көмектеседі.',
                                        correct: true,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Ай сайын қанша жинап отыру керегін есептеу және ақша жинай бастау',
                                        result: 'Бұл да дұрыс шешім, бірақ бір өзі жеткіліксіз болуы мүмкін. Өзге факторларды да ескерген маңызды. Мысалы, мыналарды: бюджетке түсетін салмақ, депозиттен түсетін пайда (бар болса), инфляция, тағы басқа.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Міндеттерден құтылғаннан кейін ғана мақсат қою',
                                        result: 'Міндет мақсатқа жетуге үнемі кедергі бола бермейді. Кедергі болса да, онысы уақытша ғана. Жоспарыңыз нақты болса, оны тиянақты орындасаңыз, міндетіңізден қашан құтыларыңызды, қашан мақсатқа жете бастайтыныңызды түсінесіз.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 2,
                                question: 'Мақсат қояр алдында қандай мақсаттарға қол жеткізіп алу керек?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Қауіпсіздік жастығын жинау',
                                        result: 'Жастық — жақсы бірінші мақсат. Біріншіден, басқа мақсаттарды орындау үшін күшті құрал, старт десек болады. Екіншіден, тұрақсыз мына әлемде бірдеңе бола қалса, көмекке келер қауіпсіздік жастығыңыз болады.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Несиені жабып, қауіпсіздік жастығына ақша жинау',
                                        result: 'Бәрін алып жүру — жақсы вариант, бірақ мұндай мүмкіндік үнемі туа бермейді. Ондайда жағдайыңызға бара беруіңіз керек.',
                                        correct: true,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Несие жабу',
                                        result: 'Бұл да жақсы шешім. Несиені қаншалықты ертерек жапсаңыз, пайызы соншалықты аз болады. Байқасаңыз, пайызы көп несиенің артық төленетін сомасы сонша көп болады.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Одан да көп ақша табуға көмектесетін затты бөліп төлеп алу',
                                        result: 'Мұндайда абай болуыңыз керек. Алар алдында өзіңізге сұрақ қойыңыз: бұл табыс көзі қаншалықты тұрақты болмақ? Ұзақ уақыт тұрақты табыс береріне сенімді болсаңыз, мұндай қарыз алудың жөні бар.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 3,
                                question: 'Мақсатқа қол жеткізу үшін мерзім неге нақты болуы керек?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Ай сайынғы жинақ қанша болуы керегін түсіну үшін',
                                        result: 'Мақсат таңдағаннан кейін, оған қажет соманы белгілегеннен соң оны күн/ай/жылға бөлуіңіз керек. Сөйтіп, бір айда не бір жылда қанша ақша жинауыңыз керегін анықтайсыз.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Инфляция деңгейін болжау үшін',
                                        result: 'Әрине, инфляцияны болжау өте қиын. Бұған сарапшылардың ғана әлі келеді, бірақ кейде оның өзінде болжамдар орындалмай жатады. Алайда қате болса да болжауға болады. Ол үшін мемлекеттік статистикалар сайтына кіріп, сарапшылардың пікірін оқыңыз, орташа пайызын ескеруге тырысыңыз.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Пассив табысты есептеу үшін әрі осы көрсеткіштерді ескеру үшін. Мысалы, депозиттен түсетін пайданы.',
                                        result: 'Инфляция болжаған қиын шаруа болса, мында керісінше жалпы қанша ақшаңыз болатынын біле аласыз.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Барлық нұсқа дұрыс',
                                        result: 'Мерзім белгілегенде мақсатыңыз нақтылана түседі, бір бағыт пайда болады. Сөйтіп ісіңіз алға басады, нәтижелі болады.',
                                        correct: true,
                                    }
                                ],
                            }
                        ],
                    },
                    {
                        id: 4,
                        number: 4,
                        name: 'Несие алуды, оны дұрыс жабуды үйренесіз',
                        slug: 'niesiie-aludy-ony-durys-zhabudy-uirieniesiz',
                        duration: '34 мин',
                        bgImage: require('@/assets/images/lessons/lesson-4.png'),
                        content: {
                            shortDescription: "<h2>Несие алуды, жабуды үйренеміз</h2>\n" +
                                "<p>Бұл курсымыздың ең үлкен сабағы болмақ. Мұнда біз көп қазақстандықтың үрейіне айналған нәрсені, яғни несиені талқылаймыз: несие не екенін әрі оны мақсатқа жету жолында қалай қолдану керегін түсіндіреміз.</p>" +
                                "<p>Көлемді ақпарат оқуға ыңғайлы болсын деп оны үш бөлікке бөлдік: банктен қалай қарыз алу керек, одан құтылудың тиімді жолдары, несие тарихының сізге не қажеті бар.</p>",
                            shortList: "<h2>Сабақта не өтеміз?</h2>\n" +
                                "<div><p>Неліктен несиесі бар адамның қаржылық сауаты өлсем де несие алмаймын деген адамның қаржылық сауатына қарағанда жоғары болатынын түсіндіреміз.</p></div>\n" +
                                "<div><p>Банктердердің қандай ұсынысы барын және өтінім бергенде неге мән беру керегін айтамыз.</p></div>\n" +
                                "<div><p>Кәдімгі мысалдарға қарап, қандай жағдайда несие алуға болатынын, ал қандай жағдайда керегі жоғын түсіндіреміз.</p></div>" +
                                "<div><p>Несие тарихы не екенін аңғарасыз, өзіңізге банк тарапынан қарап көресіз.</p></div>" +
                                "<div><p>Несие жабудың оңай тұстарын үйренесіз, жаба алмай не төлем төлей алмай жатсаңыз, кімнен көмек сұрау керегін түсіндіреміз.</p></div>",
                            description: "<p><strong><span class='green'>Қазақстанда екінің бірінің несиесі бар</span></strong></p>" +
                                "<p>Бірінші несие бюросына сенсек, еліміздің экономикалық белсенді халқының 77 пайызы (9,2 миллион адамның 7,2 миллионы) несие өтейді. Оның 25 пайызы (1,8 адам) төлем төлеуді кешіріктіреді екен.</p>" +
                                "<p>Жағдай осындай болса да, қазіргі қаржылық сауат туралы курстардың қосылып айтатыны — несиеден құтылсаңыз ғана тұрмысыңыз жақсара түседі. Бірақ статистикаға қарасақ, несие — қазақстандықтардың сұранысына ие құрал.</p>" +
                                "<p>Ипотекасы бар, айфонын несиеге алған және бизнесі бар адамның қаржылық сауаты несиенің барынан жоғы жақсы деп жүрген адамдікінен жоғары болуы мүмкін.</p>" +
                                "<blockquote>" +
                                "<p>Ойланып көріңіз, сіздің жұрт сияқты жұмыс істеп жүрген әрі жұрт сияқты ақша тауып жүрген досыңыз не танысыңыз бар шығар. Бірақ оның өзі алған пәтері бар, кеше көлік алуға алған несиесін мерзімінен бұрын жапты. Алайда мұнысы тұрмысына ешқандай зиян келтірмеді. Сіз де солай өмір сүріп жатсаңыз, неге сізде де ондай ақша жоқ: тапқан табысыңыз айдың басында құрытылып кетеді, қайда кетіп жатқанын түсінбей дал боласыз, ай аяғында қалған ақшаны үнемдеп, жалақы қашан түсер екен деп отырасыз.</p>" +
                                "</blockquote>" +
                                "<p><strong><span class='green'>Күнделікті жағдайыңызды сипаттап бердік пе? Жауабыңыз иә болса, бұған не себеп екенін білейік.</span></strong></p>" +
                                "<p>Бір жағынан қарасақ, қарыз алғандар мен коллекторлар туралы қорқынышты хикаялар басыма келмесін деп бірнеше күшті мүмкіндіктен құр қалуыңыз мүмкін. Мысалы, аз пайызды ипотека, көлік алуға берілетін жеңілдік несие. Өзге жағынан қарасақ, ойланбай тиімсіз несие ала берсеңіз де жаман.</p>" +
                                "<p>Осылай екі оттың арасында қаласыз. Мұның себебі — пайдалы ақпарат пен жөн сілтер нұсқаулықтың жоғы. Ең үлкен сабағымызда осы мәселені шешуге тырысамыз. Несие жақсы да емес, жаман да емес. Оны қаржылық тәсіл ретінде қолдана білу керек.</p>" +
                                "<h2>Несие аламыз</h2>" +
                                "<p>Несиені кім береді: банктер, микроқаржы және микронесие ұйымдары, ломбардтар, туыстар мен достар. Әрқайсының өз ережелері бар: тым ауыр шарттар, үш есе артық төленетін сома, стресс, бұзылып тынған қарым-қатынас.</p>" +
                                "<p>Біз несиелердің ең танымал әрі қауіпсіз түрін — банк несиелерін талқылаймыз.</p>" +
                                "<p>Барлық несиені екі санатқа бөлуге болады: <strong><span class='green'>мақсат үшін алынған несие</span></strong> — ипотека, көлікке берілетін несие не бизнес керек-жарағын алу үшін алынған несие мен <strong><span class='green'>мақсатсыз алынған несие:</span></strong> қарыз алған адам көңіліне қарай жұмсайтын ақша.</p>" +
                                "<p>Біз көбіне мақсатсыз алынған несиелер туралы айтпақпыз, себебі олар — несиенің ең танымалы әрі ең тиімсізі.</p>" +
                                "<p>Сырт көзге мұндай несиені алу оңай көрінеді: өтінім бір минутта қаралады, кепіл бермейсіз, ақшаны дереу аласыз. Бірақ біз мұның артында тұрған көп пайыз бен артық төленетін соманы көрмейміз.</p>" +
                                "<blockquote>" +
                                "<p>Шын өмірден алынған мысал: әлгі танымал банктен алған 1 миллион теңгеңізді кейін пайыздың кесірінен 1 миллион + 1 177 949 теңге қылып қайтарасыз.  Төрт жылға алсаңыз, негізгі қарызға екі миллион теңге қосылады. Ең оңайы — қажет соманы жинаңыз. Несиеге алсаңыз, ай сайынғы төлем ақша жинауға ғана емес, мерзімнен бұрын төлеймін деген жоспарыңызға кедергі келтіреді.</p>" +
                                "</blockquote>" +
                                "<p>Екі ситуацияны қарастырайық:</p>" +
                                "<p>1. <strong><span class='green'>Банктен несие алдыңыз</span></strong></p>" +
                                "<p>Алған бір миллион теңгеге қонақ бөлмеге жаңа диван алдыңыз, кейін үй ішіңізбен бірге Түркияға барып келдіңіз. Ендігі міндетіңіз мынандай болмақ:</p>" +
                                "<ul>" +
                                "<li>43 375 мың теңгеңіз ай сайын несиенің бір бөлігін өтеуге кетеді;</li>" +
                                "<li>несиенің ақысын төрт жыл төлейсіз.</li>" +
                                "</ul>" +
                                "<p>2. <strong><span class='green'>1 миллион теңге жинаймын деп шештіңіз</span></strong></p>" +
                                "<p>Бірақ әзірге диванды ала алмайсыз, Түркияға бара алмайсыз. Енді оның ақшасын жинайсыз. Әрекетіңіз мынандай болады:</p>" +
                                "<ul>" +
                                "<li>ай сайын 45 375 теңгені депозитке салып отырасыз;</li>" +
                                "<li>диванның ақшасы жарты жылда, саяхаттың ақшасы бір жылдан кейін жиналады.</li>" +
                                "</ul>" +
                                "<p>Бұл есептегеніміз қате болуы мүмкін. Өйткені бұдан көп ақша жинауыңыз не жұмыста сыйақы алуыңыз мүмкін. Осылай ақша қорыңыз ұлғаяды. Уақыт өте келе Түркияға бармаймын деуіңіз мүмкін немесе диванның бағасы төмендеуі ғажап емес. Қайткенде де мұндай мақсат үшін алынатын несиенің бізге қажеті жоқ. Несие алсаңыз, 300 мыңның диванын 700 мыңға аласыз, ал бір апталық сапарыңыз ақысы төленіп болмайтын несиеге айналады.</p>" +
                                "<p>Несиені мақсат үшін алсаңыз, ол жағы басқа шаруа. Иә, ол несиенің басқа несиелерден айырмасы жоқ. Алайда пайызы аз болады. Қолма-қол бір миллион теңгенің пайызы — 45%, ал, мысалы, көлік алуға берілетін жеңілдік несиенің пайыздық мөлшерлемесі — жылына  4%.</p>" +
                                "<h2>Қандай жағдайда несие алған тиімді, ал қандай жағдайда алмаған дұрыс?</h2>" +
                                "<p>Несие жаман нәрсе емес. Ақша аспаннан түспегенін, оны қарызға алғаныңызды, кейін еселеп қайтаратыныңызды есіңізге салып отырсаңыз, несие деген жақсы құрал. Банк несиесін алар алдында біраз ойланыңыз. Қарыз сізге шынымен керек боп тұр ма? Иә мә, жоқ па? Бұл жауаптың жауабын мына мысалдар береді.</p>" +
                                "<p><strong><span class='green'>Иә: жуық арада мақсатқа жету керек, бірақ оған (аса) көп ақша қажет</span></strong></p>" +
                                "<p>Қажет, бірақ қымбат дүниеге ақша жинау қиын әрі кейде қате шешім. Мысалы, жылжымайтын мүлікті тиімді пайызды ипотекаға алған дұрыс. Иә, банкке пайыз төлейсіз, бірақ ипотеканы жапқанда артық төлеген сома инфляцияның кесірінен шығынмен шамалас не тіпті аз болып шығуы мүмкін.</p>" +
                                "<blockquote>" +
                                "<p>Шын мысалға қарайық: Қазақстанда су жаңа үйдің бір шаршы метрі 2007 жылы 159 мың тұратын, 2022 жылы сома 386 мың теңгеге дейін көтерілген. Демек 2007 жылы 45 шаршы метр үшін 7 миллион теңге, ал 2022 жылы 17 миллион теңге төлер едіңіз.</p>" +
                                "</blockquote>" +
                                "<p>Жылжымайтын мүліктің бағасы күннен күнге өсіп жатыр, ал ипотека алсаңыз:</p>" +
                                "<ol>" +
                                "<li>Пәтерге кететін сома ұзақ жылдар бойы өзгермейді;</li>" +
                                "<li>Инфляция жиған-тергеніңізге кедергі болмайды;</li>" +
                                "<li>15 жылда табысыңыз артуы ғажап емес, ал пәтерге кететін шығын сол күйі қалады. Пәтерді жалға алсаңыз, шығын уақыт өте келе өседі.</li>" +
                                "</ol>" +
                                "<p>Мысалы, кеше ипотекаға пәтер алдыңыз, артық төленетін сома — 18 миллион теңге. Көп көрінеді, бірақ оны алдағы 25 жыл бойы бөліп-бөліп беретініңізді ескеріңіз. Ипотеканы жапқанда ол ақша инфляцияның кесірінен қазіргі 5-8 миллион теңгеге тең болады. Жаман емес қой. Кейбір мемлекеттік бағдарлама бұдан да тиімді шарттар ұсынады: 7-20-25, әскерилерге арналған ипотека, «Нұрлы жер» және тағы басқа. Олар артық ақша сұрамайды, бірінші жарнасы мен пайызы аз болады.</p>" +
                                "<p><strong><span class='green'>Жоқ: алғыңыз келген затты алсаңыз, қуанасыз, бірақ оның құны бюджетіңізге салмақ болады</span></strong></p>" +
                                "<p>Мында біз сабақтың басына оралуымыз керек, алған зат сыйлайтын қуаныш уақытша екенін, міндетке итермелейтінін еске түсіріңіз. Әрине, Тим Куктың жаңа iPhone көрсеткен презентациясы керемет-ақ, алайда iPhone 14 шықты екен, жұрт алып жатыр екен деп жақсы істеп тұрған ескі телефонды лақтыра салуға болмайды. Айтып тұрғанымыз — клише, бірақ әлі де өзекті мысал.</p>" +
                                "<p>Қаржылық сауаты бар адам — ақшасына да, эмоциясына да ие бола алатын адам.</p>" +
                                "<p><strong><span class='green'>Иә: дүкен бұрын-соңды болмаған жеңілдік ұсынып тұр, ал жалақы түсер емес</span></strong></p>" +
                                "<p>Жеңілдік пен науқандардың бәрі бірдей қалған тиыңызды бізге жұмсаңыз деген қитұрқы маркетиң емес. Олардың кейбірі брендті таныту үшін, адал тұтынушы жинау үшін жасалады.</p>" +
                                "<blockquote>" +
                                "<p>Nike Air Force алғыңыз кеп жүргеніне біраз болады, оның бағасы 55 мың теңге. Маусымдық науқан жүргізіп жатқан дүкен оны 38 500 теңгеге сатамыз деп шығарып қойыпты. Жалақы түсерге әлі екі күн бар, ал тауар әп-сәтте сатылып кетуі мүмкін. Қазір несие картасын пайдаланып сатып алған тиімді. Несие картасы дегеніміз — банк ақшасы салынған карта, одан қанша алсаңыз, сонша қайтарып бересіз, кешіктіріп қайтарсаңыз, айыппұл салынады. Екі күннен кейін жалақы алғаннан соң банк ақшасын қайтарып бересіз. Кеш төлемесеңіз, айыппұл салмайды.</p>" +
                                "</blockquote>" +
                                "<p>Затты бұрыннан алам деп жоспарлаған болсаңыз, қазір жеңілдік шығып, ақшаңыздың бір бөлігін үнемдеп қалу мүмкіндігі туса, несие картасын қолданғанның әбесттігі жоқ. Бірақ бір шартты ұмытпаңыз, қарызды мерзімінен бұрын және пайыз төлеместен жабасыз.</p>" +
                                "<p><strong><span class='green'>Жоқ: табысым тұрақты емес</span></strong></p>" +
                                "<p>Келер айда табыссыз қалу ықтмалдығы бар болса, қарызға жабысудың керегі жоқ. Несие бюджетіңізге қарамайды, төлеу керек нәрсені төлеуіңіз керек болады. Уақытылы!</p>" +
                                "<p><strong><span class='green'>Жоқ: әр зат не қызметтің ақысын бөліп төлеп жүрсіз</span></strong></p>" +
                                "<p>Диван, кейін шыққан эйрподс, алтын сырға мен велосипедтің ақысын бөліп төлеу ұтымды шешім көрінері анық, бірақ бәрін бір уақытта төлегенде ай сайынғы төлем үлкен сомаға айналады. Оны сіз ай сайын қайтарып отыруыңыз керек. Бөліп төлеуге де шектеу қою керек, қоймасаңыз, қарызға белшеңізден батуыңыз ғажап емес. Қалай шектейміз?</p>" +
                                "<p><i>Санын шектеу</i>. Бөліп төлейтін қарызыңыз көп дегенде үшеу бола алады, тағы бірдеңе алғыңыз келіп бара жатса, әуелі біреуінің ақысын толықтай өтеңіз.</p>" +
                                "<p><i>Мерзімін шектеу</i>. 12 және 24 айға бөліп төлемеңіз. Ойлап қараңызшы, велосипедтің ақысын екі жыл дегенде ғана толықтай төлейсіз. Одан да үш-алты айда жауып тастаған оңайырақ.</p>" +
                                "<p><i>Санатын шектейміз</i>. Бұған қолма-қол ақшаға алатын кез келген затты жатқызамыз: киім-кешек, косметика, тамақ, кітап, аксессуар және т.б. Ақша жоқ, бірақ тауар ауадай қажет боп тұрса, шектеуге қарамай, ақысын бөліп төлеймін деп ала беріңіз, бірақ тағы да айтамыз, қарызды жалақы түскен бойда мерзіміне жеткізбей жабасыз.</p>" +
                                "<p><strong><span class='green'>Иә: несие тұрмысыңызды түзеуге көмектеседі</span></strong></p>" +
                                "<p>Жас отбасын алайық. Отағасы, әйелі, қызы мен ұлы — бәрі бірге бір бөлмелі үйде тұрып жатыр. Олардың қолында мысық та бар. Үй іші түгелдей күн сайын қолайсыздық көріп жүр. Балалар әлі кішкентай, дегенмен олар өседі, есейеді, әрқайсына жеке кеңістік керек. Мұндай жағдай біздің қоғамда жиі кезігеді. Жалғыз амал — ипотека алу.</p>" +
                                "<p><strong><span class='green'>Мәселені көп шығынсыз қалай шешуге болады?</span></strong></p>" +
                                "<p>Біріншіден, кішкентай пәтерді сатып, ақшасын ипотеканың бірінші жарнасына жұмсау, сонда төленетін артық сома азырақ болады. Екіншіден, келесі төлемдерді төлей алмай қалам десеңіз, пәтерден түскен ақшаны бөліңіз де, бір бөлігін бірінші жарнаға бересіз, қалғанын депозитке салыңыз. Бұл сізге сенім береді. Бір жылда сіз әл-ауқатыңызды жақсартып ала аласыз. Мысалы, ертерек жабу үшін ақша жинап үлгересіз не жалақысы жақсы жұмысқа тұрасыз.</p>" +
                                "<p><strong><span class='green'>Жоқ: ақша қажет деп бірден несиеге жүгіру</span></strong></p>" +
                                "<p>Бұл — өте импульсив әрекет, ондайда өз эмоцияңызды қадағалаңыз. Жаңа зат алғыңыз келсе, бір апта не бір ай ережесіне сүйеніңіз. Біраз уақыт ойланыңыз, таразыға салыңыз. Уақыт әлгі зат шынымен керек, керек емесін көрсетеді. Несиені төлем төлеймін не қажет затты алам деп алғыңыз келсе, пайызы аз тәсілдер іздеп көріңіз. Досыңыздан я ата-анаңыздан сұраған, әрине, біртүрлі, бірақ оларға қарызды қайтару әлдеқайда оңай болады.</p>" +
                                "<p>Кейін төлемдер мен қарыздарды жалақы түскен бойда төлеймін деген әдет қалыптастырыңыз. Өйткені қаржылық міндетіңіз ақша барда жабылады, ай аяғында ақшаны қайдан табам деп бас қатырмайсыз (Бюджет туралы сабақта осыған толығырақ тоқталдық).</p>" +
                                "<h2>Несие егжей-тегжейі</h2>" +
                                "<p>Тиімді, онша тиімді емес несие не екенін білдіңіз. Енді оның бірнеше егжей-тегжейіне тоқталайық.</p>" +
                                "<p>Банк беретін қарызды оның бөлімшесіне барып немесе сайт-қолданбасында рәсімдеуге болады. Әуелі өтінім толтырасыз, алдын ала барлық шартын оқып аласыз: сомасын, мерзімі мен төлем төлейтін графикті көрсетесіз.</p>" +
                                "<p>Несие жабудың екі графигі бар:</p>" +
                                "<p><strong><span class='green'>Аннуитет</span></strong> — несие жабылғанға дейін ай сайынғы төлем сомасы тұрақты болады, бірақ басында пайызын төлеп аласыз, кейін негізгі қарызды төлейсіз.</p>" +
                                "<p><strong><span class='green'>Дифференциялық</span></strong> — негізгі қарыз тең бөліктерге бөлінеді. Ал ай сайын қалған сомаға пайыз қосылып отырады. Қарызыңыз ай сайын азаятындықтан төлем де пайыз төмендеген сайын азаяды.</p>" +
                                "<blockquote>" +
                                "<p>Мысалы: 36 айда өтеймін деп 2 миллион теңге несие аласыз. Пайызы — 10%. Аннуитет төлем төлейтін болсаңыз, ай сайын 64 534 теңгесін қайтарып отырасыз, демек артық төленетін сома 323 237 теңге болады; дифференциялық графикті таңдағандар бірінші айда 72 222 теңге, ал ең кейінгі айда 56 018 теңге береді. Артық сома — 308 333 теңге.</p>" +
                                "</blockquote>" +
                                "<p>Артық төленетін екі соманың айырмасы көп білінбейді, бар-жоғы 14 904 теңге. Негізгі айырма мынада: дифференциялық графигін таңдағандар несиесін ерте жабам десе, негізгі қарыздың сомасы ай өткен сайын аннуитетке қарағанда азырақ болады. Демек несиенің жалпы сомасы да азаяды.</p>" +
                                "<p><strong><span class='green'>Өтінім толтырдық, енді не болады?</span></strong> Калькулятор ай сайынғы төлемді есептеп, артық төленетін сома қанша болатын көрсетеді. Көбі артық төленетін сома негізгі қарызға қосылатын пайыз деп ойлайды. Бірақ бұған өзге шығындар да жатады. Мысалы, сақтандыру, нотариус қызметі, сервис алымы. Сондықтан келісімшарттың әр тармағын дұрыстап оқыңыз немесе өзіңіз есептеңіз.</p>" +
                                "<p>Егер шарттар мен сома көңіліңізден шықса, скориң жүйесі несие тарихыңызды тексереді: мақұлданған несиелер санын, неше рет несие берілмегенін, отау құрғансыз ба, жоқ па, жұмыс тәжірибеңізді, жоғары біліміңіз бар-жоғын. Қаншалықты көп ақша сұрасаңыз, банк сіз туралы соншалықты көп мәлімет жинайды.</p>" +
                                "<p>Кейде банктер клиент өтей алмайтын қарыз беріп жатады. Сондықтан скориң жүйесіне сүйенудің қажеті жоқ: ол сіздің орныңызға несие алғаныңыз дұрыс па, бұрыс па, айтпайды. Жүйе атыңыздан зейнетақы қорына қанша түскеніне, ресми көрсеткіштерге сүйенеді. Ол сіздің өзге де қаржылық міндеттеріңізді ескермейді. Мәселен, туысыңызға берешегіңіз бар, ломбардқа бағалы зат өткіздіңіз, асырап отырған итіңіз вирус жұқтырып алды, оған да ақша керек деген сияқты нәрселерге қарамайды.</p>" +
                                "<p>Несие — сіздің жауапкершілігіңіз, өзіңізді де, банкті де алдауға тырыспаңыз. Себебі түбінде бар шығыныңыз несиені ғана жабуға кетуі мүмкін.</p>" +
                                "<p>Таршылық көрмей өмір сүрем десеңіз, алған несиеңіз бюджетіңіздің 30-40%-нан аспауы қажет. Себебі көп сомалы төлемдерден тіпті психологиялық жағдайыңыз зардап шеге бастайды. Ал кейін оларды төлей алмай қалсаңыз, үстінен айыппұл, өсімпұл төлейсіз, шотыңыз бұғатталады, несие тарихыңыз бүлінеді.</p>" +
                                "<h2>Несие тарихы</h2>" +
                                "<p>Несие тарихы — ресми құжаттама. Соған қарап банк қарыз береміз, не бермейміз деп шешеді.</p>" +
                                "<p>Өмірден алынған мысал:</p>" +
                                "<blockquote>" +
                                "<p>Сіз бұрын несие алып көрмегенсіз. Мұны мақтан тұтасыз. Бірақ дереу ақша қажет болып, несие алуға өтінім бердіңіз. Банк несие береміз дейді, алайда бермек болған сомасы сіз сұрағаннан сомадан аз.</p>" +
                                "</blockquote>" +
                                "<p>Неге? Өйткені банк несие тарихыңызды (жоқ қой) білмейді, сондықтан міндетіңізді орындайтыныңызға күмәні бар. Несие тарихыңыз бос, банк төлем уақыты келгенде қайтеріңізді білмейді. Сол себеппен несиеңіз мақұлданды, бірақ сұрағаннан аз сома алдыңыз.</p>" +
                                "<blockquote>" +
                                "<p>Банк ұсынған сомаға несие алдыңыз, қалғанын ағаңыздан қарызға алдыңыз. Жарты жыл бойы банкке де, ағаңызда да уақытылы төлеп жүрдіңіз. Бірақ ағаңызда кенеттен мәлесе туындап, қалған қарыздың бәрін кері сұрады. Амал жоқ, қолда бар ақшаның барын соған бердіңіз. Енді несиені төлейтін қаражат жоқ. Төлем уақыты асып кетті. Енді қайттім деп бар несиені жабу үшін сол банктен тағы несие алмақсыз. Бірақ банк бермейміз дейді.</p>" +
                                "</blockquote>" +
                                "<p>Осы уақытқа дейін несие тарихыңызда ешқандай кемшілік болмайды, бірақ бір төлемді төлемей қалсаңыз, бұл фактіні банк дереу жазып алады. Төлер ақшаңыз жоғын көріп тұрған банк сізге жаңа несие бергісі келмейді. Тағы бір қарызға шамаңыз келмейді-ау деген ықтималдық тым көп.</p>" +
                                "<blockquote>" +
                                "<p>Басқа да банктер бар, олар да несие бере алмай ма? Тағы бір өтінім беріп көрдіңіз, бірақ әлгі банк те бермей қойды.</p>" +
                                "</blockquote>" +
                                "<p>Басқа банк неге сүйеніп тұр? Жаңағы несие — өміріңіздегі бірінші несие. Бірақ оны төлей алмадыңыз, оны жабу үшін жаңа қарыз алғыңыз келген еді, бірінші банк бермеді. Екінші банк осыны көріп, біз де бермейміз дейді. Несие тарихыңыз бүлінді.</p>" +
                                "<blockquote>" +
                                "<p>Тығырықтан бірдеңе қылып шықтыңыз, қарыздан құтылдыңыз делік, бірақ екі жылдан кейін сізге тағы ақша керек болып, несие алмақшысыз. Сома көп емес, сондықтан банк несие береріне сенімдісіз. Айтқаныңыз айдай келді. Алайда бұл ойыңыздан бас тартып, тағы амал іздеп көргіңіз келеді. Әлгі алған ақшаңызды бірнеше сағат пайдаланып, кері бересіз. Сөйтіп, несиеңіз жабылады. Бірер күннен кейін тағы ақша керек болып қалып, тағы өтінім бересіз. Өткенде банк мақұлдады ғой деп несие аларыңызға сенімдісіз. Бірақ бермейміз деген жауап келеді.</p>" +
                                "</blockquote>" +
                                "<p>Мұның алдыңғы несиеңізге қатысы жоқ. Банк несие ақысын жүйелі түрде төлемейсіз деп несие беруден бас тартады. Бір күнде өтінім беріп, оны кері алу немесе ақшаны алып, несиені сол күні жауып тастау — банк шешім шығарарда сүйенетін факторлар. Олар сіздің жағдай қандай екенін білмейді де, әрекеттеріңіз тұрақсыз деген тоқтамға келеді.</p>" +
                                "<p>Көріп тұрғаныңыздай, несие тарихыңыз сынғыш зат сияқты, алайда ол — бізге өте маңызды нәрсе. Ең басты қағида мынадай: әліңіз келетін несие алыңыз, уақытылы төлеп жүріңіз, болды, басқа еш нәрсе керек емес.</p>" +
                                "<p>Несие тарихыңыз бүлінсе, мысалы, телефонға алған кішкентай несиеңізді кешігіп төлесеңіз (мүлдем төлемесеңіз), бала кезіңізден армандап келе жатқан пәтеріңізге несие ала алмай қалуыңыз ғажап емес.</p>" +
                                "<p><strong><span class='green'>Несие тарихымыз қандай екенін қалай білеміз?</span></strong></p>" +
                                "<p>Несиеге қатысты рейтиңіңіз керек болса, Бірінші несие бюросының сайты я қолданбасынан біліңіз. Жылына бір сұрау тегін, бірнеше рет қараймын десеңіз, 100 және 400 теңге төлеуіңіз керек.</p>" +
                                "<h2>Несиені ертерек жабамыз</h2>" +
                                "<p>Сіз енді банк несиелерінің жүйесінен хабардарсыз. Несие алып үлгерген де шығарсыз. Тіпті бірнешеуін алған шығарсыз. Сіз бұл сабақты соны (соларды) жабу жолдарын іздеп немесе бітпейтін төлемдерді төлеп шаршағандықтан оқып отырсыз.</p>" +
                                "<p>Мұндай сізге стратегия серік болмақ. Оның бірінші ережесі — жаңа қарыз алмау. Шарттары ыңғайлы болып тұрса да бөліп төлеп алу негізгі қарыздан құтыла алмай отырғандарға жығылғанға жұдырық болады.</p>" +
                                "<p><strong><span class='green'>Несие жапқанда қандай стратегияға сүйену керек?</span></strong></p>" +
                                "<p>Жауабымыз: несиені ертерек жабарда ай сайынғы төлемді азайтуға емес, мерзімді азайтуға басымдық беріңіз. Әрине, алдағы айда азырақ төлеу нұсқасына көбірек қызығарымыз анық, бірақ одан да мерзімді азайтқан артық: қалған 36 айдың орнына екі-ақ ай төлеген күшті емес пе?</p>" +
                                "<p>Әлбетте, кейде тура керісінше істеуге мәжбүр болатын кездер болады. Түсіндірейік:</p>" +
                                "<p><strong><span class='green'>Мерзімді азайтсақ:</span></strong> табыс бағадан жылдамырақ артқанда қарыз құнсызданады, яғни несие бюджетіміздің кішкентай бөлігі болып қалады. Мысалы, айына қазір 100 000 теңге беріп жүрсіз, бұл —  табысыңыздың жартысы. Екі жылдан кейін қарыз табыстың 25%-ы болады, себебі қарыз сомасы өзгермейді. Ондайда несиені мерзімінен бұрын жабарда мерзімді азайту стратегиясын қолданыңыз. Сіздің мақсат — несиені ертерек жапқаннан пайда көру. Пайда дегеніміз — артық соманы азырақ төлеу.</p>" +
                                "<p><strong><span class='green'>Төлем сомасын азайтсақ:</span></strong> баға жоғарласа, бірақ табысыңыздың мөлшері сол күйі қалса не аз ғана өссе, қарыз қымбаттай түседі. Бұл дегеніміз — несиеге кететін ақшаның жоғы қатты білінеді. Себебі енді тұрмысқа да ақша көбірек кетеді.</p>" +
                                "<p>Мысалы, қазір несиеге, коммуналдық қызметке, азық-түлік пен транспортқа бюджетіңіздің бірден үші кетіп жатыр, үш жылдан кейін бұл көрсеткіш ⅔ болады. Келешекте не боларын білмесеңіз әрі қаржылай жағдайыңызға алаңдап жүрсеңіз, несиені жабарда ай сайынғы төлемді азайту стратегиясына сүйеніңіз.</p>" +
                                "<p><i>Есте сақтаймыз: несиенің мерзімі азайғанда артық төленетін сома кемиді, ал ай сайынғы төлемнің сомасын азайтқанда бюджетке түсетін салмақ кемиді.</i></p>" +
                                "<p><strong><span class='green'>Мүмкіндік болса, несиені ертерек жабыңыз.</span></strong>. Қарыздың жартысын төлегеннен кейін несиені ертерек жабудың керегі жоқ сияқты, себебі мерзімі азайды ғой. Десе де несиеңіздің егжей-тегжейін ашып, банкке қанша соманы артық бергеніңізді, қанша артық сома береріңізді біліңіз. Сонда мерзімінен бұрын жапқан дұрыс шешім екенін түсінесіз. Ескере жүріңіз: несиені мерзімнен ертерек жапсаңыз да, банк пайда көреді, бірақ пайда азырақ болады.</p>" +
                                "<blockquote>" +
                                "<p>Мысалға қарай: банктен екі жылда қайтарамын деп 500 мың теңге алдыңыз, пайыздың кесірінен оның үстіне 276 740 теңге қосып беруіңіз керек. Егер несиені үш айда жапсаңыз, ол сома 45 247 теңгеге дейін төмендейді. Бар берешегіңіз 545 247 теңге болады. Банк аз пайда көреді, ал сіз қарыздан сәл көбірек сома бересіз. Егер төлеміңіз ай сайын азайса, артық төлейтін сомаңыз 276 мың теңге болады да, екі жылда 776 мың (қарыз бен артық сома) теңге банкке берген боласыз.</p>" +
                                "</blockquote>" +
                                "<p>Артық ақшаңыз болса, оны бірден несие жабуға жұмсаңыз, бірақ бұдан басқа ақшаңыз жоқ болса, оны берудің қажеті жоқ. Несиені ертерек жабам деп ақшаны сандыққа тығып сақтаудың да керегі жоқ. Жинап үлгерем дегенше банк қарызыңызға пайыз қоса береді, ана ақша сол пайызды төлеуге кетеді әрі оны жоспарсыз, ұсақ-түйекке жұмсап қоюыңыз мүмкін.</p>" +
                                "<h2>Кестені пайдаланымыз</h2>" +
                                "<p>Кестеміздегі <a href='https://docs.google.com/spreadsheets/d/1Ms0RdUXuLOt1TRzTbjobODS7-0OCSMruj_aoJdg90lI/copy' target='_blank'>Несие санатын</a> ашыңыз.</p>" +
                                "<img src='"+require('@/assets/images/visuals/kz/img-4.png')+"'/>\n" +
                                "<ol>" +
                                "<li>Несиелеріңізді, бөліп төлеп жүргендеріңізді тізіңіз. Әуелі пайызы көптерін енгіңіз.</li>" +
                                "<li>Әр несиеңіздің егжей-тегжейін жазыңыз: сома, мерзім, қанша қалғанын, ай сайынғы төлем.</li>" +
                                "<li>Қалтаңызға қарап, несие жабу стратегиялардың бірін таңдаңыз.</li>" +
                                "<li>Ертерек жаба алмасаңыз, бұрынғыдай төлей беріңіз. Ал артық ақшам бар десеңіз, «Пайызы көп несиені мерзіміне жеткізбей жабу» мақсатын ойланып көріңіз (Қалай қаржылық мақсат қоямыз, оған қалай жетеміз сұрақтарына алдыңғы сабақта жауап бердік).</li>" +
                                "<li>Жалпы мұндай кесте несиеге қатысты күйіңізді көрсетеді. Қанша алдыңыз, берешегіңіз қанша, қашан қайтарасыз. Кейде тығырықтан тез шыға алмайтын кездер болады. Бірақ бастысы — кестеге қарап қайда ұмтыларыңызды түсінесіз.</li>" +
                                "</ol>" +
                                "<h2>Несие бар, алайда ай сайын төлеп отыратын ақша жоқ. Қайтпек керек?</h2>" +
                                "<p>Несие алып, ай сайын төлейтін ақша таба алмай жүрсіз делік. Бұған ешкім көмектесе алмайтыны, жұмысыңыз жоғы немесе өзге нәрсе себеп. Мұндайда бүйтіңіз: банкке барып, жағдайыңызды ештеңесін жасырмай айтып беріңіз. Банк бұл мәселені шешу үшін қисынды амалдар ұсынады. Бұлар —  несиелік каникул, жаңа несие алып, ай сайын азырақ төлеу немесе кепілгер табу.</p>" +
                                "<p><strong><span class='green'>Реструктуризация</span></strong> — банк бірінші ұсынатын нәрсе. Несиеңіз сол күйі қалады, тек төлем шарттары өзгереді: мерзімі көбейтіледі, ай сайынғы төлем сомасы азаяды, төлейтін күнді ай аяғына я басына қоюға болады. Реструктуризация үшін бірнеше құжат жинап, өтінім беруіңіз керек.</p>" +
                                "<p><strong><span class='green'>Қайта қаржыландыру</span></strong> — бар шартты өзгертіп, басқа шарттар ұсынатын несие: ай сайынғы төлемді кемітіп, мерзімді көбейту, төлем күнін өзгерту мен пайыз мөлшерлемені азайту.</p>" +
                                "<p>Екі мысылға сүйеніп түсіндірейік:</p>" +
                                "<ol>" +
                                "<li>" +
                                "<p>Бір банктен алған несиеңіз пайызы көп делік. Бірақ басқа банк сондай сомаға пайызы азырақ несие береміз дейді. Мәселен, қазіргі несиеңіздің пайыз мөлшермелесі — 45%, қайта қаржыландыру алсаңыз, 33% болады, яғни артық төленетін сома әртүрлі болады деген сөз.</p>" +
                                "</li>" +
                                "<li>" +
                                "<p>Шарттары әртүрлі несиелер алдыңыз. Мәселен, үш несиеңіздің ай сайынғы жалпы төлемі — 150 мың теңге. Егер оларды бір несиеге айырбастасаңыз, яғни қайта қаржыландырсаңыз, ай сайынғы төлем 100 мың теңге болады. Міне, осы себептен қайта қаржыландыру — несие төлеу қиын болғанда керек құрал.</p>" +
                                "</li>" +
                                "</ol>" +
                                "<p><i>Несиелерді төлеуге шамаңыз келмей жатса, ай сайынғы төлемді азайтып, қайта қаржыландыру алыңыз. Бірақ жаңа несиенің мерзімі қысқа, артық төленетін сомасы аз болғаны жөн.</i></p>" +
                                "<p><strong><span class='green'>Несиелік каникул</span></strong> — несиеге қатысты міндетіңізді орындауды доғара тұратын тәсіл. Қазақстан заңнамасында мұндай термин жоқ, бірақ бұл мұндай мүмкіндік жоқ емес деген сөз емес. Каникулда ақша төлемегеніңіз үшін айыппұл салынбайды, несие тарихыңыз өзгермейді. Қазақстан жұрты 2020 жылы, пандемия өршіп тұрғанда несиелік каникул алуға көп өтінім берген. Үкімет те дағдарысқа қарсы амал болсын деп банктерге осындай шара жасаңыздар деп үндеген еді. Сөйтіп, банктер негізгі қарыздың сомасын төлеуді 90 күнге шегерді.</p>" +
                                "<p>Мерзімді шегеру тәсілі жиі қолданылмайды. Оған қол жеткізу үшін қазір табысыңыз жоғын, жақын арада болмайтынын дәлелдеуіңіз керек. Барлық деректі банкке бергеннен кейін жауабын 15-30 күн күтесіз.</p>" +
                                "<p><strong><span class='green'>Кепілгер</span></strong> деп қарызын төлеуге мүмкіндігі жоқ қарызгердің міндетін орындай адамды айтамыз. Кепілгер болу амалы ойыншық нәрсе емес. Несиеңізді төлей алмасаңыз, оның ақысын банк кепілгерден сұрайды. Ал кепілгер қарызды сіздің орныңызға төлеуге мәжбүр болады.</p>" +
                                "<p>Ендігі қадам — жұмысқа тұрып не фриланс қызмет атқарып, қарызға ақша табу. Мүмкіндік көп, бастысы, қамықпаңыз, қарызды қалай да төлеймін деп сүрініп қалмаңыз, қателеспеңіз. Өз бетіңізбен таба алмасаңыз, eGov не enbek.kz сайтына тіркеліп, іздеп көріңіз. Бір күн ішінде Жұмыспен қамту орталығы сізге машықтарыңызға сай жұмыс орындарын ұсынады. Бәрібір жұмыс таба алмасаңыз, ресми түрде жұмыссыз статусына ие боласыз, сөйтіп ай сайын жәрдемақы алып отырасыз.</p>" +
                                "<p><strong><span class='green'>Жеке тұлғалардың банкроттығы</span></strong> бұл несиелік амнистия емес. Тақырға отырдым деп жариялағанда кредиторлар үш жыл бойы сіздің кірісіңіз бен шығыңызды тексереді, қатаң қадағалайды. Тіпті кейде қарызыңыздың бір бөлігін мүлкіңізді тапсырып жабуға тура келеді. Бес жыл бойы жаңа несие ала алмайсыз. Банкроттық — тығырықтан шығар жол мүлдем жоқ болғанда қолдануға болатын амал.</p>",
                        },
                        dictionary: [
                            {
                                id: 1,
                                name: 'Скориң',
                                description: 'несие алғысы келетін адамның несие тарихын белгілі бір критерийлерге қарап тексеру. Бұл әдіс көптеген банк пен қаржы ұйымына көмектеседі. Олар соған қарап несие береміз не бермейміз деп шешеді. Сараптама математикалық анализ бен статистикаға сүйеніп жүргізіледі.'
                            },
                            {
                                id: 2,
                                name: 'Кепіл беруші',
                                description: 'мүлкін кепілге уақытша беретін адам.'
                            },
                            {
                                id: 3,
                                name: 'Кепілгер',
                                description: 'қарызын төлеуге мүмкіндігі жоқ қарызгердің міндетін орындай адам.'
                            },
                            {
                                id: 4,
                                name: 'Қосалқы қарыз алушы',
                                description: 'өзге қарызгермен бірге несие алатын адам. Ол адам несиенің шарттарын екінші қарызгер сияқты орындауға тиіс.'
                            },
                            {
                                id: 5,
                                name: 'Несие рейтиңі',
                                description: 'қарызгердің несие төлей алатынын я төлей алмайтынын көрсететін көрсеткіш. Банк оны қарыз сұрап тұрған адамға қаншалықты сенуге болатынын білу үшін қолданады.'
                            },
                            {
                                id: 6,
                                name: 'Несие тарихы',
                                description: 'адамның бұрын алған несиесін қалай төлегенін (уақытылы не кешігіп), қанша табыс тауып жүргенін көрсететін ақпарат.'
                            },
                            {
                                id: 7,
                                name: 'Сараланған төлем',
                                description: 'несиені ай сайын әртүрлі сома төлеп жабу. Мұндайда негізгі қарыз қалған мерзімге қарай тең төлем сомаларына бөлінеді, ал ай сайынғы пайыз азайып жатқан қалдыққа қосылады.'
                            },
                            {
                                id: 8,
                                name: 'Аннуитет төлем',
                                description: 'несиені ай сайын бірдей сома төлеп жабу. Белгіленген төлемнің көп бөлігі басында пайызды төлеуге, кейін негізгі қарызды жабуға кетеді.'
                            },
                            {
                                id: 9,
                                name: 'Сатып алуға арналған несие',
                                description: 'банк адамның жеке керегіне беретін несие. Әдетте олардың мерзімі қысқа болады, лимиті болады.'
                            },
                            {
                                id: 10,
                                name: 'Мақсатқа арналған несие',
                                description: 'нақты бір тауарды алуға берілетін несие. Тауар дегеніміз көбіне ипотека, көлік не бизнес.'
                            },
                            {
                                id: 11,
                                name: 'Артық төленетін сома',
                                description: 'қарызға алынған ақшаның үстінен төленетін сома, пайыз.'
                            },
                            {
                                id: 12,
                                name: 'Стратегия',
                                description: 'әлдебір мақсатқа қол жеткізу үшін құрылған жоспар.'
                            },
                            {
                                id: 13,
                                name: 'Қайта қаржыландыру',
                                description: 'бар несиеңізді төлеуге көмектесетін жаңа несие. Оның көмегімен ай сайынғы төлемді азайтып, тиімді шарттарға қол жеткізуге болады.'
                            },
                            {
                                id: 14,
                                name: 'Реструктуризация',
                                description: 'несиесін бұрынғы шарттарға сай төлей алмайтын қарызгердің несиесінің шарттарын өзгерту, жеңілдету.'
                            },
                            {
                                id: 15,
                                name: 'Жұмыссыз',
                                description: 'жұмысы мен табысы жоқ, мемлекеттік органда жұмыс іздеп жүрген әрі жұмысқа тұруға дайын деп тіркелген адам.'
                            },
                            {
                                id: 16,
                                name: 'Жәрдемақы',
                                description: 'мемлекет түрлі жағдайда көмек болсын деп беретін ақы. Көбінесе мына жағдайларда беріледі: жүктілік, уақытша жұмыс істей алмау, жұмыстан айырылу.'
                            },
                        ],
                        tests: [
                            {
                                id: 1,
                                question: 'Көлік алуға қандай несие аласыз?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Сатып алуға арналған несие аламын, кепілсіз, сомасы 7 миллионға дейін, сатушымен тексеріссіз, қосымша құжаттарға қол қоймастан келісемін.',
                                        result: 'Мұндай несиенің пайызы 40%-дан 50%-ға дейін болады, артық төлейтін сомаңыз тым көп болады, ондай несиені жабу қиын.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Танысымнан бірініші жарнаға қарыз сұрап алам, қалғанын банктен алам.',
                                        result: 'Онда екі есе артық сома төлейсіз: танысыңыз бен банкке. Егер бірінші жарнаға деп ақша жинамаған болсаңыз, несие қалтаңызға салмақ түсірмей ме деп бір ойланыңыз.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Жеңілдік қосып беретін несие алам, сайтта тіркеле алсам, көлік алуға осы бағдарламаның несиесін алам.',
                                        result: 'Autonesie.kz сайтында ай сайын өтінім бере аласыз, іріктелсеңіз, 4% несие ала аласыз. Бұл — тиімді амал, мысалы, 5 миллион теңге тұратын көлікті несиеге алсаңыз, бес жылда төлейтін артық сома 600 мың-ақ теңге болады.',
                                        correct: true,
                                    },
                                    {
                                        id: 4,
                                        answer: 'табысымның 10%-ын cақтап отырам, кейін көліктің ақшасын қолма-қол беремін.',
                                        result: 'Бес-алты жылдан кейін көлік алам десеңіз, мұныңыз жақсы идея. Бірақ баға уақыт өте келе өсуі мүмкін.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 2,
                                question: 'Екі несиеңіз бар. Біреуінің сомасы 250 мың теңге, екіншісінікі 400 мың теңге. Әуелі қайсын жабасыз?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Әрине, 400 мың теңгенің несиесін.',
                                        result: 'Сома біріншісіне қарағанда көп, бірақ пайызы аз немесе біріншісінің сомасы екінші несиенің сомасына қарағанда көп, бірақ пайызы аз. Қайсын жабамын дегенде несиенің егжей-тегжейін қараңыз. Қайсы қымбатқа шығып тұр, әуелі соны тезірек жабуға тырысыңыз.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Әрине, 250 мың теңге',
                                        result: 'Сома аз болса да, пайызы көп болуы мүмкін немесе керісінше болуы мүмкін. Қайсын жабамын дегенде несиенің егжей-тегжейін қараңыз. Қайсы қымбатқа шығып тұр, әуелі соны тезірек жабуға тырысыңыз.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Пайызы жоғары несиені жабам',
                                        result: 'Дұрыс! 250 мың теңгенің пайызы 400 мың теңгенің пайызынан артық болуы мүмкін. Төлем уақыты мен қолыңызда бар сома да маңызды.',
                                        correct: true,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Ақшам жететін несиені жабам',
                                        result: '250 мың теңге ақша жинап, 250 мың теңгесі қалған несиені жабам деп шешуіңіз мүмкін. Бірақ шартқа сай 400 мың теңге несие одан да қымбатқа түсуі ғажап емес, себебі оның мөлшерлемесі — 20%. Сондықтан 400 мың теңгенің несиесінің бір бөлігін жапқан артық.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 3,
                                question: 'Несиені ертерек жапқыңыз келеді, банк екінің бірін таңда дейді: мерзімді азайту және төлем сомасын кеміту.',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Азырақ төлеу үшін төлем сомасын кеміту',
                                        result: 'Мұның тек уақытша пайдасы бар. Азырақ төлеймін деген ой күшті-ау, бірақ несиенің мерзімі сол күйі қалады, сондықтан артық төленетін сома қатты өзгермеуі мүмкін.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Мерзімді қысқарту',
                                        result: 'Мерзімді қысқартқанда артық төленетін сома да азаяды, мұныңыз жақсы стратегия. Бірақ несиеге әліңіз келмесе, төлем сомасын азайтыңыз. ',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Тезірек жапқым келсе, мерзімді азайтам және/немесе несиеге шамам келмей жатса, төлем сомасын азайтам',
                                        result: 'Үнемі несиенің шарттарына мән беріңіз. Мамандар мерзімнен гөрі соманы таңдаған жөн деп жатады, бірақ өз жағдайыңызға қараңыз. Егер өмір сүруге ақша жетпей жатса және несиеге бюджетіңіздің басым бөлігі кетіп жатса, төлем сомасын азайтыңыз. Көздегеніңіз несиеден құтылу болса, болашақта жинаған ақшаңызды пайдаланып мерзімді қысқартыңыз немесе соманы тағы азайтыңыз.',
                                        correct: true,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Қалай болса солай таңдаймын',
                                        result: 'Одан да уақыт бөліп, ақша үнемдеу үшін қайсын таңдарыңызды шешіп алыңыз.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 4,
                                question: 'Несие бар, бірақ ақша жоқ. Қайтейін?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Дос-жаран, әріптестеріңіз бен үй ішіңізден қарыз сұрау',
                                        result: 'Төлем уақытына дейін жалықыңыз түспейтін болса, бұдан басқа амал жоқ. Төлем уақытын шегеруін нұсқасын да қарастырып көріңіз.',
                                        correct: true,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Ескі несиені жабу үшін жаңасын алу',
                                        result: 'Бүгінгі бір несие ертең екі несие болады. Кейін бұл шұңқырдан шыға алмай қаласыз.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Несиені төлеймін деп шештім, тағы не істеуге болады?',
                                        result: 'Бүйтіп тығырықтан шыға алмайсыз, бәрібір жаза тартыласыз: айыппұл салынады, несие тарихыңыз бүлінеді, оған қоса міндетіңізді қайткенде де орындайсыз.',
                                        correct: false,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Банкротпын деп жариялаймын',
                                        result: 'Бұл да амал емес, тақырға отырғаныңызды дәлелдеу үшін біраз тер төгуіңіз керек, одан бөлек банк банкротсыз деп таныса, біраз мүмкіндіктен айыраласыз. Мысалы, бірнеше жыл бойы несие ала алмай жүресіз немесе жұмысқа тұра алмайсыз, себебі компаниялар қабылдар алдында сізді бастан аяққа дейін тексеруі мүмкін.',
                                        correct: false,
                                    }
                                ],
                            },
                            {
                                id: 5,
                                question: 'Қандай жағдайда банк несие бермейді?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Жаппаған несиеңіз болса',
                                        result: 'Несиеңіз бары несие тарихиыңыз бен банктің несие бермейміз не береміз деуіне түк те әсер етпейді. Банк басқа көрсеткіштерге қарайды, бастысы — несиелердің ай сайынғы төлемі ресми табыстан аспаса болды.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Саяси көзқарасым өзгелердікінен басқа',
                                        result: 'Қандай көзқарыңысыз, наным-сеніңіз болсын, банк несие беру туралы шешім шығарарда мұны ескермейді.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Несиені жүйелі түрде төлемеу',
                                        result: 'Егер төлемдерді кешігіп төлеп жүрсеңіз, бір уақытта түрлі банкке өтінім берген болсаңыз және несие бермейміз деген әр шешімнен кейін тағы жаңа несие сұрайтын болсаңыз, бұл факт несие тарихыңызға еніп, банкке жөн сілтер құрал болады. Несие тарихыңых бүлінеді.',
                                        correct: true,
                                    },
                                    {
                                        id: 4,
                                        answer: 'Жалақыңыз орташа жалықыдан аз',
                                        result: 'Банк шамаңыз келетін несие береді. Еңбекақыңыздың мөлшері мақұлдауға әсер етеді, бірақ ол аз екен деп банк несие бермей қалмайды.',
                                        correct: false,
                                    }
                                ],
                            },
                        ],
                    },
                    {
                        id: 5,
                        number: 5,
                        name: 'Қаржылық амалдың ыңғайлысын таңдайсыз',
                        slug: 'karzhylyk-amaldyn-yngailysyn-tandaisyz',
                        duration: '21 мин',
                        bgImage: require('@/assets/images/lessons/lesson-5.png'),
                        content: {
                            shortDescription: "<h2>Мақсатқа жетудің тиімді құралдары: Тәсілдерді тізейік</h2>\n" +
                                "<p>Осы сабақта ақша жинау мен оны көбейтудің амал-тәсілдерін талқылаймыз.</p>",
                            shortList: "<h2>Не үйренесіз?</h2>\n" +
                                "<div><p>Қандай амалдар бар?</p></div>\n" +
                                "<div><p>Әр амалдың артықшылығы мен кемшілігі</p></div>\n" +
                                "<div><p>Тұрақсыздық заманында жиған-тергеннен айырылмаудың жолы</p></div>",
                            description: "<h2>Ақша көбейтудің қандай жолдары бар?</h2>" +
                                "<p>Ақша табудың түрлі жолы барын көргенде бір ғана амалды қолдануға болмай ма деген ой туады. Бір амалға ғана сүйеніп отыруға болады, бірақ қазіргі заман — тұрақсыздық заманы. Бәрінен сақтануымыз керек.</p>" +
                                "<p>Әр амал тек бір немесе бірнеше мақсатқа жету үшін қолданылады әрі олардың артық және кем тұстары болады. Бәрін тізбектеп шығайық.</p>" +
                                "<h2>Қолма-қол ақша</h2>" +
                                "<p>Қолма-қол ақша деген — қағаз ақша. Бұл құрал осы қаржылық амалдардың ең танымал түрі шығар. Қолда бар, бірдеңе керек болса, қалтадан шығарып бере саласыз.</p>" +
                                "<p><strong><span class='green'>Артықшылығы</span></strong></p>" +
                                "<p>Қағаз ақша ең өтімді құрал саналады. Ақша қажет пе, қағаз ақшаны шығарып бере саласыз. Оның ең негізгі әрі жалғыз артық тұсы — осы.</p>" +
                                "<p><strong><span class='green'>Кемшілігі</span></strong></p>" +
                                "<ul>" +
                                "<li>Ең негізгі кемшілік — қағаз ақшадан жаңа ақша жасай алмаймыз, демек инфляциямен күресе алмайсыз. Сандыққа салынған ақша уақыт өте келе құнынан айырылады, бұрын алып жүрген зат/қызметке жетпей қалуы мүмкін. Мысалы, 2022 жылы Қазақстанда баға 20,3% өсті. Инфляция сандыққа тығып қойған ақшаңыздың 20,3%-ын жеп қойды. Қолдағы ақша сол баяғы ақша, ешқайда кеткен жоқ, бірақ құнынан айырылды.</li>" +
                                "<li>Қауіпсіздік. Қағаз ақшаңызды қай жерде сақтасаңыз да, оны біреу ұрлап кетуі мүмкін я өзіңіз жоғалтып алуыңыз мүмкін. Ақшадан әуелі инфляцияның кесірінен, кейін ұрлықтың кесірінен айырылып қаласыз.</li>" +
                                "</ul>" +
                                "<p><strong><span class='green'>Оны қандай мақсатқа жету үшін қолдану керек?</span></strong></p>" +
                                "<p>Қауіпсіздік жастығының бір бөлігін қағаз ақша қылып сақтауыңызға болады.  Оны тосыннан болатын жағдайларда пайдаланасыз: жарық, байланыс пен интернет болмағанда сізді тек қалтадағы ақша құтқарады. Оның сомасы — қауіпсіздік жастықтағы ақшаның 30%-ы. Керек кезде қажетіңізге жарайды, инфляция не ұрланғанда оның жоғы қатты әсер етпейді.</p>" +
                                "<h2>Депозиттер</h2>" +
                                "<p>Ақша сақтаудың өзіміз білетін кәдімгі әрі тиімді жолы. Ақшаңызды банкке бересіз, ол сізге пайызға сай сыйақы береді. Осылай ақша көбейеді — сіз пайыз аласыз, соның арқасында баға өскенде ақшаңыздың бір бөлігінен айырылып қалмайсыз.</p>" +
                                "<p>Депозиттің үш түрі бар:</p>" +
                                "<p><strong><span class='green'>Ақшаны шығара да, сала да алмайсыз</span></strong></p>" +
                                "<p>Мұндай депозиттердің пайызы әдетте жоғары болады. Мерзім бітпей толтыруға және картаға аударуға болмайды. Мысалы, депозитке 100 000 теңге салсаңыз, жыл аяғында 115 700 теңге аласыз. Мұндай депозитті көбіне жинақ депозит деп атайды.</p>" +
                                "<p><strong><span class='green'>Толтыруға болады, бірақ ақша алуға болмайды</span></strong></p>" +
                                "<p>Мұндайлардың пайызы көп болады, бірақ бірінші депозиттен айырмашылығы — оған ақша салуға болады. Бірақ ақша ала алмайсыз. Ондай депозит қаржылық мақсатқа қаражат жинағанда көмек болады. Ақша ала алмайсыз, біраз толтырып отыруға болады.</p>" +
                                "<p><strong><span class='green'>Толтыруға да, ақша алуға да рұқсат етілген</span></strong></p>" +
                                "<p>Депозиттің ең танымал түрі — осы. Себебі оған шектеу қойылмайды: ақша саласыз, аласыз. Алайда оның бір кем тұсы бар — пайызы азырақ болады.</p>" +
                                "<p><strong><span class='green'>Артықшылығы</span></strong></p>" +
                                "<p><strong><span class='green'>— Инфляциямен күрес</span></strong></p>" +
                                "<p>Депозит пайыздың арқасында инфляция кезінде құнынан айырылған ақшаны қайтарып береді. Түгелдей болмаса да, бір бөлігін қайтарары сөзсіз. Мысалы, Қазақстанда ең танымал депозит пайызы — 15,7%, ал инфляция — 20,3%, яғни депозит күйіп кеткен ақшаңыздың жартысынан астамын қайтарып береді.</p>" +
                                "<p><strong><span class='green'>— Пайыздан түсер пайда екеу</span></strong></p>" +
                                "<p>Депозит ашқанда банк сізге екінің бірін таңда дейді: салымнан түскен пайызды депозит шотына салып отыру немесе ол үшін бөлек шот ашу.</p>" +
                                "<p>Біріншісін таңдасаңыз, ақша көбейтудің тағы бір амалын қолданған боласыз. Бұл капитализация деп аталады, яғни пайыздан түсетін сома шотқа салынған ақшаның мөлшеріне ғана емес, сыйақыға да байланысты болады.</p>" +
                                "<p>Мысал келтірейік.</p>" +
                                "<blockquote>" +
                                "<p>Сіз депозит аштыңыз, оның пайызы — 15%. Бірінші жарна — 250 000 теңге, ай сайын 150 000 теңге салып отырасыз.</p>" +
                                "<p>Бірінші айда 3 082 теңге сыйақы аласыз, оны депозит шотында қалдырасыз. Үстінен 150 000 теңге саласыз. Енді депозитте 403 082 теңге бар.</p>" +
                                "<p>Екінші айда алатын сыйақының мөлшері 4 969,5 теңге болады. Үстінен тағы 150 000 теңге қосасыз. Енді депозитте 558 051,50 теңге бар.</p>" +
                                "<p>Айырмасы аз сияқты боп көрінеді. Дұрыс айтасыз, аз. Бірақ бір жылдан соң бұл айырманың әсерін сезе бастайсыз.</p>" +
                                "<p>Ақшаны капитализациясыз жинағанда мерзім біткенде 2 061 250 теңге жинар едіңіз. Капитализацияны қолданып жинасаңыз, жиғаныңыз 2 185 000 теңге болар еді. Айырма — 124 мың теңге.</p>" +
                                "<p>Ол айырма бес жылдан кейін 663 750 теңге болады.</p>" +
                                "</blockquote>" +
                                "<p>Міне, пайыздан түсер пайда екеу деп отырғанымыз — осы. Пайданы пайда алу үшін жаратасыз. Ақшадан ақша жасайсыз, ол ақшадан тағы ақша жасайсыз. Ғажап емес пе?</p>" +
                                "<p><strong><span class='green'>— Салымды сақтандыру</span></strong></p>" +
                                "<p>Депозит — өзгелерге қарағанда әлдеқайда қауіпсіз құрал, себебі банк депозиттерін Қазақстанның депозиттерге кепілдік беру қоры сақтандырады. Ұлттық банктен депозит қабылдауға рұқсат алған барлық банк осы жүйеге мүше болады. Демек онда тұрған депозитті қор қорғайды.</p>" +
                                "<p>Мынадай сомалардың өтемақысы міндетті түрде беріледі:</p>" +
                                "<ol>" +
                                "<li>20 миллион теңге — теңгелі жинақ депозит;</li>" +
                                "<li>10 миллион теңге — теңгелі карта, шоттар мен өзге де депозиттер;</li>" +
                                "<li>5 миллион теңге — басқа валюталы карта, шоттар мен депозиттер.</li>" +
                                "</ol>" +
                                "<p>Бұл дегеніміз — банкіңіз лицензициясынан айырылса, қор ақшаңызды қорғап, максимум осындай өтемақы береді. </p>" +
                                "<p><strong><span class='green'>Депозиттің кемшілігі</span></strong></p>" +
                                "<p><strong><span class='green'>— Ұзақ мерзімді болса да инфляцияны жеңе алмайды</span></strong></p>" +
                                "<p>Жаңа айтқанымыздай, инфляция 20,3% болғанда банк берген депозиттердің көбінің пайызы 15,7% болды. Бұл дегеніміз — депозит ұзақ мерзімді болса да, капитал әлсірей береді. Уақыт өте келе инфляцияның кесірінен баға одан сайын өсе береді.</p>" +
                                "<p><strong><span class='green'>— Кей депозиттің өтімділігі төмен</span></strong></p>" +
                                "<p>Жинақ депозиттер өтімді болмайды, себебі ақшаның бәрін я бір бөлігін кәдімгі шотқа аударғанда сыйақыдан айырыласыз. Бұл бір минус деп айтуға болмайды, өйткені соның арқасында депозит ашқанда ойланып барып ашасыз. Тез арада қажет болмайтын ақшаңыз болса, оны жинақ депозитке салыңыз.</p>" +
                                "<p><strong><span class='green'>Қандай мақсатқа жарайды?</span></strong></p>" +
                                "<ol>" +
                                "<li>" +
                                "<h2>Жастық</h2>" +
                                "<p>Депозитті қауіпсіздік жастығы қылып пайдалансаңыз болады. Біріншіден, ақшаңыз қолыңызда болады. Екіншіден, ол сақтандырылған, одан айырылып қалу қаупі аз. Керек болып қалады деген ақшаны қайда сақтарыңызды білмегенде депозит сізге көмек болады.</p>" +
                                "</li>" +
                                "<li>" +
                                "<h2>Орташа мерзімді және ұзақ мерзімді мақсаттар</h2>" +
                                "<p>Депозит ұзақ ақша жинау үшін күшті құрал. Мысалы, пәтердің алғашқы жарнасын жинау үшін. Мұндай мақсатқа жету үшін 2-3 жылдай кетеді. Осы уақыт ішінде инфляциядан зиян көрмейсіз әрі жиғаныңыз сақтандырылуы тұрады.</p>" +
                                "</li>" +
                                "</ol>" +
                                "<h2>Қор нарығы</h2>" +
                                "<p>Қаржылық амалдардың ең қызығы қор нарығы шығар. Қауіп, хайп, көп табыс, көп залал, адреналин, шығын, эйфория — осының бәрі қор нарығын түсіндіретін сөздер бола алады.</p>" +
                                "<p>Қор нарығы алуан түрлі. Одан түсетін табыстың пайызы 2%-дан 500%-ға дейін. Бәрі амал мен дұрыс шешімге байланысты. Қор нарығынан Уоррен Баффет миллиондап ақша тапты, ал Илон Маск миллиондаған ақшасынан айырылды, кейін тағы тапты.</p>" +
                                "<p>Бірақ акциядан көп пайда түсуі мүмкін болса да оны екінің бірі пайдалана алмайды. Оған кіру оңай, бірақ ақшаныңыз сол сияқты оп-оңай күйіп кетуі мүмкін. Нарыққа асықпай, жақсылап дайындалып кіру керек.</p>" +
                                "<p>Мысалы, нарыққа соңғы ақшаңызды салудың керегі жоқ. Әуелі қауіпсіздік жастығын жасап алыңыз, қауіптерін ойланыңыз. Тіпті мақсат қойсаңыз да болады: жастық жасау, кейін қор нарығын зерттеу үшін ақша жинау.</p>" +
                                "<p>Инвестиция құяр алдында істеуіңіз керек тағы бір нәрсе — өзіңізді тану. Дәлірек айтқанда, ақша күйіп кеткенде мұны қалай қабылдайтыныңызды анықтап алыңыз. Пайда қаншалықты көп болса, соншалықты қауіпті болады. Инвестицияның негізгі ерекшелігі — қанша тапсаңыз, сонша ақшадан айырылуыңыз мүмкін. Бұған дайын болу керек. Соңғы ақшаңызды инвестицияға жаратпаңыз деп отырғанымызға осы фактор себеп.</p>" +
                                "<p>Қорда түрлі мүмкіндік, амал бар. Мысалы, облигация, ETF, жарналық инвестиция қоры, акция, дериатив, опцион, IPO. Олардан түсетін пайда мен қауіп деңгейі әртүрлі болады.</p>" +
                                "<p>Бастаушыларға алғашқы төрт құрал жарайды. Солардың бәрін түсіндіріп көрейік.</p>" +
                                "<h2>Облигация</h2>" +
                                "<p>Облигация деген — бағалы қағаз. Компаниялар, тіпті мемлекеттің өзі де қосымша қаражат керек болғанда бағалы қағаз шығарады. Сіз бағалы қағаздан пайыз көре аласыз. Облигация алғанда мерзімі мен сол мерзім біткенде компания қайтарып беретін соманы көрсетесіз.</p>" +
                                "<p>Облигациядан түсетін пайда доллармен есептегенде көп дегенде 5% болады. Облигация алам деп ақшадан айырылып қалу қаупі аз әрі оның мерзімі ұзақ болады. Пайда 5% болса да, валюталық депозиттен түсетін пайдадан асады.</p>" +
                                "<h2>ETF и жарналық инвестиция қоры</h2>" +
                                "<p>ETF и жарналық инвестиция қоры — басқарушы компаниялар құрған арнайы қорлар. Олар инвестиция құюдың әлдеқайда сенімдірек тәсілі саналады.</p>" +
                                "<p>Оның жұмысы былай: компания акция портфелін жинайды, ал тұтынушы басқарушы компанияның акцияларын сатып алады, яғни бір акция алып-ақ, портфельдің барлық компаниясыдан пайда көреді.</p>" +
                                "<p>Мысалы, SPY — АҚШ-тың қор нарығындағы ірі 505 компаниядан тұратын S&P 500-дегі ETF. Солардың бір акциясын алсаңыз, одан пайда түсері немесе түспесі осы аталған компанияларға байланысты болады. Жауапкершілікті осылай бөліп тастағандықтан қауіп-қатер төмендейді.</p>" +
                                "<p>Мұндай қорлардан түсетін пайда мен қауіп 10-15%-ға дейін жетеді.</p>" +
                                "<h2>Акция</h2>" +
                                "<p>Осы тізімдегі ең қатерлі құрал. Компаниядан акция алатын болсаңыз, оның бір иесі боласыз, яғни компания пайда көрсе, сіз де пайда көресіз, залалға ұшыраса, сіз де залал көресіз. Компанияның бизнесі өссе, акцияңыздың құны да өседі. Ал компания пайданы бөлгісі келсе, сізге дивидент төлейді.</p>" +
                                "<p>Акциядан түсетін пайда 30%-ға дейін жетеді, бірақ мұнда қауіп-қатер пайызға сай жоғары болады. Сондықтан акция алар алдында компанияның бизнесін зерттеп алуыңыз керек.</p>" +
                                "<p>Жалпы айтқанда инвестиция ақша көбейтудің түрлі жолын ұсынады. Тәжірибелі инвесторлар оларды қалай пайдалану керегін, портфельге қалай жинау керегін біледі. Кей инвестор акция аларда бұрынғы тәсілдерге сүйеніп, портфелінің көп бөлігін облигация мен ETF-ке береді, ал кейбіреуі тәуекел еткенді жақсы көріп акция сатып алады.</p>" +
                                "<p><strong><span class='green'>Қор нарығының артықшылықтары</span></strong></p>" +
                                "<p><strong><span class='green'>— Жоғары табыс</span></strong></p>" +
                                "<p>Қор нарығынан түсетін табыс инфляциядан қорғап қана қоймай, ақша көбейтуге мүмкіндік береді. ЖҚО мен ETF сияқты құралдар да инфляциямен күресте көмекші бола алады. Сондықтан АҚШ халықының жартысы жиған-тергенін инвестицияға құяды.</p>" +
                                "<p><strong><span class='green'>Қор нарығының кемшіліктері</span></strong></p>" +
                                "<p><strong><span class='green'>— Қауіп көп</span></strong></p>" +
                                "<p>Инвестиция депозит сияқты сақтандырылмайды. Нарық құласа, сіздің салымыңыз да күйіп кетеді. Бірақ бір жақсысы, нарық құласа да кейін бұрынғы қалпына келеді. Бір тәсіл іске жарамаса, өзге тәсілдің пайдасы артады. Қысқасы, бұл нарық — бітпейтін айналсоқ.</p>" +
                                "<p><strong><span class='green'>— Көп көңіл бөлу керек</span></strong></p>" +
                                "<p>Депозитке ақша салғаннан кейін ол үшін көп алаңдамайсыз, ал қор нарығында үнемі алғыр болуыңыз керек. Нарық өзгеріп отыратындықтан үнемі мүмкіндік іздеп жүру керек. Бұған уақыт пен күш қажет. Алайда одан түскен пайда оның бәрінің орнын толтырады. Кей инвестор портфелін жылына бір рет тексеріп отырады, бірақ ол үшін көп білуіңіз керек.</p>" +
                                "<p><strong><span class='green'>— Өтіміділігі азырақ болуы мүмкін (тәсілге байланысты)</span></strong></p>" +
                                "<p>Ақша тез арада қажет болғанда, қор нарығының жағдайы мүшкіл болуы мүмкін, дәлірек айтқанда активті ақшаға айналдыру мүмкін болмауы ғажап емес. Сонда да сатам десеңіз, активті сатып, ақшаны шотқа аудару мен валютаға қарап конвертация жасаудың амалын іздеуіңіз керек. Оның кесірінен біраз табысыңыздан айырылуыңыз мүмкін.</p>" +
                                "<p><strong><span class='green'>Қандай мақсатқа жарайды?</span></strong></p>" +
                                "<p><strong><span class='green'>— Орташа мерзімді мен ұзақ мерзімді мақсаттар</span></strong></p>" +
                                "<p>Инфляцияның кесірінен жиған ақшаңыздан айырылмайсыз, стратегияңыз сәтті болса, депозитке салғаннан гөрі көп ақша табасыз. Зейнетке ақша жинау мен балаларға капитал жинау — қор нарығын пайдаланып қол жеткізуге болатын ұзақ мерзімді мақсат. Бірақ пайданы сіз тек болжай аласыз, мынандай уақытта осындай пайда көресіз деп ешкім айта алмайды. Нарықтың халіне қарап шешім қабылдау керек әрі портфельді тексеріп отыру қажет.</p>" +
                                "<p>Қысқа мерзімді мақсат үшін инвестиция нарығын қолданған дұрыс болмас. Нарықтың жағдайы тез өзгереді, сондықтан қауіп-қатері аз тәсілдерге сүйенгеніңіз абзал болар.</p>" +
                                "<h2>Валюта</h2>" +
                                "<p>Валюта сатып алу бір қарағанда оңай тәсіл болып көрінеді. Бірақ оның көзге көрінбейтін тұстары бар. Қазақстандықтар сатып ала алатын екі-ақ валюта бар: еуро мен доллар. Көп адам валютаны кейін қымбатқа сату үшін алады. Мысалы, долларды 450 теңгеге алып, 470 теңгеге саттың.</p>" +
                                "<p><strong><span class='green'>Артықшылықтары</span></strong></p>" +
                                "<ul>" +
                                "<li>Ақшаны өзге валютаға айырбастап сақтау девальциядан зиян көрмеуге көмектеседі. Девальция деген — бір валютаның құны басқа валютаға қарағанда төмендеуі. Мысалы, бір мың долларды 450 000 теңгеге алдыңыз делік, ертең доллардың баға өседі, сөйтіп манағы бір мың доллардың бағасы теңгеге шаққанда 470 мың болады.</li>" +
                                "</ul>" +
                                "<p><strong><span class='green'>Кемшіліктері</span></strong></p>" +
                                "<ul>" +
                                "<li>Валютаны алып қолма-қол ақша қылып сақтасаңыз, бірақ инвестицияға пайдаланбасаңыз, ұзақ мерзімнен кейін инфляцияның кесірінен бір бөлігі күйіп кетеді. Себебі доллар да арзандауы мүмкін, яғни қазір мың долларға алған затыңыз, бес жылдан кейін екі мың доллар тұруы мүмкін.</li>" +
                                "<li>Алып-сатарлықпен айналысам десеңіз, әуелі макроэкономика мен микроэконимакадан хабардар болуыңыз керек. Кейде ол білім де жетпей жатады, себебі валюта бағамына әсер ететін түрлі фактор бар. Оның бәрін болжап отыра алмайсыз. Алып-сатарлықтың қатері көп. Есіңізде болсын: пайда қаншалықты көп болса, қауіп-қатер соншалықты көп болады.</li>" +
                                "</ul>" +
                                "<p><strong><span class='green'>Қандай мақсатқа жарайды?</span></strong></p>" +
                                "<p>Қауіпсіздік жастығының бір бөлігін өзге валюта қылып сақтасаңыз, девальциядан қорғанасыз.</p>" +
                                "<h2>Жылжымайтын мүлік</h2>" +
                                "<p>Жылжымайтын мүлікке актив қана деп қарамау керек. Бұл да табыс әкеле алатын қаржылық амал. Көбі жылжымайтын мүлікті сенімді құралдардың бірі санайды, себебі қор нарығы я инфляция деген сыртқы факторлар оның құнына зиян келтіре алмайды.</p>" +
                                "<p><strong><span class='green'>Артық тұстары</span></strong></p>" +
                                "<ul>" +
                                "<li>" +
                                "<p>Сіз ол үйде тұра аласыз, яғни ол пәтер не үй ақшаңызды сақтап қана қоймай, сізге баспана бола алады. Басқа тәсілдердің мұндай артықшылығы жоқ.</p>" +
                                "</li>" +
                                "<li>" +
                                "<p>Оны жалға беріп, қосымша табысқа ие бола аласыз.</p>" +
                                "</li>" +
                                "<li>" +
                                "<p>Алматыда пәтердің бір шаршы метрінің бағасы 15 жылда 120%, ал баға 125% өскенін ескерсек, жылжымайтын мүлік инфляциядан қорғайтын күшті құрал. Инфляция өссе, құрылыс материалдары қымбаттайды, одан кейін жылжымайтын мүлік қымбаттайды. Егер инфляция төмендесе, несие алу жеңілдеп, сұраныс артады, онымен бірге жылжымайтын мүліктің бағасы өседі.</p>" +
                                "</li>" +
                                "</ul>" +
                                "<p><strong><span class='green'>Кемшіліктері</span></strong></p>" +
                                "<ul>" +
                                "<li>Ең өтімді актив емес. Жылжымайтын мүлікті апталап, айлап сатасыз. Егер ақша тез арада қажет болса, пәтер сізге көмектесе алмайды.</li>" +
                                "<li>Жалға беруде де мәселе жетіп артылады: жауапсыз жалға алушылардың кесірінен үйді жөндеймін деп екі есе шығындалуыңыз мүмкін.</li>" +
                                "<li>Алғашқы жарнасы тым көп. Ипотека алам десеңіз де, әуелі бағасының 20%-ын беруіңіз керек. Оған бірнеше миллион теңге кетеді.</li>" +
                                "</ul>" +
                                "<p><strong><span class='green'>Қандай мақсатқа жарайды?</span></strong></p>" +
                                "<p>Жылжымайтын мүлік — актив те, қаржылық құрал да, тіпті мақсат болуы да мүмкін. Ол сіздің жиған ақшаңызды сақтайды, тіпті пайда әкеледі.</p>" +
                                "<h2>Әртараптандыру</h2>" +
                                "<p>Сабақтың кіріспесіндегі “Көп қаржылық тәсілдің не қажеті бар” деген сұраққа жауап болар амал. Амал көп болғаны жақсы, бір-біріне көмектеседі, бірі сенімдірек, бірақ аз пайдалы болса, өзгесі ұзақ мерзімді мақсатқа жарайды. Бастысы — олар жиған-тергеніңізді сақтауға көмектеседі.</p>" +
                                "<p>Ақша көбейту үшін бір-ақ амалға сүйеніп отыруға болмайды. Қазіргі заман — қиын заман. Жел тұрса болды, ақшамыздан айырылып қалуымыз мүмкін.</p>" +
                                "<p>Мынандай жағдайды елестетіп көріңізші: ақшаңызды сандыққа тығып сақтап жүрсіз, бір күні үйіңізге ұры түсіп, бар жиғаныңызды алып кетеді немесе ақшаңызды депозитке салдыңыз, бірақ банк рұқсатынан айырылды. Сақтандырылған сома төленеді-ау, бірақ бірден берілмейді. Ол төленеді дегенше өміріңізде ақша керек болар түрлі оқиға болуы мүмкін.</p>" +
                                "<p>Мұндай жағдайға тап болғым келмейді десеңіз, әртараптандырыңыз. Мысалы, қауіпсіздік жастығын қолма-қол теңге және өзге валюта қылып сақтаңыз, теңге және өзге валюталық депозитке де салыңыз. Ақша жинарда қалай көбейтсем екен деп ғана емес, қалай қорғасам деп те ойлану қажет.</p>"
                        },
                        dictionary: [
                            {
                                id: 1,
                                name: 'Әртараптандыру',
                                description: 'табыс көзін бірнешеге бөлу.'
                            },
                            {
                                id: 2,
                                name: 'ETF | жарналық инвестиция қоры',
                                description: 'басқарушы компаниялар басқаратын арнайы қорлар. Схемасы былай: компания өзі акциялардан портфель жасайды, ал тұтынушылар басқарушы компанияның акцияларын сатып алады.'
                            },
                            {
                                id: 3,
                                name: 'Күрделі пайыз (капитализация)',
                                description: 'салым сомасының емес, банк берген сыйақының үстінен берілетін пайыз.'
                            },
                            {
                                id: 4,
                                name: 'Өтімділік',
                                description: '(активке қатысты) тез әрі көп шығын келтірмей ақшаға айырбастауға болатын мүлік.'
                            },
                        ],
                        tests: [
                            {
                                id: 1,
                                question: 'Бір жылдан кейін пәтердің бірінші жарнасын төлегім келеді. Соманың бір бөлігі бар, қалғанын қалай тез жинауға болады?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Акция алам',
                                        result: 'Бұл амал мақсатты бір жылда орындауға жарамайды: қауіп-қатері көп.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Депозитке салам',
                                        result: 'Қазіргі өмірде депозит — ең тиімді әрі қауіп-қатері барынша аз құрал. Себебі депозитке салынған 10 миллион теңгеңізді мемелекет қорғайды. Ал бұл сома жинақ салым болса, яғни ақша алуға рұқсат етілмеген депозит болса, мемлекет 20 миллионыңыз күйіп кетпейтіне кепіл болады.',
                                        correct: true,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Валюта алып сатам',
                                        result: 'Оның қауіп-қатері сіз ойлағаннан көп болуы мүмкін. Есіңізде болсын: акция не валюта алам деген ойыңыз болса, әуелі сақтанып алыңыз — қауіпсіздік жастығыңызға ақша жинаңыз, ауыр міндеттеріңізден құтылыңыз.',
                                        correct: false,
                                    },
                                ],
                            },
                            {
                                id: 2,
                                question: 'Қор нарығына кіріп көргім келеді. Неден бастайын?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Қымбат акциялар сатып аламын. Пайдасы мол болатын шығар, иә?',
                                        result: 'Акция қымбат болса, соны сатып отырған компания сенімді әрі табысты деген сөз емес. Мысалы, Tesla мен Netflix-тің акциялары қымбат тұрады, бірақ Tesla-ның акциялары бір жылда екі есе, ал Netflix-тікі бір күнде 25% арзандаған еді.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Мұнай компаниясының акциясын алам',
                                        result: 'Жақсы ұйғарым, ондай акциялардан әдетте табыс тұрақты түрде түседі. Десе де мұнай секторына ғана инвестиция құю өте қатерлі әрі онда акция бағалары құлдырап тұрады.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'ETF қорының акциясын сатып алам',
                                        result: 'Оңтайлы вариант. Өйткені қордың портфельдері — бірнеше акциялы дайын тұрған, бірақ көлемі азырақ портфель. Бір акциясы құласа, екіншісі жоғарлайды, сөйтіп айырма білінбейді.',
                                        correct: true,
                                    },
                                ],
                            },
                            {
                                id: 3,
                                question: 'Қомақты ақшам бар. Оны не қылайын?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Бәрін акцияға жұмсаймын',
                                        result: 'Тағы қайталайық: акция — қатерлі актив. Жиған-тергеніңізден түгелдей не бір бөлігінен айырылып қалғыңыз келмесе, нарық қорын ақшаңызды сақтар жалғыз амал ретінде пайдаланбаңыз.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Доллар сатып алам',
                                        result: 'Әр Қазақстан азаматының ойына бірінші келетін нәрсе шығар доллар сатып алу. Бірақ бізде бағам тұрақсыз. Бұл дегеніміз — теңгенің құны артуы да, төмендеуі де мүмкін.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Амалдың түр-түрін қолданам',
                                        result: 'Әртараптандыру — бізге ең керек нәрсе. Жиған-тергеніңізді былай бөліңіз: негізгі бөлігі қолжетімді болсын, мысалы, оны депозитке салыңыз не валюта қылып сақтаңыз. Мұндайда акция не бағалы металға иек сүйеудің қажеті жоқ. Оларды тез сата алмайсыз.',
                                        correct: true,
                                    },
                                ],
                            }
                        ],
                    },
                    {
                        id: 6,
                        number: 6,
                        name: 'Болашаққа қаржылық жоспар құрасыз',
                        slug: 'bolashakka-karzhylyk-zhospar-kurasyz',
                        duration: '18 мин',
                        bgImage: require('@/assets/images/lessons/lesson-6.png'),
                        content: {
                            shortDescription: "<h2>Болшағыңызды болжар қаржылық жоспар құрамыз</h2>\n" +
                                "<p>Өткен сабақтарда қаражат басқаруды үйрендіңіз — кесте құрдыңыз, табысыңыздың мөлшерін анықтадыңыз, қаржылық амалдың қандай түрі барын білдіңіз. Курсымыздың соңғы сабағында мұның бәрін үлкен бір қаржылық жоспарына қосамыз.</p>" +
                                "<p>Осы сабақта дұрыс болжам жасай білуді, бастысы жасаған жоспарымызға сай ақша жұмсауды үйренеміз.</p>",
                            shortList: "<h2>Не үйренеміз:</h2>\n" +
                                "<div><p>Табыс пен шығын мөлшерін біліп алғаннан соң, мақсат қоюды үйренгеннен кейін қаржылық жоспарды не үшін құру керегін айтып береміз.</p></div>\n" +
                                "<div><p>Жоспардың арқасында қол жетпес көрінген мақсатқа да қол жеткізуге болатынын түсінесіз.</p></div>\n" +
                                "<div><p>Әлдебір себеппен жағдайыңыз болжағандай болмай жатса, қаржылық жоспарыңызды соған сай қалай өзгерту керегін түсінесіз.</p></div>\n",
                            description: "<h2>Қаржылық жоспардың не қажеті бар?</h2>\n" +
                                "<p>Мысалы:</p>" +
                                "<p>Көк Жайлауға барам деп жоспар құрасыз: әуелі жолыңызды қай жерден бастайтыныңызды, қай жерде аяқтайтыныңызды ойластырып аласыз, оятқыш қоясыз, телефоныңыз бен пауербанкіңізді қуаттайсыз. Бұл жоспардың әр бөлігі маңызды, әрбіріне көңіл бөлуіңіз керек, бүйтпесеңіз, сапарыңыздың сиқы кетуі мүмкін.</p>" +
                                "<p>Ал енді рюкзагыңызға бәрін салмағаныңызды елестетіп көріңізші. Мысалы, киіміңізді, дәріңізді, тамағыңызды салдыңыз, бірақ су салуды ұмытып кетіпсіз. Жолды жалғастыра берерсіз-ау, бірақ сапардан алар ләззатыңыз аз болады. Сусыз қалай жүресіз? Міне, жоспардың бір бөлігіне көңіл бөлмесеңіз, ісіңіз ойдағыдай болмауы мүмкін, су ішіп ары қарай жүре берудің, көңіл көтерудің орнына су табудың амалын іздейсіз.</p>" +
                                "<p>Қаржылық жоспар да солай: егжей-тегжейін ойластырып алмасаңыз, қол жетпес деген мақсатқа шынымен қол жеткізе алмауыңыз мүмкін.</p>" +
                                "<p>Біз көздеген нәрселер басында қорқынышты, тым өтірік болып көрінеді: ай сайын 500 мың теңге зейнетақы алып отыру, бір жылыңызды саяхатқа арнау, Алматыдан жер үй алу. Бір елестетіп көргеннің өзінде арман орындалмас нәрсе болады, оған қанша уақытта ақша жинап үлгереріңізді білмей дал боласыз. Қорықпаңыз, біздің курсты мұқият оқып шығыңыз. Біз білгеніміздің бәрін сізге дұрыстап, егжей-тегжейлі түсіндіреміз.</p>" +
                                "<h2>Деректердің бәрін жинаймыз</h2>" +
                                "<p>Қорықпаңыз деп неге айттық? Себебі қаржылық жоспарыңыз дайын тұр. Оған кіретіндердің бәрін өткен сабақтарда анықтап алғанбыз. Енді оларды біріктіруіміз керек. Оның амалын қазір түсіндіреміз.</p>" +
                                "<p>Кестенің <a href='https://docs.google.com/spreadsheets/d/1Ms0RdUXuLOt1TRzTbjobODS7-0OCSMruj_aoJdg90lI/copy' target='_blank'>Дэшборд</a> деген бірінші парағын ашыңыз. Аты-жөніңіз бен туған жылыңызды жазыңыз. Бұл кесте — ендігі сіздің қарыжлық дәшбордыңыз. Өзіңізге қатысты маңызды ақпараттың бәрі осында тұрады.</p>" +
                                "<img src='"+require('@/assets/images/visuals/kz/img-5.png')+"'/>\n" +
                                "<p><strong><span class='green'>— Әл-ауқатыңызды есіңізге түсіріңіз</span></strong></p>" +
                                "<p>«Актив», «Қаржылық міндет» пен «Әл-ауқат» алаңдарына қараңыз. Бірінші сабақта қолыңызда бардың бәрін есептедіңіз: ақшаға айналдыруға болатын актив пен төлейтін қарыздарыңызды жазып шықтыңыз. Сөйтіп әл-ауқатыңыз қандай деңгейде екенін білдіңіз. Әл-ауқат деген — қарызыңызды төлеп, активіңізді сатқаннан кейін қолыңызға тиетін ақша.</p>" +
                                "<p>Бұл тапсырмаларды орындаған болсаңыз, Анкетаңызда оның нәтижесі көрініп тұрады.</p>" +
                                "<p>Ендігі міндетіңіз — ай сайын Активтерім парағын ашып, деректерді толтырып отыруыңыз керек. Cебебі қойған мақсатыңызға қарай актив не міндет парақтары өзгеріп отырады: активіңізге актив қосылады, ал қарызыңыз азаяды.</p>" +
                                "<p>Бұл өзгерістерді бақылаған өте қызық, өйткені өз өміріңізді, кем дегенде оның ақша жағын бақылап отырасыз.</p>" +
                                "<p><strong><span class='green'>— Шығын</span></strong></p>" +
                                "<p>Егер бір ай бойы есеп-қисап жүргізсеңіз, айына қалтаңыздан шамамен қанша ақша кететін білесіз деген сөз. Ол \"Ай сайынғы шығын\" шығын санатында пайда болады. Егер сіз бір жылғы бюджет парағын толтырсаңыз, Жыл сайынғы шығын алаңын толтырмай-ақ қойыңыз, өзі толады.</p>" +
                                "<p>Ол көрсеткіштер өте маңызды, себебі олар — табуыңыз керек ақшаның минимал сомасы. Одан аз болмауы керек.</p>" +
                                "<p>Ай сайынғы шығындар есеп-қисабын жүргізе беріңіз, қанша жұмсағаныңызды жазып отырыңыз, оларды ретке келтіруге тырысыңыз. Жыл сайынғы бюджет кестесі туралы да ұмытпаңыз. Ақша жұмсаймын десеңіз, жоспар құрып жұмсаңыз. Шығындарыңызды жазыңыз, кестеге енгізіңіз, сөйткенде Анкета өзі-ақ есептеп береді.</p>" +
                                "<p><strong><span class='green'>— Табыс</span></strong></p>" +
                                "<p>Кіріс сомасы шығыннан артық болғаны жақсы. Егер сіз ай сайынғы бюджетті толтырған болсаңыз, айлық кіріс озі пайда болады, ал кесте сіздің жылдық табысыңызды көрсетеді.  Енді оны Жыл сайынғы шығындар бағанымен салыстырыңыз. Бұл айырмаға көңіліңіз толды ма?</p>" +
                                "<p>Одан бөлек табысыңыз қанша болғанын қалайсыз және ол үшін не істеуіңіз қажет деп ойлайсыз? Осыған қарап «‎Болады» және «‎Керек» деп тізім жасаңыз: Бірінші бағанға табыс арттырудың бар амалын жазыңыз, мына жағына үйренуіңіз керек жаңа тәсілдерді енгізіңіз.</p>" +
                                "<p>Мысал келтірейік.</p>" +
                                "<p>Әлгі Даниярымызды еске түсірейік. Ол айына 400 000 теңге табады. Қызметі — сату менеджері. Жұмысқа шамамен бір жыл бұрын кірді. Бос уақытында Данияр видео өңдеу мен велосипед тебуді үйреніп жүр.</p>" +
                                "<p><strong><span class='green'>Болады</span></strong></p>" +
                                "<ul>" +
                                "<li>Жалақысын арттыруды өтіну, себебі: 1 — Данияр бастығы қойған талаптарды мүлтіксіз орындап жүр; 2 — сатып отырған өнімдері туралы өзгелерге қарағанда жақсы біледі; 3 — жұмысқа кешікпей әрі қалмай барып жүр, 4 — баға 20% өсті.</li>" +
                                "<li>Кассашы лауазымына дейін көтеруді өтіну, себебі: 1 — Данияр өзгелерге қарағанда жауаптырақ; 2 — сатып отырған өнімі туралы бәрін біледі; 3 — қызметін адал атқарып жүр, себебі ол көбі жұмыстан кетіп жатқанына қарамастан әлі сол жерде істеп жүр.</li>" +
                                "</ul>" +
                                "<p><strong><span class='green'>Керек</span></strong></p>" +
                                "<ul>" +
                                "<li>Видео өңдеу білігін арттыру, кейін ұсақ-түйек тапсырыс қабылдап, білігін арттыра беру, көбірек клиент табу.</li>" +
                                "<li>Велосипед айдағаннан пайда түсіру, мысалы, тапсырыс таси бастау. Денсаулыққа да, қалтаға да пайдалы :)</li>" +
                                "</ul>" +
                                "<p>Лайфхак: жалақыны көтеруді өтінерде инфляция көрсеткішіне мән беріңіз. Өскен баға қалтаңызға қалай әсер ететін қадағалу үшін түбіртектерді сақтап жүріңіз. Қазір оларды онлайн алуға да, компьютер не телефонға енгізуге де  болады. Салыстырсаңыз, түбіртектегі азық-түлік бағасы екі жылда өскенін көресіз. Мысалы, 2022 жылы баға 20 пайыз өсті. Сондықтан ақша жинарда, болашаққа жоспар құрарда барынша артық жинауға тырысыңыз. Ақша уақыт өте келе құнсызданады, тауар қымбаттайды.</p>" +
                                "<p><strong><span class='green'>— Несиелеріңіз</span></strong></p>" +
                                "<p>Несие туралы сабақта несиелерді пайызына қарап тізіп шықтық. Әуелі пайызы көптерін жаздық, кейін пайызы аздарын тіздік. Ерінбей әр несиенің егжейі мен ай сайынғы төлемін жазған болсаңыз, дәшборд ай сайынғы төлемдердің жалпы сомасын есептеп береді.</p>" +
                                "<p>Қарыздарды ертерек жаба бастасаңыз, Несиелер парағын түзеп отырыңыз. Сөйткенде төлем сомасы да өзгеріп отырады.</p>" +
                                "<p>Сондай-ақ дәшбордта несиенің бюджетіңізге әсері көрінеді, яғни ай сайын несиеге табысыңыздың неше пайызы кетіп жатқаны жазылады. Сіздің міндетіңіз — сол көрсеткішті 40%-ға дейін, кейін қолыңыздан келсе 10%-ға дейін кеміту.</p>" +
                                "<h2>Мақсаттарымызды енгіземіз</h2>" +
                                "<p>Ал енді ең бастысы. Қаржылық мақсаттар туралы сабақта сіз олардың орындалуын қадағалай отырып, қаржылық мақсаттарды қалай қою керектігін және оларға қол жеткізуді үйрендіңіз. Сондай-ақ, олар өмірде қол жеткізгіңіз келетін барлық нәрсені жазып, Smart жүйесі бойынша тізімді бағалады. Сіздің өміріңізге мақсаттар қоюдың уақыты келді.</p>" +
                                "<ul>" +
                                "<li>SMART тексеруден өткен барлық мақсаттар, менің мақсаттарым бағанына жазыңыз.</li>" +
                                "<li>Әр соманың жанына қойып, жақын жерде қанша жинағаныңызды жазыңыз.</li>" +
                                "</ul>" +
                                "<p>Осылайша сіз өзіңіздің мақсаттарыңызды қаржылық өмір жоспарыңызға сәйкес келтірдіңіз. Сіздің мақсатыңыздың жасырын шотында тұрған соманы қуана көбейтіңіз және екі соманың да қалай теңестірілетінін көріңіз. Жинақтаған кезде жасыл түске бояңыз. Бұл сіздің өміріңіздің керемет және әдемі жетістігі болсын.</p>" +
                                "<h2>Дайын!</h2>" +
                                "<p>Мана жоспар дайын деп пе едік? Міне, дәл алдыңызда тұр. Сізге қатысты бар ақпарат (ақшаңыз қанша, неге мән беруіңіз керек, неге ұмтылуыңыз керек) қарсы алдыңызда тұр. Сіздің бар міндетіңіз алған бетіңізден тайынбау, көрсеткіштерге үңіліп, қаржылық жағдайыңызды түзеу.</p>" +
                                "<h2>Қосымша есік: Жоспар іске жарамай жатса, қайтпек керек?</h2>" +
                                "<ul>" +
                                "<li>" +
                                "<h2>Жоспарды қайта-қайта тексеріңіз</h2>" +
                                "<p>Мақсатқа жету үшін жинап жүрген ақшаңызды үнемі тексеріп отырыңыз, шығыныңыз бен табысыңызды қадағалаңыз. Қаражатыңызды дұрыс басқарсаңыз ғана мақсатқа жақындай түсесіз. Маңыздысы — тұрақтылық. Сондықтан жоспарыңызды екі аптада бір тексеріңіз.</p>" +
                                "</li>" +
                                "<li>" +
                                "<h2>Не нәрсеге ақша жинап жүрсіз, соның бағасын бақылап отырыңыз</h2>" +
                                "<p>Нарық тұрақсызыдығы, дефицит — тауар мен қызмет бағасына әсер ететін факторлар. Сондықтан нарықтағы өзгеріс жоспарыңызды өзгертуі мүмкін. Бәріне дайын болыңыз.</p>" +
                                "</li>" +
                                "<li>" +
                                "<h2>Инфляцияны ескеріңіз, болжам жасауға тырысыңыз</h2>" +
                                "<p>Нарық өзгерерін-өзгермесін болжау қиын болса, инфляция өкінішке қарай біз жүрген жерде жүретін нәрсе, яғни кетпейтін, тұрақты нәрсе. Сондықтан мақсат қоярда инфляцияны да ескеріңіз. Оның қазіргі деңгейін <a href='https://new.stat.gov.kz/' target='_blank'>мына жерден</a> көріңіз.</p>" +
                                "</li>" +
                                "<li>" +
                                "<h2>Жоспар іске жарамай жатса, қайтадан есептеңіз</h2>" +
                                "<p>Іс ойдағыдай жүрмей жатса да, қорықпаңыз. Өмір қатып қалған математика емес, тосынсый жасар дүние. Сондықтан өзіңізді жегідей жемеңіз, есеп-қисабыңызды қайта жүргіңіз: табыс азайса, ай сайынғы жинақ сомасын азайтып, мерзімін көбейтіңіз; оқыс жағдайға ақша керек болса, бірақ қауіпсіздік жастығыңыздағы ақша жетпесе, жинап жүрген ақшаңыздың бір бөлігін сол жаққа жұмсаңыз.</p>" +
                                "</li>" +
                                "</ul>" +
                                "<p>Осы сабақта қаржылық жоспар не екенін, қалай құру керегін үйрендік. Сол білгенімізді мысылға қарап бір қайталап алайық.</p>" +
                                "<p>Даниярды алайық тағы. Оған жоспар құрамыз.</p>" +
                                "<ol>" +
                                "<li>Ол неге қанша жұмсап жүргенін біледі. Қандай әлсіздігі барын, қайсын түзеу керегін білді.</li>" +
                                "<li>Жылына қанша жұмсайтынын біледі, ол енді жоспарға сай әрекет етпек, соның арқасында аяқ астынан ананы алам, мынаны алам деп шықпайды:)</li>" +
                                "<li>Айына қанша алып жүргенін және қанша алғысы келгенін нақты біледі. Әттең, сонша алсам ғой деген емес бұл. Ол не қалайтынын нақты біледі.</li>" +
                                "<li>Несиенің бюджетіне түсірген салмағынан хабардар және оның сомасын азайту керегін біледі: оның бұған арнаған стратегиясы бар.</li>" +
                                "<li>Бастысы, Даниярдың мақсаты бар. 2, 10 жылдан кейін, тіпті 60-қа жеткенде неге қол жеткізгісі келетінін, оның амалын біледі. Уақыт та оған жәрдем болмақ. Данияр табысы мен білігін арттыруға болатынын біледі.</li>" +
                                "</ol>" +
                                "<p>Данияр бар білгенін бір жерде жиып, үлкен іс атқарды. Бірақ бұл тек басы ғана. Ол өзіне сал жасап алды, енді мұхитты бағындырмақ.</p>",
                        },
                        dictionary: [
                            {
                                id: 1,
                                name: 'Жеке қаржылық жоспар',
                                description: 'сіздің жан досыңыз, көмекшіңіз. Онда барлық кіріс-шығыныңыз, жалпы бюджетіңіз бен мақсаттарыңыз тұрады. Ақша жұмсағанда соған қарап жұмсайсыз.'
                            },
                            {
                                id: 2,
                                name: 'Күрделі пайыз (капитализация)',
                                description: 'өзіңізге алып қалмай, депозитте қалдыратын пайыз. Депозит ашқанда банк сізге екінің бірін таңда дейді: пайыздан түскен пайданы шотта сақтау не депозитте қалдыру.'
                            },
                            {
                                id: 3,
                                name: 'Мақсат қолжетімділігі',
                                description: 'мақсат қоярда осы көрсеткішке аса назар аударыңыз. Талдаңыз, саралаңыз. Әйтпесе — өзіңіз бен өзгелерден көңіліңіз қалуы мүмкін.'
                            },
                            {
                                id: 4,
                                name: 'Инвестиция калькуляторы',
                                description: 'салымнан түсер пайданы есептеуге көмектесетін құрал. Соның көмегімен мақсаттың мерзімін, ай сайынғы салым, алғашқы жарна не капиталды анықтап алуға болады.'
                            },
                            {
                                id: 5,
                                name: 'Несие калькуляторы',
                                description: 'несиенің тиімділігін, ай сайынғы төлем сомасын, мерзімі мен артық төленетін соманы көрсететін көрсеткіш. Несие аларда әрі оны ертерек жабарда қолданыңыз.'
                            },
                        ],
                        tests: [
                            {
                                id: 1,
                                question: 'Ақшамды аяқ астынан жұмсап қоя берем. Жоспарды не қылайын?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Елемеу',
                                        result: 'Жұмсағалы отырған сома көп болса (мысалы, емделу не көлік жөндету), жоспарды елемей қойған болмас. Сондықтан оған өзгеріс енгізіңіз. Әйтпесе оның керегі болмай қалады.',
                                        correct: false,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Қарыз алу',
                                        result: 'Бір қарағанда қарыз алған мәселені шешетін сияқты, бірақ одан тағы он мәселе шығады. Сөйтіп, несиеге тәуелді болып қаласыз.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Сомаларды, шығындар мен мақсат мерзімдерін қайта қарап шығуыңыз керек.',
                                        result: 'Қайта есептеңіз: табыс азайса, жинақ сомасын кемітіп, мерзімін көбейтіңіз; оқыс жағдай болып ақша керек болып жатса, ал қауіпсіздік жастығындағы ақша жетпесе, жиған ақшаңызды жағдайға қарай қайта бөліңіз.  Бастысы — қапаланбаңыз: амал-тәсілдің бәрін білесіз, демек қолыңыздан бәрі келеді!',
                                        correct: true,
                                    },
                                ],
                            },
                            {
                                id: 2,
                                question: 'Бірнеше мақсатым бар. Жиған ақшамды әрқайсына қалай бөлем?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Санатына қарай бөліңіз',
                                        result: 'Иә, керегі осы. Әуелі мақсаттарды SMART жүйесіне сай, яғни басымдыққа қарай бөліңіз. Соған қарап қай мақсат бірінші орындалуы керегін түсінесіз.',
                                        correct: true,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Жинақ сомасын есептеп шығару',
                                        result: 'Бес мақсатыңыз бар, ал ақша жиғыш біреу. Баға күн сайын өсіп жатыр, кей амалдың тиімділігі азаяды, ал кей мақсат керек емес болып қалады. Бейберекет болып кетеді. Есіңізде болсын, бір мақсат — бір ақша жиғыш.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Қайсына ақша жинағым келеді, соған жинаймын',
                                        result: 'Бәріміз бір сәтте-ақ бақытқа кенелгіміз-ақ келеді, бірақ бұдан ұтарымыз аз. Ақшадан ақша жасауға тырысыңыз: бір бөлігін депозитке салыңыз, басқа бөлігін қауіпсіздік жастығы қылып сақтаңыз. Басында қиын болады, бірақ кейін ұтарыңыз көп болады.',
                                        correct: false,
                                    },
                                ],
                            },
                            {
                                id: 3,
                                question: 'Қаржылық жоспар құрдым. Енді мақсат жолымды қалай қадағалап отырам?',
                                answers: [
                                    {
                                        id: 1,
                                        answer: 'Кіріс-шығыныңызды кестеге енгізу',
                                        result: 'Жоспарды қайта-қайта тексеріп отырыңыз. Әр мақсатқа жинап жүрген ақшаңыздың мөлшерін қараңыз. Шығын мен кірісіңізді саралаңыз. Өзіңізді алдамаңыз, сонда ғана ісіңіз алға басады. Екі аптада бір тексеріңіз. Оған қоса мақсатыңызға қанша керегін де анда-санда тексеріп отырыңыз. Себебі түрлі факторға байланысты баға төмендеуі не көтерілуі мүмкін.',
                                        correct: true,
                                    },
                                    {
                                        id: 2,
                                        answer: 'Ай сайын керек соманы жинап отыру',
                                        result: 'Тәртіп пен тұрақтылық керек-ақ. Бірақ мына өмірде нюанс жетіп жатыр. Мысалы, келер айда мереке, қонаққа бару көп болуы мүмкін. Бәрін жоспарлап қойсаңыз да, шындығында ойдағыдай болмауы мүмкін немесе табысыңыз көтеріліп, ай сайынғы соманы артыруыңыз керек болатын шығар.',
                                        correct: false,
                                    },
                                    {
                                        id: 3,
                                        answer: 'Шығын деңгейін тексеру',
                                        result: 'Дұрыс шешім, бірақ оны өзге тәсілдермен бірге қолдануыңыз керек. Шығынға қарап шығындар кестесін реттеуге болады, алайда жоспарыңыз іске жарасын десеңіз, кіріс пен шығынды қадағалап отыруыңыз керек.',
                                        correct: false,
                                    },
                                ],
                            },
                        ]
                    },
                ]
            },
                {
                    id: 2,
                    name: 'Несиелер туралы білуіңіз керек барлық нәрсе',
                    slug: 'credits',
                    background: '#0029FA',
                    payType: 'Тегін курс',
                    duration: '1 сағат 10 минут',
                    lessonCount: '4 сабақ',
                    bgImage: require('@/assets/images/courses/course-2-promo-1-kz.png'),
                    promoImage: require('@/assets/images/courses/course-2-promo-1-kz.png'),
                    mobilePromoImage: require('@/assets/images/courses/course-2-promo-1-kz.png'),
                    mobilePromoCardRemHeight: '23',
                    mobilePromoBackgroundColor: '#FFC8BC',
                    content: {
                        description: "<div class='course-content-second__block-with-icon'>\n" +
                            "              <img src='"+require('@/assets/images/icons/finger-icon.png')+"'/>\n" +
                            "              <div>\n" +
                            "                  <p>Әдетте несие бойынша артық төлем несие сомасының өзінен көп болады. Бірақ біз несиені қолжетімді болғаны үшін және жылдам ақша керек болған кезде аламыз. Мұның бәрі несиелердің көп болуына, үлкен қарыздарға және бәрін бөліп төлеуге алатын өмір салтына әкеледі. Кейде банктерге қолымызда бар ақшадан әлдеқайда көп ақша қайтаруымыз керек. Өкінішке қарай, бұл - Қазақстандағы қазіргі жағдай.</p>\n" +
                            "              </div>\n" +
                            "          </div>\n" +
                            "          <h3>Бұл курс қазақстандықтар арасында ең кең таралған және ең тығырыққа тірейтін несие түрі – тұтынушылық несие тақырыбына арналған.</h3>\n" +
                            "          <p>Біз оқырмандарымызға пайдалы ақпарат дайындадық: төрт қарапайым сабақ барысында сіз ақшаға қатысты эмоцияларыңызды басқаруды, несиелердің құрамдас бөліктерін және оларды дұрыс өтеу жолдарын үйренесіз.</p>\n" +
                            "          <div class=\"course-content-second__block-with-icon\">\n" +
                            "              <img src='"+require('@/assets/images/icons/lamp-icon.png')+"'/>\n" +
                            "              <div>\n" +
                            "                  <h3>Тұтынушылық несие, яғни тұтыну мақсаттарына берілетін несие</h3>\n" +
                            "                  <p>Бұл тауарлар мен қызметтерді сатып алу, медициналық төлемдерді төлеу, жол жүру, үйдегі жөндеу жұмыстары және басқа да жеке қажеттіліктер үшін банктен қарызға алатын ақша.</p>\n" +
                            "              </div>\n" +
                            "          </div>\n" +
                            "          <div class=\"course-content-second__start\">\n" +
                            "              <a href='/kz/lessons/credits/psihologiya-zhane-aksha' class='course-content-second__start-btn'>Үйренуді бастаңыз</a>" +
                            "          </div>\n" +
                            "          <h2>4 сабақтың ішінде алған несиеңіз сіздің мақсаттарыңызға жетуге көмектесетін қаржылық құралға айналатын </h2>\n" +
                            "          <p>Сабақ барысында не үйренесіз:</p>\n" +
                            "          <div class=\"course-content-second__block-with-img\">\n" +
                            "              <img src='"+require('@/assets/images/courses/course-2/img-1.png')+"'/>\n" +
                            "              <ul>\n" +
                            "                  <li>Эмоциялардың қаржыға қатысты шешімдерге әсері</li>\n" +
                            "                  <li>Ақшаға қатысты дұрыс және бұрыс дағдыларымыз</li>\n" +
                            "                  <li>Несиеге тәуелділік деген ауру ма? Оны қалай емдейді?</li>\n" +
                            "              </ul>\n" +
                            "          </div>\n" +
                            "          <div class=\"course-content-second__block-with-img\">\n" +
                            "              <img src='"+require('@/assets/images/courses/course-2/img-2.png')+"'/>\n" +
                            "              <ul>\n" +
                            "                  <li>Банктер қалай жұмыс істейді және адамдар оларды неге ұнатпайды</li>\n" +
                            "                  <li>Несиенің қандай түрлері бар?</li>\n" +
                            "                  <li>Тұтынушылық несиесі неден тұрады?</li>\n" +
                            "              </ul>\n" +
                            "          </div>\n" +
                            "          <div class=\"course-content-second__block-with-img\">\n" +
                            "              <img src='"+require('@/assets/images/courses/course-2/img-3.png')+"'/>\n" +
                            "              <ul>\n" +
                            "                  <li>Несиені мерзімінен бұрын өтеу туралы пайдалы ақпарат</li>\n" +
                            "                  <li>Несиені өтеу әдістері және олардың комбинациясы</li>\n" +
                            "                  <li>Несиені өтейтін ақшаң болмаса, не істеу керек?</li>\n" +
                            "              </ul>\n" +
                            "          </div>\n" +
                            "          <div class=\"course-content-second__block-with-img\">\n" +
                            "              <img src='"+require('@/assets/images/courses/course-2/img-4.png')+"'/>\n" +
                            "              <ul>\n" +
                            "                  <li>Банктер сіз жайлы ақпаратты қалай көретінін білесіз</li>\n" +
                            "                  <li>Сонымен қатар несиеге қатысты айла-амалдарды табасыз.</li>\n" +
                            "                  <li>Алаяқтар сіздің атыңызға несие алғысы келеді. Өзіңізді қалай қорғай аласыз?</li>\n" +
                            "              </ul>\n" +
                            "          </div>\n" +
                            "          <h2>Курс кімге арналған?</h2>\n" +
                            "          <div class=\"course-content-second__block\">\n" +
                            "              <h3>Егер несиелеріңіз бар болса</h3>\n" +
                            "              <p>— сіз оны пайыздар есебінен үнемдейтіндей жабуды, өтеу әдісін дұрыс таңдауды үйренетін боласыз. Сонымен қатар, біз ұсынған қарапайым мысалдар арқылы несиелеріңізді есептей аласыз. Сондай-ақ, несиенің қай түрі мерзімінен бұрын өтеуді қажет етпейтінін айтып береміз.</p>\n" +
                            "          </div>\n" +
                            "          <div class=\"course-content-second__block\">\n" +
                            "              <h3>Егер несиеңіз болмаса</h3>\n" +
                            "              <p>— банктердің қалай жұмыс істейтінін, адамдар оларды неге ұнатпайтынын және несиенің неден тұратынын білетін боласыз. Несиенің құрамдас бөліктері, яғни несие мерзімі, мөлшерлемесі, несие түрі сіздің шарттарыңызға қалай әсер ететіні жайлы айтатын боламыз. Болашақта түрлі себептермен несие қажет болып қалған жағдайда, бұндай ақпарат керек болатынына сенімдіміз.</p>\n" +
                            "          </div>\n" +
                            "          <div class=\"course-content-second__block\">\n" +
                            "              <h3>Несие алуды жоспарлап жатсаңыз</h3>\n" +
                            "              <p>— сіз ақша психологиясын түсініп, балалық шақтан келе жатқан дағдыларға немесе қоғам көзқарастарына негізделген қаржылық әдеттеріңізді анықтай аласыз. Курс несие алуды жоспарлап отырғандар үшін пайдалы, өйткені олардың көпшілігі - эмоциялық шешімдер. Қарапайым мысал - бұрынғы, әлі жұмыс істейтін телефон үлгіңізді жаңа айфонға ауыстыру.</p>\n" +
                            "          </div>\n" +
                            "          <div class=\"course-content-second__block\">\n" +
                            "              <h3>Курсымыз тіпті жақын болашақта несие алғысы келмейтіндерге де пайдалы болады. </h3>\n" +
                            "              <p>Өзіңіз немесе алаяқтар несие ала алмайтындай қандай қызметтерді пайдалану керектігін білетін боласыз (яғни, өзіңізге несие алуға ресми тыйым салу арқылы). Сонымен қатар, банктердің сізге деген қарым-қатынасын білетін боласыз.</p>\n" +
                            "          </div>\n" +
                            "          <h2>Мысалдар, нұсқаулар, сөздік және тесттер</h2>\n" +
                            "          <p>Біз сабақтарды ақшамен дұрыс қарым-қатынас орнатқысы келетіндерген арнап жаздық. Мәтінде көптеген егжей-тегжейлі нұсқаулар мен мысалдар бар. Ал әр сабақтың соңында терминдер жазылған сөздік және біліміңізді бекітуге арналған тест беріледі. Егер сіз сабақты мұқият оқып, жаттығуларды орындап, терминдерге үңіліп, содан кейін тесттен өтсеңіз, еш алаңдамауыңызға болады.</p>\n" +
                            "          <h2>Бастаймыз ба?</h2>\n" +
                            "          <p>Курс қарапайым тілде және түсінікті мысалдармен жазылған төрт сабақтан ғана тұрады. Мұнда күрделі терминдер немесе түсінуі қиын құжаттар жоқ, курсты өту 1 сағаттан көп уақытты алмайды. Курс барысында алған біліміңіз өмір бойы пайдалы болатыны сөссіз.</i></p>\n" +
                            "          <p>Бәрін кезегімен бастаған дұрыс — әуелі сіз несиелердің эмоционалдық жағын түсінесіз, олардың құрылымына үңілесіз, несиені өтеу стратегияларын түсінесіз және соңында қосымша айла-тәсілдерді үйренесіз.</p>",
                    },
                    fakeLessons: [
                        {
                            number: 4,
                            name: 'Лайфхактар және қызметтер',
                            duration: '13 мин',
                        }
                    ],
                    lessons: [
                        {
                            id: 1,
                            number: 1,
                            name: 'Психология және ақша',
                            slug: 'psihologiya-zhane-aksha',
                            duration: '20 мин',
                            bgImage: require('@/assets/images/visuals/course-2/lesson-1/kz/bg.png'),
                            content: {
                                description: "<div>\n" +
                                    "<p>Курстың бірінші бөлімінде біз Данияр туралы жиі атайтынбыз – жоғары жалақыға қарамастан, оның үлкен істерге ақшасы жетпейтін. Даниярдың басты проблемасы  ақшамен қарым-қатынасында болатын. Ол ақшаны тез және еш ойланбастан жұмсайтын: барларда ақша кетіру, кешкі асты қымбат мейрамханаларда ішу, жағдайы келмесе де, қымбат нәрселер сатып алу. Осындай шығындардың кесірінен тіпті көбірек табыс табатындардың да жағдайы жарты жылдың ішінде құрыдымға кетуі мүмкін.</p>\n" +
                                    "<p>Шығындардың аз болуы және табыстың көп болуы қаржылық әл-ауқаттың негізі болып көрінгенімен, іс жүзінде бәрі оңай емес. Тіпті қаржы сауатын үйренген болсаңыз да, оның өзі жеткіліксіз болуы мүмкін. Кейде біз ақшаның қайда кеткенін санап, жоспар құрып, ақша үнемдесек, кейде сол жинаған ақшаның бәрін бір сәтте-ақ әдемі және өте жағымды нәрсеге жұмсап қоятынымыз да бар.</p>\n" +
                                    "</div>\n" +
                                    "<section>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-9.png')+"'/>\n" +
                                    "<p>Ақшамен қарым-қатынаста адам психологиясының маңызы зор.</p>\n" +
                                    "</section>\n" +
                                    "<blockquote>\n" +
                                    "<p>Сондықтан, кредитті дұрыс жабудың тәсілдерін үйренбес бұрын, сіздің ортаңыз бен балалық шағыңыз кредит алуға қалай әсер ететінін сөз етейік.</p>\n" +
                                    "<p>Кредиттеріңіздің көп болуы және қаржыға қатысты проблемалардың себебі мінез-құлық ерекшеліктеріңіздің емес, өмір бойы қалыптасқан әдеттер мен көзқарастар жиынтығы болуы мүмкін. Ал олардан оп-оңай арылуға болады, өйткені көбінесе олар сырттан таңылған. Ол үшін оларды тани білуіңіз керек және оларды өзгерту үшін аздап күш салуыңыз керек. Сонда бәрі ойдағыдай болады 💚</p>\n" +
                                    "</blockquote>\n" +
                                    "<section>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-10.png')+"'/>\n" +
                                    "<p>Ақшамен қарым-қатынастың қалай қалыптасатынын егжей-тегжейлі түсіну үшін біз <a href='https://www.tanympro.kz/' target='_blank'>Tanym Pro</a> терапиялық қызметінің психологы Карина Муртазалиевамен бірге бұл сабақты дайындадық.</p>\n" +
                                    "</section>\n" +
                                    "<hr>\n" +
                                    "<h3>Төмендегі мәмілелер сізге таныс па?</h3>\n" +
                                    "<ul>\n" +
                                    "<li>" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-11.png')+"'/>\n" +
                                    "<p>Мен бүгінгі күнмен өмір сүремін. Ертең не болса болсын, ең бастысы — менің қазіргі жағдайым, сондықтан қалаған нәрсемнің бәрін сатып аламын.</p>" +
                                    "</li>\n" +
                                    "<li>" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-12.png')+"'/>\n" +
                                    "<p>Егер мен бірдеңені қаласам, мен оны бірден алуым керек, өйткені ол нәрсе маған қатты керек</p>" +
                                    "</li>\n" +
                                    "<li>" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-13.png')+"'/>\n" +
                                    "<p>Мен қанша табыс табатынымды бақыламаймын. Мұны жоғарыдағылар анықтайды. Менің талпынысым еш маңызды емес. Табыстың өсуіне күш салудың және оған әсер етудің мағынасы жоқ.</p>" +
                                    "</li>\n" +
                                    "<li>" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-14.png')+"'/>\n" +
                                    "<p>Ақша деген жаман нәрсе. Ақшаң көп болса, басқаларға зиян келтіретін істер жасайсың.</p>" +
                                    "</li>\n" +
                                    "<li>" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-15.png')+"'/>\n" +
                                    "<p>Ақша деген үлкен жауапкершілік. Оны тек сол жауапкершілікті алып жүре алатын адамдар көтере алады.</p>" +
                                    "</li>\n" +
                                    "<li>" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-16.png')+"'/>\n" +
                                    "<p>Ақша достықтың/қарым-қатынастың/бақыттың негізі. Ақша жоқ болса, мені ешкім жақсы көрмейді.</p>" +
                                    "</li>\n" +
                                    "</ul>\n" +
                                    "<p>Өзіңізді таныдыңыз ба? Сізден басқа талай адам да осылай ойлайды, бірақ мұны мойындауға ешқашан батылы батпайды. Бізге және біздің мінез-құлқымызға әдеттеріміз мен көзқарастарымыз тікелей әсер етеді. Барлығы (әдеттегідей) бала кезінен басталды. Қазір бәрін түсіндіреміз.</p>\n" +

                                    "<h2>Отбасы және ақша</h2>\n" +
                                    "<p>Біздің қаржымен қарым-қатынасымыздың негізі көп жағдайда балалық және жасөспірім кезеңдегі бақылаулардан қалыптасады: ересектер ақшаны қалай табады, қалай жұмсайды, қалай пайдаланады. Оларға қарап, біз өзіміздің ақшаға деген алғашқы көзқарасымызды қалыптастырамыз және көп жағдайда болашақта олардың істерін қайталаймыз.</p>\n" +
                                    "<h4>Өмірден алынған бес қарапайым мысалға назар аударайық:</h4>\n" +
                                    "<ol>\n" +
                                    "<li>Бала ауқаты мол отбасында дүниеге келді, бірақ сонымен бірге ақшаның көбі ағасының ставкалардың кесірінен болған қарыздарын жабуға кететінін жиі көріп өсті. Содан балада көп ақша табу тек ыңғайсыздықпен және сол ақшаны жоғалтудың қаупімен байланысты деген теріс ой қалыптасты. Енді оның көбірек ақша табуға мотивациясы болмайды, өйткені «ақша бәрібір басқа жаққа кетеді», ол басқалардың мәселелерін шешуге мәжбүр болады, ақшадан рахат емес, тек азап көресің деп ойлайтын болады.</li>" +
                                    "<li>Бала әр жұмсалған тиынды санайтын, қаржылық жағдайы сәл қиындау отбасында өсті. Ондай бала болашақта ақшаны жұмсамай, тек жинайтын және үлкен шығындардан қорқатын болуы мүмкін. Өйткені бала кезіндегі ақша жетпеген кездерді еске алғысы келмейді, ол шаққа қайта оралуға деген қорқыныш пайда болады.</li>\n" +
                                    "<li>Бала ақшаны заңсыз жолмен табатын отбасында өсті. Ол өмір әділетсіз деп ойлайды және ар-ұжданыңды сатпай, адамдарды алдамай ақша таба алмайсың деген ойда болады. Тиісінше, ол қаржылық табысқа жетуге тырыспайды. Өйткені ақшаны «жақсылық пен жамандық» категорияларында қарастырсақ, ақшаны соңғысына жатқызады. «Егер мен көп ақша тапсам, мен де жаман боламын» деген қаржылық дамуға кедергі келтіретін ойда болады.</li>\n" +
                                    "<li>Ата-анасының өмірі көбірек материалист құндылықтарға негізделген, ал бала болса ұқсас құндылықтарды бойына сіңіреді. Егер отбасы тұтынушылық өмір салтын жүргізіп, көбірек соған мән берсе, балаға оның әсері тиеді. «Барлық мәселелерді сауда-саттық жасаумен, жаңа нәрселер сатып алумен шешуге болады, мен қиындықтарды осылайша жеңемін» — бұндай көзқарас жұбанышты ақша жұмсауда іздеуге итермелейді.</li>\n" +
                                    "<li>Ал егер отбасы бюджет жоспарын құрып, оны сабырлы түрде талқыласа, балада да ақшаға деген дұрыс көзқарас қалыптасады.</li>\n" +
                                    "</ol>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/kz/img-1.png')+"'/>\n" +
                                    "<p>Егер несие тақырыбына оралсақ: бала ата-анасы ақшалары жетпесе кредит ала салатынын көреді және «егер ақша керек болса, әрқашан кредит алуға болады» деген қарапайым ережеге сүйенеді. Бұны оған қаламаса да, ата-анасы үйретті, демек оны шындық ретінде қабылдайды.</p>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/kz/img-2.png')+"'/>\n" +

                                    "<p>Қоғамның шағын бөлшегінен (отбасы) шығып, үлкеніне аяқ аттаған кезде, кешегі бала бүгінгі ересек адам жаңа стандарттарға тап болады және ақшаны қалай пайдалану керектігін жаңадан үйренеді.</p>\n" +
                                    "<h4>Қоғам өз ережелері мен нұсқауларын айта бастайды:</h4>\n" +
                                    "<ol>\n" +
                                    "<li>Достарымыз/әріптестерімізде бар нәрсе бізде жоқ болса — ұят</li>\n" +
                                    "<li>Жағдайың келмесе да, жомарттық танытып, жақындарыңа көмектесуің керек</li>\n" +
                                    "<li>Жағдайың келмесе да бәрін дәстүр бойынша істемесең — ұят</li>\n" +
                                    "<li>30 жасқа дейін иелігіңде пәтер мен көлік болуы керек, және жылына екі рет демалысқа шығып тұруың керек</li>\n" +
                                    "<li>Табысыңды шотыңдағы ақша анықтайды. Ақшаң көп болса, бақыт та, махаббат та болады.</li>\n" +
                                    "<li>Ер адам отбасын асырап, көп ақша табуы керек</li>\n" +
                                    "<li>Әйел жақсы жар және ана болуы керек және сонымен қатар жұмыста табысты болуы керек (бірақ тым табысты да болуға болмайды)</li>\n" +
                                    "</ol>\n" +
                                    "<p>Қоғам бізді қабылдағанын қалаймыз, сондықтан ойынның жаңа ережелерін қабылдаймыз. Егер сіз қоғамның стандарттарына сай болмасаңыз, онда қоғам сізге басқаша қарайды. Сәйкесінше сіз оны ауыр қабылдайсыз, ұяласыз, тіпті өзіңізді жалғыз сезінуіңіз мүмкін.</p>\n" +
                                    "<p>Содан сіз өзіңізді дәрменсіз сезінесіз. «Неге менен басқаның бәрі жетістікке жетіп жатыр?» деп ойлай бастайсыз, қысыласыз. Сонымен қатар, біз айналамыздағы адамдардың іс-әрекеттеріне қарап, олардан үйренеміз: мысалы, туыстарымыз той жасауға несие алады, достарымыз кредитке жаңа айфон алады, әріптесіміз саяхатқа барамын деп несие алады.</p>\n" +
                                    "<p>Нәтижесінде, бір жағынан басқалардан кем болмайын деген талпыныс бар, өйткені басқалардан кем болған ұят және ауыр. Ал екінші жағынан осы жағымсыз жағдайларды болдырмайтын, бір қарағанда тамаша жұмыс істейтін құрал бар. Оның үстіне, бәрі кредит алады және еш қиналмайтындай көрінеді. Өйткені, ешкім жағымсыз тәжірибесімен бөліскісі келмейді, алып алған бес кредиттен кейін қандай қиындықтарға тап болғанын айтпайды.</p>\n" +
                                    "<p>Қорыта келе, бізді және ақшамен қарым-қатынасымызды қалыптастыратын ең маңызды екі факторды көреміз. Біріншісі — отбасымыздың қаржымен қарым-қатынасы. Екіншісі — белгілі бір үміттерді, мақсаттарды, құндылықтарды және оларға жету құралдарын мадақтайтын қоғамның бөлігі болуға деген талпынысымыз. Осылай өмір сүруіміздің себебі де осында.</p>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/kz/img-3.png')+"'/>\n" +

                                    "<div>\n" +
                                    "<p>Ақшамен қарым-қатынастағы тағы бір маңызды элемент — бұл біздің эмоцияларымыз, дәлірек айтсақ, олармен дұрыс жұмыс істей алмауымыз. Яғни, көбінесе шешімді ақылға салып қабылдамаймыз, себебі қалағанымызды тезірек алғымыз кеп тұрады.</p>\n" +
                                    "<p>Бұл жерде біздің қанағаттанбаушылық жағдайына деген көзқарасымыз өте күшті рөл атқарады. Егер адам іс-әрекеттерді кенеттен жасаса және жалпы төзімсіз болса, онда ол жағымсыз эмоцияларды бастан кешірудің және жағдайын жақсартудың жылдам әдісін іздейді.</p>\n" +
                                    "<p>Қазір жағдайыңыз келмесе де, басқалардан байқалған әрекеттер алгоритмі іске қосылады (қоршаған ортаның арқасында). Мысалға назар аударайық:</p>\n" +
                                    "</div>\n" +
                                    "<div>\n" +
                                    "<p>Бастықтың назары үшін жарысып жүрген әріптесіңіз көлік сатып алады. Жұмысқа мәз болып келеді, барлығы оны құттықтайды, бастық әріптесіңіздің қолын қысып, мақтайды. Сіз мұң, қызығу, қызғаныш сияқты жағымсыз эмоциялардың бірнеше түрін бастан кешіресіз. Сіз әріптестеріңіз бен басшылықтың назарын қайта өзіңізге аудартқыңыз келеді. Жаңағы жағымсыз эмоциялар сізді ірі сауда-саттық жасауға итермелейді. Ірі сауда-саттық жасағаннан кейін сіз жақсы сезімде, жақсы көңіл-күйде боласыз.</p>\n" +
                                    "<p>Қоғам тарапынан таңылған құндылықтарды бірден көруге болады: <br>👜 бақыттың кілті материалды заттарда; <br>💰 жұмыстағы табыс пен жетістіктер ақшаға тікелей байланысты; <br>🤦‍♂️ басқалардан кем болу ұят.</p>\n" +
                                    "<p>Бірақ жаңағы затыңызды сатып алуға ақшаңыз болмаған шығар. Ал сіз болсаңыз ол затты эмоцияға беріліп, салдарын еш ойланбастан кредитке немесе бөліп төлеу арқылы алдыңыз.</p>\n" +
                                    "<p><span class='green'><i>Қоғам айтқанынан шықпай, эмоцияға беріліп қалағаныңызды сатып алдыңыз, ал кредит алудың тез әрі оңай болуы ол процесті жеңілдетті.</i></span></p>\n" +
                                    "</div>\n" +
                                    "<p>Ең өкініштісі, жаңағы затты сатып алғаныңызға көп қуанбайсыз. Алғашқы төлемді жасаған кезде “Неге алдым екен?” деген ойлар келеді.</p>\n" +
                                    "<p>Ал одан да сорақысы – егер сіз несиені төлей алмасаңыз, несие тарихыңызды бұзып аласыз да, ипотека немесе автонесие ала алмай қаласыз. Яғни, бес минуттық эмоциялар ұзақ мерзімді перспективада өміріңізге кедергі келтіруі мүмкін.</p>\n" +

                                    "<h2>Қолжетімділік пен несие</h2>\n" +
                                    "<p>Әрине, біз несиенің қолжетімді болуы және оның мінез-құлқымызға әсер етуі сияқты факторларды айтпай кете алмаймыз. Өйткені мұның да психологиялық астары бар.</p>\n" +
                                    "<p>Мысалға тағы да назар аударайық:</p>\n" +
                                    "<div>\n" +
                                    "<p>Бірнеше ай бұрын Данияр табысы жетпесе да, 90 000 теңге тұратын әп-әдемі Nike аяқ киімін бөліп төлеу арқылы сатып алды. Енді оның назары дизайны әдемі, шуды естіртпейтін және әлжеліде барлығы тағып жүрген жаңа құлаққаптарға ауды. Данияр бір сәтке де өзін жаман сезінгісі келмейді, сондықтан дереу банктің қосымшасын ашады да, жарты минут ішінде бірнеше батырманы басып, тез арада кредит ала қояды.</p>\n" +
                                    "</div>\n" +
                                    "<div>\n" +
                                    "<p>Бұл іс-әрекет біздің миымыз үшін өте оңай, сондықтан да ыңғайлы болып көрінеді. Өйткені шын мәнінде біздің миымыз жалқау. Ыңғайлы, қарапайым жолдарды іздейді, көп жұмыс істеуді қаламайды. Кредит алсаңыз, ештеңе ойлаудың, жоспарлаудың немесе ақша жинаудың қажеті жоқ. Адам мұндай жүйеге үйреніп кетеді. Ал болашақта не болатыны жайлы біз ойланғымыз келмейді. Себебі алда бізді ай сайынғы төлемдер мен ақша жетпей қалған жағдайда туындайтын стресс күтіп тұр. Және ақша шын мәнінде жетпей қалуы мүмкін.</p>\n" +
                                    "<p>Өйткені, шын мәнінде, аурулардың халықаралық классификациясында адам мінез-құлқын өзгертетін алкоголь немесе есірткіге тәуелділік сияқты «несиеге тәуелділік» деген ауру жоқ. Бірақ несие — бұл кенеттен пайда болған тілектерді тез және оңай қанағаттандыруға мүмкіндік беретін құрал екені сөссіз.</p>\n" +
                                    "</div>\n" +
                                    "<div class='with-background'>\n" +
                                    "<p>Нәтижесінде адамның қаржылық шешімдеріне әсер ететін бірнеше фактор бар. Олар: отбасы, қоғам және оның ережелер, эмоциялар және проблеманы шешудің қарапайым жолдарын табуға деген ұмтылысымыз.</p>\n" +
                                    "</div>\n" +

                                    "<h2>Өзіңізге қалай көмектесе аласыз?</h2>\n" +
                                    "<p>Енді жағымды нәрселер туралы сөз қозғайық! Жағдайымызды қалай түзетеміз?</p>\n" +
                                    "<div>\n" +
                                    "<p>Кез келген мәселені дұрыс шешудің жолы - білімге жүгіну. Және білімге қол жеткізу оңай. Алдымен біз «Баршаға арналған қаржылық сауат» атты бірінші курсымызды өтуге кеңес береміз. Онда біз жеке қаржыны қалай басқару керектігін үйреткен болатынбыз. Курс түсінікті тілде жазылған ақпаратқа, анық мысалдарға негізделген. Сонымен қатар, бюджетті басқаруға арналған кесте және пайдалы нұсқаулықтар да бар.</p>\n" +
                                    "</div>\n" +
                                    "<a href='/kz/course/personal-finance/' class='course-link'>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/img-17.png')+"'/>\n" +
                                    "<div class='course-link__text'>" +
                                    "<p class='course-link__title'>Баршаға арналған қаржылық сауат</p>" +
                                    "<p class='course-link__subtitle'>6 сабақ • 2 сағат 10 минут</p>" +
                                    "</div>\n" +
                                    "<p class='course-link__btn'>Өту</p>\n" +
                                    "</a>\n" +

                                    "<h2>Эмоциялар күнделігі</h2>\n" +
                                    "<div>\n" +
                                    "<p>Біз жаңа ғана қаржылық мінез-құлқымызға қандай факторлар әсер ететінін қарастырдық, енді біз осы факторларды бір-бірінен ажыратып, олармен күресуді үйренетін боламыз.</p>\n" +
                                    "<p>Шешім қабылдау кезінде барлық нәрсені ескеру қажет: бұндай әрекетке бару себебіңіз, эмоцияларыңыз, ойларыңыз, тілектеріңіз. Өйткені бұның барлығын талдағаннан кейін ғана ақылға негізделген шешім қабылдауға болады.</p>\n" +
                                    "</div>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/kz/img-4.png')+"'/>\n" +
                                    "<p><i>Күнделікті қойын дәптерге салуға, Google Docs немесе Excel бағдарламалары арқылы кесте қылып жасауға болады. Немесе арнайы қосымшаның көмегіне жүгінуге болады: Android қолданушылары үшін — <a href='https://play.google.com/store/apps/details?id=com.cbt.mindhealthy' target='blank'>жүктеп алу</a> / iOS қолданушылары үшін - <a href='https://apps.apple.com/us/app/%D0%B4%D0%BD%D0%B5%D0%B2%D0%BD%D0%B8%D0%BA-%D0%BA%D0%BF%D1%82-%D1%81%D0%BC%D1%8D%D1%80/id1644324300?l=ru' target='blank'>жүктеп алу</a></i></p>\n" +
                                    "<p>Енді күнделікті қалай толтыру керектігін қарастырайық. Мысал ретінде келесі жағдайды алайық:</p>\n" +
                                    "<div>\n" +
                                    "<p>Әйелі күйеуіне әдемі шаш үлгілерін жасағысы келетінін айтты. Күйеуі болса бірден: «Мен ер адаммын, отбасында бәрі маған байланысты. Мен әйеліме қалаған затын алып беруім керек, сондықтан мен оған Dyson сатып аламын», — деп шешті.</p>\n" +
                                    "</div>\n" +
                                    "<div>\n" +
                                    "<p><span class='green'>Оқиға/жағдай</span> деген жерге: “әйелім Dyson қалайтынын айтты” деп жазамыз</p>\n" +
                                    "</div>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/kz/img-5.png')+"'/>\n" +
                                    "<div>\n" +
                                    "<p><span class='green'>Ойларға</span>: “Dyson сыйлағым келеді, мен оған осындай сыйлықтар әперуім керек. Оның үстіне қазір барлық ер адамдар әйелдеріне Dyson алып беріп жатыр, менің олардан қай жерім кем? Бірақ оны сатып алуға қазір ақша жоқ. Неге жағдай жоқ кезде сұрады екен? Неге оны алып беруге жағдайым жоқ?” деп жазамыз</p>\n" +
                                    "</div>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/kz/img-6.png')+"'/>\n" +
                                    "<div>\n" +
                                    "<p><span class='green'>Эмоцияларға</span>: “көңілдің бұзулуы, ашулану” деп жазуға болады</p>\n" +
                                    "</div>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/kz/img-7.png')+"'/>\n" +
                                    "<div>\n" +
                                    "<p><span class='green'>Реакцияларға</span>:</p>\n" +
                                    "<ul>\n" +
                                    "<li>физиология: жүрек соғысы жиіледі, бұлшықеттерім қысылып қалғандай сезімі пайда болды</li>\n" +
                                    "<li>мінез-құлық: маған бұндай сезім ұнамайды, сыйлықты бөліп төлеу арқылы ала аламын</li>\n" +
                                    "</ul>\n" +
                                    "</div>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/kz/img-8.png')+"'/>\n" +
                                    "<p>Яғни, бұл жағдайда күйеуі эмоцияға беріліп әрекет етуді жөн көреді де, фенді бөліп төлеу арқылы сатып алуды шешеді.</p>\n" +
                                    "<p>Бірақ басқаша да әрекет етсе болады. Ол үшін күнделікке қараймыз да, өзімізге сұрақ қоямыз.</p>\n" +
                                    "<ol>\n" +
                                    "<li>Әйелім айтты екен деп бірден байбаламға салынудың орнына одан келесі нәрселерді сұрауға болады: қандай шаш кептіргіш алғысы келеді? Неге? Ол шаш кептіргішті мен сатып алғанымды қалай ма? Қашан алып бергенімді қалайды? Сәл шыдай тұра ала ма? Ол шынымен де шаш кептіргіштің осы қымбат моделін қалай әлде басқа түрлері бар ма?</li>\n" +
                                    "<li>Ойлар: бұлардың қайсысы сырттан келген ойлар, ал қайсысы өзімнің құндылықтарым? Сатып алмасам, басқалардан жаманмын деген сөз бе? Егер шаш кептіргішті сатып алмасам, мен жаман күйеумін деген сөз бе? Отбасы тек маған ғана тәуелді болу керек пе, әлде әйелім де тең рөл атқара ма? Шаш кептіргішті бірден сатып ала алмауым мені неге сонша қыңжылтады? Бұл менің балалық шағымда болған оқиғаны есіме түсіре ме?</li>\n" +
                                    "<li>Эмоциялар: эмоцияларыммен не істей аламын? Мен эмоцияларым жайлы әйеліммен сөйлесе аламын ба? Мен оларды бастан кешіре аламын ба? Қабылдай аламын ба?</li>\n" +
                                    "<li>Реакцияларыммен не істей аламын? Денені қалай бос ұстауға болады? Дұрыс тыныс алсам немесе спортқа үзіліс жасасам жағдайым оңала ма?</li>\n" +
                                    "<li>Мінез-құлқыммен не істеуім керек? Менің таңдаған мінез-құлқымның (қысқа мерзімді және ұзақ мерзімді) салдары қандай болады? Қандай мінез-құлық көрсеткенім дұрысырақ болады? Мен затты бөліп төлеу арқылы алғым келеді, бірақ тағы біреуін төлей аламын ба? Қиналып кетпеймін бе? Осы ақшаны жинауға болатын басқа мақсаттарым бар ма?</li>\n" +
                                    "</ol>\n" +
                                    "<p>Осындай сұрақтар қою арқылы сіз өзіңіздің мінез-құлқыңызды, ойларыңызды, эмоцияларыңызды және сезімдеріңізді талдайсыз. Сіз әлі де бірінші шешімім дұрыс деген қорытындыға келуіңіз мүмкін, бірақ көп ойланып, мүлдем басқа шешімге келетін шығарсыз. Қалай болғанда да, үлкен қаржылық шешімдеріңізді прагматикалық тұрғыдан ғана емес, сонымен қатар эмоционалдық тұрғыдан да талдаған жөн: бұл сатып алу сіз үшін нені білдіреді? Сіз бұл арқылы басқа қандай мәселені шешіп жатырсыз? Сіз онымен қандай қажеттіліктерді қанағаттандырғыңыз келеді және мұны істеудің басқа жолдары бар ма? сияқты сұрақтарға жауап берген абзал.</p>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-1/kz/img-18.png')+"'/>\n" +

                                    "<p>Негатив эмоциялар жайлы тағы айтарымыз</p>\n" +
                                    "<div>\n" +
                                    "<p>Көбінесе негізгі мәселе кез келген ыңғайсыздықты болдырмау немесе одан қашу әрекеті екенін түсінуіміз керек. Бірақ бірдеңе істей алмайтыныңызды және бірдеңенің жоқтығын түсіну және оны мойындай білу — дұрыс нәрсе. «Егер барлығы 14-ші iPhone сатып алып, ал менің телефоным одан ескі болса, мен өмірде ештеңеге қол жеткізген жоқпын» деп ойлаудың қажеті жоқ. Бұл бір-бірімен еш байланысы жоқ ұғымдар жиынтығы.</p>\n" +
                                    "<p>Екіншіден, мұңнан аулақ болмай, оны қабылдап үйрену керек. Жағымсыз сезімдерді бастан кешіру — қалыпты жағдай. Олар бір кезде пайда болады, бірақ біраз уақыттан кейін сіз олардан арыласыз. Әрине, ойлардың шындық емес, жай ойлар екенін түсіну өте қиын. Бірақ егер сіз олардың пайда болу себебін түсінсеңіз, онда олар жай ғана сырттан таңылған ойлар екеніне көз жеткізуіңіз әбден мүмкін.</p>\n" +
                                    "</div>\n" +
                                    "<p>Жағдайыңыз келмесе, сізге телефонның соңғы үлгісі керек емес. Бұл сіздің сәтсіздікке ұшырағаныңызды білдірмейді. Жай ғана дәл қазір оны сатып ала алмайтыныңызды білдіреді.</p>\n" +
                                    "<p>Қанша маңызды болса да, өміріңіздегі кез келген оқиғаны тойлау үшін, несие алудың қажеті жоқ. Бұл сіз ештеңеге қуана алмайсыз деген сөз емес. Бұл жай ғана қазіргі жағдайда сіз үшін қаржылық және эмоционалды сау болғаныңыз маңыздырақ екенін білдіреді.</p>\n" +
                                    "<blockquote>\n" +
                                    "<p>Соңында «30 күн ережесін» еске түсірейік. Бір нәрсені сатып алу туралы ой келгенде, ол ойды 30 күн бойы қорытыңыз. 30 күннен кейін ол зат қатты қажет екенін және онсыз өмір сүре алмайтыныңызды түсінсеңіз, затты сатып алыңыз немесе оған ақша жинаңыз. Егер қатты қажеті жоқ болса, онымен қош айтысыңыз.</p>\n" +
                                    "</blockquote>\n" +
                                    "<div>\n" +
                                    "<p>Қаржылай сауатты адам — бұл қаржыны да, қаржыға қатысты эмоцияларды да басқара білетін адам.</p>\n" +
                                    "</div>\n" +
                                    "</div>",
                            },
                            dictionary: [
                                {
                                    id: 1,
                                    name: 'Эмоциялар',
                                    description: 'адамның белгілі бір жағдайларға реакциясы. Эмоциялар өмір бойы адамның бойында болады және оның бар немесе болуы мүмкін жағдайларға көзқарасын көрсетеді.'
                                },
                                {
                                    id: 2,
                                    name: 'Ақша',
                                    description: 'құнды өлшейтін және сауда-саттықта қолданылатын жалпы элемент. Ақша қағаз немесе металл түрінде болады және жоғары өтімділікке ие.'
                                },
                                {
                                    id: 3,
                                    name: 'Эмоциялар күнделігі',
                                    description: 'бұл әрекеттің, эмоциялардың, ойлардың, армандардың, реакциялардың себебін анықтауға көмектесетін арнайы күнделік. Өйткені осының барлығын талдағаннан кейін ғана дұрыс шешім қабылдауға болады.'
                                },
                                {
                                    id: 4,
                                    name: 'Эмоциялық сатып алу',
                                    description: 'бұл ақылған салынған есептеуден гөрі эмоцияның әсерінен жасалған сатып алу. Мысалы, үйде үш курткаңыз бола тұра, «күшті жарасып тұр, алғым келеді» деген ниетпен сатып алынған жаңа куртка - эмоциялық сатып алу.'
                                },
                                {
                                    id: 5,
                                    name: 'Дағды',
                                    description: 'бұл айналадағы дүниені қабылдау мен бағалауға әсер ететін бейімділіктер немесе стереотиптер. Психологиялық көзқарастар оң немесе теріс болуы мүмкін және тәжірибеге, тәрбиеге, білімге және әлеуметтік-мәдени контекстке байланысты болады.'
                                },
                                {
                                    id: 6,
                                    name: 'Қаржы сауаты бар адам',
                                    description: 'бұл қаржыны да, қаржыға қатысты эмоцияларды да басқара білетін адам.'
                                },
                            ],
                            tests: [
                                {
                                    id: 1,
                                    question: 'Эмоциялар күнделігін неге жүргізу керек?',
                                    answers: [
                                        {
                                            id: 1,
                                            answer: 'Мінез-құлқыңызды талдау және көңіл-күйіңіз, эмоцияларыңыз, өмірдегі жағдайлар мен қаржылық шешімдер арасындағы байланысты анықтау.',
                                            result: 'Эмоциялар күнделігі өзіңізді түсінуге және ішкі сезімдер мен сыртқы реакциялар арасындағы байланысты табуға мүмкіндік береді. Мысалы, жаман көңіл-күй = кенеттен зат сатып алу. Бұл әдіс (жазба) сіздің эмоцияларыңызды түсінуге, оларды талдауға және қайта бағалауға көмектеседі. Сондай-ақ олар сіздің қаржылық шешімдеріңіз бен әрекеттеріңізге әсер етпеуі үшін релаксация немесе өзін-өзі реттеу арқылы жұмыс істеуге де көмектеседі.',
                                            correct: true,
                                        },
                                        {
                                            id: 2,
                                            answer: 'Сіздің кірістеріңіз бен шығындарыңызды тіркеу үшін',
                                            result: 'Атауы (Эмоциялар күнделігі) айтып тұрғандай, бұл күнделік эмоцияларыңызды бақылау үшін арналған жазба. Әрине, оның қаржыға да қатысы бар, бірақ ең алдымен ол сезімдеріңіз бен көңіл-күйіңізді жазып алу үшін қажет, сонда сіз олармен дұрыс жұмыс жасай аласыз.',
                                            correct: false,
                                        },
                                        {
                                            id: 3,
                                            answer: 'Өзіңіздің жақсы көңіл-күйде екеніңізді, сәйкесінше жаңадан сауда-саттық жасай алатыныңызды түсіну үшін',
                                            result: 'Керісінше. Күнделік эмоцияларды бақылау және кенеттен болатын эмоциялық сатып алуларды болдырмау үшін қажет.',
                                            correct: false,
                                        },
                                        {
                                            id: 4,
                                            answer: 'Барлық эмоцияларды сезінетіңізді түсіну үшін',
                                            result: 'Эмоцияларыңызды түсіну - өте дұрыс. Бірақ бұл әлемдегі барлық эмоцияларды сезіну бойынша жарыс емес. Біздің жағдайда күнделік сіздің қазіргі эмоцияларыңызды талдау және түсіну үшін, сонымен қатар олардың негізінде қаржылық әдеттеріңізбен жұмыс жасау үшін қажет.',
                                            correct: false,
                                        }
                                    ],
                                },
                                {
                                    id: 2,
                                    question: 'Эмоциялар күнделігіне не жазу керек?',
                                    answers: [
                                        {
                                            id: 1,
                                            answer: 'Тапқан табысыңызды және қаржыға қатысты мақсаттарыңызды',
                                            result: 'Эмоциялар ішкі уайымдарыңызға қатысты және бөлек талдауды қажет етеді. Егер эмоциялар күнделігі мен қаржылық есептеулерді араластырып жүргізсеңіз, онда сіз өзіңіздің мінез-құлқыңыз бен эмоцияларыңызды жеке тоқтала алмайсыз, сәйкесінше оларды талдай алмайсыз.',
                                            correct: false,
                                        },
                                        {
                                            id: 2,
                                            answer: 'Жағдайлар/оқиғалар (сатып алу немесе бірдеңе сатып алу туралы шешім), ойлар, эмоциялар, реакциялар (дене және мінез-құлық)',
                                            result: 'Ақша жұмсағаннан кейін немесе шығындарды жоспарлағаннан кейін сезінетін эмоцияларыңызды жазып алыңыз. Ол қуаныш, қанағаттану, алаңдаушылық, стресс немесе кінәлау сезімдері болуы мүмкін. Осы сатып алумен немесе оқиғамен байланысты үміттеріңізді немесе мотивацияңызды сипаттап жазыңыз. Бұл жаттығу ақшаны не үшін жұмсағыңыз келетінін және ақша жұмсаған сізге көмектесетінін/көмектеспейтінін түсінуге мүмкіндік береді.',
                                            correct: true,
                                        },
                                        {
                                            id: 3,
                                            answer: 'Оқылған кітаптардан, көрген фильмдерден және тыңдалған музыкадан алған әсерлерді',
                                            result: 'Пікірлерді арнайы веб-сайттарда, бұқаралық ақпарат құралдарында немесе әлеуметтік желілерде жазуға болады. Сезімдерді, ойларды, оқиғаларды және олардың қарым-қатынасын анықтау үшін эмоциялар күнделігі қажет.',
                                            correct: false,
                                        },
                                        {
                                            id: 4,
                                            answer: 'Өмірдің кішкентай қуанышты сәттерін',
                                            result: 'Әрине, бұларды да жазып отырған жақсы, бірақ бұл үшін бөлек күнделік жүргізіңіз. Эмоциялар күнделігінде сіз тек жағымды эмоцияларды ғана емес, сонымен бірге жағымсыз эмоцияларды да жазуыңыз керек. Қуаныш, қанағаттану, алаңдаушылық, стресс, кінә және т.б..',
                                            correct: false,
                                        }
                                    ],
                                },
                                {
                                    id: 3,
                                    question: 'Жаңа iPhone 15 шықты, мен iPhone 14-імді жаңартқым келеді. Бұл шешімге қандай эмоциялар/сезімдер итермелейді?',
                                    answers: [
                                        {
                                            id: 1,
                                            answer: 'Қорқыныш',
                                            result: 'Телефон үлгіңіз ескіріп қалады деп қорқу әлі ерте.',
                                            correct: false,
                                        },
                                        {
                                            id: 2,
                                            answer: 'Шабыт',
                                            result: 'Көбірек ақша жұмсауға деген шабыт па?',
                                            correct: false,
                                        },
                                        {
                                            id: 3,
                                            answer: 'Ашу-ыза',
                                            result: 'Айфондардың бір-біріне жақын үлгілерінің бір-бірінен айтарлықтай айырмашылықтары жоқ. Тиісінше мұндай жағдайда ашулануға себеп жоқ.',
                                            correct: false,
                                        },
                                        {
                                            id: 4,
                                            answer: 'Сауда жасағаннан кейін болатын қуаныш сезімі',
                                            result: 'Жаңа зат сатып алдым деп қуануыңыз мүмкін, бірақ ол әдетте уақытша ғана сезім.',
                                            correct: true,
                                        }
                                    ],
                                }
                            ],
                        },
                        {
                            id: 2,
                            number: 2,
                            name: 'Тұтынушылық несиені рәсімдеу',
                            slug: 'tutynushylyk-nesieni-rasimdeu',
                            duration: '20 мин',
                            bgImage: require('@/assets/images/visuals/course-2/lesson-2/kz/bg.png'),
                            content: {
                                description: "<div>\n" +
                                    "<p>Алдыңғы сабақта ақша психологиясы жайлы сөз қозғаған болатынбыз, бұл сабақта несиелердің құрылымына үңілеміз, сонымен қатар тиімді ұсынысты жағдайыңызға зиян келтіретін ұсыныстан ажыратуды үйренетін боламыз.</p>\n" +
                                    "</div>\n" +
                                    "<hr>\n" +
                                    "<h3 style='color: #C3F53C;'>Кең таралмаған пікір: несие дегеніміз ел экономикасының дамуы, ал банктердің еш зияны жоқ.</h3>\n" +
                                    "<p>Несие – ел экономикасының және оның дамуының құрамдас бөлігі, әйтпесе бұл қаржы құралы біздің өмірімізде болмас еді.</p>\n" +
                                    "<div>\n" +
                                    "<p>Кредитті көбіне зұлымдықтың синонимі ретінде қабылдауымыздың себебі неде? Біздің несие алу тәжірибемізде, сондай-ақ оның құрылымын және эмоционалдық жағын түсінбеуімізде.</p>\n" +
                                    "<p>Негізінде несие жаман нәрсе де емес, жақсы нәрсе де емес, оның көңіл-күйі де, ниеті де, түсі де, реңі де жоқ. Қолданылу аясына байланысты ол әркім үшін жеке мағыналарға ие болады. Кей адамдар үшін кредит жетістікке жету, өсу мен даму құралы болса, кей адамдар үшін қарызға батудың жолы.</p>\n" +
                                    "</div>\n" +
                                    "<div>\n" +
                                    "<p>Негізі несие сіздің әл-ауқатыңызға ешқандай қауіп төндірмейді. Иә, адам сенгісіз болып естіледі. Әсіресе несиенің жұмыс механизмімен танысқан кезде, яғни қарызға алынғаннан көбірек ақша қайтару керек болғанда бұндай әңгімеге сену қиын. Бірақ мейірімін көрсетіп жайдан жай сізге қарызға ақша беретін банк сіздің туысыңыз емес. Банк та компания болып табылады, сондықтан нарықта қалу үшін оған да ақша табу керек.</p>\n" +
                                    "<p>Банк ақша айналымынан табыс көреді. Мысалы, сауда-саттық жасаған кезде картамен төлегенде, банк ақша табады; капиталыңызды ұлғайту мақсатында ақшаңызды сақтау шотына салған кезде банк ақша табады; өзіңіздің активтеріңізді (пәтер, автокөлік немесе бизнес инвестициялары) толтыру үшін қарызға ақша берген кезде де банк ақша табады. Бірақ бұл бір жақты процесс емес, өйткені сіз де табыс табасыз. Мысалы: сіз сатып алған заттарыңыз үшін банктан кешбэк аласыз, ақшаңызды банкта сақтағаныңыз үшін одан сыйақы аласыз және несие алу арқылы сіз өз мақсатыңызға мерзімінен бұрын қол жеткізесіз, яғни активтерді, тауарларды немесе қызметтерді банктің ақшасына қазір сатып алып, қарызды болса кейінірек өтейсіз.</p>\n" +
                                    "</div>\n" +
                                    "<p>Егер несие алу көпшілік арасында кеңінен таралған болса, демек адамдар несие алуға дайын және оны қайтара алатындарын біледі  (кем дегенде білуі керек); және банктер болса адамдардың төлем қабілеттілігін көргендіктен несиені мақұлдайды. Тауарды сату сатушыға табыс әкеледі. Сатушы болса өз кезегінде өз қызметтері мен өнімдерін несие алып толықтыра алады. Осылай ақша үнемі қозғалыста болады да ел экономикасын дамытады.</p>\n" +
                                    "<div>\n" +
                                    "<p>Егер банктер тек ақша сақтау функциясын орындаса, онда олар жайлы теріс пікір айтылмайтын еді. Олардың күзет компанияларынан еш айырмашылығы болмайтын еді. Олар сіздің капиталыңызды сақтап қана қоймай, оны айналымға енгізіп, одан ақша табады. Міне, осы процесс олардың жұмысын қиындаты, бірақ сонымен қатар маңызды қылады.</p>\n" +
                                    "<p>Банктер несие беру арқылы адамдар және олардың қажеттіліктерінен ақша табады деп банктерді кінәлауға болады немесе несиенің қалай жұмыс істейтінін түсініп, оны мақсатқа жету құралы ретінде қарастыруға болады. Сабақ барысында үйренетініміз - осы.</p>\n" +
                                    "</div>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/kz/img-1.png')+"'/>\n" +

                                    "<p>Несие мәселесіне келгенде адамдар ең алдымен өз мақсаттарын ойлайды. Ең бастысы қалағанын сатып алуға ақша табу, міндеттемелер жайлы кейін ойланады. Онда тұрған ештеңе жоқ, өйткені несие – мақсатқа жету құралы. Яғни, оны қажетті уақытта және дұрыс мақсатта пайдалана білсек болғаны.</p>\n" +
                                    "<div>\n" +
                                    "<p>Әрқайсымыздың түрлі мақсаттарымыз бар және оларды асықпай ақша жинап орындаған дұрыс. Алайда мақсатыңызды дәл қазір орындау керек болып жатса, банктен несие алуға болады. Бірақ бұл жағдайда да дұрыс таңдау жасай білу керек.</p>\n" +
                                    "<p>Сіздің міндеттемелеріңіз сіз таңдаған несиеге байланысты болады. Бір мақсат үшін бірнеше ұсыныс болуы мүмкін: мысалы, тоңазытқышты сатып алған кезде, бөліп төлеу рәсімдеуге, қолма-қол ақшалай несие алуға немесе несие картасын пайдалануға болады. Үшеуі де мақсатқа жетуге мүмкіндік береді, бірақ бұлардың барлығы дұрыс шешім деген сөз емес.</p>\n" +
                                    "</div>\n" +
                                    "<p>Өзіңіз қараңыз:</p>\n" +
                                    "<ul>\n" +
                                    "<li>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/img-2.png')+"'/>\n" +
                                    "<p>Қолма-қол несие — бұл қолыңызға берілетін ақша.</p>\n" +
                                    "</li>\n" +
                                    "</ul>\n" +
                                    "<p>Мұндай несиелер бойынша пайыздық мөлшерлеме әдетте жоғары болады, яғни артық төлем де жоғары болады. Тиісінше, несиенің бұндай түрі сізге келмейді.</p>\n" +
                                    "<ul>\n" +
                                    "<li>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/img-3.png')+"'/>\n" +
                                    "<p>Несие картасы — бұл банк ақшасы салынған карта, оны кез келген уақытта несие алуға өтініш бермей-ақ пайдалануға болады.</p>\n" +
                                    "</li>\n" +
                                    "</ul>\n" +
                                    "<p>Несие картасын пайдалану жақсы шешім болып көрінгенімен, шын мәнінде бұл тиімсіз шешім. Мысалы, сіздің несие картаңыз 200 мың теңгелік карта, ал тоңазытқыш 350 мың теңге тұрады. Немесе басқа жағдай: сіз керек сомаға тоңазытқыш таптыңыз, бірақ карта сатып алуды үш айға ғана бөледі, ал сізге сатып алуды алты айға бөліп төлеу ыңғайлырақ болар еді.</p>\n" +
                                    "<ul>\n" +
                                    "<li>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/img-4.png')+"'/>\n" +
                                    "<p>Бөліп төлеу жоспары — белгілі бір тауардың немесе қызметтің құнын бірнеше ай бойы пайыссыз бөліп төлеу.</p>\n" +
                                    "</li>\n" +
                                    "</ul>\n" +
                                    "<p>Сізге тиімді болатын шешім — осы. Бөліп төлеуді өз қажетіңізге қарай бірнеше айға дейін рәсімдеуге болады.</p>\n" +

                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/kz/img-5.png')+"'/>\n" +
                                    "<ul>\n" +
                                    "<li>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/img-6.png')+"'/>\n" +
                                    "<p>Тұтыну мақсаттарына берілетін несие — бұл тауарлар мен қызметтерді сатып алу, медициналық төлемдерді төлеу, жол жүру, үйдегі жөндеу жұмыстары және басқа да жеке қажеттіліктер үшін банктен қарызға алатын ақша.</p>" +
                                    "</li>\n" +
                                    "</ul>\n" +
                                    "<p>Бұл мәселеге тереңірек үңілсек, несиелердің көбі тұтынушылық мақсаттарға берілетін несиелер. Яғни, адамдар мен олардың жеке мақсаттары үшін берілетін несиелер. Автокөлік несиесі немесе ипотека да осы тұтынушылық несиеге жатады. Бірақ банктер оларды жеке санатқа жатқызады, өйткені оларды рәсімдеу үшін көбірек құжат жинау керек, түрлі тексерістерден өту керек. Бірақ біз тіпті студенттерге берілетін тұтынушылық несие, оның ішінде ақшалай несие мен бөліп төлеуге тоқталатын боламыз.</p>\n" +
                                    "<blockquote class='lamp'>\n" +
                                    "<p>Бұндай несиенің ең басты қаупі — оның қолжетімділігі.</p>\n" +
                                    "</blockquote>\n" +

                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/kz/img-7.png')+"'/>\n" +
                                    "<p>Сіз бір өте маңызды нәрсені түсінуіңіз керек: кез келген несие қарызға ақша алу деген сөз. Сондықтан пайыздық мөлшерлемені, артық төлемді және несие мерзімін ескере отырып, несие түрін дұрыс таңдай білу керек.</p>\n" +
                                    "<div>\n" +
                                    "<p>Тұтыну несиелерін мақсаттың бар немесе жоқ болуына, мерзімінің ұзақтығына және сомасына қарай бірнеше санатқа бөлуге болады. Кейде оларды біріктіруге де болады.</p>\n" +
                                    "</div>\n" +
                                    "<p>Мақсатына қарай олар келесі түрлерге бөлінеді:</p>\n" +
                                    "<div>\n" +
                                    "<h2>Мақсатты несие</h2>\n" +
                                    "<p>Мұнда бәрі түсінікті — несие білгілі бір мақсатқа беріледі. Несие алуға өтініш бергенде, сіз мақсатыңызды көрсетесіз. Мысалы, смартфон сатып алу. Тауар сатып алғыңыз келетін дүкенді таңдайсыз, ал банк болса ақшаны тікелей сатушының шотына аударады. Бұл ақшаның нақты бір мақсатқа, яғни смартфон сатып алуға жұмсалуын қамтамасыз етеді.</p>\n" +
                                    "</div>\n" +
                                    "<div>\n" +
                                    "<h2>Мақсатсыз несие</h2>\n" +
                                    "<p>Мақсатты несиенің қарама-қарсы түрі. Сіз ақшалай несие аласыз және банкке есеп бермейсіз, яғни банк берген ақшаны өз қалауыңызша жұмсайсыз. Ол ақшаға смартфон аласыз ба, жиһаз аласыз ба немесе саяхаттайсыз ба, оны сізден ешкім сұрамайды. Әдетте мұндай несиелер ең тиімсіз болып келеді.</p>\n" +
                                    "</div>\n" +
                                    "<p>Мерзімі мен сомасына байланысты несие кепілдік немесе кепілсіз болуы мүмкін. Мысалы, несие алу үшін жылжымайтын мүлкіңізді кепілдікке қою керек пе, жоқ па?</p>\n" +
                                    "<div>\n" +
                                    "<h2>Кепілдік несие</h2>\n" +
                                    "<p>Бұндай несие ұзақ мерзімге, әдетте 15-25 жылға дейін беріледі. Егер кепілге қойылған мүлік сұралған несие сомасынан артық болса, мұндай несиелер кез келген мөлшерге дейін берілуі мүмкін. Әдетте несие мөлшері кепіл құнының 50-80%-ын құрайды. Несие өтелмеген жағдайда банк өзін осылай сақтандырады.</p>\n" +
                                    "</div>\n" +
                                    "<div>\n" +
                                    "<h2>Кепілсіз несие</h2>\n" +
                                    "<p>Банкке ешқандай кепіл керек емес. Сондықтан мұндай несиелердің мерзімі мен мөлшері шектеулі болады. Әдетте несие 7 000 000 теңгеге дейінгі сомаға 5 жылға дейін беріледі.</p>\n" +
                                    "</div>\n" +
                                    "<p>Пайыздың бар/жоғына байланысты:</p>\n" +
                                    "<div>\n" +
                                    "<h2>Пайыздық несие</h2>\n" +
                                    "<p>Несиенің барлық түрі пайыздық несие болып табылады. Оның жұмыс істеу тәртібі түсінікті, яғни қарыз алушы белгілі бір ақша сомасын қарызға алады, бірақ банкке бұл сомамен қоса тағы қосымша соманы қайтаруға міндетті. Ол қосымша сома пайыз деп аталады. Банк сізге несие беру арқылы ақша табады. Ал сіз болсаңыз банк сізге керек кезде ақша бергені үшін оған қосымша ақша төлейсіз.</p>\n" +
                                    "</div>\n" +
                                    "<div>\n" +
                                    "<h2>Пайызсыз несие</h2>\n" +
                                    "<p>Пайызсыз несие бөліп төлеу деп аталады. Бұл қарыз алушының дәл сол соманы қосымша пайызсыз қайтаратынын білдіреді. Бөліп төлеу өте тиімді құрал, бірақ оны асыра пайдаланудан аулақ болу керек.</p>\n" +
                                    "</div>\n" +
                                    "<p>Жоғарыда айтып кеткеніміздей, несиелердің біріккен түрлері де болады.</p>\n" +
                                    "<p>Яғни, электроника дүкенінде телефон сатып алуға бөліп төлеу рәсімдеу - пайызсыз, кепілсіз, мақсатты несие болып табылады. Сіз ол үшін артық төлем жасамайсыз (пайызсыз), мүлкіңізді немесе автокөлігіңізді кепілдікке қоймайсыз (кепілсіз) және банк нақты мақсат үшін, яғни телефон сатып алу үшін ақшаны тікелей сатушының шотына аударады (мақсатты).</p>\n" +
                                    "<div>\n" +
                                    "<p>Ақшалай несие болса — мақсатты емес, кепілсіз және пайыздық несие. Сіз мүлкіңізді кепілдікке қоймайсыз, бірақ банк ақшаны тікелей сізге жібереді және оны қайда жұмсайтыныңызды сұрамайды. Онымен қоса сіз алған сомаңыздың үстінен несие пайызын да төлейсіз. Ал бұндай несиенің пайызы әдетте жоғары болады. Несие пайыздары жайлы кейінірек сөз қозғайтын боламыз.</p>\n" +
                                    "</div>\n" +

                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/kz/img-8.png')+"'/>\n" +
                                    "<p>Несиелерді жақсы түсіну үшін олардың жіктелуін ғана емес, сонымен қатар олардың құрамдас бөліктерін, яғни сома, мерзім, мөлшерлеме және төлем кестесі қалай және не нәрсеге әсер ететінін білу маңызды. Әрине, бұл құрамдас бөліктер несиенің барлық түрлерінде маңызды рөл атқарады, бірақ әсіресе ақшалай несиеге мән бергеніміз жөн, себебі несиенің бұл түрі басқаларына қарағанда әлдеқайда тиімсіз.</p>\n" +
                                    "<p>Барлығын рет ретімен қарастырайық, ал содан кейін бір қорытындыға келейік.</p>\n" +

                                    "<h3 style='color: #C3F53C;'>Несие мөлшерлемесі</h3>\n" +
                                    "<p>Артық төлем тікелей несие мөлшерлемесіне байланысты. Ол сіз қарызға алған сомаңыздың үстінен қанша төлеу керек екеніңізді білдіреді. Бұл сіз қарызға алған ақшаның өзіндік құны десе де болады. Мысалы, сіз бір миллион теңгеге ақшалай несие алдыңыз делік, бірақ банкке қайтаратын сомаңыз - 1,5 миллион теңге. Бұл дегеніміз 500 000 теңге сіз алған миллион теңгеңіздің бағасы.</p>\n" +
                                    "<blockquote>\n" +
                                    "<p>Пайыздық мөлшерлеме несиелердегі ең шешуші компонент болып табылады. Банктер жылдық пайыздық мөлшерлеме туралы айтқан кезде, сіз бірден артық төлем сомасын ойыңызда санап қоюыңыз керек.</p>\n" +
                                    "</blockquote>\n" +
                                    "<blockquote>\n" +
                                    "<p>Мөлшерлеме инфляция деңгейі, несиенің түрі мен мақсаты, сақтандырудың бар/жоғы, қарыз алушының несиелік рейтингі және Ұлттық банк белгілеген пайыздық мөлшерлеме сияқты әртүрлі факторларға байланысты. Ұлттық банк пайыздық мөлшерлемені төмендеткен кезде банктер де несие өнімдерінің пайыздық мөлшерлемесін төмендетеді. Негізгі мөлшерлеме көтерілсе, банктер өз ұсыныстарын көтереді.</p>\n" +
                                    "</blockquote>\n" +
                                    "<h3 style='color: #C3F53C;'>Мен пайыздық мөлшерлемені білемін, енді артық төлемді қалай есептеймін?</h3>\n" +
                                    "<p>Мысалы, сіз жылдық 34%-бен 500 000 теңгеге ақшалай несие аласыз. Біз <span style='color: #C3F53C;'>сома * пайыз / 100</span> формуласын қолданамыз да жылдық артық төлемді есептейміз. Ол 170 000 теңгені құрайды. Ал 170 000 теңгені 12 айға бөлсеңіз, 14 166 теңге шығады – бұл осы мөлшерлеме бойынша ай сайынғы артық төлеміңіз.</p>\n" +
                                    "<h3 style='color: #C3F53C;'>Мен ай сайынғы төлем сомасын білемін, енді пайыздық мөлшерлемені қалай есептеймін керек?</h3>\n" +
                                    "<p>Ай сайынғы төлем сомасын төлем жасайтын айлар санына көбейтіңіз, содан кейін сол сомадан негізгі қарыз сомасын шегеріңіз. Шыққан сома сіздің артық төлеміңіз. Әрі қарай, мына формула бойынша есептеу жүргіземіз: <span style='color: #C3F53C;'>артық төлем сомасы * 100 / қарыз сомасы.</span></p>\n" +
                                    "<div>\n" +
                                    "<h2>Мысалымызды бөлек жазайық:</h2>\n" +
                                    "<p>500 000 – несие сомасы, негізгі қарыз</p>\n" +
                                    "<p>34% — жылдық пайыздық мөлшерлеме</p>\n" +
                                    "<p>12 ай – несие мерзімі</p>\n" +
                                    "<hr>\n" +
                                    "<p>500 000 теңге * 34% = 170 000 теңге (артық төлем)</p>\n" +
                                    "<p>170 000 теңге/12 = 14 166 теңге (айына артық төлем)</p>\n" +
                                    "<p>500 000 теңге/12 = 41 666 теңге (айына негізгі қарыз)</p>\n" +
                                    "<p>14 166 теңге + 41 666 теңге =55 833 теңге (ай сайынғы төлем = айына негізгі қарыз + айына артық төлем)</p>\n" +
                                    "<p>170 000 * 100 / 500 000 = 34% (пайыздық мөлшерлеме)</p>\n" +
                                    "</div>\n" +
                                    "<blockquote class='lamp'>\n" +
                                    "<p>Сіз бірнеше жылға несие ресімдедіңіз, бірақ оның пайыздық мөлшерлемесін білмейсіз. Ендеше бір жылдық артық төлемді есептеп, содан кейін мына формуланы қолдануыңыз керек: <span style='color: #C3F53C;'>артық төлем сомасы * 100 / қарыз сомасы.</span></p>\n" +
                                    "</blockquote>\n" +
                                    "<h2>Мысалы:</h2>\n" +
                                    "<p>Ай сайынғы төлемі 113 647 теңге құрайтын 36 айға (3 жылға) алынған 3 000 000 теңге мөлшеріндегі несие бойынша мөлшерлеме қандай болады?</p>\n" +
                                    "<div>\n" +
                                    "<p>Ай сайынғы төлемді (113 647 теңге) бүкіл мерзімге (36 ай) көбейтсек, 4 091 292 теңге аламыз. Егер сіздің қарызыңыз 3 000 000 теңге болса, онда артық төлем 1 091 292 теңгені құрайды.</p>\n" +
                                    "</div>\n" +
                                    "<div>\n" +
                                    "<p>Сіздің 1 091 292 т құрайтын артық төлеміңізді үшке бөлсек, (несиеңіздің 3 жылы), нәтижесінде біз жылына 363 764 теңге құрайтын артық төлем сомасын аламыз. Енді тағы да сол формуланы қолдансақ: 363 764 * 100 / 3 000 000 теңге = 12, яғни пайыздық мөлшерлемеңіз 12 % құрайды.</p>\n" +
                                    "</div>\n" +
                                    "<p>Банктер өз ұсыныстарында несие мөлшерлемесінің <span style='color: #C3F53C;'>номиналды</span> және <span style='color: #C3F53C;'>тиімді</span> деген екі түрін көрсетеді. Біріншісі таза несие бойынша мөлшерлемені көрсетсе, екіншісі сақтандыру, техникалық қызмет көрсету және т.б. түріндегі қосымша шығындар қосылған мөлшерлемені көрсетеді. Несие бойынша ұсыныстарды салыстыру кезінде тиімді пайыздық мөлшерлемеге мән берген дұрыс, өйткені ол қарыз алушыға несиенің бір жыл ішіндегі шынайы құнын көрсетеді.</p>\n" +
                                    "<blockquote class='lamp'>\n" +
                                    "<p>Пайыздық мөлшерлемеге қатысты қарапайым ережені есіңізге сала кетейік: пайыздық мөлшерлеме неғұрлым жоғары болса,  артық төлем де соғұрлым көп болады.</p>\n" +
                                    "</blockquote>\n" +

                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/kz/img-9.png')+"'/>\n" +
                                    "<p>Сома қарыз алушыға, яғни сіздің сұрауларыңызға, төлем қабілеттілігіңізге, мәртебеңізге, несие тарихыңызға байланысты.</p>\n" +
                                    "<blockquote>\n" +
                                    "<p>Берілетін несие аясында қалағаныңызша сұрай аласыз, бірақ банк сізге мұндай соманы беру/бермеуі банктің сізге қаншалықты сенетініне байланысты. Егер сіз бұрын несиелеріңізді жақсы өтеп, төлемдеріңіздің мерзімін кешіктірмеген болсаңыз және ағымдағы несиелік жүктемеңіз рұқсат етсе, онда банк толық соманы мақұлдауы әбден мүмкін.</p>\n" +
                                    "</blockquote>\n" +
                                    "<p>Бірақ мәселе мүмкіндігінше көбірек ақша алуда емес, керісінше - мүмкіндігінше азырақ алуда. Өйткені алған қарызыңызды әлі пайызын қосып қайтару керек.</p>\n" +
                                    "<h2 style='color: #FFFFFF;'>Бұл мәселеде көмектесетін кеңестерге назар аударайық:</h2>\n" +
                                    "<ol>\n" +
                                    "<li>\n" +
                                    "<h2 style='color: #C3F53C;'>Қажет соманы ғана алыңыз</h2>\n" +
                                    "<p>Өз қажеттіліктеріңізге қарай ғана қарыз алуға тырысыңыз. Ақшалай несиені рәсімдеген кезде барлық шығындарыңызды есептеңіз. Мысалы, балаларды тіс дәрігеріне көрсетуге және шатырды жөндеуге ақша қажет, яғни смартфон сатып алу үшін қосымша ақша алудың қажеті жоқ.</p>\n" +
                                    "<p>Сондай-ақ түрлі тиімді ұсыныстардан аулақ болыңыз: банк кепілсіз 4 миллион несие ұсынады, ал сізге 3 миллион керек. Қажетінен артық ақша алудың қажеті жоқ. Әрбір теңге үшін үстінен тағы теңге қосып қайтаратыныңызды ұмытпаңыз.</p>\n" +
                                    "</li>\n" +
                                    "<li>\n" +
                                    "<h2 style='color: #C3F53C;'>Барлық ұсыныстарды қарастырыңыз</h2>\n" +
                                    "<p>Жоспар мынадай: егер ақшалай несие алғыңыз келсе, онда барлық банктерді аралап, олардың шарттарын мұқият зерттеңіз (әсіресе мөлшерлемелерін). Егер сіз мақсатты несие алуды жоспарласаңыз, құны мен сапасы бойынша ең жақсы ұсынысы бар дүкенді немесе ең жақсы ұсынысы бар банктті таңдаңыз. Әдетте, дүкендердің  бірнеше серіктес банктері болады. Соның ішінде сіз өзіңіз үшін ең тиімдісін таңдай аласыз.</p>\n" +
                                    "</li>\n" +
                                    "</ol>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-2/kz/img-10.png')+"'/>\n" +
                                    "<p>Бұл несиенің ең икемді бөлігі. Ол ай сайынғы төлем сомасын да, артық төлемді де, ал кейбір жағдайларда пайыздық мөлшерлемені де өзгертеді.</p>\n" +
                                    "<h2 style='color: #FFFFFF'>Несие мерзімі қалай және не нәрсеге әсер етеді?</h2>\n" +
                                    "<table>\n" +
                                    "<tr>\n" +
                                    "<td>Несие мерзімі және ай сайынғы төлем</td>\n" +
                                    "<td>Несие мерзімі неғұрлым қысқа болса, ай сайынғы төлем соғұрлым жоғары болады.</td>\n" +
                                    "</tr>\n" +
                                    "<tr>\n" +
                                    "<td>Несие мерзімі және артық төлемі</td>\n" +
                                    "<td>Несие мерзімі неғұрлым қысқа болса, артық төлем соғұрлым аз болады.</td>\n" +
                                    "</tr>\n" +
                                    "<tr>\n" +
                                    "<td>Несие мерзімі және пайыздық мөлшерлемесі</td>\n" +
                                    "<td>Кейбір жағдайларда төменірек пайыздық мөлшерлеме қысқа мерзімге беріледі.</td>\n" +
                                    "</tr>\n" +
                                    "</table>\n" +
                                    "<h2 style='color: #FFFFFF'>Несие мерзімін қалай анықтауға болады?</h2>\n" +
                                    "<p>Көріп отырғаныңыздай, тұтынушылық несие рәсімдеген кезде ол сізге екі, тіпті үш есе қымбатқа түспейтіндей қысқа мерзімді таңдаған дұрыс.</p>\n" +
                                    "<div>\n" +
                                    "<p>Сондай-ақ несиені не үшін алып жатқаныңызды міндетті түрде ескеруіңіз керек. Егер алып жатқан тауарыңыз ескіретін өнім болса, құндылығы мен қажеттілігін жоғалтқан нәрсенің ақысын төлеуден аулақ болу үшін несиені міндетті түрде қысқа мерзімге рәсімдеу керек.</p>\n" +
                                    "</div>\n" +
                                    "<p>Ескеретін тағы бір нәрсе - сіздің несие жүктемеңіз. Несие мерзімі ай сайынғы төлемді арттырады немесе азайтады. Басқа қарыздарды жабу үшін қарызға батпайтындай және ай сайынғы соманы төлей алатындай алыңыз. Бұл жағдайда тиімді болмаса да, несиені ұзақ уақытқа рәсімдеуді ойластырыңыз. Осы уақыт ішінде сіз өз жағдайыңызды жақсартып, несиені тезірек өтей алуыңыз мүмкін.</p>\n" +
                                    "<hr>\n" +
                                    "<p>Қорыта келе, тұтынушылық несиені алу үшін үш нәрсені – несие мөлшерлемесін, мерзімін және сомасын, сондай-ақ олардың бір-біріне қалай әсер ететінін түсіну керек. Несиенің егжей-тегжейін білу арқылы сіз банк ұсыныстарын өзіңіз салыстыра аласыз және олардың ішінен ең тиімдісін таңдай аласыз.</p>\n" +
                                    "<div>\n" +
                                    "<p>Ал егер сіз несиенің тіпті бір құрамдас бөлігіне мән бермейтін болсаңыз, қарызға батып кетуіңіз әбден мүмкін. Бұл туралы келесі сабақта сөз қозғайтын боламыз.</p>\n" +
                                    "</div>\n"
                            },
                            dictionary: [
                                {
                                    id: 1,
                                    name: 'Тұтынушылық несие (немесе ақшалай несие)',
                                    description: 'тұтыну мақсаттарына берілетін несие, яғни тауарлар мен қызметтерді сатып алу, медициналық төлемдерді төлеу, жол жүру, үйдегі жөндеу жұмыстары және басқа да жеке қажеттіліктер үшін банктен қарызға алатын ақша. Мұндай несие алған кезде оның мақсатын растаудың қажеті жоқ.'
                                },
                                {
                                    id: 2,
                                    name: 'Бөліп төлеу',
                                    description: 'тауардың ақысын тең бөліктерге бөліп, бірнеше ай бойы төлеуге мүмкіндік беретін сатып алу әдісі.'
                                },
                                {
                                    id: 3,
                                    name: 'Мақсатты несие',
                                    description: 'бұл белгілі бір анық мақсат үшін алынатын несие. Көбінесе автокөлік, пәтерді, тауар сатып алар кезде бөлінеді.'
                                },
                                {
                                    id: 4,
                                    name: 'Мақсаты жоқ несие.',
                                    description: 'несиенің бұл түрін рәсімдеген кезде ақшаның қайда жұмсалғанын растауды банк талап етпейді. Бұл тұтынушылық несие.'
                                },
                                {
                                    id: 5,
                                    name: 'Кепілді қажет ететін несие',
                                    description: 'несиені рәсімдеген кезде банк кепіл (әдетте мүлік) қоюды талап ететін несие түрі. Мұндай несиелердің мерзімі ұзағырақ, сомасы көбірек және пайыздық мөлшерлемесі төмен болады.'
                                },
                                {
                                    id: 6,
                                    name: 'Кепілсіз несие',
                                    description: 'кепілді қажет етпейтін несие. Мұндай несиелер үшін өтінім берген кезде банк қосымша кепіл қоюды талап етпейді. Әдетте олардың сомасы мен мерзімі азырақ болады. Рәсімдеу барысында көп құжат қажет етпейді.'
                                },
                                {
                                    id: 7,
                                    name: 'Пайыздық мөлшерлеме',
                                    description: 'бұл несиенің құны. Пайыздық мөлшерлеме неғұрлым жоғары болса, артық төлем соғұрлым көп болады.'
                                },
                            ],
                            tests: [
                                {
                                    id: 1,
                                    question: 'Банк несиенің қай түрін ұсынып жатыр?',
                                    hint: 'Жеке қажеттіліктеріңізге 7 миллион теңгеге дейін ақшалай несие алыңыз. Кепілсіз, жылдық 35%-бен 5 жылға дейінгі мерзімге.',
                                    answers: [
                                        {
                                            id: 1,
                                            answer: 'Бұл мақсатсыз несие',
                                            result: 'Банк шығыстардың мақсатын көрсетпеді – бұл мақсатсыз несие. Сонымен қатар, шарттарға сәйкес, бұл жеке қажеттіліктерге арналған қолма-қол ақша, яғни бұл тұтынушылық және кепілсіз несие.',
                                            correct: false,
                                        },
                                        {
                                            id: 2,
                                            answer: 'Бұл кепілсіз несие',
                                            result: 'Банк мүлікті кепілге қоюды талап етпейді. Шарттарға сәйкес, сіз банктен ақша аласыз да, оны өз қалауыңызша жұмсай аласыз. Бұл несие де кепілсіз және тұтынушылық несие екенін білдіреді.',
                                            correct: false,
                                        },
                                        {
                                            id: 3,
                                            answer: 'Бұл тұтынушылық несие',
                                            result: 'Дұрыс. Бірақ, сондай-ақ, бұл кепілсіз және мақсатсыз несие.',
                                            correct: false,
                                        },
                                        {
                                            id: 4,
                                            answer: 'Бұл тұтынушылық кепілсіз мақсатсыз несие',
                                            result: 'Бұл тұтынушылық несие, бірақ қосымша шарттар бойынша бұл мақсатсыз және кепілсіз несие.',
                                            correct: true,
                                        }
                                    ],
                                },
                                {
                                    id: 2,
                                    question: 'Тіс дәрігерінің қызметін төлеу керек, бірақ айлық түскенге дейін әлі біршама уақыт бар. Мұндай жағдайда не істейсіз?',
                                    answers: [
                                        {
                                            id: 1,
                                            answer: 'Бөліп төлеймін',
                                            result: 'Бұл жақсы ой, себебі төлеміңіз бірнеше айға бөлінеді. Егер тіс клиникасы бөліп төлеу әдісін ұсынса, онда бұл ең дұрыс шешім. Бөліп төлеу опциясы болмаса, біреуден қарызға ақша алуды қарастыруға болады.',
                                            correct: true,
                                        },
                                        {
                                            id: 2,
                                            answer: 'Қарызға ақша сұраймын',
                                            result: 'Бұл да, негізі, жақсы шешім, бірақ оның да қиын тұстары бар. Біріншіден, сіз айлық алғаннан кейін қарызды өтеуіңіз керек, ал сома үлкен болса, бюджетке айтарлықтай салмақ түседі. Екіншіден, досыңыз немесе әріптесіңіз ақшаны мерзімінен бұрын қайтаруды сұрауы мүмкін, сонда айлық алғанға дейін ақша табудың басқа нұсқаларын іздеп, дүрбелеңге салынасыз. Бұл опцияны клиника бөліп төлеу әдісін ұсынбаса ғана таңдаңыз.',
                                            correct: false,
                                        },
                                        {
                                            id: 3,
                                            answer: 'Ақшалай несие аламын',
                                            result: 'Алғашқы екі опция мүмкін болмаған жағдайда ғана соңғы шара ретінде ақшалай несие алуға болады. Есіңізде болсын, ақшалай несие алған кезде банкке қайтаратын сомаңыз емделу құнынан артық болады.',
                                            correct: false,
                                        },
                                        {
                                            id: 4,
                                            answer: 'Емделуді кейінге қалдырамын',
                                            result: 'Болашақта тіс дәрігері қызметінің бағасы көтерілуі мүмкін. Тістеріңіздің жағдайы нашарлайды, ал қызметтер қымбаттайды.',
                                            correct: false,
                                        }
                                    ],
                                },
                                {
                                    id: 3,
                                    question: '700 000 теңгені ақшалай 37%-бен аласыз. Ай сайынғы артық төлеміңіз қандай болады?',
                                    answers: [
                                        {
                                            id: 1,
                                            answer: '295 мың теңге.',
                                            result: '700 000 теңге * 37% / 100 = 295 000 теңге. Сіз бір жылдық артық төлемді есептедіңіз.',
                                            correct: false,
                                        },
                                        {
                                            id: 2,
                                            answer: '45 583 теңге',
                                            result: 'Бұндай жауапты қайдан шығарғаныңызды білмейміз 🙂',
                                            correct: false,
                                        },
                                        {
                                            id: 3,
                                            answer: '21 583 теңге',
                                            result: '700 000 теңге * 37% / 100 = 295 000 теңге. 295 000 / 12 ай = 21 583 теңге. Дәл таптыңыз! Бүкіл мерзім бойынша соманы бүкіл мерзім бойынша пайызға көбейттіңіз, содан кейін 12 айға бөлдіңіз.',
                                            correct: true,
                                        },
                                        {
                                            id: 4,
                                            answer: '82 917 тенге',
                                            result: '700 000 теңге * 37% / 100 = 295 000 теңге. 295 000 теңге + 700 000 теңге / 12 ай. Сіз ай сайынғы төлемді есептедіңіз.',
                                            correct: false,
                                        }
                                    ],
                                },
                                {
                                    id: 4,
                                    question: 'Қай несие сізге қымбатқа түседі?',
                                    answers: [
                                        {
                                            id: 1,
                                            answer: 'Екі жылға 42%-бен алатын 500 000 теңге',
                                            result: 'Барлық мерзім бойынша артық төлем 420 000 теңгені құрайды. Бұл мерзімі мен мөлшерлемесі бойынша тізімде көрсетілгендердің ішіндегі ең қымбатқа түсетін несие.',
                                            correct: true,
                                        },
                                        {
                                            id: 2,
                                            answer: 'Үш жылға 28%-бен алатын 500 000 теңге',
                                            result: 'Біріншінікіндей бұл несиенің де бүкіл мерзім бойынша артық төлемі 420 000 теңгені құрайды. Алайда бұл жерде несие мерзімі ұзағырақ, сондықтан бұл тізімдегі ең қымбат несие емес.',
                                            correct: false,
                                        },
                                        {
                                            id: 3,
                                            answer: '500 000 теңгелік айфонды бір жылға бөліп төлеуге рәсімдеу',
                                            result: 'Бөліп төлеудің пайызы жоқ, сондықтан бұл тиімді несие.',
                                            correct: false,
                                        },
                                        {
                                            id: 4,
                                            answer: '12 айға 42%-бен алатын 500 000 теңге',
                                            result: 'Барлық мерзім бойынша артық төлем 210 000 теңгені құрайды. Бұл тізімдегіі ең қымбат несие емес. Сомасы мен мерзімі бірдей (бірінші нұсқадағыдай), бірақ мерзімі азырақ болғандықтан, артық төлемі де азырақ болады.',
                                            correct: false,
                                        }
                                    ],
                                }
                            ],
                        },
                        {
                            id: 3,
                            number: 3,
                            name: 'Тұтынушылық несиені қалай тиімді өтуге болады',
                            slug: 'tutynushylyk-nesieni-kalaj-tiimdi-otuge-bolady',
                            duration: '17 мин',
                            bgImage: require('@/assets/images/visuals/course-2/lesson-3/kz/bg.png'),
                            content: {
                                description: "<h3 style='color: #C3F53C;'>Шағын несие байқалмай үлкен қарызға айналады</h3>\n" +
                                    "<div>\n" +
                                    "<p>Сіздің қолданыстағы несиеңіз және оны тез жабамын деген ниетіңіз бар. Ал біздің осы тақырыпқа арналған сабағымыз бар.</p>\n" +
                                    "<p>Мақсаттарымызды біріктіріп, ақша туралы алаңдауымызды қоюға тырысайық. Ол үшін қарызымызды нақты санап, оны дұрыс өтеуді жоспарлайық, сондай-ақ несиені жабу мүмкіндігі болмаған жағдайда не істеу керектігін қарастырайық. Маңызды нәрседен бастаймыз. Неліктен несиелерді, тіпті бюджетке айтарлықтай әсер етпейтіндерді де ертерек жапқан дұрыс?</p>\n" +
                                    "</div>\n" +
                                    "<ol>\n" +
                                    "<li>\n" +
                                    "<p>Несие — қарызға алынатын ақша. Ол ақшаны пайдалану мүмкіндігі үшін сіз банкке белгілі бір мөлшерде пайыз төлейсіз. Бұл сізді несиені тезірек мерзімінен бұрын өтеуге ынталандыратын бірінші себеп болуы керек, өйткені осылайша сіз несиені нақты пайдаланған уақыт үшін ғана төлейсіз. Банкке төлейтін пайыз ай сайынғы төлеміңізге әлден қосылған және жалпы сома ретінде көрсетіледі, сондықтан біз оған аса назар аудармаймыз.</p>\n" +
                                    "</li>\n" +
                                    "</ol>\n" +
                                    "<div>\n" +
                                    "<h2>Мысалы,</h2>\n" +
                                    "<p>несие бойынша ай сайынғы төлеміңіз 50 000 теңге, оның 35 мыңы негізгі қарыз, ал қалғаны банкке төлейтін пайыз. Несиені толығымен өтеуге тура алты ай қалды, ал егер несиені бүгін мерзімінен бұрын жапсаңыз, 90 мың теңге үнемдейсіз. Бұл мысалда банкке төлейтін пайыз айына 15 мың теңгені құрайды, сәйкесінше әр күн 15 000 теңгенің 0,5% құрайды. Бұл дегеніміз 500 теңге. Неғұрлым көп уақыт өткен сайын, банкке соғұрлым көп ақша қайтарасыз.</p>\n" +
                                    "</div>\n" +
                                    "<ol style='counter-reset: number-counter 1'>\n" +
                                    "<li>\n" +
                                    "<p>Қарыз алу біздің психологиялық жағдайымызға да әсер етуі мүмкін. Rutgers University Study зерттеушілері депрессия деңгейі мен қарыз мөлшері арасында байланыс бар екенін айтады. Олардың айтуынша, қарызымыз көбейген сайын күйзеліске жиірек ұшыраймыз.</p>\n" +
                                    "</li>\n" +
                                    "<li>\n" +
                                    "<p>Несиені ертерек жабуға ынталандыратын тағы бір себеп — ол қаржыны басқа мақсаттарға жұмсай алуыңыз. Несие толығымен өтелгеннен кейін, бұрын ай сайынғы төлемдерге жұмсалған ақшаны жинай аласыз, бұрыннан бері қалап жүрген затыңызды сатып ала аласыз немесе инвестиция құя аласыз.</p>\n" +
                                    "</li>\n" +
                                    "<li>\n" +
                                    "<p>Кішкентай қарыз үлкен сомадағы қарыздарға айналады. Осылайша адамдар зат сатып алуға несие ала беріп өздері байқамай қарыздың астында қалады.</p>\n" +
                                    "</li>\n" +
                                    "</ol>\n" +
                                    "<div>\n" +
                                    "<h2>Мысалы,</h2>\n" +
                                    "<p>сіз смартфон сатып алдыңыз. Кейін сізге жөндеу жұмыстарының ақысын төлеуге ақша керек болды, ал біраз уақыттан кейін әйеліңізге алқа сыйладыңыз. Алқаны да бөліп төлеу арқылы сатып алдыңыз. Мұның бәрінің ақысы енді сіздің табысыңыздың жартысын құрайды. Бір несиені өтеу үшін басқа несие керек болатын жағдайға тап болмас үшін, несие өтеуді алдын ала жоспарлаған дұрыс.</p>\n" +
                                    "</div>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/kz/img-1.png')+"'/>\n" +
                                    "<p>Заң бойынша, егер сіз банктен несиені жеке мақсаттарыңызға алған болсаңыз, несиені жартылай немесе толығымен мерзімінен бұрын өтеуге құқығыңыз бар. Банк сізге кедергі жасай алмайды.</p>\n" +
                                    "<div>\n" +
                                    "<p>Алайда, несиенің кейбір түрлерінде жартылай және толық мерзімінен өтеу бойынша шектеулер бар. Мысалы, кәсіпкерлерге арналған несиелер және келісім-шартта мерзім бойынша шектеулер қойылған несиелер. Құжатқа қол қоятын кезде клиент банк шарттарын қабылдайды.</p>\n" +
                                    "</div>\n" +
                                    "<p>Тұтыну несиелерінің қарызды мерзімінен бұрын өтеуге тыйым салатын шарттары жоқ. Егер сіз несиені мерзімінен бұрын жапқыңыз келсе, сіз нақты пайдаланған уақытыңыз үшін ғана ақша төлейсіз.</p>\n" +
                                    "<div>\n" +
                                    "<p>Сіз жеке қажеттіліктеріңізге ақшалай несие алдыңыз, бірақ кейбір жоспарларыңыз өзгерді, ақшаның бір бөлігі қолыңызда қалды. Ол ақшаға жалақы алып, несиенің жұмсалған бөлігі мөлшеріндегі соманы қостыңыз да, банкке қарызыңызды қайтаруды шештіңіз. Бұл жағдайда сіз тек алған соманы және несиені пайдаланған күндер үшін қосымша пайызды ғана төлейсіз. Бұл тіпті бір күн болуы да мүмкін. Мысалы, жылдық 34%-бен 12 айға алынған 200 мың теңге. Бұл дегеніміз сіздің банкке төлейтін пайызыңыз айына 2,8% және күніне 0,09% құрайды. Біздің сценарий бойынша несиені келесі күні жапсаңыз, банкке тек 200 188 теңге қайтарасыз.</p>\n" +
                                    "</div>\n" +
                                    "<p>Егер банк сіздің құқықтарыңызды бұзып, келісім-шартта қарастырылмаған мерзімінен бұрын өтеу үшін тұрақсыздық айыппұлын өндіріп алса, Сіз Қазақстан Республикасы Қаржы нарығын реттеу және дамыту агенттігіне өтініш беруге құқылысыз.</p>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/kz/img-2.png')+"'/>\n" +
                                    "<p>Банк несие шарттары бұзылған жағдайда ғана оны мерзімінен бұрын өтеуді талап етеді. Ең басында банк келісілген шарттарды орындамау себебін білу үшін сізге қоңырау шалады. Мысалы, ипотекаға алынған үйдің  сақтандыруының болмауы немесе қаражатты дұрыс пайдаланбау, мәселен сіз ақшаны несиелеріңізді қайта қаржыландыру үшін алып, оны жеке қажеттіліктеріңізге жұмсадыңыз. Ал тұтыну несиелері туралы айтатын болсақ, банк көбінесе қарызды өтемеген жағдайда ақшаны қайтаруға мәжбүрлейді.</p>\n" +
                                    "<p>Төлемді бір рет кешіктірсеңіз, банк қарызды толық қайтаруды талап етпейді, бірақ бірнеше ай бойы төлем жасамасаңыз, онда банк ақшасын талап етеді. Әдетте банктер ақшаны сот арқылы өндіріп алады. Мерзімінен бұрын өтеу, төлемді мерзімінен бұрын жасау, басқа елге жол жүру сияқты жағдайлар болса, банк ақшаны қайтаруды сұрап, мазаңызды алмайды.</p>\n" +
                                    "<div>\n" +
                                    "<p>Қолданыста несиеңіз бар, бірақ жұмысыңыз бен табысыңыздан айырылдыңыз. Соған қарамастан, бұған дейін жинаған ақшаңыздан ай сайынғы төлемдерді уақытылы төлейсіз. Бұл жағдайда банк несиені қайтаруды сұрамайды және сізді сенімсіз қарыз алушы деп санамайды. Өйткені, сіз міндеттемелеріңізді уақытылы орындап жүрсіз. Бұл жағдай сіздің несиелік рейтингіңізге әсер етуі мүмкін, бірақ бұл қолданыстағы несие үшін емес, жаңа несие үшін маңызды.</p>\n" +
                                    "</div>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/kz/img-3.png')+"'/>\n" +
                                    "<p>Төлеу жоспарын құру үшін сіз несиелеріңіз туралы барлық ақпаратты білуіңіз керек және кірістеріңізге қарай оларды реттестіруіңіз керек. Ал бастайық.</p>\n" +
                                    "<h3 style='color: #C3F53C;'>Барлық несиелеріңіз</h3>\n" +
                                    "<p>Егер қолданыста бірнеше несиеңіз бар болса, оларды дұрыс реттестіру қажет. Әуелі пайыздық мөлшерлемесі жоғары қарыздарды мерзімінен бұрын өтеуді жоспарлаңыз. Пайыздың есебінен сіз көбірек ақша үнемдейсіз.</p>\n" +
                                    "<p>Екіншіден, несиелеріңізді барлық мәліметтерімен бірге мына ретпен жазып шығыңыз:</p>\n" +
                                    "<div>\n" +
                                    "<h2>Мысал:</h2>\n" +
                                    "<p>1-несие: негізгі қарызы 160 000 теңгені, ал артық төлемі айына 12 мың теңгені құрайды. Төлейтін 6 ай қалды;</p>\n" +
                                    "<p>2-несие: негізгі қарызы 80 000 теңгені, ал артық төлемі айына 6 мың теңгені құрайды. Төлейтін 4 ай қалды.</p>\n" +
                                    "</div>\n" +
                                    "<p>Сіз несиелердің тек ағымдағы теңгерімін анықтадыңыз. Әзірге бұл жай ғана мәліметтер. Енді бұл ақпаратты ай сайынғы артық төлем мен қалған қарыз мөлшеріне қарап, талдап көрейік. Артық төлем қай несие үшін көбірек ақша жұмсайтыныңызды көрсетеді.</p>\n" +
                                    "<h3 style='color: #C3F53C;'>Қалай өтеуге болады:</h3>\n" +
                                    "<p>Қолыңызда дәл 80 000 теңге бар делік. Сіз не 2-несиені мерзімінен бұрын толығымен жабуды не 1-несиені жартылай өтеуді таңдауыңыз керек.</p>\n" +
                                    "<div>\n" +
                                    "<h2>1-несиені өтеу</h2>\n" +
                                    "<p>Біз пайыз есебінен көбірек ақша үнемдеу мақсатымен несиенің мерзімін қысқартып қарызды өтейміз. Банк сіздің несиеңізді қайта есептеді (160 000 теңге құрайтын негізгі қарыздан 80 000 теңге шегерілді). Енді сізддің 80 000 теңгені құрайтын негізгі қарызыңыз және үш айлық төлеміңіз қалды. Сіз несие пайызы бойынша ай сайын 12 000 теңге төлегендіктен, сіз 36 000 теңге (12 000 х 3 ай) үнемдейсіз.</p>\n" +
                                    "<hr>\n" +
                                    "<p>Екі несие бойынша не қалады? 1-несиенің 80 000 теңгесі және 2-несиенің 80 000 теңгесі қалды, барлығы — 160 000 теңге. 1-несие бойынша артық төлем (12 000 х 3 ай) 36 000 теңгеге, ал 2 несие бойынша (6 000 х 4 ай) 24 000 теңгеге тең. Қорытындылай келе екі несиенің пайызы бойынша банкке 60 000 теңге қайтарасыз.</p>\n" +
                                    "</div>\n" +
                                    "<div>\n" +
                                    "<h2>2-несиені өтеу</h2>\n" +
                                    "<p>Қолыңызда бар 80 000 теңгені 2-несиені өтеуге жұмсайсыз. Негізгі қарыз қолыңызда бар сомаға тең болғандықтан, несиені толығымен жабасыз. 24 000 теңгеңіз қолыңызда қалады.</p>\n" +
                                    "<hr>\n" +
                                    "<p>Сіздің 1-несиеңіз және 160 000 теңге құрайтын қарызыңыз бар (бірінші мысалдағыдай), бірақ ол бойынша артық төлем өзгеріссіз қалады – алты айға 72 000 теңге. Екі несие емес, бір несие алсаңыз да, екі несиенің тиімсізінің қарызын азайтсаңыз, бәрібір көбірек ақша жұмсайсыз. Бұл жағдайда бірінші мысалға қарағанда сіз 12 000 теңге жоғалтасыз.</p>\n" +
                                    "</div>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/kz/img-4.png')+"'/>\n" +
                                    "<p>Несие мерзімін қысқарту немесе ай сайынғы төлем сомасын азайту арасында таңдау жасау сіздің қаржылық жағдайыңызға, жеке мақсаттарыңызға және сізге маңызды нәрселерге байланысты.</p>\n" +
                                    "<ul>\n" +
                                    "<li>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-5.png')+"'/>\n" +
                                    "<p>Есіңізде болсын, несиені жартылай мерзімінен бұрын өтеу үшін ай сайынғы төлемнің ең төменгі сомасы қажет.</p>\n" +
                                    "</li>\n" +
                                    "</ul>\n" +
                                    "<h3 style='color: #C3F53C;'>Несие мерзімін қысқарту</h3>\n" +
                                    "<p>Мүмкіндігіңізге қарай несиенін мерзімін қысқартқан дұрыс. Осылайша сіз пайыз есебінен ақша үнемдейсіз. Мысал келтірейік:</p>\n" +
                                    "<div>\n" +
                                    "<p>Сіз банктен 6 айға 300 000 теңге қарыз алдыңыз. Жалпы артық төлем 36 мың теңге. Сіз бірінші ай сайынғы төлемді төледіңіз де, содан кейін несиенің бір бөлігін, 100 мың теңгені мерзімінен бұрын өтеймін деп шештіңіз. Несиенің мерзімін қысқартасыз. Енді қарызды өтейтін 3 айыңыз ғана қалды, ал пайыз есебінен 12 мың теңге үнемдедіңіз.</p>\n" +
                                    "</div>\n" +
                                    "<p>Қалған 3 ай бойы сіз сол 56 мың теңге төлейсіз. Себебі несие мерзімі қысқарды, бірақ ай сайынғы төлем сол қалпында қалады.</p>\n" +
                                    "<h3 style='color: #C3F53C;'>Ай сайынғы төлемді азайту</h3>\n" +
                                    "<p>Бұл да жақсы шешім, дегенмен несие мерзімін қысқарту дұрысырақ. Несиені азайтудың бұл түрін уақытша ақшалай қиналсаңыз және ай сайынғы төлемдер табысыңыздың жартысынан көбін құрайтын болса таңдауға болады. Осылайша сіз біраз болса да жағдайыңызды түзетіп, ақшаны басқа қажетті төлемдерге жұмсай аласыз.</p>\n" +
                                    "<div>\n" +
                                    "<p>Алдыңғы мысалдағыдай ай сайынғы төлем сомасын азайту үшін 100 000 теңге жұмсасаңыз, келесі 5 ай бойы ай сайын 36 мың теңгеден төлейсіз. Бұндай жағдайда сіз пайыз есебінен бар болғаны 6 мың теңге үнемдейсіз.</p>\n" +
                                    "</div>\n" +
                                    "<p>Осылайша сомаңыз азаяды, бірақ несие мерзімі өзгеріссіз қалады.</p>\n" +
                                    "<h3 style='color: #C3F53C;'>Әдістерді біріктіру және пайыз есебінен ақша үнемдеу</h3>\n" +
                                    "<ol>\n" +
                                    "<li>\n" +
                                    "<p>Сіз екі әдісті біріктіре аласыз: бірінші айда ай сайынғы төлемді азайтыңыз, содан кейін ақша пайда болған кезде несие мерзімін қысқартуды таңдаңыз. Керісінше де істеуге болады. Қалай болғанда да, сіз ақша үнемдейсіз.</p>\n" +
                                    "</li>\n" +
                                    "<li>\n" +
                                    "<p>Сонымен қатар, ай сайынғы төлемді азайту да, несие мерзімін қысқарту да бір нәтижеге әкелетіндей жағдайлар да болады.</p>\n" +
                                    "</li>\n" +
                                    "</ol>\n" +
                                    "<div>\n" +
                                    "<h2>Толығырақ айтсақ</h2>\n" +
                                    "<p>Қайтадан 300 мың теңгенің мысалына оралайық. Алдымен сіз ай сайынғы төлемді төледіңіз, содан кейін 100 000 теңгемен несиенің бір бөлігін мерзімінен бұрын өтедіңіз. Сәйкесінше ай сайынғы төлемдерді 56 мыңнан 36 мың теңгеге дейін азайттыңыз. Әрі қарай, банк мәліметтерінде 36 мың деп көрсетілсе де, егер бұрынғы төлем сомасын, яғни 56 мың теңге төлейтін жағдайыңыз болса, сіз несие бойынша артық төлемді несие мерзімін қысқартқандай теңестіресіз. Сіз ағымдағы айлардың ақысын төлеп жатқан кезде, ол 20 мың теңге өткен айлардың бір бөлігін өтейді.</p>\n" +
                                    "</div>\n" +
                                    "<ol style='counter-reset: number-counter 2'>\n" +
                                    "<li>\n" +
                                    "<p>Тағы қайтсеңіз болады? Ай сайынғы төлемді кез келген уақытта кез келген қолжетімді сомаға азайтыңыз (несиені күніне тек бір рет мерзімінен бұрын жартылай өтей аласыз). Содан кейін жалпы қарыз ай сайынғы төлем сомасына тең болғанда (яғни, қалған 4 айға бөлінген 56 мың теңге) несиені толығымен жабыңыз.</p>\n" +
                                    "</li>\n" +
                                    "</ol>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/kz/img-6.png')+"'/>\n" +
                                    "<p>Барлық несиелерді мерзімінен бұрын өтеу қажет деп ойласаңыз, қателесесіз. Көбінесе барлығы несиенің пайыздық мөлшерлемесіне, несие түріне және қарыз сомасына байланысты. Сондықтан курстың екінші сабағында біз несиенің түрлі бөліктері шарттарды қалай өзгерте алатынын және несиені сіз үшін тиімді немесе тиімсіз қылатынын түсіндірдік. Бірақ несиелерді өтеуде сіздің қазіргі қаржылық жағдайыңыз, жеке өміріңіз, отбасыңыздағы жағдай үлкен рөл атқарады.</p>\n" +
                                    "<p>Несиені мерзімінен бұрын өтеу қажет емес жағдайларды қарастырайық:</p>\n" +
                                    "<h3 style='color: #C3F53C;'>Пайыздық мөлшерлемесі төмен несие</h3>\n" +
                                    "<p>Ондай несиелер бар, көбінесе бұл мемлекеттік бағдарлама аясында берілетін несиелер. Мысалы, белгіленген мөлшерлемесі бар 7-20-25 ипотекасы. Бірақ тұтынушылық несиелер төмен мөлшерлемемен сирек беріледі. Бұл сабақта біз басқа несиелердің егжей-тегжейлеріне үңілмейміз (болашақта бұл тақырыпқа арналған арнайы курс болады), бірақ мысал ретінде бұндай жағдайды да қарастырып көрейік.</p>\n" +
                                    "<p>Егер несиенің пайыздық мөлшерлемесі төмен, ал инфляция деңгейі жоғары болса, бұндай несиені мерзімінен бұрын өтеу ақшаны қалдырып, оны инвестициялауға жұмсаған сияқты көп пайда әкелмеуі мүмкін. Дегенмен, ақшаңызды инвестициялаған кезде тәуекелге баратыңызды есте ұстаған абзал.</p>\n" +
                                    "<h3 style='color: #C3F53C;'>Несиені мерзімінен бұрын өтейтін қаражатыңыз бар, бірақ несие де сізге ауыртпалық тигізбейді</h3>\n" +
                                    "<div>\n" +
                                    "<p>Қолданыстағы несиеңіз табысыңыздың 10-20% ғана құрайтын болса, несиенің пайызын ойламауға да болады. Келісеміз, бұл несиелер туралы барлық курстарда айтылатын ақпаратқа қайшы келеді. Бірақ егер қарыз сізді мазаламаса және ақша жағынан болашағыңыз туралы алаңдамасаңыз, онда қолда бар қаражатты тиімдірек жұмсауға болады. Мысалы, ақшаны депозит шотына салу.</p>\n" +
                                    "</div>\n" +
                                    "<p>Депозиттен түсетін ақшаға сіз несие пайызын өтей аласыз.</p>\n" +
                                    "<h3>Затты бөліп төлеуге сатып алғандағы жағдайды қарастырайық:</h3>\n" +
                                    "<div>\n" +
                                    "<p>Сіз смартфон мен Dyson шаш кептіргішін бөліп төлеуге сатып алдыңыз. Сатып алған заттарыңыздың ақысы 1 200 000 теңгені құрады. Бөліп төлеу мерзімі 12 ай, ал ай сайынғы төлемі 100 000 теңгеге тең.</p>\n" +
                                    "<p>Бұл ретте сол 1 200 000 теңге сіздің депозит шотыңызда жатыр (мөлшерлеме: 15%, мерзімі: 1 жыл). Бұл депозитке айына 14 000 теңгедей ақша түседі деген сөз. Бір жылдан кейін сіз 170 000 теңге пайда табасыз.</p>\n" +
                                    "<p>Егер несиенің ай сайынғы төлемі табысыңыздың жартысын құрамаса, онда 12 айдан кейін сіз несиені толығымен өз қаражатыңызбен өтейсіз, оның үстіне депозит шотына түскен сыйақыңызды аласыз. <span style='color: #C3F53C;'>Ал егер пайыздық мөлшерлемесі бар несие алған болатын болсаңыз, депозиттің пайызы несиенің пайызын төмендетеді.</span></p>\n" +
                                    "</div>\n" +
                                    "<h3 style='color: #C3F53C;'>Жалақыңыз жақын арада түспесе және бұл — қалтаңыздағы соңғы ақша болса</h3>\n" +
                                    "<p>Қарыздың кесірінен психологиялық тұрғыдан өзіңізді ыңғайсыз сезінсеңіз де, несиеңізді мерзімінен бұрын өтеу үшін соңғы ақшаңызды берудің керегі жоқ. Неге?</p>\n" +
                                    "<div>\n" +
                                    "<h2>Мысалы,</h2>\n" +
                                    "<p>сіз жалақы алдыңыз және барлық қаражатты қолданыстағы несиені мерзімінен бұрын өтеуге жұмсаймын деп шештіңіз делік. Енді сіз ақшасыз қалдыңыз, бірақ әлі қалай болса да күн көру керек. Медициналық шығындар, көлік жөндеу, жұмыстан айырылу сияқты күтпеген жағдайлар туындаған кезде адамдар көбінесе несиелерге жүгінеді. Яғни, қайтсе де сіз өз жағдайыңызды өзгерте алмайсыз. Мұндай жағдайларда қаржылық резервтің, яғни сақтап қойған ақшаңыздың болғаны абзал. Ол ақшаның көмегімен сіз қарыздарыңызды көбейтпестен тығырықтан шығасыз.</p>\n" +
                                    "</div>\n" +
                                    "<p>Артық ақшаңыз болса, оны арнайы депозит шотына салып қойыңыз. Бұл ақшаны күтпеген жағдайларда қолдана аласыз. Оған қоса, банктен депозитіңізде ақша сақтағаныңыз үшін ай сайын сыйақы түсіп тұрады. Сыйақы көмегімен депозиттегі ақшаңыз артады.</p>\n" +
                                    "<ul>\n" +
                                    "<li>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-7.png')+"'/>\n" +
                                    "<p>Қауіпсіздік жастығы — бұл сіздің шығындарыңыздың кем дегенде үш еселенген мөлшеріндегі ақша.</p>\n" +
                                    "</li>\n" +
                                    "</ul>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/kz/img-8.png')+"'/>\n" +
                                    "<p>Ең алдымен, жақындарыңыздан ақшаны қарызға алып көріңіз. Бұл жағдайда қарыздың пайызы немесе ай сайынғы төлемдер болмайды. Алайда араларыңыз суып кетпес үшін қарыз мөлшерін және оны қайтаратын мерзімді міндетті түрде алдын ала шешіп алу керек.</p>\n" +
                                    "<p>Достарыңыз бен туыстарыңыздан қарыз ала алмасаңыз, банкке барыңыз. Банк қызметкері сізге несиені қайта құрылымдауды немесе оны қайта қаржыландыруды ұсынады.</p>\n" +
                                    "<ul>\n" +
                                    "<li>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-9.png')+"'/>\n" +
                                    "<p>Қайта құрылымдау — банкте қолданыстағы несиенің шарттарын өзгерту. Бұл шара қазіргі уақытта және жақын болашақта қарызын қайтара алмайтындарға арналған. Әдетте, қарыз алушылар дефолтқа ұшырайды.</p>\n" +
                                    "</li>\n" +
                                    "</ul>\n" +
                                    "<p>Қайта құрылымдау мысалы:</p>\n" +
                                    "<div>\n" +
                                    "<p>Сіз ай сайын 150 000 теңге төлеп жүрдіңіз. Несиеңізді әлі екі жыл бойы өтейсіз. Сіздің ақша жағынан жағдайыңыз өзгерді, енді ай сайынғы төлем жасауға қаражатыңыз жеткіліксіз. Несиені қайта құрылымдауды сұрату үшін банкке хабарласуыңыз керек. Банк несие мерзімін ұзарту арқылы ай сайынғы төлеміңізді азайтады. Тиімді шешім деп айта алмаймыз, бірақ жағдайыңызды кішкене болса да түзетіп аласыз.</p>\n" +
                                    "</div>\n" +
                                    "<p>Несиені қайта құрылымдау үшін сіз қарызыңызды өтей алмайтыныңызды растауыңыз керек. Ол үшін банк бөлімшесіне келіп, қайта құрылымдау туралы өтініш жазып, соңғы 6 айдағы табысыңызды растайтын анықтама ұсыну қажет. Кейде медициналық қорытынды немесе сот қорытындысы жарайды. Мұның бәрі несиені бастапқы сомада өтей алмауына әкелген факторларға байланысты. Одан кейін не істеу керектігін банк қызметкері айтады.</p>\n" +
                                    "<ul>\n" +
                                    "<li>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-10.png')+"'/>\n" +
                                    "<p>Қайта қаржыландыру — бұл әдетте қарыз алушы үшін тиімді шарттар негізіндегі, қолданыстағы несиелерді жабу үшін алынатын жаңа несие.</p>\n" +
                                    "</li>\n" +
                                    "</ul>\n" +
                                    "<p>Қайта қаржыландыру мысалы:</p>\n" +
                                    "<div>\n" +
                                    "<p>Түрлі себептермен қаржылық жағдайыңызға қатты әсер ететін бірнеше несиеңіз жиналып қалды. Ол несиелердің шарттары да әртүрлі (пайыздық мөлшерлемелер, ай сайынғы төлемдері, мерзімдері). Қайта қаржыландыру кезінде сіз бұрынғы несиелеріңіздің мөлшерінде немесе одан да көп сомаға жаңа несие аласыз. Бұрынғы несиелеріңіз біріктіріледі де, бірдей шарттармен қарастырылады. Ол шарттар әдетте бұрынғыға қарағанда тиімді болады.</p>\n" +
                                    "</div>\n" +
                                    "<h3>1-несие</h3>\n" +
                                    "<p>Негізгі қарызы 250 000 теңгені, ай сайынғы артық төлемі 12 мың теңгені құрайды. Төлейтін 6 ай қалды.</p>\n" +
                                    "<h3>2-несие</h3>\n" +
                                    "<p>Негізгі қарызы 80 000 теңгені, ай сайынғы артық төлемі 6 мың теңгені құрайды. Төлейтін 4 ай қалды.</p>\n" +
                                    "<h3>3-несие</h3>\n" +
                                    "<p>Негізгі қарызы 450 000 теңгені, ай сайынғы артық төлемі 8500 теңгені құрайды. Төлейтін 13 ай қалды.</p>\n" +
                                    "<div>\n" +
                                    "<p>Сіз несиелеріңізді жаңа банкте қайта қаржыландырдыңыз. Енді бөлек үш несиенің орнында сіздің 12 ай мерзімге алынған, жалпы қарызы 780 000 теңгені құрайтын, ай сайынғы артық төлемі 14 000 теңге тең бір несиеңіз бар. Бұрынғы үш несиеге қарағанда банк сізге тиімдірек шарттар ұсынды. Үш несие бойынша жалпы артық төлем, егер мерзімінен бұрын өтемесеңіз, 206 500 теңге, ал жаңа несие бойынша 168 000 теңгені құрайды. Қарыз сомасы сол күйінде қалды, ал мерзімі болса бір айға қысқарды.</p>\n" +
                                    "</div>\n" +
                                    "<p>Басқа жол - банкрот болу, бірақ біз оны проблемаларыңыздың шешімі ретінде ұсынбаймыз. Өзіңіз қарап көріңіз:</p>\n" +
                                    "<ul>\n" +
                                    "<li>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-11.png')+"'/>\n" +
                                    "<p>Өтінішті тек банктерден алған қарыздарыңызды өтей алмайтындай жағдайда болсаңыз тапсыра аласыз;</p>\n" +
                                    "</li>\n" +
                                    "</ul>\n" +
                                    "<ul>\n" +
                                    "<li>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-12.png')+"'/>\n" +
                                    "<p>Борышкер ешбір мүлікке иелік етпейді;</p>\n" +
                                    "</li>\n" +
                                    "</ul>\n" +
                                    "<ul>\n" +
                                    "<li>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-13.png')+"'/>\n" +
                                    "<p>Қолданыстағы несиенің төлемдері соңғы 12 ай бойы өтелген жоқ.</p>\n" +
                                    "</li>\n" +
                                    "</ul>\n" +
                                    "<p>12 ай ішінде сіз өзіңіздің қаржылық жағдайыңызды жақсарта аласыз. Осы сабақты оқып жатсаңыз, сіз дұрыс жолмен келе жатырсыз. Банкроттықтан кейін тағы бес жыл бойы сіз жаңа несие алуға өтініш бере алмайсыз. Сәйкесінше сіз маңызды және пайдалы несие ұсыныстарын, мысалы, 7-20-25 бағдарламасы бойынша тұрғын үй несиесін немесе бизнес несиелерін жіберіп алуыңыз мүмкін.</p>\n" +
                                    "<ul>\n" +
                                    "<li>\n" +
                                    "<img src='"+require('@/assets/images/visuals/course-2/lesson-3/img-14.png')+"'/>\n" +
                                    "<p>Жағдай жақсарған кезде борышкер қарызды өтеуі керек екенін есте сақтаңыз. Мысалы, егер мүлкіңіз пайда болса немесе кіріс көзін тапсаңыз, сіз олардың көмегімен ақшаны банкке қайтаруға міндеттісіз.</p>\n" +
                                    "</li>\n" +
                                    "</ul>\n" +
                                    "<p>Келесі маңызды қадамыңыз — тез жұмыс тауып, төлем жасауға қабілетті болу үшін жұмыс немесе тағылымдамаларды іздестіру. Қазіргі заманда мүмкіндік көп, бастысы - еңсеңізді түсірмей, қиындықтарға берілмеу және соңы жақсылыққа әкелмейтін істерден аулақ болу. Егер өзіңіз жұмыс таба алмасаңыз, көмек алу үшін сіз <a href='https://egov.kz/cms/kk' target='_blank'>eGov</a> порталы арқылы немесе <a href='https://www.enbek.kz/ru' target='_blank'>электронды еңбек биржасының сайтында</a> тіркеле аласыз.</p>\n" +
                                    "<div>\n" +
                                    "<p>Бір күн ішінде жергілікті Жұмыспен қамту орталығы сізге сәйкес келетін бос жұмыс орындар тізімін ұсынады. Егер сонда да жұмыс таба алмасаңыз, сізге жұмыссыз мәртебесі беріледі және жәрдемақы тағайындалады.</p>\n" +
                                    "</div>\n"
                            },
                            dictionary: [
                                {
                                    id: 1,
                                    name: 'Несиені толық мерзімінен бұрын өтеу',
                                    description: 'банктен алған несие бойынша қарызды мерзімінен толығымен бұрын өтеу.'
                                },
                                {
                                    id: 2,
                                    name: 'Несиені жартылай мерзімінен бұрын өтеу',
                                    description: 'банктен алған несие бойынша қарыздың бір бөлігін мерзімінен бұрын өтеу.'
                                },
                                {
                                    id: 3,
                                    name: 'Несие мерзімін өткізіп алу',
                                    description: 'міндеттемелерді орындау мерзімдерін бұзу. Төлемді кешіктіргені үшін борышкерге өсімпұл салынады.'
                                },
                                {
                                    id: 4,
                                    name: 'Қайта қаржыландыру',
                                    description: 'бұл бұрыннан бар несиелерді өтеу үшін, әдетте қарыз алушы үшін қолайлы шарттармен берілетін жаңа несие.'
                                },
                                {
                                    id: 5,
                                    name: 'Қайта құрылымдау',
                                    description: 'бұл қолданыстағы несиенің шарттарын өзгерту. Әдетте мерзімін және/немесе ай сайынғы төлемді қысқарту мақсатында жасалады. Қиын жағдайға тап болғандарға арналған.'
                                },
                            ],
                            tests: [
                                {
                                    id: 1,
                                    question: 'Сіздің пайыздық мөлшерлемесі 40%-дан асатын үш несиеңіз бар. Қайтесіз?',
                                    answers: [
                                        {
                                            id: 1,
                                            answer: 'Қайта қаржыландырамын және оларды біріктіремін.',
                                            result: 'Дұрыс, сіз үш несиені біріктіресіз, сонымен қатар мөлшерлемені төмендете аласыз. Бұл тиімді шешім, өйткені ай сайынғы төлеміңіз аз болады.',
                                            correct: true,
                                        },
                                        {
                                            id: 2,
                                            answer: 'Жаңадан бір үлкен несие алып, кем дегенде екеуін жабамын',
                                            result: 'Алатын жаңа несиеңіз тұтынушылық несие болуы мүмкін. Бұл ол несиенің пайыздық мөлшерлемесі де жоғары болатынын білдіреді. Сіз керісінше қарызыңызды көбейтесіз.',
                                            correct: false,
                                        },
                                        {
                                            id: 3,
                                            answer: 'Әкімдікке шағымданамын',
                                            result: 'Банктен қарызға ақша алар кезде сіз банктің барлық шарттарымен келісесіз. Әкімдік банкке хабарласуды ұсынады.',
                                            correct: false,
                                        },
                                        {
                                            id: 4,
                                            answer: 'Несиесі жоқ адам жоқ! Әйтеуір бір амалын тауып төлеймін',
                                            result: 'Егер сіз барлық үш несие бойынша артық төлемді есептесеңіз, несиені пайдаланғаныңыз үшін көп ақша төлейтініңізді түсінесіз. Қайта қаржыландыру немесе қайта құрылымдау арқылы несиені мерзімінен бұрын өтеуге немесе несие шарттарын өзгертуге тырысыңыз.',
                                            correct: false,
                                        }
                                    ],
                                },
                                {
                                    id: 2,
                                    question: 'Несиенің қай түрін мерзімінен бұрын өтеуге болады?',
                                    answers: [
                                        {
                                            id: 1,
                                            answer: 'Банк рұқсат берген несиені',
                                            result: 'Банктің несиелері бойынша әр түрлі шарттары болуы мүмкін, бірақ заң бойынша кез келген несиені мерзімінен бұрын жартылай немесе толық өтеуге болады. Әдетте, мерзімінен бұрын толықтай немесе жартылай өтеу туралы шарттар тұтынушылық несиелерге емес, бизнес несиелері үшін белгіленеді.',
                                            correct: false,
                                        },
                                        {
                                            id: 2,
                                            answer: 'Кем дегенде алты айлық ай сайынғы төлемі төленген несиені',
                                            result: 'Кейбір банктерде несиелердің белгілі бір түрлері үшін мұндай шарттар бары рас, бірақ бұл несиелердің кейбір түрлері үшін ғана. Әдетте тұтынушылық несиелердің мұндай шарттары жоқ.',
                                            correct: false,
                                        },
                                        {
                                            id: 3,
                                            answer: 'Кез келген түрін',
                                            result: 'Дұрыс, заң бойынша кез келген несиені мерзімінен бұрын өтеуге болады. Ал банк бұған араласа алмайды.',
                                            correct: true,
                                        },
                                        {
                                            id: 4,
                                            answer: 'Ипотека, автокөлік несиесі және ақшалай несие',
                                            result: 'Бұның барлығы — тұтынушылық несиелер және олардың түрлері. Бірақ бұл толық тізім емес. Тұтынушылық несиенің кез келген түрін мерзімінен бұрын өтей аласыз (егер келісімде басқаша жазылмаса).',
                                            correct: false,
                                        }
                                    ],
                                },
                                {
                                    id: 3,
                                    question: '1 500 000 теңгеге дейін жететін қарыздарыңыз бар, бірақ несиені өтейтін жағдайыңыз жоқ. Қайтесіз?',
                                    answers: [
                                        {
                                            id: 1,
                                            answer: 'Тағы  2 000 000 теңгеге несие алып, қолымдағы несиелерді төлеймін. Жаңа несиенің бірінші төлемі әдетте келесі айда төленеді, сондықтан біраз уақытым барү Оған қоса  500 мың теңге артық қалады. Жоспарым дайын!',
                                            result: 'Жоспар, өкінішке орай, жүзеге аспайды. Сіз жаңа төлемді бір айға шегерсеңіз де, қарызыңызды одан сайын көбейттіңіз. Бұған қоса, егер сіз қолда бар несиелеріңізді төлей алмасаңыз, банктердің сізге жаңа несие беруі екіталай.',
                                            correct: false,
                                        },
                                        {
                                            id: 2,
                                            answer: 'Мен банкке хабарласып, олардың шарттары мен банк ұсыныстарымен танысамын. Соған қарай несиелерімді қайта құрылымдауды немесе қайта қаржыландыруды сұраймын.',
                                            result: 'Мұндай жағдайға тап болсаңыз, тығырықтан шығуға көмектесетін ең бірінші жол — осы. Сіз несиені қайта құрылымдау арқылы ай сайынғы төлеміңізді немесе қайта қаржыландыру арқылы пайыздық мөлшерлемеңізді азайта аласыз. Бірақ әуелі қолданыстағы несиелеріңізді жақсылап қарастыруыңыз керек.',
                                            correct: true,
                                        },
                                        {
                                            id: 3,
                                            answer: 'Мен банкротпын деп айтамын және несиелерімді төлемей қоямын. Жұрттың бәрі солай істейді.',
                                            result: 'Бұл ең қауіпті нұсқа. Банкрот мәртебесінің де жаман жақтары бар: 5 жылға дейін жаңа несиелерге тыйым салу (шынымен пайдалы ұсыныстарды жіберіп алуыңыз мүмкін), мүлкіңіз арқылы қарыздарды өндіріп алу және т.б. Сондай-ақ сіз 12 ай бойы қарызды өтей алмайтындай болуыңыз керек; бұл уақыт ішінде қаржылық жағдайыңыз өзгеруі мүмкін, сондықтан банкроттыққа қатты сенбеуіңіз керек.',
                                            correct: false,
                                        },
                                        {
                                            id: 4,
                                            answer: 'Мен банктен, коллекторлардан және полициядан жасырынамын. Несие берген солар. Өздері жауап берсін.',
                                            result: 'Міндеттемелер орындалуы керек. Егер қаржылық жағдайыңыз нашарлап кетсе, банкке хабарласудан тартынбаңыз және несиені қалай өтеуге болатынын қарастырыңыз. Сіз сондай-ақ жұмыс орындарына өтініш бере аласыз және интервьюлерден өте аласыз немесе электронды еңбек биржасының сайтында жұмыс іздеу үшін тіркеле аласыз.',
                                            correct: false,
                                        }
                                    ],
                                },
                            ],
                        },
                    ],
                }
            ]
        }
    ],
})

// getters
const getters = {
    getLocaleElement: (state) => {
        return state.all[0][i18n.locale]
    },
    getLocaleCourses: (state, getters) => {
        return getters.getLocaleElement
    },
    getCourseBySlug: (state, getters) => (slug) => {
        const localeElement = getters.getLocaleElement

        return localeElement.find(course => course.slug === slug)
    },
    getCourseById: (state, getters) => (id) => {
        const localeElement = getters.getLocaleElement

        return localeElement.find(course => course.id === id)
    },
    getLessonBySlug: (state, getters) => (courseSlug, lessonSlug) => {
        const course = getters.getCourseBySlug(courseSlug)

        return course.lessons.find(lesson => lesson.slug === lessonSlug)
    },
    getLessonTests: (state, getters) => (courseSlug, lessonSlug) => {
        const lesson = getters.getLessonBySlug(courseSlug, lessonSlug)

        return lesson.tests
    },
    getLesson: (state) => {
        return state.lesson
    }
}

// actions
const actions = {
    getLessonBySlug: ({state, commit}, payload) => {
        commit('GET_LESSON_BY_SLUG', payload)

        return state.lesson
    }
}

// mutations
const mutations = {
    GET_LESSON_BY_SLUG: (state, payload) => {
        const course = state.all[0][payload.locale].find(course => course.slug === payload.courseSlug)
        state.lesson = course.lessons.find(lesson => lesson.slug === payload.lessonSlug)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}