<template>
  <transition name="fade">
      <div class="loader-overlay" v-show="isActive">
          <div class="loader">
              <img src="@/assets/images/logo-white.png" alt="logo">
          </div>
      </div>
  </transition>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "LoaderModal",
    computed: {
        ...mapGetters({
            isActive: 'modalManager/preloader',
        }),
    },
}
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
  }
</style>